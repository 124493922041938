import { schema } from 'normalizr'

export const corporateSchema = new schema.Entity(
  'corporates',
  {},
  { idAttribute: 'corporateNo' }
)

export const corporateListSchema = new schema.Array(corporateSchema)

const corporateCategorySchema = new schema.Entity(
  'corporateCategory',
  { corporateList: corporateListSchema },
  { idAttribute: 'corporateCategoryNo' }
)

export const corporateCategoryListSchema = new schema.Array(
  corporateCategorySchema
)
