import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const Portal = ({
  children,
  shouldRender = true,
  className,
  element = 'div'
}) => {
  const isSSR = typeof window === 'undefined'
  const [container] = useState(() => {
    if (!isSSR) {
      const el = document.createElement(element)
      className && el.classList.add(className)
      return el
    }
    return null
  })

  useEffect(() => {
    if (shouldRender) {
      document.body.appendChild(container)
      return () => {
        document.body.removeChild(container)
      }
    }
  }, [shouldRender])

  return isSSR ? null : createPortal(children, container)
}
