import * as R from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'

import AspectBox from '../../components/AspectBox'
import Button from '../../containers/Button'
import SvgIcon from '../../components/SvgIcon'
import { withTranslation } from '../../i18n'
import CopyLinkSvg from '../../public/static/images/icon-share-copylink.svg'
import FacebookSvg from '../../public/static/images/icon-share-facebook.svg'
import WhatsappSvg from '../../public/static/images/icon-share-whatsapp.svg'
import { getColor, getFontSize } from '../../utils/cssUtils'
import mediaQuery from '../../utils/mediaQuery'
import { Col, Row } from '../Layout/FlexGrid'
import { loadingAnimation } from './BackgroundEffect'

const DonorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: ${getFontSize('body2')};
  font-weight: 500;
  line-height: 1.5;
  color: ${getColor('grey')};
`

const DonorAvatar = styled.div`
  width: 44px;
  height: 44px;
  margin-right: 8px;
  background: ${getColor('skeleton')};
  border-radius: 50%;
`

const DonorName = styled.div`
  width: 94px;
  height: 16px;
  ${loadingAnimation}
`

const Thanks = styled.div`
  width: 224px;
  height: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
  ${loadingAnimation}
`
const InfoBlock = styled.div`
  width: 50%;
  float: left;
  padding: 0 16px;
  > div:last-child {
    margin-bottom: 0;
  }
  ${mediaQuery.mobile} {
    width: 100%;
    order: 1;
  }
`
const Block = styled.div`
  margin: 0 -16px;
  &::after {
    display: block;
    content: '';
    clear: both;
  }
  ${mediaQuery.mobile} {
    display: flex;
    flex-flow: row wrap;
  }
`
const ProjectMainImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${getColor('skeleton')};
`
const ProjectTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 12px;
  width: 100%;
  height: 22px;
  ${loadingAnimation}
`
const Info = styled.div`
  padding: 0 16px;
  font-size: ${getFontSize('body1')};
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: ${getColor('grey')};
  line-height: 1.43;
  &.margin {
    margin-bottom: 0px;
  }
`
const InfoLabel = styled.div`
  width: 80px;
  height: 14px;
  ${loadingAnimation}
`
const InfoValue = styled.div`
  width: 80px;
  height: 14px;
  ${loadingAnimation}
`
const ShareLabel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 16px;
  margin-bottom: 8px;
  width: 100%;
  line-height: 1;
  > hr {
    width: 100%;
    border: 0;
    height: 1px;
    background: ${getColor('border')};
  }
  > label {
    white-space: nowrap;
    margin: 0 23px;
    font-weight: 400;
    font-size: ${getFontSize('body1')};
    opacity: 0.5;
    color: ${getColor('grey')};
  }
  ${mediaQuery.mobile} {
    display: none;
  }
`

const MsgBlock = styled.div`
  width: 100%;
  float: left;
  padding: 0 16px;
  ${mediaQuery.mobile} {
    order: 2;
  }
`
const OrgMessageWrapper = styled.div`
  ${mediaQuery.mobile} {
    margin-bottom: 24px;
  }
`
const OrgMessageTitle = styled.div`
  width: 224px;
  height: 14px;
  ${loadingAnimation}
`
const OrgMessage = styled.div`
  margin-top: 8px;
  width: 100%;
  height: 136px;
  background-color: ${getColor('skeleton')};
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: ${getColor('border')};
`

const FormBlock = styled.div`
  width: 50%;
  float: right;
  padding: 0 16px;
  ${mediaQuery.mobile} {
    width: 100%;
    padding: 0 48px;
    order: 4;
  }
`
const ReceiptMessage = styled.div`
  width: 70%;
  height: 14px;
  &:first-child {
    width: 100%;
    margin-bottom: 10px;
  }
  ${({ center }) =>
    center &&
    css`
      width: 70%;
      margin: 0 auto;
    `}
  ${loadingAnimation}
`

const InputLabel = styled.div`
  width: 30%;
  height: 14px;
  margin-bottom: 10px;
  ${loadingAnimation}
`
const Input = styled.div`
  width: 100%;
  height: 36px;
  border: 1px solid ${getColor('border')};
  border-radius: 4px;
`
const InputReminder = styled.div`
  width: 100%;
  height: 14px;
  margin-top: 10px;
  &:last-child {
    width: 50%;
  }
  ${loadingAnimation}
`

const ShareWrapper = styled.div`
  float: left;
  width: 50%;
  padding: 0 16px;
  ${mediaQuery.mobile} {
    width: 100%;
    order: 3;
  }
`

const SocialBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

const SocialBtn = styled(({ ...props }) => (
  <Button
    bgColor={getColor('lightYellow')}
    textColor={getColor('theme')}
    {...props}
  />
))`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 0;
  margin: 12px;
  pointer-events: none;
`

const MobileDivider = styled(Divider)`
  display: none;
  ${mediaQuery.mobile} {
    display: block;
  }
`

const MyDonationDetailSkeleton = ({ t }) => {
  const renderInfo = () => (
    <InfoBlock>
      <AspectBox>
        <ProjectMainImg />
      </AspectBox>
      <ProjectTitle />
      {R.times(
        index => (
          <Info key={index}>
            <InfoLabel />
            <InfoValue />
          </Info>
        ),
        4
      )}
    </InfoBlock>
  )

  const renderMsg = () => (
    <MsgBlock>
      <Divider />
      <OrgMessageWrapper>
        <OrgMessageTitle />
        <OrgMessage />
      </OrgMessageWrapper>
    </MsgBlock>
  )

  const renderForm = () => (
    <FormBlock>
      <ReceiptMessage />
      <ReceiptMessage />
      <Row gutter={[0, 24]}>
        <Col>
          <InputLabel />
          <Input disabled />
        </Col>
        <Col>
          <InputLabel />
          <Input disabled />
          <InputReminder />
          <InputReminder />
        </Col>
        <Col>
          <InputLabel />
          <Input disabled />
        </Col>
      </Row>
    </FormBlock>
  )

  const renderSharing = () => (
    <ShareWrapper>
      <ShareLabel>
        <hr />
        <label>{t('thankyou.share')}</label>
        <hr />
      </ShareLabel>
      <SocialBtns>
        <SocialBtn data-sharer='whatsapp'>
          <SvgIcon svg={<WhatsappSvg />} />
        </SocialBtn>
        <SocialBtn data-sharer='facebook'>
          <SvgIcon svg={<FacebookSvg />} />
        </SocialBtn>
        <SocialBtn>
          <SvgIcon svg={<CopyLinkSvg />} />
        </SocialBtn>
      </SocialBtns>
      <ReceiptMessage center />
      <MobileDivider />
    </ShareWrapper>
  )

  return (
    <>
      <DonorWrapper>
        <DonorAvatar />
        <DonorName />
      </DonorWrapper>
      <Thanks />
      <Block>
        {renderInfo()}
        {renderForm()}
        {renderSharing()}
        {renderMsg()}
      </Block>
    </>
  )
}

export default withTranslation('common')(MyDonationDetailSkeleton)
