import Link from 'next/link'
import React, { useCallback } from 'react'

import { slugify } from '../utils/urlUtils'

const OrgLinkWrapper = ({
  organizationNo,
  organizationName,
  onLinkClick,
  className,
  children
}) => {
  const handleClick = useCallback(
    e => {
      e.stopPropagation()

      onLinkClick && onLinkClick(e)
    },
    [onLinkClick]
  )

  return (
    <Link href={`/org/${organizationNo}/${slugify(organizationName)}`} passHref>
      <a
        onClick={handleClick}
        className={className}
        css={`
          max-width: 100%;
          pointer-events: auto;
        `}
      >
        {children}
      </a>
    </Link>
  )
}

export default OrgLinkWrapper
