import Link from 'next/link'
import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { useTranslation } from '../i18n'
import { getColor, getFontSize } from '../utils/cssUtils'
import mq from '../utils/mediaQuery'

const FooterWrapper = styled.footer`
  position: relative;
  height: ${R.path(['theme', 'layout', 'footerHeight'])};
  padding: 24px 50px;
  font-size: ${getFontSize('body1')};
  background-color: #fff;
  box-shadow: 0 -2px 23px 0 rgba(0, 0, 0, 0.1);
`
const FooterContent = styled.div`
  font-weight: 300;
  line-height: 1;
  letter-spacing: 1.8px;
  text-align: right;
  color: ${getColor('grey')};
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  ${mq.mobile} {
    font-size: ${getFontSize('body1')};
  }
`

const Footer = React.memo(({ className }) => {
  const { t } = useTranslation('common')

  const getCopyRightText = t('footer.cr') + new Date().getFullYear()

  return (
    <FooterWrapper className={className}>
      <FooterContent>
        <a
          target='_blank'
          rel='noreferrer'
          href={`${process.env.HK01_URL}/terms`}
        >
          {t('footer.terms')}
        </a>
        ｜
        <Link href='/about'>
          <a>{t('footer.about')}</a>
        </Link>
        ｜
        <a target='_blank' rel='noreferrer' href={process.env.HK01_FAQ_URL}>
          {t('footer.qa')}
        </a>
        ｜{getCopyRightText}
      </FooterContent>
    </FooterWrapper>
  )
})

export default Footer
