import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { withTranslation } from '../../i18n'
import { getColor } from '../../utils/cssUtils'
import mediaQuery from '../../utils/mediaQuery'
import { Col, Row } from '../Layout/FlexGrid'
import MaxWidth from '../Layout/MaxWidth'
import SectionTitle from '../Text/SectionTitle'
import OrganizationInfo from './OrganizationInfo'
import ProjectCard from './ProjectCardSkeleton'

const ActionBar = styled(Row)`
  border-top: 1px solid ${getColor('border')};
  padding: 23px 0px 4px 0px;
  ${mediaQuery.mobile} {
    padding: 16px 0px 4px 0px;
  }
`

const Organization = ({ t }) => (
  <>
    <OrganizationInfo />
    <MaxWidth>
      <ActionBar>
        <Col xs={24} md={8}>
          <SectionTitle>{t('organization.projects')}</SectionTitle>
        </Col>
      </ActionBar>
      <Row
        gutter={[24, 24]}
        css={`
          margin-bottom: 24px;
        `}
      >
        {R.times(
          index => (
            <Col xs={24} md={12} xl={8} className='projectWrapper' key={index}>
              <ProjectCard />
            </Col>
          ),
          6
        )}
      </Row>
    </MaxWidth>
  </>
)

export default withTranslation('common')(Organization)
