import styled, { css } from 'styled-components'

import { getColor, getFontSize } from '../../../utils/cssUtils'

const FieldComponent = styled.div`
  width: 100%;
  position: relative;
`
const InputWrapper = styled.div.attrs({ className: 'inputwrapper' })`
  display: flex;
  width: 100%;
  border-radius: 4px;
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 0 1px ${getColor('lightGrey')};
  &:focus-within {
    box-shadow: 0 0 0 1px ${getColor('grey')};
  }
  ${({ hasError }) =>
    hasError &&
    css`
      box-shadow: 0 0 0 1px ${getColor('error')};
    `}
`
const OutsideLabel = styled.label`
  color: ${getColor('black')};
  font-size: ${getFontSize('body2')};
  display: inline-flex;
  margin-bottom: 8px;
`
const InsideLabel = styled.label`
  position: absolute;
  color: ${getColor('lightGrey')};
  font-size: ${getFontSize('body2')};
  line-height: 1;
  top: 17px;
  left: 8px;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
  transform-origin: left center;
  ${({ isFieldEmpty }) =>
    !isFieldEmpty &&
    css`
      color: ${getColor('lightGrey')};
      transform: translateY(-13px) scale(0.625);
      & ~ ${Input} {
        padding-top: 20px;
      }
    `}
  ${InputWrapper}:focus-within & {
    color: ${getColor('lightGrey')};
    transform: translateY(-13px) scale(0.625);
  }
`
const HelpText = styled.span`
  display: flex;
  margin-top: 4px;
  font-size: ${getFontSize('body1')};
  color: ${getColor('grey')};
`
const ErrorLabel = styled.span`
  display: flex;
  color: ${getColor('error')};
  font-size: ${getFontSize('body1')};
  position: absolute;
  top: 100%;
`
const Input = styled.input.attrs({
  autoComplete: 'off',
  className: 'inputwrapper__input'
})`
  appearance: none;
  display: inline-flex;
  width: 100%;
  height: 50px;
  border: none;
  background-color: ${getColor('input', 0.04)};
  padding: 8px 8px 8px 8px;
  font-size: ${getFontSize('body2')};
  color: ${getColor('black')};
  resize: none;
  outline: none;
  transition: padding 0.3s ease-in-out;
  transform-origin: left center;
  &::placeholder {
    color: ${getColor('lightGrey')};
    ${({ hasInsideLabel }) =>
      hasInsideLabel &&
      css`
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      `}
  }
  &:focus {
    ${({ hasInsideLabel }) =>
      hasInsideLabel &&
      css`
        padding-top: 20px;
        &::placeholder {
          opacity: 1;
          transition-delay: 0.1s;
        }
      `}
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${getColor('lightGrey', 0.2)};
      &::placeholder {
        color: ${getColor('lightGrey')};
      }
      & ~ ${InsideLabel} {
        color: ${getColor('lightGrey')};
      }
    `}
`
export {
  FieldComponent,
  InputWrapper,
  OutsideLabel,
  InsideLabel,
  HelpText,
  ErrorLabel,
  Input
}
