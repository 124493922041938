import Link from 'next/link'
import * as R from 'ramda'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Col, Row } from '../components/Layout/FlexGrid'
import MaxWidth from '../components/Layout/MaxWidth'
import NextSeo from '../components/NextSeo'
import {
  HomeCatIconsSkeleton,
  ProjectCardSkeleton,
  PromotionItemSkeleton
} from '../components/Skeleton/index'
import SectionTitle from '../components/Text/SectionTitle'
import { AppviewNavigation } from '../containers/Appview'
import { CtaBtn } from '../containers/Button'
import HomeCatIcons from '../containers/CategoryIcon/HomeCatIcons'
import PersonalFundraisingHomeSlider from '../containers/PersonalFundraising/HomeSlider'
import ProjectCard from '../containers/ProjectCard'
import PromoBanner from '../containers/PromoBanner'
import PromoListing from '../containers/PromoListing'
import { useTracking } from '../hooks'
import { withTranslation } from '../i18n'
import { generateShareMsg, getPromoItemRequest } from '../redux/app/actions'
import {
  getDonationStatsRequest,
  getHomePageRequest
} from '../redux/project/actions'
import {
  getHomeHighlightedProjectsNos,
  getRandomProjectsNos
} from '../redux/project/selectors'
import { getColor } from '../utils/cssUtils'
import { homeClickDiscoverBtn, homeView } from '../utils/dataTracker'
import mq from '../utils/mediaQuery'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled.section`
  padding: 40px 0px;
  ${mq.mobile} {
    padding: 24px 0;
  }
`
const PromoListingWrapper = styled(Section)`
  background-color: rgba(181, 181, 181, 0.2);
  &:empty {
    padding: 0;
  }
`
const Highlighted = styled(Section)`
  background: ${getColor('lightYellow')};
  box-shadow: inset 0 0 23px 0 rgba(0, 0, 0, 0.1);
`
const Other = styled(Section)``
const BtnWrapper = styled(MaxWidth)`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  ${mq.mobile} {
    margin-top: 24px;
  }
`

const Index = withTranslation('common')(({ t }) => {
  const dispatch = useDispatch()

  const track = useTracking()

  const isInitialRender = useSelector(R.path(['appState', 'isInitialRender']))

  const highlightedProjectsNos = useSelector(
    getHomeHighlightedProjectsNos,
    R.equals
  )

  const randomProjectsNos = useSelector(getRandomProjectsNos, R.equals)

  const promoWidget1ProjectsNos = useSelector(
    R.path(['projectState', 'result', 'promoWidget1']),
    R.equals
  )

  const promoWidget2ProjectsNos = useSelector(
    R.path(['projectState', 'result', 'promoWidget2']),
    R.equals
  )

  const isFetching = useSelector(
    R.path(['projectState', 'fetching', 'getHomepageData'])
  )

  const trackHomeClickDiscoverBtn = () => {
    track(homeClickDiscoverBtn())
  }

  const renderProjectCards = (projectNos, position) =>
    isFetching
      ? R.times(
          index => (
            <Col xs={24} md={12} xl={8} key={index}>
              <ProjectCardSkeleton />
            </Col>
          ),
          R.length(projectNos)
        )
      : projectNos.map((projectNo, i) => (
          <Col xs={24} md={12} xl={8} key={projectNo}>
            <ProjectCard
              projectNo={projectNo}
              projectType={position}
              index={i}
            />
          </Col>
        ))

  const renderHighlightedProjects = () => (
    <Highlighted>
      <MaxWidth>
        <SectionTitle>{t('highlightedProject.title')}</SectionTitle>
        <Row gutter={{ xl: 24, md: 24, xs: 16 }}>
          {renderProjectCards(highlightedProjectsNos, 'featured')}
        </Row>
      </MaxWidth>
    </Highlighted>
  )

  const renderOtherProjects = () => (
    <Other>
      <MaxWidth>
        <Row gutter={{ xl: 24, md: 24, xs: 16 }}>
          {renderProjectCards(randomProjectsNos, 'normal')}
        </Row>
      </MaxWidth>
      <BtnWrapper>
        <Link as='/discover' href='/discover'>
          <a>
            <CtaBtn onClick={trackHomeClickDiscoverBtn} height={48}>
              {t('index.loadListing')}
            </CtaBtn>
          </a>
        </Link>
      </BtnWrapper>
    </Other>
  )

  useEffect(() => {
    // get donation stats in componentDidMount only when SSR
    if (isInitialRender) {
      const projectNos = R.reject(
        R.isNil,
        R.flatten([
          highlightedProjectsNos,
          randomProjectsNos,
          promoWidget1ProjectsNos,
          promoWidget2ProjectsNos
        ])
      )
      if (!R.isEmpty(projectNos)) {
        dispatch(getDonationStatsRequest(projectNos))
      }
    }
    dispatch(generateShareMsg(t('shareMsg.home')))

    track(homeView(), true)
  }, [])

  if (R.isEmpty(highlightedProjectsNos) || R.isEmpty(randomProjectsNos)) {
    return null
  }

  return (
    <Wrapper>
      <NextSeo
        title={t('seo.homePage.ogTitle')}
        description={t('seo.homePage.ogDescription')}
      />
      {isFetching ? <PromotionItemSkeleton /> : <PromoBanner />}
      {isFetching ? <HomeCatIconsSkeleton /> : <HomeCatIcons />}
      <PromoListingWrapper>
        <PromoListing widgetKey={1} />
        <PromoListing widgetKey={2} />
      </PromoListingWrapper>
      {renderHighlightedProjects()}
      <PersonalFundraisingHomeSlider />
      {renderOtherProjects()}
    </Wrapper>
  )
})

export default Index

Index.renderPersistentLayout = () => {
  return {
    appviewNavigation: () => <AppviewNavigation />
  }
}

Index.getInitialProps = async ({ req, query, store }) => {
  store.dispatch(getPromoItemRequest())

  store.dispatch(getHomePageRequest())

  return {}
}
