import { handleActions } from 'redux-actions'

import { getPromoWidgetSucceeded } from '../project/actions'
import {
  checkWebviewSucceeded,
  dismissFeedback,
  generateShareMsg,
  getFrontendSettingSucceeded,
  getPromoItemSucceeded,
  hideHeader,
  setFeedbackText,
  setIsAppview,
  setIsInitialRender,
  setShouldBackHome,
  setShowAppviewSearchOverlay,
  setShowErrorPage,
  toggleBindPhoneScreen,
  toggleDonationItemDrawer,
  toggleLoadingBackBtn,
  updateBindPhoneCounter
} from './actions'

const defaultBindPhoneCounter = process.env.BIND_PHONE_COUNTER

export const appInitialState = {
  isWebview: false,
  isAppview: false,
  isInitialRender: false,
  shouldBackHome: true,
  showBindPhoneScreen: false,
  showLoadingBackBtn: false,
  bindPhoneCounter: defaultBindPhoneCounter,
  frontendSetting: null,
  promoItems: [],
  feedback: '',
  feedbackType: null,
  feedbackTs: new Date().getTime(),
  showDonationItemDrawer: false,
  isSubmitReceipt: false,
  isResendReceipt: false,
  isHeaderHidden: false,
  shareMsg: '',
  showAppviewSearchOverlay: false,
  showErrorPage: false,
  errorCode: null,
  promoWidget1TotalDonation: null,
  promoWidget2TotalDonation: null
}

const appReducer = handleActions(
  {
    [setIsAppview]: (state, action) => ({
      ...state,
      isAppview: action.payload.isAppview
    }),
    [setIsInitialRender]: (state, action) => ({
      ...state,
      isInitialRender: action.payload.isInitialRender
    }),
    [setShouldBackHome]: (state, action) => ({
      ...state,
      shouldBackHome: action.payload.shouldBackHome
    }),
    [checkWebviewSucceeded]: (state, action) => ({
      ...state,
      isWebview: action.payload.isWebview
    }),
    [toggleBindPhoneScreen]: (state, action) => ({
      ...state,
      showBindPhoneScreen: action.payload.isShown
    }),
    [toggleLoadingBackBtn]: (state, action) => ({
      ...state,
      showLoadingBackBtn: action.payload.isShown
    }),
    [updateBindPhoneCounter]: (state, action) => ({
      ...state,
      bindPhoneCounter: action.payload.count
    }),
    [getFrontendSettingSucceeded]: (state, action) => ({
      ...state,
      frontendSetting: action.payload.frontendSetting
    }),
    [getPromoItemSucceeded]: (state, action) => ({
      ...state,
      promoItems: action.payload.promoItems
    }),
    [getPromoWidgetSucceeded]: (state, action) => {
      const { key, totalDonation } = action.payload

      return {
        ...state,
        [`promoWidget${key}TotalDonation`]: totalDonation
      }
    },
    [setFeedbackText]: (state, action) => ({
      ...state,
      feedback: action.payload.text,
      feedbackType: action.payload.type,
      feedbackTs: new Date().getTime()
    }),
    [dismissFeedback]: state => ({
      ...state,
      feedback: '',
      feedbackType: null
    }),
    [toggleDonationItemDrawer]: state => ({
      ...state,
      showDonationItemDrawer: !state.showDonationItemDrawer
    }),
    [hideHeader]: (state, action) => ({
      ...state,
      isHeaderHidden: action.payload.isHeaderHidden
    }),
    [generateShareMsg]: (state, action) => ({
      ...state,
      shareMsg: action.payload.shareMsg
    }),
    [setShowAppviewSearchOverlay]: (state, action) => ({
      ...state,
      showAppviewSearchOverlay: action.payload.show
    }),
    [setShowErrorPage]: (state, action) => ({
      ...state,
      showErrorPage: action.payload.show,
      errorCode: action.payload.errorCode
    })
  },
  appInitialState
)

export default appReducer
