import * as R from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { getColor } from '../../utils/cssUtils'

const Wrapper = styled.div`
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(196, 205, 213, 0.5);
  position: relative;
  overflow: hidden;
  transition: all 0.3s linear;
  ${({ showHoverEffect }) =>
    showHoverEffect &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 4px;
        border: 1px solid transparent;
        transition: all 0.3s linear;
        pointer-events: none;
      }
      ${({ isAppview }) =>
        !isAppview &&
        css`
          &:hover {
            box-shadow: 0 0 16px 0 rgba(227, 39, 60, 0.2);
            cursor: pointer;
          }
          &:hover:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 4px;
            border: 1px solid ${getColor('pink')};
          }
        `}
    `}
`

const CardWrapper = ({ showHoverEffect, children, className, ...props }) => {
  const isAppview = useSelector(R.path(['appState', 'isAppview']))
  return (
    <Wrapper
      isAppview={isAppview}
      showHoverEffect={showHoverEffect}
      className={className}
      data-test-id='card-wrapper'
      {...props}
    >
      {children}
    </Wrapper>
  )
}

export default CardWrapper
