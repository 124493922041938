import * as R from 'ramda'
import { createSelector } from 'reselect'

export const getFirstName = ({ authState }) =>
  authState.hk01MemberProfile.firstName
const getLastName = ({ authState }) => authState.hk01MemberProfile.lastName

const getMemberProfileEntitySelector = ({ authState }) =>
  authState.memberProfile

export const getFullName = createSelector(
  getFirstName,
  getLastName,
  (firstName, lastName) =>
    R.trim(R.defaultTo('')(firstName) + ' ' + R.defaultTo('')(lastName))
)

export const getMemberProfileSelector = createSelector(
  getMemberProfileEntitySelector,
  memberProfileEntity => ({
    accountId: memberProfileEntity?.accountId,
    receiptName: memberProfileEntity?.receiptName,
    email: memberProfileEntity?.email,
    avatarUrl: memberProfileEntity?.avatarUrl,
    updatedTime: memberProfileEntity?.updatedTime,
    agreeToAutoFill: memberProfileEntity?.agreeToAutoFill ?? false
  })
)

export const getHk01MemberProfile = ({ authState }) =>
  authState.hk01MemberProfile
