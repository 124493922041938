import { fork, all, takeEvery } from 'redux-saga/effects'

import {
  fireGtmEvent,
  firePageView,
  fireTracking,
  trackShareClick
} from '../actions'
import {
  fireGtmEventWorker,
  firePageViewWorker,
  fireTrackingWorker,
  trackShareClickWorker,
  trackInstantSearchWorker
} from './trackingSaga'

export function* trackingWatcher() {
  try {
    yield all([
      takeEvery(firePageView, firePageViewWorker),
      takeEvery(fireTracking, fireTrackingWorker),
      takeEvery(fireGtmEvent, fireGtmEventWorker),
      takeEvery(trackShareClick, trackShareClickWorker),
      fork(trackInstantSearchWorker)
    ])
  } catch (err) {
    console.log(err)
  }
}
