import { all, takeLatest } from 'redux-saga/effects'

import {
  checkWebviewRequest,
  dismissWebviewRequest,
  getFrontendSettingRequest,
  getPromoItemRequest,
  goTo01ArticleRequest,
  goTo01PageRequest,
  hideWebviewHeaderRequest,
  initBindPhoneCounter,
  openNativeInAppBrowserRequest,
  showShareDialog
} from '../actions'
import {
  checkWebviewWorker,
  dismissWebviewWorker,
  getFrontendSettingWorker,
  getPromoItemWorker,
  goTo01ArticleWorker,
  goTo01PageWorker,
  hideWebviewHeaderWorker,
  initBindPhoneCounterWorker,
  openNativeInAppBrowserWorker,
  showShareDialogWorker
} from './appSaga'

export function* appWatcher() {
  try {
    yield all([
      takeLatest(checkWebviewRequest, checkWebviewWorker),
      takeLatest(hideWebviewHeaderRequest, hideWebviewHeaderWorker),
      takeLatest(goTo01PageRequest, goTo01PageWorker),
      takeLatest(goTo01ArticleRequest, goTo01ArticleWorker),
      takeLatest(openNativeInAppBrowserRequest, openNativeInAppBrowserWorker),
      takeLatest(dismissWebviewRequest, dismissWebviewWorker),
      takeLatest(showShareDialog, showShareDialogWorker),
      takeLatest(initBindPhoneCounter, initBindPhoneCounterWorker),
      takeLatest(getFrontendSettingRequest, getFrontendSettingWorker),
      takeLatest(getPromoItemRequest, getPromoItemWorker)
    ])
  } catch (err) {
    console.log(err)
  }
}
