import * as R from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'

import mq from '../../utils/mediaQuery'

const genWidth = span => `width:${(100 / 24) * span}%;`
const genTopZeroPadding = R.pipe(
  R.times(i => `&:nth-of-type(${R.inc(i)}){padding-top:0}`),
  R.join('')
)
const genBottomZeroPadding = R.pipe(
  R.times(i => `&:nth-last-of-type(${R.inc(i)}){padding-bottom:0}`),
  R.join('')
)
const countFirstRowCols = spans => {
  let counter = 0
  R.reduceWhile(
    (acc, span) => {
      const cont = Math.round(acc * 10) / 10 - span >= 0
      if (cont) {
        counter += 1
      }
      return cont
    },
    R.subtract,
    24,
    spans
  )
  return counter
}

const countLastRowCols = spans => {
  // create an array of indexes at which the cols should break
  let slicePts = []
  const max = 24
  R.addIndex(R.reduce)(
    (acc, span, i) => {
      if (acc + span > max) {
        acc = span
        slicePts = R.append(i - 1, slicePts)
      } else if (acc + span === max) {
        acc = 0
        slicePts = R.append(i, slicePts)
      } else {
        acc = acc + span
      }
      return acc
    },
    0,
    spans
  )
  slicePts = R.prepend(0, R.map(R.add(1), slicePts)) // add 1 to the indexes since R.slice exludes the toIndex
  // create a map of how the cols should break
  const gridMap = R.reject(
    R.isEmpty,
    R.addIndex(R.map)((fromIndex, index) => {
      const toIndex = slicePts[index + 1] || Infinity
      return R.slice(fromIndex, toIndex, spans)
    }, slicePts)
  )
  // take the last item in the array
  return R.length(R.last(gridMap))
}
const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  ${({ hSpacer }) =>
    hSpacer &&
    R.join('')(
      R.map(
        size => {
          return `
          ${mq[size[1]]} {
            margin: 0 ${R.negate(hSpacer[size[0]])}px;
          }`
        },
        [
          ['xl', 'desktop'],
          ['md', 'tablet'],
          ['xs', 'mobile']
        ]
      )
    )}
`
const FlexCol = styled.div`
  ${({ xl, md, xs }) => {
    if (R.all(R.isNil)([xl, md, xs])) {
      return css`
        ${genWidth(24)}
      `
    }
    return R.map(
      size => {
        return css`
          ${mq[size[1]]} {
            ${genWidth(size[0])}
          }
        `
      },
      [
        [xl, 'desktop'],
        [md, 'tablet'],
        [xs, 'mobile']
      ]
    )
  }}
`
const getFirstNotNil = R.pipe(R.reject(R.isNil), R.head)
const setSpacer = gutter =>
  R.type(gutter) === 'Object'
    ? {
        xl: getFirstNotNil([gutter.xl, gutter.md, gutter.xs, 0]) / 2,
        md: getFirstNotNil([gutter.md, gutter.xs, 0]) / 2,
        xs: getFirstNotNil([gutter.xs, 0]) / 2
      }
    : R.type(gutter) === 'Number'
    ? {
        xl: gutter / 2,
        md: gutter / 2,
        xs: gutter / 2
      }
    : { xl: 0, md: 0, xs: 0 }

export const Row = React.forwardRef(({ gutter, children, ...props }, ref) => {
  let validChildren, hSpacer, vSpacer
  const isNumber = R.is(Number)
  if (R.type(gutter) === 'Object') {
    hSpacer = vSpacer = setSpacer(gutter)
  } else if (R.type(gutter) === 'Array') {
    const spacer = R.map(setSpacer, gutter)
    hSpacer = spacer[0]
    vSpacer = spacer[1]
  } else if (isNumber(gutter)) {
    hSpacer = vSpacer = setSpacer(gutter)
  } else {
    hSpacer = vSpacer = setSpacer(0)
  }
  if (React.isValidElement(children)) {
    validChildren = [children]
  } else if (R.type(children) === 'Array') {
    // remove null or false
    validChildren = R.reject(
      R.either(R.isNil, R.complement(R.identity)),
      children
    )
  } else {
    return null
  }

  let patchedValidChildren = []
  React.Children.forEach(validChildren, children => {
    const {
      props: { xl, md, xs }
    } = children
    patchedValidChildren = R.append(
      React.cloneElement(children, {
        xl: xl || md || xs || 24,
        md: md || xs || 24,
        xs: xs || 24
      }),
      patchedValidChildren
    )
  })
  const getSpans = size => R.map(R.path(['props', size]), patchedValidChildren)

  const genFirstAndLastRowPaddings = () =>
    R.join('')(
      R.map(
        size => {
          const firstRowColsCount = countFirstRowCols(getSpans(size[0]))
          const lastRowColsCount = countLastRowCols(getSpans(size[0]))
          return `
            ${mq[size[1]]} {
              padding: ${vSpacer[size[0]]}px ${hSpacer[size[0]]}px;
              ${genTopZeroPadding(firstRowColsCount)}
              ${genBottomZeroPadding(lastRowColsCount)}
            }
          `
        },
        [
          ['xl', 'desktop'],
          ['md', 'tablet'],
          ['xs', 'mobile']
        ]
      )
    )

  return (
    <FlexRow ref={ref} hSpacer={hSpacer} vSpacer={vSpacer} {...props}>
      {React.Children.map(validChildren, (child, index) => {
        return React.cloneElement(child, {
          css: genFirstAndLastRowPaddings()
        })
      })}
    </FlexRow>
  )
})

export const Col = React.forwardRef(({ xs, md, xl, css, ...props }, ref) => {
  return (
    <FlexCol
      xl={xl || md || xs || 24}
      md={md || xs || 24}
      xs={xs || 24}
      css={css}
      ref={ref}
      {...props}
    />
  )
})
