import * as R from 'ramda'
import React, { useContext } from 'react'
import styled, { ThemeContext, keyframes } from 'styled-components'

import { getColor } from '../../utils/cssUtils'

const progressAnimation = keyframes`
  0% {
      width: 0;
      opacity: 0.1;
  }
  20% {
      width: 0;
      opacity: 0.5;
  }
  100% {
      width: 100%;
      opacity: 0;
  }
`
const Bar = styled.div`
  width: 100%;
  height: ${({ small }) => (small ? '4px' : '8px')};
  border-radius: 4px;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
`
const Progress = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${R.prop('percent')}%;
  height: 100%;
  border-radius: 4px;
  background-color: ${getColor('theme')};
  background-image: linear-gradient(
    90deg,
    ${R.prop('gradientStartColor')},
    ${R.prop('gradientEndColor')}
  );
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    background-color: #fff;
    animation: 2.4s ${progressAnimation} cubic-bezier(0.23, 1, 0.32, 1) infinite;
  }
`

export default ({ small, percent, strokeColor, ...props }) => {
  const themeContext = useContext(ThemeContext)
  const gradientStartColor = R.propOr(
    R.path(['color', 'theme'], themeContext),
    '0%',
    strokeColor
  )
  const gradientEndColor = R.propOr(
    R.path(['color', 'pink'], themeContext),
    '100%',
    strokeColor
  )
  return (
    <Bar small={small} {...props} data-test-id='progressbar'>
      <Progress
        gradientStartColor={gradientStartColor}
        gradientEndColor={gradientEndColor}
        percent={R.min(100, percent)}
        data-test-id='progress'
      />
    </Bar>
  )
}
