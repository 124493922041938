import Router from 'next/router'
import { normalize } from 'normalizr'
import * as R from 'ramda'
import { all, call, delay, put, take } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import { getLoginOpts } from '../../../utils/commonUtils'
import jssdk from '../../../utils/sdkUtils'
import { toggleBindPhoneScreen, toggleLoadingBackBtn } from '../../app/actions'
import {
  bindPhoneFinished,
  checkBindPhoneRequest,
  checkBindPhoneSucceeded,
  refreshLoginStatusRequest,
  refreshLoginStatusSucceeded
} from '../../auth/actions'
import { memberAuthorizer } from '../../auth/saga/authSaga'
import {
  getDonationHistorySucceeded,
  getDonationOrdersSucceeded,
  getMyDonationPageSucceeded,
  getPartnerOrderHistorySucceeded,
  getPartnerOrdersSucceeded,
  getThankyouPageSucceeded,
  saveReceiptSucceeded,
  sendReceiptFailed,
  sendReceiptSucceeded
} from '../actions'
import { orderSchema, ordersSchema } from '../schema'

export function* initDonationWorker(action) {
  try {
    const [isWebview, isLoggedIn] = yield all([
      call(jssdk.isWebview),
      call(jssdk.isLoggedIn)
    ])
    const { redirectPath } = action.payload
    if (!isLoggedIn) {
      // go to login
      if (isWebview) {
        yield call(Router.push, redirectPath)
      } else {
        yield call(
          jssdk.login,
          `${window.location.origin}${redirectPath}`,
          null,
          getLoginOpts(redirectPath)
        )
      }
    } else {
      // logged in
      Router.push(redirectPath)
      // go to donation
    }
  } catch (err) {
    console.log(err)
  }
}

export function* proceedDonationWorker(action) {
  const { orderParams } = action.payload
  try {
    const [isWebview, isLoggedIn] = yield all([
      call(jssdk.isWebview),
      call(jssdk.isLoggedIn)
    ])
    if (!isLoggedIn) {
      if (isWebview) {
        yield put(toggleLoadingBackBtn(true))
        yield delay(0)
        yield call(jssdk.login, null, null, getLoginOpts(window.location.href))
        yield put(toggleLoadingBackBtn(false))
        // re run the saga again
        yield call(proceedDonationWorker, action)
      } else {
        yield call(
          jssdk.login,
          window.location.href,
          null,
          getLoginOpts(window.location.href)
        )
      }
    } else {
      // logged in
      // postLogin
      yield call(postLogin, orderParams)
    }
  } catch (err) {
    console.log(err)
  }
}

function* postLogin(orderParams) {
  try {
    yield put(toggleLoadingBackBtn(false))
    yield call(handleBindPhone)
    yield call(createOrder, orderParams)
  } catch (err) {
    console.log(err)
  }
}

export function* handleBindPhone() {
  try {
    yield put(toggleBindPhoneScreen(false))
    yield put(checkBindPhoneRequest())
    let {
      payload: { isBoundPhone, isMergingAccount }
    } = yield take(checkBindPhoneSucceeded)
    if (isMergingAccount) {
      // refresh jwt
      yield put(refreshLoginStatusRequest())
      yield take(refreshLoginStatusSucceeded)
      isBoundPhone = yield call(jssdk.isBoundPhone)
    }
    if (!isBoundPhone) {
      // bindPhone
      // toggle bindPhone page
      yield put(toggleBindPhoneScreen(true))
      yield take(bindPhoneFinished)
      yield call(handleBindPhone)
    }
  } catch (err) {
    console.log(err)
  }
}

function* createOrder(orderParams) {
  try {
    const {
      charity: { token }
    } = yield call(jssdk.getTokens)
    const walletUrl = yield call(charityApi.createOrder, {
      token,
      ...orderParams
    })
    window.location.replace(walletUrl)
  } catch (err) {
    console.log(err)
    Router.replace('/')
  }
}

function* pollOrder(orderNo, token) {
  const initialTimestamp = new Date().getTime()

  for (let i = 1; ; i++) {
    const elapsedTime = new Date().getTime() - initialTimestamp

    if (elapsedTime > process.env.THANKYOU_PAGE_POLLING_TIMEOUT) {
      throw new Error('PollingTimeoutError')
    }

    try {
      const response = yield call(charityApi.getOrderByNo, orderNo, token)

      if (!R.any(R.equals(response.status))([0, 1])) {
        // https://github.com/hk01-digital/charity-api-gateway/blob/0ce08d3b07a8340928254c24348cecd009b16bde/src/constants/model.constant.ts#L14
        throw new Error('ResultNotReadyError')
      }

      return response
    } catch (err) {
      console.log(err)

      yield delay(
        Math.pow(process.env.THANKYOU_PAGE_POLLING_DELAY_BASE, i) * 1000
      )
    }
  }
}

export const getThankyouPageWorker = memberAuthorizer(function* (
  token,
  action
) {
  const { orderNo } = action.payload

  try {
    const response = yield call(pollOrder, orderNo, token)

    const normalized = yield call(normalize, response, orderSchema)

    yield put(getThankyouPageSucceeded(normalized))
  } catch (err) {
    console.log(err)

    window.location.replace(`${window.location.origin}/account/donation`)
  }
})

export const sendOrSaveReceiptWorker = memberAuthorizer(function* (
  token,
  action
) {
  const {
    orderNo,
    receiptName,
    receiptEmail,
    promoCode,
    resendReceipt,
    forceResend
  } = action.payload
  try {
    yield call(
      charityApi.sendReceipt,
      orderNo,
      receiptName,
      receiptEmail,
      promoCode,
      resendReceipt,
      forceResend,
      token
    )
    if (resendReceipt) {
      yield put(sendReceiptSucceeded())
    } else {
      yield put(saveReceiptSucceeded())
    }
  } catch (err) {
    console.log(err)
    yield put(sendReceiptFailed())
  }
})

export const getMyDonationPageWorker = memberAuthorizer(function* (
  token,
  action
) {
  const { page, limit } = action.payload

  try {
    const [ordersStats, donationOrders, partnerOrders] = yield all([
      call(charityApi.getOrdersStats, token),
      call(charityApi.getDonationHistory, page, limit, token),
      call(charityApi.getPartnerOrderHistory, page, limit, token)
    ])

    const normalizedDonationOrders = yield call(
      normalize,
      donationOrders.result,
      ordersSchema
    )

    const normalizedPartnerOrders = yield call(
      normalize,
      partnerOrders.result,
      ordersSchema
    )

    yield all([
      put(
        getDonationOrdersSucceeded(
          1,
          3,
          donationOrders.total,
          normalizedDonationOrders
        )
      ),
      put(
        getPartnerOrdersSucceeded(
          1,
          3,
          partnerOrders.total,
          normalizedPartnerOrders
        )
      ),
      put(getMyDonationPageSucceeded(ordersStats))
    ])
  } catch (err) {
    console.log(err)
  }
})

export const getDonationHistoryWorker = memberAuthorizer(function* (
  token,
  action
) {
  const { page, limit } = action.payload

  try {
    const response = yield call(
      charityApi.getDonationHistory,
      page,
      limit,
      token
    )

    const normalized = yield call(normalize, response.result, ordersSchema)

    yield all([
      put(
        getDonationOrdersSucceeded(
          response.page,
          response.limit,
          response.total,
          normalized
        )
      ),
      put(getDonationHistorySucceeded())
    ])
  } catch (err) {
    console.log(err)
  }
})

export const getPartnerOrderHistoryWorker = memberAuthorizer(function* (
  token,
  action
) {
  const { page, limit } = action.payload

  try {
    const response = yield call(
      charityApi.getPartnerOrderHistory,
      page,
      limit,
      token
    )

    const normalized = yield call(normalize, response.result, ordersSchema)

    yield all([
      put(
        getPartnerOrdersSucceeded(
          response.page,
          response.limit,
          response.total,
          normalized
        )
      ),
      put(getPartnerOrderHistorySucceeded())
    ])
  } catch (err) {
    console.log(err)
  }
})
