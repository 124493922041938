import moment from 'moment-timezone'
import * as R from 'ramda'
import { createSelector } from 'reselect'

import {
  DEFAULT_PLACEHOLDER,
  PROJECT_MODE,
  PROJECT_STATUS,
  PROJECT_TYPE
} from '../../constants'
import { computeProjectStatus } from '../../utils/commonUtils'
import formatter from '../../utils/formatter'
import { patchHTML } from '../../utils/patch'
import { getCategories } from '../category/selectors'

const defaultProfileUrl = '/static/images/default-profile.svg'

export const getProjectNoFromProps = (_, val) =>
  R.type(val) === 'Object' ? R.prop('projectNo', val) : val

export const getCurrentProjectNo = R.path(['projectState', 'result', 'project'])

export const getHomeHighlightedProjectsNos = R.pipe(
  R.path(['projectState', 'result', 'highlightedProjects']),
  R.take(6)
)

export const getProjectDetailHighlightedProjectsNos = ({ projectState }) => {
  const highlighted = projectState?.result?.highlightedProjects

  const currentProject = projectState?.result?.project

  return R.pipe(R.without([currentProject]), R.take(6))(highlighted)
}

export const getRandomProjectsNos = createSelector(
  R.path(['projectState', 'result', 'randomProjects']),
  randomProjectsNos =>
    R.dropLast(R.modulo(R.max(6, randomProjectsNos.length), 6))(
      randomProjectsNos
    )
)

export const getProjects = R.path(['projectState', 'entities', 'projects'])

const getDonationItems = R.path(['projectState', 'entities', 'donationItems'])

// For AppHeader
export const getCurrentProjectName = createSelector(
  getProjects,
  getCurrentProjectNo,
  (projects, currentProjectNo) =>
    R.pathOr(null, [currentProjectNo, 'name'], projects)
)

const getProject = createSelector(
  getProjects,
  getProjectNoFromProps,
  (projects, projectNo) => projects?.[projectNo]
)

const getDonorCount = createSelector(getProject, R.pathOr(0, ['donorCount']))

const getStartTime = createSelector(getProject, R.pathOr(null, ['startTime']))

const getEndTime = createSelector(getProject, R.pathOr(null, ['endTime']))

const getDonationTarget = createSelector(
  getProject,
  R.pathOr(Infinity, ['projectDonationTarget'])
)

const getDonationCount = createSelector(getProject, R.path(['donationCount']))

const getTotalDonatedAmount = createSelector(
  getProject,
  R.path(['totalDonatedAmount'])
)

export const getProjectType = createSelector(getProject, project =>
  R.path(['projectDonationTarget'], project)
    ? PROJECT_TYPE.WITH_TARGET
    : PROJECT_TYPE.WITHOUT_TARGET
)

export const getProjectMode = createSelector(getEndTime, endTime =>
  R.isNil(endTime) ? PROJECT_MODE.EVER_LASTING : PROJECT_MODE.TIME_LAPSE
)

export const getProjectStatus = createSelector(
  getStartTime,
  getEndTime,
  computeProjectStatus
)

const getProjectCardStatusDescription = createSelector(
  getStartTime,
  getEndTime,
  getProjectType,
  getProjectMode,
  getProjectStatus,
  getTotalDonatedAmount,
  getDonationTarget,
  (
    startTime,
    endTime,
    projectType,
    projectMode,
    projectStatus,
    totalDonatedAmount,
    donationTarget
  ) => {
    return R.cond([
      [
        R.propEq('projectStatus', PROJECT_STATUS.COMING_SOON),
        R.always({
          label: moment(startTime).format('YYYY.MM.DD'),
          value: 'common.comingSoon'
        })
      ],
      [
        R.where({
          projectStatus: R.equals(PROJECT_STATUS.ENDED),
          projectType: R.equals(PROJECT_TYPE.WITH_TARGET),
          fundraisedAmt: R.gt(R.__, donationTarget / 100)
        }),
        R.always({
          value: 'common.endedAndMeetTarget'
        })
      ],
      [
        R.propEq('projectStatus', PROJECT_STATUS.ENDED),
        R.always({ value: 'common.endedAndBelowTarget' })
      ],
      [
        R.whereEq({
          projectStatus: PROJECT_STATUS.IN_PROGRESS,
          projectMode: PROJECT_MODE.TIME_LAPSE
        }),
        R.always({
          label: 'common.endCountDown',
          value: formatter.dateDiff(endTime)
        })
      ],
      [
        R.whereEq({
          projectStatus: PROJECT_STATUS.IN_PROGRESS,
          projectMode: PROJECT_MODE.EVER_LASTING
        }),
        R.always({
          value: 'common.constantProject'
        })
      ],
      [R.T, R.always({ label: null, value: null })]
    ])({
      projectStatus,
      projectType,
      projectMode,
      fundraisedAmt: totalDonatedAmount / 100
    })
  }
)

const getProjectDetailHeroStatusDescription = createSelector(
  getEndTime,
  getProjectType,
  getProjectMode,
  getProjectStatus,
  getTotalDonatedAmount,
  getDonationTarget,
  (
    endTime,
    projectType,
    projectMode,
    projectStatus,
    totalDonatedAmount,
    donationTarget
  ) => {
    return R.cond([
      [
        R.whereEq({
          projectMode: PROJECT_MODE.EVER_LASTING
        }),
        R.always({
          value: 'common.constantProject'
        })
      ],
      [
        R.whereEq({
          projectMode: PROJECT_MODE.TIME_LAPSE,
          projectStatus: PROJECT_STATUS.IN_PROGRESS
        }),
        R.always({
          label: 'common.endCountDown',
          value: formatter.dateDiff(endTime)
        })
      ],
      [
        R.where({
          projectMode: R.equals(PROJECT_MODE.TIME_LAPSE),
          projectStatus: R.equals(PROJECT_STATUS.ENDED),
          projectType: R.equals(PROJECT_TYPE.WITH_TARGET),
          fundraisedAmt: R.gt(R.__, donationTarget / 100)
        }),
        R.always({
          value: 'common.endedAndMeetTarget'
        })
      ],
      [
        R.whereEq({
          projectMode: PROJECT_MODE.TIME_LAPSE,
          projectStatus: PROJECT_STATUS.ENDED
        }),
        R.always({ value: 'common.endedAndBelowTarget' })
      ],
      [R.T, R.always({ label: null, value: null })]
    ])({
      projectStatus,
      projectType,
      projectMode,
      fundraisedAmt: totalDonatedAmount / 100
    })
  }
)

const getDonationItemsDonorCount = createSelector(
  getProject,
  getDonationItems,
  (project, allDonationItems) => {
    const donationItems = R.pick(project?.donationItems ?? [], allDonationItems)

    if (R.isEmpty(donationItems)) {
      return {}
    } else {
      const custom = R.pipe(
        R.values,
        R.find(R.propEq('type', 'custom'))
      )(donationItems)

      return R.pipe(
        R.assoc('custom', custom),
        R.dissoc(custom?.donationItemId),
        R.map(R.prop('donationCount')),
        R.reject(R.isNil)
      )(donationItems)
    }
  }
)

const getProjectCustomDonationItemId = createSelector(
  getProject,
  getDonationItems,
  (project, allDonationItems) => {
    const donationItems = R.pick(project?.donationItems ?? [], allDonationItems)
    if (!R.isEmpty(donationItems)) {
      const customDonationItemId = R.pipe(
        R.values,
        R.find(R.propEq('type', 'custom')),
        R.prop('donationItemId')
      )(donationItems)
      return customDonationItemId
    }
    return null
  }
)

const getProjectDonationItems = createSelector(
  getProject,
  getDonationItems,
  (project, donationItems) => {
    const donationItemIds = project?.donationItems ?? []

    return R.pipe(
      R.map(id => R.prop(id, donationItems)),
      R.reject(R.propEq('type', 'custom')),
      R.map(
        R.pick([
          'donationItemId',
          'sequence',
          'title',
          'amount',
          'description',
          'minAmount',
          'minAmountDescription',
          'partnerEventId'
        ])
      )
    )(donationItemIds)
  }
)

export const getDonationItem = ({ projectState }, donationItemId) => {
  const donationItems = projectState?.entities?.donationItems

  return donationItems[donationItemId]
}

const getTotalArchivedDonationCounts = createSelector(
  getProject,
  getDonationItemsDonorCount,
  (project, donationItemsDonorCount) => {
    if (!R.isNil(project)) {
      const { donationItems } = project

      // defaultTo empty array in case donationItems is undefined
      const availableItems = donationItems ?? []

      const isAllowInputDonationAmount = R.pathOr(
        false,
        ['allowInputDonationAmount'],
        project
      )

      const archivedCounts = R.omit(
        isAllowInputDonationAmount
          ? R.append('custom', availableItems) // if allow custom amount input, also omit it from the archived count
          : availableItems,
        donationItemsDonorCount // consist of all donation items, whether archived or not
      )

      const fundraisingEventDonationCount = R.subtract(
        R.propOr(0, 'donationCount', project),
        R.sum(R.values(donationItemsDonorCount))
      )

      const totalArchivedDonationCounts = R.sum([
        fundraisingEventDonationCount,
        ...R.values(archivedCounts)
      ])

      return totalArchivedDonationCounts
    }
    return null
  }
)

const getProjectPromoCategorySelector = createSelector(
  getProject,
  getCategories,
  (project, categories) =>
    R.pipe(
      R.propOr([], 'categories'),
      R.pick(R.__, categories),
      R.values,
      R.filter(
        R.where({ isPromo: R.identity, miniIconImage: R.complement(R.isNil) })
      ),
      R.sort(R.ascend(R.prop('categoryNo'))),
      R.take(3)
    )(project)
)

export const projectSelector = createSelector(
  getProject,
  getProjectType,
  getProjectStatus,
  getProjectMode,
  getProjectCardStatusDescription,
  getProjectDetailHeroStatusDescription,
  getDonationTarget,
  getDonationItemsDonorCount,
  getProjectCustomDonationItemId,
  getProjectDonationItems,
  getTotalArchivedDonationCounts,
  getProjectPromoCategorySelector,
  getDonorCount,
  getDonationCount,
  getTotalDonatedAmount,
  (
    projectEntity,
    projectType,
    projectStatus,
    projectMode,
    projectCardStatusDescription,
    projectDetailHeroStatusDescription,
    donationTarget,
    donationItemsDonorCount,
    customDonationItemId,
    donationItems,
    totalArchivedDonationCounts,
    projectPromoCategory,
    donorCount,
    donationCount,
    totalDonatedAmount
  ) => {
    if (!R.isNil(projectEntity)) {
      const get = R.pathOr(R.__, R.__, projectEntity)

      return {
        mainImg:
          get(null, ['mainImage', 'url']) ??
          get(null, ['titleImage', 'url']) ??
          DEFAULT_PLACEHOLDER,
        id: get(null, ['projectId']),
        name: get(null, ['name']),
        tnc: patchHTML(get('', ['tnc'])),
        donationTarget,
        donorCount,
        donors: R.map(
          donor => ({
            avatar: R.when(
              R.either(R.isNil, R.isEmpty),
              R.always(defaultProfileUrl)
            )(R.prop('avatarUrl', donor)),
            donorId: R.propOr(null, 'donorId')(donor)
          }),
          R.pathOr([], ['latestDonors'], projectEntity)
        ),
        organizationId: get(null, ['organizationId']),
        organizationNo:
          get(null, ['organizationNo']) ?? get(null, ['organization']),
        ogImage: get(null, ['ogImage', 'url']),
        ogTitle: get(null, ['ogTitle']),
        ogDescription: get(null, ['ogDescription']),
        startTime: get(null, ['startTime']),
        endTime: get(null, ['endTime']),
        projectType,
        projectStatus,
        projectMode,
        projectCardStatusDescription,
        projectDetailHeroStatusDescription,
        youtubeVideoIds: get([], ['youtubeVideoIds']),
        hk01VideoIds: get([], ['hk01VideoIds']),
        description: patchHTML(get('', ['description'])),
        acknowledgement: patchHTML(get('', ['acknowledgement'])),
        eventArrangementDetail: patchHTML(get('', ['eventArrangementDetail'])),
        allowInputDonationAmount: get('', ['allowInputDonationAmount']),
        allowInputDonationAmountDescription: get('', [
          'allowInputDonationAmountDescription'
        ]),
        supportsFundraisingEvent: get(false, ['supportsFundraisingEvent']),
        donationItemsDonorCount,
        customDonationItemId,
        providedReceipt: get(false, ['providedReceipt']),
        donationItems,
        totalArchivedDonationCounts,
        projectPromoCategory: R.map(
          category => ({
            categoryNo: category.categoryNo,
            displayName: category.displayName,
            miniIconImage: R.pathOr(
              DEFAULT_PLACEHOLDER,
              ['miniIconImage', 'url'],
              category
            ),
            iconImage: R.pathOr(
              DEFAULT_PLACEHOLDER,
              ['iconImage', 'url'],
              category
            )
          }),
          projectPromoCategory
        ),
        donationCount,
        totalDonatedAmount
      }
    }
    return null
  }
)

const getOrganizations = R.path(['projectState', 'entities', 'organizations'])

const getOrganizationNoFromProps = (_, val) =>
  R.type('val') === 'Object' ? R.prop('organizationNo', val) : val

export const getCurrentOrgNo = R.path([
  'projectState',
  'result',
  'organization'
])

export const getCurrentOrgName = createSelector(
  getOrganizations,
  getCurrentOrgNo,
  (orgEntity, orgNo) => {
    const org = R.pathOr(null, [orgNo], orgEntity)

    return R.trim(
      R.has('displayName', org)
        ? R.take(80, R.prop('displayName', org))
        : R.take(200, R.prop('nameChi', org))
    )
  }
)

const getOrganization = createSelector(
  getOrganizations,
  getOrganizationNoFromProps,
  (organization, organizationNo) => organization?.[organizationNo]
)

export const getOrgName = createSelector(getOrganization, organization => {
  if (R.isNil(organization)) return null

  const { displayName, nameChi } = organization

  return R.isNil(displayName)
    ? R.trim(R.take(200, nameChi))
    : R.trim(R.take(80, displayName))
})

export const orgSelector = createSelector(
  getOrganization,
  getOrgName,
  (organization, orgName) => {
    if (R.isNil(organization)) return null

    const get = R.pathOr(R.__, R.__, organization)

    return {
      id: get('', ['organizationId']),
      name: orgName,
      organizationNo: get('', ['organizationNo']),
      noOfProject: get(0, ['noOfPublishedProject']),
      avatar:
        organization?.logoSquare?.url ??
        organization?.logoSquareFile?.url ??
        DEFAULT_PLACEHOLDER,
      avatarLandscape:
        organization?.logoLandscape?.url ??
        organization?.logoLandscapeFile?.url ??
        DEFAULT_PLACEHOLDER,
      description: get('', ['description']),
      hk01Page: get('', ['hk01PageUrl']),
      isMerchantAccountReady: get(false, ['isMerchantAccountReady']),
      serveTargets: get([], ['serveTargets'])
    }
  }
)

const getRouter = (_, { router }) => router

export const getIsDonationDisabled = createSelector(
  getRouter,
  getCurrentOrgNo,
  getOrganizations,
  getProjectStatus,
  (router, organizationNo, organizations, projectStatus) => {
    const previewToken = R.pathOr(null, ['query', 'previewToken'], router)

    const isMerchantAccountReady = R.pathOr(
      false,
      [organizationNo, 'isMerchantAccountReady'],
      organizations
    )

    return (
      !!previewToken ||
      !(projectStatus === PROJECT_STATUS.IN_PROGRESS) ||
      !isMerchantAccountReady
    )
  }
)
