module.exports = {
  ...require('./config.default.js'),
  ...(process.env.APP_ENV === 'production'
    ? require('./config.prod.js')
    : process.env.APP_ENV === 'staging'
    ? require('./config.stg.js')
    : process.env.APP_ENV === 'development'
    ? require('./config.dev.js')
    : process.env.APP_ENV === 'local'
    ? require('./config.local.js')
    : process.env.APP_ENV === 'test'
    ? require('./config.test.js')
    : require('./config.local.js'))
}
