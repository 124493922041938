import Router from 'next/router'
import objectFitImages from 'object-fit-images'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { checkWebviewRequest } from '../redux/app/actions'
import { checkLoginRequest } from '../redux/auth/actions'
import jssdk from '../utils/sdkUtils'

const PageInitialize = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    jssdk.init()
    if (Router.route !== '/_error') {
      window.history.replaceState(
        Object.assign(window.history.state, {
          options: { shouldBackHome: true }
        })
      )
    }

    dispatch(checkWebviewRequest())

    dispatch(checkLoginRequest())

    objectFitImages()
  }, [])

  return null
}

export default PageInitialize
