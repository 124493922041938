const formatter = require('./utils/formatter')

const NextI18Next = require('next-i18next').default

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'zh',
  otherLanguages: [''],
  strictMode: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
    format: (value, format, lng) => {
      if (format === 'uppercase') return value.toUpperCase()
      if (format === 'number') return formatter.toNumber(value)
      if (format === 'currency') return formatter.toCurrency(value)
      return value
    }
  }
})

module.exports = NextI18NextInstance
