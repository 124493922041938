import { all, takeLatest } from 'redux-saga/effects'

import {
  getCorporateCategoryListPageRequest,
  getCorporateCategoryListRequest,
  getCorporateDetailRequest,
  getCorporateHomePageRequest,
  getCorporateListRequest,
  sendContactFormRequestAsync
} from '../actions'
import {
  getCorporateCategoryListPageWorker,
  getCorporateCategoryListWorker,
  getCorporateDetailWorker,
  getCorporateHomePageWorker,
  getCorporateListWorker,
  sendContactFormWorker
} from './corporateSaga'

export function* corporateWatcher() {
  try {
    yield all([
      takeLatest(getCorporateListRequest, getCorporateListWorker),
      takeLatest(getCorporateDetailRequest, getCorporateDetailWorker),
      takeLatest(
        getCorporateCategoryListRequest,
        getCorporateCategoryListWorker
      ),
      takeLatest(
        getCorporateCategoryListPageRequest,
        getCorporateCategoryListPageWorker
      ),
      takeLatest(getCorporateHomePageRequest, getCorporateHomePageWorker),
      takeLatest(sendContactFormRequestAsync, sendContactFormWorker)
    ])
  } catch (err) {
    console.log(err)
  }
}
