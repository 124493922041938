import * as R from 'ramda'
import { handleActions } from 'redux-actions'

import { getWidgetDonorsSucceeded } from './actions'

export const donorInitialState = {
  page: 1,
  limit: 6,
  total: null,
  donors: null
}

const donorReducer = handleActions(
  {
    [getWidgetDonorsSucceeded]: (state, action) => {
      const { page, limit, total, result } = action.payload

      return {
        ...state,
        page,
        limit,
        total,
        donors: R.concat(state.donors ?? [], result),
        hasMoreDonors: R.lt(page * limit, total)
      }
    }
  },
  donorInitialState
)

export default donorReducer
