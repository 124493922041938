import * as R from 'ramda'
import { all, call, put } from 'redux-saga/effects'

import { handleSyncAsync } from '../../../utils/reduxUtils'
import { getProjectDetailWorker } from '../../project/saga/projectSaga'
import { setFundraisingEventArchived } from '../actions'
import {
  getFundraisingCreatorStatisticsWorker,
  getLatestDonorsWorker,
  getPublicFundraisingWorker
} from './fundraisingSaga'

const getPublicFundraisingPage = function* (action) {
  try {
    const { fundraisingEventNo } = action.payload

    const fundraisingDetail = yield call(
      getPublicFundraisingWorker,
      fundraisingEventNo
    )

    const projectNo = R.path(['project', 'projectNo'], fundraisingDetail)

    yield all([
      put(setFundraisingEventArchived(false)),
      call(getProjectDetailWorker, projectNo),
      call(getLatestDonorsWorker, fundraisingEventNo),
      call(getFundraisingCreatorStatisticsWorker, fundraisingEventNo)
    ])
  } catch (e) {
    if (e.message === '403') {
      yield put(setFundraisingEventArchived(true))
    }
    throw e
  }
}

export const getPublicFundraisingPageWorker = handleSyncAsync(
  getPublicFundraisingPage
)
