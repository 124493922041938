module.exports = {
  PORT: 3000,
  APP_VERSION: require('../package.json').version,
  NOTIFICATION_COOKIE: 'hk01_noti',
  COOKIE_MAX_AGE: 86400, // second // 2 hours
  BIND_PHONE_COUNTER: 5, // second
  FUNDRAISING_EVENT_FORM_STORAGE_KEY: 'hk01_heart_personal_fundraising',
  BUGSNAG_API_KEY: '3309007cdab4fb342e93dd937de12618',
  HK01_VIDEO_SERVER: 'https://vdo.cdn.hk01.com',
  MIN_DONATION_PAGE_TIMEOUT: 10, // minute
  THANKYOU_PAGE_POLLING_TIMEOUT: 10 * 1000, // ms
  THANKYOU_PAGE_POLLING_DELAY_BASE: 2, // s
  HK01_FAQ_URL:
    'https://faq.hk01.com/hc/zh-tw/categories/360002126534-01%E5%BF%83%E6%84%8F',
  OG_IMG_PATH: '/static/images/og-image.png',
  THUMBOR_PREDEF_WIDTH: [
    1920,
    1760,
    1600,
    1440,
    1280,
    1120,
    960,
    800,
    640,
    480,
    320
  ]
}
