import * as R from 'ramda'
import { createSelector } from 'reselect'

import { isExist } from '../../utils/ramdaUtils'

export const getCategory = (state, categoryNo) =>
  R.pathOr({}, ['categoryState', 'entities', 'categories', categoryNo], state)

const getHomeCategoryNos = R.pathOr([], ['categoryState', 'result', 'home'])

const getSearchCategoryNos = R.pathOr([], ['categoryState', 'result', 'search'])

export const getCategories = R.pathOr({}, [
  'categoryState',
  'entities',
  'categories'
])

export const getSearchCategories = createSelector(
  getSearchCategoryNos,
  getCategories,
  (searchCategoryNos, categories) =>
    R.pipe(R.pick(searchCategoryNos), R.values)(categories)
)

export const getHomeCategoryDisplayNames = createSelector(
  getHomeCategoryNos,
  getCategories,
  (homeCategoryNos, categories) =>
    R.pipe(
      R.pick(homeCategoryNos),
      R.values,
      R.pluck('displayName')
    )(categories)
)

export const getCategoryDisplayNames = (state, categoryNos) => {
  return isExist(categoryNos)
    ? R.pipe(
        R.pathOr({}, ['categoryState', 'entities', 'categories']),
        R.pick(categoryNos),
        R.values,
        R.map(R.prop('displayName'))
      )(state)
    : []
}

// TODO: move to category state
export const getCurrentCatNos = R.path(['projectState', 'result', 'categories'])

export const getCurrentCatNames = createSelector(
  getCurrentCatNos,
  getCategories,
  (currentCatNos, categories) =>
    R.pipe(R.pick(currentCatNos), R.values, R.pluck('displayName'))(categories)
)
