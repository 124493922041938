import Link from 'next/link'
import { GoogleFonts } from 'next-google-fonts'
import * as R from 'ramda'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import styled from 'styled-components'

import AspectBox from '../../components/AspectBox'
import Odometer from '../../components/Odometer'
import { ProjectCardSkeleton } from '../../components/Skeleton'
import { MEDIA_CONDITIONS } from '../../constants'
import { useTracking } from '../../hooks'
import { getColor, getFontSize } from '../../utils/cssUtils'
import {
  homeClickPromotionListingBanner,
  homeReachProjectCard,
  homeReachPromotionListing
} from '../../utils/dataTracker'
import { toCurrency } from '../../utils/formatter'
import mq from '../../utils/mediaQuery'
import { slugify } from '../../utils/urlUtils'
import CardSlider from '../CardSlider'
import ProjectCard from '../ProjectCard'

const Container = styled.div`
  max-width: 1200px;
  margin: 64px auto;
  ${mq.tablet} {
    margin: 32px 16px;
  }
  ${mq.tabletMobile} {
    margin: 16px;
  }
`
const KeyVisual = styled.img`
  width: 100%;
  height: auto;
`
const NgoInfoWrapper = styled.div`
  max-width: 696px;
  display: flex;
  flex-wrap: nowrap;
  margin: 24px auto;
  ${mq.mobile} {
    flex-wrap: wrap;
  }
`
const NgoTagLine = styled.h2`
  width: 50%;
  color: ${getColor('black')};
  font-size: ${getFontSize('headline')};
  margin: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  ${mq.mobile} {
    width: 100%;
    margin-bottom: 16px;
  }
`
const NgoFundraisedAmount = styled.div`
  display: flex;
  flex-flow: row wrap;
  border-left: 8px solid ${({ accentColor }) => accentColor && accentColor};
  padding-left: 8px;
  flex-shrink: 0;
  ${mq.mobile} {
    width: 100%;
  }
`
const FundraisedAmountTitle = styled.div`
  width: 100%;
  color: ${getColor('grey')};
  font-size: ${getFontSize('title')};
`
const FundraisedAmount = styled.div`
  width: 100%;
  color: ${({ accentColor }) => accentColor && accentColor};
  font-size: 64px;
  font-family: 'Libre Baskerville';
`
const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${getColor('grey')};
`
const CarouselWrapper = styled.div`
  position: relative;
`
const SubHeadline = styled.h2`
  align-self: flex-start;
  margin: 32px 0 24px;
  ${mq.mobile} {
    margin: 53px 0 15px;
  }
  &::after {
    margin-bottom: 0;
  }
`

const widgetData = [
  {
    keyVisual: {
      l: '/static/images/worldvision-promo@3x.jpg',
      m: '/static/images/worldvision-promo@2x.jpg',
      s: '/static/images/worldvision-promo@1x.jpg'
    },
    organizationNo:
      process.env.APP_ENV === 'production' ? 'ORG10162' : 'ORG10731',
    organizationName: '香港世界宣明會',
    tagline: '支持四位饑饉之星挑戰30小時飢餓體驗，幫助非洲飢民走出困境',
    subHeadline: '饑饉30項目',
    color: '#ff6b02',
    fundraisedAmountTitle: '已籌得總金額'
  },
  {
    keyVisual: {
      l: '/static/images/promotion-listing-demo-2.jpg',
      m: '/static/images/promotion-listing-demo-2.jpg',
      s: '/static/images/promotion-listing-demo-2.jpg'
    },
    organizationNo:
      process.env.APP_ENV === 'production' ? 'ORG10131' : 'ORG10732',
    organizationName: '國際培幼會',
    tagline: '我們秉持著理解和接納的態度，以專業服務讓他們健康成長。',
    subHeadline: '國際培幼會',
    color: '#2a419b',
    fundraisedAmountTitle: '已籌得總金額'
  }
]

const WaypointTracker = React.memo(({ onEnter }) => {
  return <Waypoint onEnter={R.once(onEnter)} bottomOffset='-56px' />
})

const PromoListing = React.memo(({ widgetKey, className, ...props }) => {
  const track = useTracking()

  const [shouldAnimate, setShouldAnimate] = useState(false)

  const projectNos = useSelector(
    R.path(['projectState', 'result', `promoWidget${widgetKey}`]),
    R.equals
  )
  const isFetching = useSelector(
    R.path(['projectState', 'fetching', 'getHomepageData'])
  )

  const projectNames = useSelector(
    R.pipe(
      R.path(['projectState', 'entities', 'projects']),
      projectState => R.map(projectNo => projectState[projectNo], projectNos),
      R.pluck('name')
    ),
    R.equals
  )

  const amount = useSelector(
    R.path(['appState', `promoWidget${widgetKey}TotalDonation`])
  )

  const slides = isFetching
    ? R.times(i => <ProjectCardSkeleton key={i} />, R.length(projectNos))
    : projectNos.map((projectNo, i) => (
        <ProjectCard
          projectNo={projectNo}
          key={i}
          projectType='promotion_listing'
          index={i}
        />
      ))

  const reachTracking = index =>
    R.once(() => {
      const common = [
        projectNos[index],
        projectNames[index],
        index,
        'promotion_listing'
      ]
      track(homeReachProjectCard(...common))
    })

  const {
    keyVisual,
    tagline,
    subHeadline,
    color,
    fundraisedAmountTitle,
    organizationNo,
    organizationName
  } = widgetData[widgetKey - 1]

  const handleEnterViewport = useCallback(() => {
    setShouldAnimate(true)
    track(
      homeReachPromotionListing(organizationNo, organizationName, widgetKey)
    )
  }, [organizationNo, organizationName, widgetKey])

  const handleBannerClick = () => {
    track(
      homeClickPromotionListingBanner(
        widgetKey,
        organizationNo,
        organizationName
      )
    )
  }

  const displayAmount = Math.round(amount / 100)

  return R.isEmpty(projectNos) ? null : (
    <Container className={className}>
      <GoogleFonts href='https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap' />
      <Link href={`/org/${organizationNo}/${slugify(organizationName)}`}>
        <a onClick={handleBannerClick}>
          <AspectBox ratio={33.33333}>
            <KeyVisual
              src={keyVisual.l}
              srcSet={`${keyVisual.s} 600w, ${keyVisual.m} 1200w, ${keyVisual.l} 1800w`}
              sizes={`${MEDIA_CONDITIONS.DESKTOP} 1200px, calc(100vw - 32px)`}
              alt={organizationNo}
            />
          </AspectBox>
        </a>
      </Link>
      <NgoInfoWrapper>
        <NgoTagLine>{tagline}</NgoTagLine>
        <NgoFundraisedAmount accentColor={color}>
          <FundraisedAmountTitle>{fundraisedAmountTitle}</FundraisedAmountTitle>
          <FundraisedAmount accentColor={color}>
            <Odometer start={shouldAnimate}>
              {toCurrency(displayAmount)}
            </Odometer>
          </FundraisedAmount>
        </NgoFundraisedAmount>
      </NgoInfoWrapper>
      <WaypointTracker onEnter={handleEnterViewport} />
      <Separator />
      <CarouselWrapper>
        <SubHeadline>{subHeadline}</SubHeadline>
        <CardSlider slides={slides} reachTracking={reachTracking} />
      </CarouselWrapper>
    </Container>
  )
})

export default PromoListing
