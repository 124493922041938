import Link from 'next/link'
import * as R from 'ramda'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'

import { Col, Row } from '../../../components/Layout/FlexGrid'
import MaxWidth from '../../../components/Layout/MaxWidth'
import { ProjectCardSkeleton } from '../../../components/Skeleton'
import SectionTitle from '../../../components/Text/SectionTitle'
import { useTracking } from '../../../hooks'
import { useTranslation } from '../../../i18n'
import FundraisingBannerSvg from '../../../public/static/images/personal-fundraising-banner-16-9.svg'
import { getWidgetFundraisingsRequestAsync } from '../../../redux/fundraising/actions'
import { getColor, getFontSize } from '../../../utils/cssUtils'
import {
  homeClickFundraisingEventCta,
  homeReachFundraisingEventCard
} from '../../../utils/dataTracker'
import mq from '../../../utils/mediaQuery'
import Button from '../../Button'
import CardSlider from '../../CardSlider'
import FundraisingCard from '../FundraisingCard'

const Section = styled.section`
  padding: 40px 0px;
  ${mq.mobile} {
    padding: 24px 0;
  }
`
const Wrapper = styled(Section)`
  background: ${getColor('white')};
`
const Content = styled(MaxWidth)`
  position: relative;
  background: ${getColor('input', 0.1)};
  padding: 24px;
  ${mq.mobile} {
    padding: 16px;
  }
`
const Headline = styled.h1`
  font-size: ${getFontSize('display2')};
  ${mq.mobile} {
    font-size: ${getFontSize('display1')};
  }
`
const TitleBottom = styled.span`
  display: block;
`
const MainImgContainer = styled.div`
  width: 100%;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
`
const StyledFundraisingBanner = styled(FundraisingBannerSvg)`
  width: 100%;
  height: 100%;
`
const Summary = styled.p`
  font-size: ${getFontSize('body2')};
  color: ${getColor('grey')};
`

const ProjectsContainer = styled.div`
  position: relative;
`
const SubHeadline = styled(SectionTitle)`
  align-self: flex-start;
  margin: 32px 0 24px;
  ${mq.mobile} {
    margin: 53px 0 15px;
  }
  &::after {
    margin-bottom: 0;
  }
`

const ProjectCarousel = React.memo(({ isLoading, fundraisingEventNos }) => {
  const track = useTracking()

  const fundraisingEventNames = useSelector(
    R.pipe(
      R.path(['fundraisingState', 'entities', 'fundraisings']),
      fundraisingState =>
        R.map(
          fundraisingEventNo => fundraisingState[fundraisingEventNo],
          fundraisingEventNos
        ),
      R.pluck('title')
    ),
    R.equals
  )

  const reachTracking = index => () => {
    track(
      homeReachFundraisingEventCard(
        fundraisingEventNos[index],
        fundraisingEventNames[index]
      )
    )
  }

  const slides = isLoading
    ? R.times(i => <ProjectCardSkeleton key={i} />, 3)
    : R.addIndex(R.map)(
        (fundraisingEventNo, i) => (
          <FundraisingCard fundraisingEventNo={fundraisingEventNo} />
        ),
        fundraisingEventNos
      )

  return <CardSlider slides={slides} reachTracking={reachTracking} />
})

const PersonalFundraising = React.memo(() => {
  const dispatch = useDispatch()

  const { t } = useTranslation('common')

  const track = useTracking()

  const { color } = useContext(ThemeContext)

  const [isLoadingFrEvents, setIsLoadingFrEvents] = useState(false)

  const featuredFundraisingEventNos = useSelector(
    R.pathOr([], ['fundraisingState', 'result', 'fundraisings']),
    R.equals
  )

  const trackCtaClick = () => {
    track(homeClickFundraisingEventCta())
  }

  useEffect(() => {
    let cancel = false
    ;(async () => {
      try {
        setIsLoadingFrEvents(true)

        await dispatch(getWidgetFundraisingsRequestAsync())

        if (!cancel) setIsLoadingFrEvents(false)
      } catch (e) {
        if (!cancel) setIsLoadingFrEvents(false)
      }
    })()

    return () => {
      cancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <Content>
        <Row gutter={{ xl: 24, md: 16, xs: 16 }}>
          <Col xs={24} md={24} xl={12}>
            <MainImgContainer>
              <StyledFundraisingBanner />
            </MainImgContainer>
          </Col>
          <Col xs={24} md={24} xl={12}>
            <Headline>
              {t('personalFundraising.titleSection1')}
              <TitleBottom>
                {t('personalFundraising.titleSection2')}
              </TitleBottom>
            </Headline>
            <Summary>{t('personalFundraising.summary')}</Summary>
            <Link
              href='/discover?typeFilter=supportsFundraisingEvent'
              as='/discover?typeFilter=supportsFundraisingEvent'
            >
              <a>
                <Button height={48} type='button' onClick={trackCtaClick}>
                  {t(
                    'personalFundraising.ctaButton.choosePersonalFundraisingProjectNow'
                  )}
                </Button>
              </a>
            </Link>
          </Col>
        </Row>
        {!R.isEmpty(featuredFundraisingEventNos) && (
          <ProjectsContainer>
            <SubHeadline color={color.black}>
              {t('personalFundraising.subHeadline')}
            </SubHeadline>
            <ProjectCarousel
              fundraisingEventNos={featuredFundraisingEventNos}
              isLoading={isLoadingFrEvents}
            />
          </ProjectsContainer>
        )}
      </Content>
    </Wrapper>
  )
})

export default PersonalFundraising
