import Link from 'next/link'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import ErrorInfo from '../../../components/ErrorInfo'
import Flex from '../../../components/Layout/Flex'
import { Modal } from '../../../components/PopUp'
import Progress from '../../../components/Progress'
import { ERROR_ICON_TYPE, FUNDRAISING_STATUS } from '../../../constants'
import { useTranslation } from '../../../i18n'
import { deleteFundraisingRequestAsync } from '../../../redux/fundraising/actions'
import { getColor, getFontSize } from '../../../utils/cssUtils'
import formatter from '../../../utils/formatter'
import mq from '../../../utils/mediaQuery'
import Button from '../../Button'

const FundraisingEditButton = styled(Button)`
  z-index: 2;
`
const ProgressWrapper = styled.div`
  height: 32px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`
const TextProgress = styled.div`
  display: flex;
  font-size: ${getFontSize('body2')};
`
const Donated = styled.div`
  color: ${getColor('black')};
  margin-left: 4px;
  font-weight: 600;
`
const Slash = styled.span`
  &::before {
    content: '/';
    margin: 0 4px;
    display: inline-block;
  }
`
const StatusLine = styled.div`
  display: flex;
  font-size: ${getFontSize('body1')};
`
const DonorCountLabel = styled.div`
  color: ${getColor('grey')};
  margin-right: 4px;
`
const DonorCount = styled.div`
  color: ${getColor('black')};
  font-weight: bold;
`
const Separator = styled.div`
  margin: 0 12px;
`
const StatusText = styled.div`
  color: ${getColor('theme')};
  font-weight: bold;
`
const DeleteDraft = styled.a`
  text-decoration: underline;
  font-size: ${getFontSize('body1')};
  z-index: 2;
`

const StyledErrorInfo = styled(ErrorInfo)`
  padding: 32px 62px;
  ${mq.mobile} {
    padding: 32px 41px;
  }
`

const MyAccountContent = ({
  fundraisingEventNo,
  status,
  totalDonatedAmount = 0,
  donationCount = 0,
  donationTarget = 0,
  endTime
}) => {
  donationTarget = parseInt(donationTarget) / 100

  totalDonatedAmount = parseInt(totalDonatedAmount) / 100

  const dispatch = useDispatch()

  const { t } = useTranslation('common')

  const [showModal, setShowModal] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)

  const currentTs = new Date().getTime()

  const showProgress = status !== FUNDRAISING_STATUS.DRAFT

  const fundraisedPercentage = (totalDonatedAmount / donationTarget) * 100

  const statusText = useMemo(() => {
    return status === FUNDRAISING_STATUS.ARCHIVED
      ? t('common.unpublished')
      : currentTs < endTime
      ? t('common.inProgress')
      : t('common.ended')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, endTime, currentTs])

  const handleOpenModal = e => {
    e.preventDefault()
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      await dispatch(deleteFundraisingRequestAsync(fundraisingEventNo))
      setIsDeleting(false)
      setShowModal(false)
    } catch (e) {
      // TODO: show error
      console.log('err', e)
      setIsDeleting(false)
      setShowModal(false)
    }
  }

  return showProgress ? (
    <>
      <ProgressWrapper>
        <Progress small percent={fundraisedPercentage} />
        <TextProgress>
          {t('project.fundraised')}
          <Donated>{formatter.toCurrency(totalDonatedAmount)}</Donated>
          <Slash />
          {formatter.toCurrency(donationTarget)}
        </TextProgress>
      </ProgressWrapper>
      <StatusLine>
        <DonorCountLabel>{t('project.donationCount')}</DonorCountLabel>
        <DonorCount>
          {t('project.donations', { value: donationCount })}
        </DonorCount>
        <Separator>|</Separator>
        <StatusText>{statusText}</StatusText>
      </StatusLine>
    </>
  ) : (
    <>
      <Link href={`/fundraising/edit/${fundraisingEventNo}`}>
        <a>
          <FundraisingEditButton height={32}>
            {t('common.edit')}
          </FundraisingEditButton>
        </a>
      </Link>
      <StatusLine>
        <DeleteDraft onClick={handleOpenModal}>
          {t('personalFundraising.ctaButton.archiveDraft')}
        </DeleteDraft>
      </StatusLine>
      <Modal show={showModal} onClose={handleCloseModal}>
        <StyledErrorInfo
          type={ERROR_ICON_TYPE.DELETE_DRAFT}
          title={t('personalFundraising.deleteDraftConfirm')}
          description={t('personalFundraising.deleteReminder')}
        >
          <Flex>
            <Button onClick={handleCloseModal} height={48}>
              {t('personalFundraising.ctaButton.keepDraft')}
            </Button>
            <Button
              transparent
              onClick={handleDelete}
              height={48}
              loading={isDeleting}
              css={`
                margin-left: 24px;
              `}
            >
              {t('personalFundraising.ctaButton.confirmDelete')}
            </Button>
          </Flex>
        </StyledErrorInfo>
      </Modal>
    </>
  )
}

export default MyAccountContent
