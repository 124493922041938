import React from 'react'
import styled from 'styled-components'

const Flag = styled.div`
  height: 32px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  &::after {
    content: '';
    width: calc(100% + 12px);
    height: 100%;
    position: absolute;
    left: -6px;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: -1;
    transform: skew(-20deg);
    border-radius: 3px 0 0 4px;
  }
`

const CardFlag = ({ children, className }) => (
  <Flag className={className}>{children}</Flag>
)

export default CardFlag
