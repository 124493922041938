import React from 'react'
import styled, { css } from 'styled-components'

import Flex from '../../../components/Layout/Flex'
import { getColor } from '../../../utils/cssUtils'
import mq from '../../../utils/mediaQuery'
import { loadingAnimation } from '../BackgroundEffect'

const FlexCenter = ({ className, children }) => (
  <Flex align='center' justify='center' className={className}>
    {children}
  </Flex>
)
const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  flex-wrap: nowrap;
  padding: 24px;
  border-radius: 4px;
  ${mq.mobile} {
    padding: 16px;
  }
`
const Logo = styled(FlexCenter)`
  flex-shrink: 0;
  width: ${({ horizontal }) => (horizontal ? '144px' : '100%')};
  height: 144px;
  margin: ${({ horizontal }) => (horizontal ? '0 24px 0 0' : '0 0 16px 0')};
  ${mq.mobile} {
    width: ${({ horizontal }) => (horizontal ? '120px' : '100%')};
    height: 120px;
    margin: ${({ horizontal }) => (horizontal ? '0 16px 0 0' : '0 0 8px 0')};
  }
`
const LogoImg = styled.div`
  ${({ horizontal }) =>
    horizontal
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          width: 128px;
          height: 128px;
          ${mq.mobile} {
            width: 96px;
            height: 96px;
          }
        `}
  background-color: ${getColor('skeleton')};
`
const TextContent = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
`
const Name = styled.div`
  width: 100%;
  height: ${18 * 1.5 * 2}px;
  ${({ horizontal }) =>
    !horizontal &&
    css`
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    `}
`
const Description = styled.div`
  width: 100%;
  margin: 8px 0 0 0;
`
const Bar = styled.div`
  width: ${({ long, mid }) => (long ? '100%' : mid ? '70%' : '45%')};
  height: 20px;
  ${({ long }) =>
    long &&
    css`
      margin-bottom: 8px;
    `}
  ${loadingAnimation}
`
const NameBar = styled(Bar)`
  &:first-of-type {
    max-width: 180px;
  }
`
const DescBar = styled(Bar)`
  ${mq.mobile} {
    &:nth-of-type(2) {
      display: none;
    }
    &:last-of-type {
      width: 45%;
    }
  }
`

const CorporateLogo = React.memo(({ horizontal, showDescription }) => {
  return (
    <Wrapper horizontal={horizontal}>
      <Logo horizontal={horizontal}>
        <LogoImg horizontal={horizontal} />
      </Logo>
      <TextContent>
        <Name horizontal={horizontal}>
          {horizontal ? (
            <>
              <Bar long />
              <Bar />
            </>
          ) : (
            <>
              <NameBar long />
              <NameBar />
            </>
          )}
        </Name>
        {showDescription && (
          <Description>
            <DescBar long />
            <DescBar long />
            <DescBar mid />
          </Description>
        )}
      </TextContent>
    </Wrapper>
  )
})

export default CorporateLogo
