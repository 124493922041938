import * as R from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { animated, config, useSpring } from 'react-spring'
import styled from 'styled-components'

const Meter = styled.div`
  font-size: inherit;
  display: flex;
  font-display: swap;
  flex-wrap: nowrap;
  font-family: inherit;
  * {
    font-family: inherit;
  }
`
const SsrHidden = styled.div`
  opacity: 0;
`
const DigitWrapper = styled.div`
  height: 1.2em;
  width: auto;
  overflow: hidden;
`
const Digit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.3em;
  width: auto;
  line-height: 1;
  box-sizing: content-box;
  letter-spacing: -3px;
  user-select: ${({ isLast }) => (isLast ? 'auto' : 'none')};
`

const getRand = n => Math.floor(Math.random() * n)

const Count = ({ shouldStart, endDigit }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)

  const rand = getRand(10)

  const end = useMemo(() => {
    const zeroToTen = R.unnest(R.repeat([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], rand))

    return R.concat(zeroToTen, Array.from(Array(endDigit + 1).keys()))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDigit])

  const style = useSpring({
    transform:
      shouldAnimate && shouldStart
        ? `translateY(-${(R.length(end) - 1) * 1.3}em)`
        : `translateY(0em)`,
    config: [config.molasses, config.default, config.slow][getRand(3)]
  })

  useEffect(() => {
    setShouldAnimate(true)
  }, [])

  return (
    <DigitWrapper>
      {shouldAnimate ? (
        <animated.div style={style}>
          {end.map((val, i) => (
            <Digit key={i} isLast={i === R.length(end) - 1}>
              {val}
            </Digit>
          ))}
        </animated.div>
      ) : (
        <SsrHidden>{endDigit}</SsrHidden>
      )}
    </DigitWrapper>
  )
}

const Odometer = ({ children, start = true, ...props }) => {
  if (R.isNil(children)) return null

  const splitted = children
    .toString()
    .split('')
    .map(char => (isNaN(Number(char)) ? char : Number(char)))

  return (
    <Meter>
      {splitted.map((char, i) =>
        Number.isInteger(char) ? (
          <Count shouldStart={start} endDigit={char} key={i} />
        ) : (
          <Digit key={i}>{char}</Digit>
        )
      )}
    </Meter>
  )
}

export default Odometer
