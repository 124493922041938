import React from 'react'
import styled from 'styled-components'

import { getColor, getFontSize } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'

const SectionTitle = styled.h2`
  font-size: ${getFontSize('headline')};
  font-weight: 600;
  line-height: 1.45;
  color: ${({ $color }) => $color || getColor('theme')};
  &:: after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    border-radius: 10px;
    background-color: ${({ $color }) => $color || getColor('theme')};
    margin-top: 6px;
    margin-bottom: 15px;
    ${mq.tablet} {
      margin-bottom: 11px;
    }
    ${mq.mobile} {
      margin-bottom: 24px;
    }
  }
`

export default React.memo(({ color, children, className }) => (
  <SectionTitle $color={color} className={className}>
    {children}
  </SectionTitle>
))
