import { createActions } from 'redux-actions'

export const {
  loginRequest,
  loginSucceeded,
  loginFailed,
  logoutRequest,
  checkLoginRequest,
  checkLoginSucceeded,
  checkBindPhoneRequest,
  checkBindPhoneSucceeded,
  bindPhoneRequest,
  bindPhoneFinished,
  memberVisitRequest,
  authorizeVisitRequest,
  getHk01MemberProfileSucceeded,
  refreshLoginStatusRequest,
  refreshLoginStatusSucceeded,
  refreshJwtRequest,
  refreshJwtSucceeded,
  getMemberProfileDataRequest,
  getMemberProfileDataSucceeded,
  updateMemberProfileDataRequest,
  updateMemberProfileDataSucceeded
} = createActions({
  LOGIN_REQUEST: undefined,
  LOGIN_SUCCEEDED: isLoggedIn => ({ isLoggedIn }),
  LOGIN_FAILED: undefined,
  LOGOUT_REQUEST: undefined,
  CHECK_LOGIN_REQUEST: undefined,
  CHECK_LOGIN_SUCCEEDED: isLoggedIn => ({ isLoggedIn }),
  CHECK_BIND_PHONE_REQUEST: undefined,
  CHECK_BIND_PHONE_SUCCEEDED: (isBoundPhone, isMergingAccount) => ({
    isBoundPhone,
    isMergingAccount
  }),
  BIND_PHONE_REQUEST: redirectPath => ({ redirectPath }),
  BIND_PHONE_FINISHED: undefined,
  MEMBER_VISIT_REQUEST: (href, as) => ({ href, as }),
  AUTHORIZE_VISIT_REQUEST: (href, as) => ({ href, as }),
  GET_HK01_MEMBER_PROFILE_SUCCEEDED: hk01MemberProfile => ({
    hk01MemberProfile
  }),
  REFRESH_LOGIN_STATUS_REQUEST: undefined,
  REFRESH_LOGIN_STATUS_SUCCEEDED: undefined,
  REFRESH_JWT_REQUEST: undefined,
  REFRESH_JWT_SUCCEEDED: undefined,
  GET_MEMBER_PROFILE_DATA_REQUEST: undefined,
  GET_MEMBER_PROFILE_DATA_SUCCEEDED: memberProfile => ({ memberProfile }),
  UPDATE_MEMBER_PROFILE_DATA_REQUEST: ({
    agreeToAutoFill,
    receiptEmail,
    receiptName
  }) => ({
    agreeToAutoFill,
    receiptEmail,
    receiptName
  }),
  UPDATE_MEMBER_PROFILE_DATA_SUCCEEDED: memberProfile => ({ memberProfile })
})
