import Router from 'next/router'
import * as R from 'ramda'
import URI from 'urijs'

import {
  POST_DONATE_PHASE1,
  POST_DONATE_PHASE2,
  POST_DONATE_PHASE3,
  PROJECT_STATUS
} from '../constants'
import { routesConfig } from '../routes'
import jssdk from './sdkUtils'

const moment = require('moment-timezone')

export const scrollToTop = () => window && window.scrollTo(0, 0)

export const resizeImg = (imageUrl, width) => {
  if (R.test(/^data:image/, imageUrl)) return imageUrl

  if (
    R.startsWith(process.env.HEART_CDN_URL, imageUrl) ||
    R.startsWith(process.env.LETZGOAL_CDN_URL, imageUrl)
  ) {
    return URI(imageUrl)
      .setQuery({
        w: width
      })
      .toString()
  }

  if (R.startsWith(process.env.ARTICLE_CDN_URL, imageUrl)) {
    // media thumbor supports width from 320 to 1920 only
    const predefined = [
      1920,
      1760,
      1600,
      1440,
      1280,
      1120,
      960,
      800,
      640,
      480,
      320
    ]

    // get nearest width if not in predefined
    const safeWidth = predefined.reduce((prev, curr) =>
      Math.abs(prev - width) > Math.abs(curr - width) ? curr : prev
    )

    return URI(imageUrl)
      .setQuery({
        v: `w${safeWidth}`
      })
      .toString()
  }

  return imageUrl
}

const parentsMatches = (fromEl, toSelector, matchSelector) => {
  let matchEl = null
  for (; fromEl !== document; fromEl = fromEl.parentNode) {
    if (fromEl.matches(matchSelector)) {
      matchEl = fromEl
      break
    } else if (fromEl.matches(toSelector)) {
      break
    }
  }
  return matchEl
}

export const hk01LinkClient = target => {
  const isWebview = jssdk.isWebview()

  const goTo01ArticleRequest = jssdk.goTo01Article

  const goTo01PageRequest = jssdk.goTo01Page

  const projectPathRegex = /(?:\/zh)?\/project\/(\d+)/

  const orgPathRegex = /(?:\/zh)?\/org\/(ORG\d{5})/

  const articleRegex = R.equals(process.env.APP_ENV, 'production')
    ? /^https:\/\/www\.hk01\.com\/[^/]+\/(\d+)\/.+$/
    : /^https:\/\/www\.hktester\.com\/[^/]+\/(\d+)\/.+$/

  const uri = target.href ? new URI(target.href) : new URI(target)

  const origin = uri.origin()

  const domain = uri.domain()

  const href = uri.href()

  const pathname = uri.pathname()

  const targetWindow = R.defaultTo(null)(target.target)

  const isHeartPage =
    process.env.APP_ENV === 'production'
      ? R.equals(process.env.HEART_URL, origin)
      : R.equals('hkdev.com', domain) || R.equals(process.env.HEART_URL, origin)

  // allow visiting faq page by opening a new webview
  const isHk01Page = R.includes(domain, ['hk01.com', 'hktester.com'])

  const isProjectPage = R.test(projectPathRegex, pathname)

  const isOrgPage = R.test(orgPathRegex, pathname)

  const isHk01Article = R.test(articleRegex, href)

  const articleId = R.match(articleRegex, href)[1]

  const goToHeartPage = () => {
    if (isProjectPage || isOrgPage) {
      Router.push(uri.resource()) // allowed by dynamic routes
    } else {
      Router.push(R.replace(origin, '', href))
    }
  }
  if (isWebview) {
    if (isHeartPage) {
      goToHeartPage()
    } else if (isHk01Page) {
      if (isHk01Article) {
        goTo01ArticleRequest(articleId)
      } else {
        goTo01PageRequest(href)
      }
    } else {
      goTo01PageRequest(href)
    }
  } else {
    // browser, not webview
    if (isHeartPage) {
      goToHeartPage()
    } else if (isHk01Page) {
      if (targetWindow === '_blank') {
        window.open(href, '_blank')
      } else {
        window.location.href = href
      }
    } else {
      window.open(href, '_blank')
    }
  }
}

export const hk01LinkHandler = e => {
  e.preventDefault()
  const parentALink = parentsMatches(e.target, '.projectDetailsTab', 'a')
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isALink = R.useWith(R.equals('a'), [R.toLower])
  if (
    isALink(e.currentTarget.tagName) ||
    isALink(e.target.tagName) ||
    parentALink
  ) {
    const target = R.or(
      parentALink,
      isALink(e.currentTarget.tagName) ? e.currentTarget : e.target
    )
    hk01LinkClient(target)
  }
}

export const computeProjectStatus = (startTime, endTime) => {
  if (moment().isBefore(startTime)) {
    return PROJECT_STATUS.COMING_SOON
  } else {
    // started
    if (!R.isNil(endTime) && moment().isAfter(endTime)) {
      return PROJECT_STATUS.ENDED
    }
    return PROJECT_STATUS.IN_PROGRESS
  }
}

export const detectMobile = userAgent => {
  const PLATFORM_TYPE = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
    APP: 'app'
  }
  const detectIsApp = userAgent => {
    return /app\/com\.hk01\.\w+-app/i.test(userAgent)
  }
  const isApp = detectIsApp(userAgent)
  if (isApp) {
    return {
      detectedPlatform: PLATFORM_TYPE.APP,
      isMobile: true
    }
  }
  const isAndroid =
    userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1
  const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  return {
    detectedPlatform:
      isAndroid || isIOS ? PLATFORM_TYPE.MOBILE : PLATFORM_TYPE.DESKTOP,
    isMobile: isAndroid || isIOS,
    isIOS
  }
}

export const getLoginOpts = url => {
  if (R.isNil(url)) {
    return {
      campaign: null
    }
  }

  const getCampaignAttributionFromUrl = url => {
    const uri = new URI(url)

    const uriSegment = uri.segment()

    const isRedirectPage = R.includes('redirect', uriSegment)

    const isProjectPage = R.includes('project', uriSegment)

    const isFundraisingEventCreateForm =
      R.includes('fundraising', uriSegment) && R.includes('create', uriSegment)

    const isFundraisingEvent =
      R.includes('fundraising', uriSegment) && !R.includes('create', uriSegment)

    let attribution

    if (isRedirectPage) {
      const { project, fundraisingEventNo } = URI.parseQuery(uri.search())
      attribution = R.isNil(fundraisingEventNo) ? project : fundraisingEventNo
    } else if (isProjectPage || isFundraisingEvent) {
      attribution = R.last(uriSegment)
    } else if (isFundraisingEventCreateForm) {
      attribution = R.concat(R.last(uriSegment), '_fundraising')
    }

    return attribution || null
  }

  return {
    campaign: getCampaignAttributionFromUrl(url)
  }
}

export const sleep = ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

// if ORGXXXXX return true
export const orgIdentityChecker = orgId => R.test(/^ORG[0-9]{5}$/, orgId)

export const getPicProps = (imgUrl, sizes, minMediaWidth, maxMediaWidth) => {
  const predefWidth = process.env.THUMBOR_PREDEF_WIDTH
  const getMaxInList = R.reduce(R.max, -Infinity, R.__)
  const getMinInList = R.reduce(R.min, Infinity, R.__)
  const minBreakPt = R.defaultTo(getMinInList(predefWidth))(minMediaWidth)
  const maxBreakPt = R.defaultTo(getMaxInList(predefWidth))(
    R.equals(minMediaWidth, maxMediaWidth) ? null : maxMediaWidth
  )
  const getLargerSiblingInArray = (n, arr) =>
    R.reduce(
      (acc, val) => {
        const max = getMaxInList(arr)
        return R.gte(n, max) ? max : R.gte(val, n) ? val : acc
      },
      0,
      arr
    )
  const minImgWidth = getLargerSiblingInArray(minBreakPt, predefWidth)
  const maxImgWidth = getLargerSiblingInArray(maxBreakPt, predefWidth)
  const srcListWidth = R.reject(
    R.gt(R.__, maxImgWidth),
    R.reject(R.lt(R.__, minImgWidth), predefWidth)
  )
  const srcset = R.map(
    width => `${resizeImg(imgUrl, width)} ${width}w`,
    srcListWidth
  )

  const media = R.isNil(minMediaWidth) ? null : `(min-width: ${minBreakPt}px)`
  return {
    media,
    sizes,
    srcSet: R.join(',', srcset)
  }
}

export const getPageNameFromCurrentPath = currentPath =>
  R.pipe(
    R.pickBy(val => val.link.href === currentPath),
    R.keys,
    R.head
  )(routesConfig)

export const textRepeatCounter = (input, search) =>
  (input.match(new RegExp(search, 'gi')) || []).length

export const computePostDonatePhase = paidTime => {
  const timeDiff = moment().diff(moment(paidTime), 'minutes')
  return R.cond([
    [
      R.lt(R.__, process.env.PROMO_CODE_EDIT_TIMEOUT),
      R.always(POST_DONATE_PHASE1)
    ],
    [
      R.both(
        R.gte(R.__, process.env.PROMO_CODE_EDIT_TIMEOUT),
        R.lt(R.__, process.env.ORDER_EDIT_TIMEOUT)
      ),
      R.always(POST_DONATE_PHASE2)
    ],
    [R.gte(R.__, process.env.ORDER_EDIT_TIMEOUT), R.always(POST_DONATE_PHASE3)]
  ])(timeDiff)
}

export class SafeSetInterval {
  constructor(fn, interval) {
    this.interval = interval
    this.fn = fn
    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
  }

  start() {
    this.startTime = new Date().getTime()
    this.timerFn = () => {
      const drift = (new Date().getTime() - this.startTime) / 1000
      const adjustedInterval = Math.max(0, this.interval - drift)
      this.startTime = new Date().getTime()
      this.fn()
      this.timer = setTimeout(this.timerFn, adjustedInterval)
    }
    this.timer = setTimeout(this.timerFn, this.interval)
    return this
  }

  stop() {
    clearTimeout(this.timer)
    return this
  }

  reset() {
    this.stop()
    this.start()
  }
}

export const isSsr = typeof window === 'undefined'
