import * as R from 'ramda'
import { call } from 'redux-saga/effects'

import { handleSyncAsync } from '../../../utils/reduxUtils'
import { memberAuthorizer } from '../../auth/saga/authSaga'
import { getProjectDetailWorker } from '../../project/saga/projectSaga'
import {
  getFundraisingCreatorStatisticsWorker,
  getMemberFundraisingWorker
} from './fundraisingSaga'

// member
const getMemberFundraisingPage = memberAuthorizer(function* (token, action) {
  const { fundraisingEventNo } = action.payload
  const fundraisingData = yield call(
    getMemberFundraisingWorker,
    fundraisingEventNo,
    token
  )
  const projectNo = R.path(['project', 'projectNo'], fundraisingData)
  yield call(getProjectDetailWorker, projectNo)
  yield call(getFundraisingCreatorStatisticsWorker, fundraisingEventNo)
  return fundraisingData
})

export const getMemberFundraisingPageWorker = handleSyncAsync(
  getMemberFundraisingPage
)
