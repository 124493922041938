import { promiseMiddleware } from '@adobe/redux-saga-promise'
import reduceReducers from 'reduce-reducers'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { nextDispatch } from '../utils/reduxUtils'
import appReducer, { appInitialState } from './app/reducers'
import articleReducer, { articleInitialState } from './article/reducers'
import authReducer, { authInitialState } from './auth/reducers'
import categoryReducer, { categoryInitialState } from './category/reducers'
import corporateReducer, { corporateInitialState } from './corporate/reducers'
import donorReducer, { donorInitialState } from './donor/reducers'
import fileReducer, { fileInitialState } from './file/reducers'
import fundraisingReducer, {
  fundraisingInitialState
} from './fundraising/reducers'
import masterReducer from './master/reducers'
import orderReducer, { orderInitialState } from './order/reducers'
import paymentReducer, { paymentInitialState } from './payment/reducers'
import projectReducer, { projectInitialState } from './project/reducers'
import { rootSaga } from './rootSaga'

const combinedReducers = combineReducers({
  appState: appReducer,
  authState: authReducer,
  orderState: orderReducer,
  projectState: projectReducer,
  paymentState: paymentReducer,
  corporateState: corporateReducer,
  articleState: articleReducer,
  fileState: fileReducer,
  fundraisingState: fundraisingReducer,
  categoryState: categoryReducer,
  donorState: donorReducer
})

const rootReducer = reduceReducers(combinedReducers, masterReducer)

export const rootInitialState = {
  appState: appInitialState,
  authState: authInitialState,
  orderState: orderInitialState,
  projectState: projectInitialState,
  paymentState: paymentInitialState,
  corporateState: corporateInitialState,
  articleState: articleInitialState,
  fileState: fileInitialState,
  fundraisingState: fundraisingInitialState,
  categoryState: categoryInitialState,
  donorState: donorInitialState
}

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export function initializeStore(
  initialState = rootInitialState,
  { isServer, req = null }
) {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(promiseMiddleware, sagaMiddleware))
  )

  store.nextDispatch = nextDispatch(store)

  if (req || !isServer) {
    store.sagaTask = sagaMiddleware.run(rootSaga)
  }
  return store
}
