import React from 'react'
import styled from 'styled-components'

import mq from '../../../utils/mediaQuery'
import { loadingAnimation } from '../BackgroundEffect'

const Category = styled.div`
  flex-shrink: 0;
  margin-bottom: 16px;
  ${mq.mobile} {
    margin-bottom: 0;
    margin-right: 16px;
  }
`
const Anchor = styled.div`
  width: 120px;
  height: 18px;
  ${loadingAnimation};
`
const CorporateCategoryName = () => (
  <Category>
    <Anchor />
  </Category>
)

export default CorporateCategoryName
