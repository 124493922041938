import * as R from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTransition } from 'react-spring'
import styled from 'styled-components'

import Feedback from '../../components/Feedback'
import { dismissFeedback } from '../../redux/app/actions'
import { getFeedbackStatus } from '../../redux/app/selectors'

const FeedbackWrapper = styled.div`
  position: fixed;
  bottom: 100%;
  z-index: 12; // notification is 11
  width: 100%;
  display: flex;
  justify-content: center;
`

const dismissTimeout = 5000

const dismissAnimationDuration = 300

const FeedbackBar = React.memo(({ className }) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  const dismissTimer = useRef(null)

  const dismissAnimationTimer = useRef(null)

  const haveFeedback = useSelector(getFeedbackStatus)

  const [text, type = null, ts] = useSelector(
    R.paths([
      ['appState', 'feedback'],
      ['appState', 'feedbackType'],
      ['appState', 'feedbackTs']
    ]),
    R.equals
  )

  const slideIn = useTransition(show, {
    from: { opacity: 0, transform: 'translate3d(0,calc(0% + 0px),0)' },
    enter: {
      opacity: 1,
      transform: 'translate3d(0,calc(100% + 80px),0)'
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0,calc(100% + 80px),0)',
      config: { duration: dismissAnimationDuration }
    },
    reset: show,
    expires: false
  })

  const clearTimers = () => {
    if (dismissTimer.current) clearTimeout(dismissTimer.current)

    if (dismissAnimationTimer.current)
      clearTimeout(dismissAnimationTimer.current)
  }

  const handleFeedbackClick = () => {
    setShow(false)
    setTimeout(() => {
      dispatch(dismissFeedback())
    }, dismissAnimationDuration)
    clearTimers()
  }

  useEffect(() => {
    if (haveFeedback) {
      dismissTimer.current = setTimeout(() => {
        dispatch(dismissFeedback())
      }, dismissTimeout + dismissAnimationDuration)

      dismissAnimationTimer.current = setTimeout(() => {
        setShow(false)
      }, dismissTimeout)

      setShow(true)
    }

    return () => {
      clearTimers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveFeedback, ts])

  return (
    <FeedbackWrapper>
      {slideIn(
        (styles, show) =>
          show && (
            <Feedback
              style={styles}
              type={type}
              onClick={handleFeedbackClick}
              className={className}
            >
              {text}
            </Feedback>
          )
      )}
    </FeedbackWrapper>
  )
})

export default FeedbackBar
