import * as R from 'ramda'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import SvgIcon from '../../components/SvgIcon'
import { usePrev } from '../../hooks'
import WarningSvg from '../../public/static/images/icon-warning.svg'
import { dismissFeedback } from '../../redux/app/actions'
import { getFeedbackStatus } from '../../redux/app/selectors'
import { getColor, safeArea } from '../../utils/cssUtils'

const Alert = styled.div`
  margin-top: 18px;
  line-height: 1;
  display: flex;
  align-items: center;
  margin: 0px;
  background-color: ${getColor('theme')};
  width: 100%;
  height: 48px;
  padding: 0 16px;
  span {
    color: #fff;
  }
  svg {
    fill: white;
  }
  ${({ hasBottomNav }) =>
    !hasBottomNav &&
    css`
      ${safeArea(
        inset => css`
          padding-bottom: ${inset};
          height: calc(${inset} + 48px);
        `
      )}
    `}
`
const StyledSvgIcon = styled(SvgIcon)`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`

const AppviewAlert = ({ className, hasBottomNav }) => {
  const dispatch = useDispatch()
  const show = useSelector(getFeedbackStatus)
  const text = useSelector(R.path(['appState', 'feedback']))
  const timer = useRef()

  const prevShow = usePrev(show)
  const prevText = usePrev(text)

  const handleClick = useCallback(e => {
    e.nativeEvent.stopImmediatePropagation()
    clearTimeout(timer.current)
    dispatch(dismissFeedback())
  }, [])

  useEffect(() => {
    if (!prevShow && show) {
      timer.current = setTimeout(() => {
        dispatch(dismissFeedback())
      }, 5000)
    }
    // within 5s and alert with other msg
    if (prevShow && show && prevText !== text) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        dispatch(dismissFeedback())
      }, 5000)
    }
  }, [show])

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  if (!show) return null

  return (
    <Alert
      className={className}
      hasBottomNav={hasBottomNav}
      onClick={handleClick}
    >
      <StyledSvgIcon svg={<WarningSvg />} color='#fff' />
      <span>{text}</span>
    </Alert>
  )
}

export default AppviewAlert
