import { useRouter } from 'next/router'
import * as R from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'

import { MemberAvatarInfo } from '../../../components/CardAvatar'
import ProjectCardComponent from '../../../components/ProjectCard'
import {
  DEFAULT_PROFILE_URL,
  FUNDRAISING_STATUS,
  PROJECT_STATUS,
  PROJECT_TYPE
} from '../../../constants'
import { useTracking } from '../../../hooks'
import {
  getFundraisingData,
  getFundraisingStatData,
  getFundraisingStatus,
  getFundraisingStatusText
} from '../../../redux/fundraising/selectors'
import { getPageNameFromCurrentPath } from '../../../utils/commonUtils'
import { homeClickFundraisingEventCard } from '../../../utils/dataTracker'
import CardProgress from '../../ProjectCard/CardProgress'
import MyAcctCardContent from '../MyAcctCardContent'

const FundraisingCardContainer = ({
  fundraisingEventNo,
  listView,
  index,
  currentPage,
  ...props
}) => {
  const router = useRouter()

  const track = useTracking()

  const currentTs = new Date().getTime()

  const pageName = getPageNameFromCurrentPath(router.pathname)

  const isInMyAccount = R.includes('MY_', pageName)

  const isAppview = useSelector(R.path(['appState', 'isAppview']))

  const {
    titleImage,
    title,
    creator,
    donationTarget,
    endTime,
    project
  } = useSelector(getFundraisingData(fundraisingEventNo), R.equals)

  const { totalNoOfDonation, totalDonationAmount } = useSelector(
    getFundraisingStatData(fundraisingEventNo),
    R.equals
  )

  const fundraisingStatus = useSelector(
    getFundraisingStatus(fundraisingEventNo),
    R.equals
  )

  const statusText = useSelector(
    getFundraisingStatusText(fundraisingEventNo),
    R.equals
  )

  const isDraft = fundraisingStatus === FUNDRAISING_STATUS.DRAFT

  // eslint-disable-next-line no-unused-vars
  const isArchived = fundraisingStatus === FUNDRAISING_STATUS.ARCHIVED

  const href = isInMyAccount
    ? isArchived
      ? null
      : `/fundraising/${isDraft ? 'edit/' : ''}${fundraisingEventNo}`
    : `/fundraising/${fundraisingEventNo}`

  const fallbackImg = R.pathOr('', ['titleImage', 'url'], project)

  const fireClickTracking = () => {
    track(homeClickFundraisingEventCard(fundraisingEventNo, title))
  }

  return (
    <ProjectCardComponent
      avatar={
        <MemberAvatarInfo
          listView={listView}
          avatar={R.pipe(
            R.prop('avatarUrl'),
            R.when(R.either(R.isEmpty, R.isNil), R.always(DEFAULT_PROFILE_URL))
          )(creator)}
          name={R.prop('displayName', creator)}
        />
      }
      fundraisingProgress={
        isInMyAccount ? (
          <MyAcctCardContent
            fundraisingEventNo={fundraisingEventNo}
            status={fundraisingStatus}
            totalDonatedAmount={totalDonationAmount}
            donationTarget={donationTarget}
            endTime={endTime}
            donationCount={totalNoOfDonation}
          />
        ) : (
          <CardProgress
            projectNo={fundraisingEventNo}
            projectType={PROJECT_TYPE.WITH_TARGET}
            donationTarget={donationTarget}
            startTime={currentTs}
            endTime={endTime}
            donationCount={totalNoOfDonation}
            totalDonatedAmount={totalDonationAmount}
            projectStatus={
              currentTs > endTime
                ? PROJECT_STATUS.ENDED
                : PROJECT_STATUS.IN_PROGRESS
            }
            projectCardStatusDescription={statusText}
          />
        )
      }
      mainImg={R.propOr(fallbackImg, 'url', titleImage)}
      name={title}
      id={fundraisingEventNo}
      listView={listView}
      href={href}
      showHoverEffect={!isArchived}
      inActive={isArchived}
      clickTracking={fireClickTracking}
      isAppview={isAppview}
      {...props}
    />
  )
}

export default FundraisingCardContainer
