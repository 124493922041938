import { all, takeLatest } from 'redux-saga/effects'

import {
  getPartnerOrderInfoRequest,
  patchPartnerOrderRequest,
  patchPartnerOrderSucceeded
} from '../actions'
import {
  getPartnerOrderInfoWorker,
  patchPartnerOrderWorker,
  redirectToWalletWorker
} from './orderSaga'

export function* orderWatcher() {
  try {
    yield all([
      takeLatest(getPartnerOrderInfoRequest, getPartnerOrderInfoWorker),
      takeLatest(patchPartnerOrderRequest, patchPartnerOrderWorker),
      takeLatest(patchPartnerOrderSucceeded, redirectToWalletWorker)
    ])
  } catch (err) {
    console.log(err)
  }
}
