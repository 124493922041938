import Link from 'next/link'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { AppviewNavContainer } from '../../components/Appview/shared'
import Button from '../Button'
import TabNav from '../../components/TabNav'
import { routesConfig } from '../../routes'
import { PAGE, PROJECT_STATUS } from '../../constants'
import { useTracking } from '../../hooks'
import { withTranslation } from '../../i18n'
import { toggleDonationItemDrawer } from '../../redux/app/actions'
import { getFundraisingData } from '../../redux/fundraising/selectors'
import {
  getCurrentProjectName,
  getCurrentProjectNo,
  getIsDonationDisabled,
  getProjectStatus
} from '../../redux/project/selectors'
import { getPageNameFromCurrentPath } from '../../utils/commonUtils'
import {
  detailClickDonatePopup,
  detailClickHome,
  fundraisingDetailClickAppviewHomeBtn,
  fundraisingDetailClickAppviewShowDrawerBtn
} from '../../utils/dataTracker'

const StyledDonateNow = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 16px;
`
const HomeTab = styled.a`
  width: 125px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: -16px;
  flex-shrink: 0;
`

const DonateNow = ({ t }) => {
  const dispatch = useDispatch()

  const track = useTracking()

  const router = useRouter()

  const currentProjectNo = useSelector(getCurrentProjectNo)

  const currentProjectName = useSelector(getCurrentProjectName)

  const isDonationDisabled = useSelector(state =>
    getIsDonationDisabled(state, { projectNo: currentProjectNo })
  )

  const projectStatus = useSelector(state =>
    getProjectStatus(state, { projectNo: currentProjectNo })
  )

  const pageName = getPageNameFromCurrentPath(router.pathname)

  const isFundraisingEvent = pageName === PAGE.FUNDRAISING_DETAIL

  const { fundraisingEventNo } = router.query

  const { title: fundraisingEventTitle, endTime } = useSelector(
    getFundraisingData(fundraisingEventNo),
    R.equals
  )

  const isFundraisingEventArchived = useSelector(
    R.path(['fundraisingState', 'isFundraisingEventArchived'])
  )

  const currentTs = new Date().getTime()

  const trackOpenDrawer = isFundraisingEvent
    ? () =>
        track(
          fundraisingDetailClickAppviewShowDrawerBtn(
            fundraisingEventNo,
            fundraisingEventTitle
          )
        )
    : detailClickDonatePopup.bind(null, currentProjectNo, currentProjectName)

  const trackHomeBtnClick = isFundraisingEvent
    ? () =>
        track(
          fundraisingDetailClickAppviewHomeBtn(
            fundraisingEventNo,
            fundraisingEventTitle
          )
        )
    : detailClickHome.bind(null, currentProjectNo, currentProjectName)

  const handleDonateBtnClick = () => {
    dispatch(toggleDonationItemDrawer())
    trackOpenDrawer()
  }

  const homeItem = R.pathOr(null, [PAGE.HOME], routesConfig)

  const menuTitle = R.pathOr(null, ['menuTitle'], homeItem)

  const href = R.pathOr('', ['link', 'href'], homeItem)

  const as = R.pathOr('', ['link', 'as'], homeItem)

  const btnText = isFundraisingEvent
    ? currentTs > endTime
      ? t('personalFundraising.fundraisingEnded.donateNow')
      : t('personalFundraising.ctaButton.donateNow')
    : t(
        R.cond([
          [
            R.equals(PROJECT_STATUS.ENDED),
            R.always('common.endedAndBelowTarget')
          ],
          [R.equals(PROJECT_STATUS.COMING_SOON), R.always('common.comingSoon')],
          [R.T, R.always('project.donateNow')]
        ])(projectStatus)
      )

  const isVisuallyDisabled = isFundraisingEvent
    ? currentTs > endTime
    : isDonationDisabled

  return (
    !isFundraisingEventArchived && (
      <AppviewNavContainer>
        <StyledDonateNow>
          <Link href={href} as={as} passHref>
            <HomeTab onClick={trackHomeBtnClick}>
              <TabNav icon={homeItem.menuIcon} text={menuTitle} />
            </HomeTab>
          </Link>
          <Button
            onClick={handleDonateBtnClick}
            visuallyDisabled={isVisuallyDisabled}
            fullWidth
          >
            {btnText}
          </Button>
        </StyledDonateNow>
      </AppviewNavContainer>
    )
  )
}

export default withTranslation('common')(DonateNow)
