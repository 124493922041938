import { normalize } from 'normalizr'
import * as R from 'ramda'
import { call, put } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import {
  getArticleListByIdSucceeded,
  getArticleListByTagIdsSucceeded,
  getArticleListByTagIdSucceeded
} from '../actions'
import { articleListSchema } from '../schema'

export function* getArticleListByIdWorker(action) {
  try {
    const { articleIds } = action.payload
    const articleList = yield call(charityApi.getArticleListById, articleIds)
    yield put(
      getArticleListByIdSucceeded(
        normalize(R.prop('items', articleList), articleListSchema)
      )
    )
  } catch (err) {
    console.log(err)
  }
}
export function* getArticleListByTagIdWorker(action) {
  try {
    const { tagId, offset, limit } = action.payload
    const articleData = yield call(
      charityApi.getArticleListByTagId,
      tagId,
      offset,
      limit
    )
    yield put(
      getArticleListByTagIdSucceeded(
        normalize(R.prop('items', articleData), articleListSchema),
        R.prop('nextOffset', articleData)
      )
    )
  } catch (err) {
    console.log(err)
  }
}

export function* getArticleListByTagIdsWorker(action) {
  try {
    const { tagIds, offset, limit } = action.payload
    const articleData = yield call(
      charityApi.getArticleListByTagIds,
      tagIds,
      offset,
      limit
    )
    yield put(
      getArticleListByTagIdsSucceeded(
        normalize(R.prop('items', articleData), articleListSchema),
        R.prop('nextOffset', articleData)
      )
    )
  } catch (err) {
    console.log(err)
  }
}
