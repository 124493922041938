import React from 'react'
import styled from 'styled-components'

import MaxWidth from '../components/Layout/MaxWidth'
import CloseSvg from '../public/static/images/icon-close.svg'
import { getColor } from '../utils/cssUtils'
import SvgIcon from './SvgIcon'

const Wrapper = styled.div`
  z-index: 11;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  a {
    color: #fff;
    &:hover {
      color: ${getColor('pink')};
    }
  }
`
const Content = styled.div`
  padding: 18px 0;
`
const CloseBtn = styled.a`
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`

const Notification = React.memo(({ className, message, onClose }) => (
  <Wrapper className={className}>
    <MaxWidth>
      <Content>
        <CloseBtn onClick={onClose} data-test-id='notification-close-btn'>
          <SvgIcon
            svg={<CloseSvg />}
            css={`
              width: 14px;
              height: 14px;
            `}
          />
        </CloseBtn>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Content>
    </MaxWidth>
  </Wrapper>
))

export default Notification
