import * as R from 'ramda'

export const emptyStrToNull = R.when(
  R.both(R.is(String), R.isEmpty),
  R.always(null)
)

export const isNilOrEmpty = R.either(R.isNil, R.isEmpty)

export const isExist = R.complement(R.either(R.isNil, R.isEmpty))
