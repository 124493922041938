import React from 'react'

import { PAGE } from './constants'
import HeartLogo from './public/static/images/hk01-heart-logo.svg'
import AboutUsSvg from './public/static/images/icon-about-us.svg'
import AccountSvg from './public/static/images/icon-account.svg'
import ArticleSvg from './public/static/images/icon-article.svg'
import FaqSvg from './public/static/images/icon-faq.svg'
import HomeSvg from './public/static/images/icon-home.svg'
import MyDonationSvg from './public/static/images/icon-my-donation.svg'
import MyInfoSvg from './public/static/images/icon-my-info.svg'
import PartnerSvg from './public/static/images/icon-partner.svg'
import SearchSvg from './public/static/images/icon-search.svg'
import TAndCSvg from './public/static/images/icon-t-and-c.svg'

export const routesConfig = {
  [PAGE.HOME]: {
    title: <HeartLogo />,
    link: {
      href: '/',
      as: '/'
    },
    menuIcon: <HomeSvg />,
    menuTitle: '主頁',
    appview: {
      showNav: true,
      isTabbed: true,
      showShare: true
    },
    utm: {
      utm_source: 'heart_home_share',
      utm_medium: 'referral'
    }
  },
  [PAGE.DISCOVER]: {
    title: '探索',
    link: {
      href: '/discover',
      as: '/discover'
    },
    menuIcon: <SearchSvg />,
    menuTitle: '探索',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: true
    },
    utm: {
      utm_source: 'heart_listing_share',
      utm_medium: 'referral'
    }
  },
  [PAGE.PROJECT]: {
    title: '項目詳情',
    link: {
      href: '/project/[projectNo]/[projectName]',
      as: '/project/'
    },
    menuIcon: null,
    menuTitle: '項目詳情',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: true
    },
    utm: {
      utm_source: 'heart_detail_share',
      utm_medium: 'referral'
    }
  },
  [PAGE.FUNDRAISING_DETAIL]: {
    title: '個人籌款項目詳情',
    link: {
      href: '/fundraising/[fundraisingEventNo]',
      as: '/fundraising/'
    },
    menuIcon: null,
    menuTitle: '個人籌款項目詳情',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: true
    },
    utm: {
      utm_source: 'heart_detail_share',
      utm_medium: 'referral'
    }
  },
  [PAGE.FUNDRAISING_CREATE]: {
    title: '自設個人籌款專頁內容',
    link: {
      href: '/fundraising/create/[projectNo]',
      as: '/fundraising/create/'
    },
    menuIcon: null,
    menuTitle: '',
    appview: {
      showNav: false,
      isTabbed: false,
      showShare: false
    },
    utm: {}
  },
  [PAGE.FUNDRAISING_DRAFT_EDIT]: {
    title: '編輯個人籌款專頁內容',
    link: {
      href: '/fundraising/edit/[fundraisingEventNo]',
      as: '/fundraising/edit/'
    },
    menuIcon: null,
    menuTitle: '',
    appview: {
      showNav: false,
      isTabbed: false,
      showShare: false
    },
    utm: {}
  },
  [PAGE.FUNDRAISING_PREVIEW]: {
    title: '預覽個人籌款專頁內容',
    link: {
      href: '/fundraising/preview/[fundraisingEventNo]',
      as: '/fundraising/preview/'
    },
    menuIcon: null,
    menuTitle: '',
    appview: {
      showNav: false,
      isTabbed: false,
      showShare: false
    },
    utm: {}
  },
  [PAGE.FUNDRAISING_PUBLISH_SUCCESS]: {
    title: '成功建立個人籌款專頁',
    link: {
      href: '/fundraising/success/[fundraisingEventNo]',
      as: '/fundraising/success/'
    },
    menuIcon: null,
    menuTitle: '',
    appview: {
      showNav: false,
      isTabbed: false,
      showShare: false
    },
    utm: {}
  },
  [PAGE.ORGANIZATION]: {
    title: '慈善組織',
    link: {
      href: '/org/[organizationNo]/[organizationName]',
      as: '/org/'
    },
    menuIcon: null,
    menuTitle: '機構詳情',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: true
    },
    utm: {
      utm_source: 'heart_organization_share',
      utm_medium: 'referral'
    }
  },
  [PAGE.CORPORATE]: {
    title: '企業心意夥伴',
    link: {
      href: '/corporate',
      as: '/corporate'
    },
    menuIcon: <PartnerSvg />,
    menuTitle: '企業心意夥伴',
    appview: {
      showNav: true,
      isTabbed: true,
      showShare: true
    },
    utm: {
      utm_source: 'heart_corporate_share',
      utm_medium: 'referral'
    }
  },
  [PAGE.CORPORATE_LISTING]: {
    title: '企業列表',
    link: {
      href: '/corporate/listing',
      as: '/corporate/listing'
    },
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: true
    }
  },
  [PAGE.CORPORATE_DETAIL]: {
    title: '企業詳情',
    link: {
      href: '/corporate/[corporateNo]',
      as: '/corporate/'
    },
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: true
    }
  },
  [PAGE.CORPORATE_CONTACT]: {
    title: '企業申請',
    link: {
      href: '/corporate/contact',
      as: '/corporate/contact'
    },
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: true
    }
  },
  [PAGE.ARTICLE]: {
    title: '專題報道',
    link: {
      href: '/article',
      as: '/article'
    },
    menuIcon: <ArticleSvg />,
    menuTitle: '專題報道',
    appview: {
      showNav: true,
      isTabbed: true,
      showShare: true
    }
  },
  [PAGE.ABOUT]: {
    title: '關於我們',
    link: {
      href: '/about',
      as: '/about'
    },
    menuIcon: <AboutUsSvg />,
    menuTitle: '關於我們',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.FAQ]: {
    title: '常見問題',
    link: {
      href: process.env.HK01_FAQ_URL,
      as: null
    },
    menuIcon: <FaqSvg />,
    menuTitle: '常見問題',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.MY_ACCOUNT]: {
    title: '我的',
    link: {
      href: '/account',
      as: '/account'
    },
    menuIcon: <AccountSvg />,
    menuTitle: '我的',
    appview: {
      showNav: false,
      isTabbed: true,
      showShare: false
    }
  },
  [PAGE.TERMS]: {
    title: '條款及細則',
    link: {
      href: `${process.env.HK01_URL}/terms`,
      as: null
    },
    menuIcon: <TAndCSvg />,
    menuTitle: '條款及細則'
  },
  [PAGE.MY_DONATION]: {
    title: '我的捐款',
    link: {
      href: '/account/donation',
      as: '/account/donation',
      authorizer: 'donor'
    },
    menuIcon: <MyDonationSvg />,
    menuTitle: '我的捐款',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.HK01_MY_DONATION]: {
    title: '我的捐款',
    link: {
      href: '/01account/donation',
      as: '/01account/donation',
      authorizer: 'donor'
    },
    menuIcon: null,
    menuTitle: '我的捐款',
    appview: {
      showNav: false,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.MY_PERSONAL_FUNDRAISING]: {
    title: '我的籌款專頁',
    link: {
      href: '/account/fundraising',
      as: '/account/fundraising',
      authorizer: 'donor'
    },
    menuTitle: '我的籌款專頁',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.MY_TICKET]: {
    title: '我的電子票',
    link: {
      href: '/account/ticket',
      as: '/account/ticket',
      authorizer: 'donor'
    },
    menuTitle: '我的電子票',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.MY_INFO]: {
    title: '我的預設資料',
    link: {
      href: '/account/info',
      as: '/account/info',
      authorizer: 'donor'
    },
    menuIcon: <MyInfoSvg />,
    menuTitle: '我的預設資料',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.DONATION_DETAIL]: {
    title: '捐款詳情',
    link: {
      href: '/account/donation/[orderNo]',
      as: '/account/donation/',
      authorizer: 'donor'
    },
    menuIcon: null,
    menuTitle: '捐款詳情',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    },
    utm: {
      utm_source: 'heart_order_sharebutton',
      utm_medium: 'referral'
    }
  },
  [PAGE.REDIRECT]: {
    title: '轉載中',
    link: {
      href: '/redirect',
      as: '/redirect'
    },
    appview: {
      showNav: false,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.AUTHORIZE]: {
    title: '轉載中',
    link: {
      href: '/authorize',
      as: '/authorize'
    },
    appview: {
      showNav: false,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.THANKYOU]: {
    title: '捐款詳情',
    link: {
      href: '/thankyou/[orderNo]',
      as: '/thankyou/'
    },
    menuIcon: null,
    menuTitle: '捐款詳情',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    },
    utm: {
      utm_source: 'heart_order_sharebutton',
      utm_medium: 'referral'
    }
  },
  [PAGE.EXTRA_DONATION]: {
    title: '捐款支持',
    link: {
      href: '/extra-donation',
      as: '/extra-donation'
    },
    menuIcon: null,
    menuTitle: '捐款支持',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    }
  },
  [PAGE.TIMEOUT]: {
    title: '逾時',
    link: {
      href: '/timeout',
      as: '/timeout'
    },
    menuIcon: null,
    menuTitle: '逾時',
    appview: {
      showNav: true,
      isTabbed: false,
      showShare: false
    }
  },
  [undefined]: {
    title: '錯誤',
    link: {
      href: undefined,
      as: undefined
    }
  }
}
