import FocusTrap from 'focus-trap-react'
import * as R from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { animated, useTransition } from 'react-spring'
import styled, { css } from 'styled-components'

import { useBodyScrollLock } from '../../hooks'
import CloseSvg from '../../public/static/images/icon-close.svg'
import { CloseBtn, getUnit, Mask } from '../PopUp/shared'
import { Portal } from '../Portal'
import SvgIcon from '../SvgIcon'

const AnimatedDrawer = styled(animated.div)`
  position: fixed;
  background-color: #fff;
  z-index: 99999;
  ${({ $placement, $width, $height }) =>
    $placement &&
    css`
      ${[$placement]}: 0;
      ${($placement === 'top' || $placement === 'bottom') &&
      css`
        left: 0;
        width: 100%;
        height: ${$height && parseFloat($height) + getUnit($height)};
      `}
      ${($placement === 'left' || $placement === 'right') &&
      css`
        top: 0;
        height: 100%;
        width: ${$width && parseFloat($width) + getUnit($width)};
      `}
    `}
`
const Scrollable = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const Drawer = React.memo(
  ({
    height = '100%',
    width = '100%',
    show,
    placement,
    onOpen,
    onClose,
    showCloseBtn,
    closeOnMaskClick,
    autoFocus = false,
    children,
    ...props
  }) => {
    const scrollNode = useBodyScrollLock(show)

    const [shouldRenderPortal, setShouldRenderPortal] = useState(show)

    const [isFocus, setIsFocus] = useState(false)

    const handleAfterOpen = useCallback(
      e => {
        if (show) {
          onOpen && onOpen(e)
          setIsFocus(true)
        }
      },
      [show, onOpen]
    )

    const handleClose = useCallback(
      e => {
        onClose && onClose(e)
      },
      [onClose]
    )

    const handleAfterClose = useCallback(
      e => {
        if (!show) {
          setShouldRenderPortal(false)
          setIsFocus(false)
        }
      },
      [show]
    )

    const calcTranslate = useCallback(
      R.cond([
        [R.equals('top'), R.always({ x: 0, y: '-100%' })],
        [R.equals('right'), R.always({ x: '100%', y: 0 })],
        [R.equals('bottom'), R.always({ x: 0, y: '100%' })],
        [R.equals('left'), R.always({ x: '-100%', y: 0 })]
      ]),
      []
    )

    const maskTransition = useTransition(show, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      expires: 0
    })

    const translate = useMemo(() => calcTranslate(placement), [placement])

    const drawerTransition = useTransition(show, {
      from: { transform: `translate3d(${translate.x},${translate.y},0)` },
      enter: {
        transform: 'translate3d(0%,0%,0)',
        onRest: handleAfterOpen
      },
      leave: {
        transform: `translate3d(${translate.x},${translate.y},0)`,
        onRest: handleAfterClose
      },
      expires: 0
    })

    useEffect(() => {
      if (show) {
        setShouldRenderPortal(true)
      }
    }, [show])

    return (
      <Portal shouldRender={shouldRenderPortal}>
        {maskTransition(
          (styles, item) =>
            item && (
              <Mask
                style={styles}
                {...(closeOnMaskClick ? { onClick: handleClose } : {})}
              />
            )
        )}
        {drawerTransition(
          (styles, item) =>
            item && (
              <FocusTrap
                focusTrapOptions={{
                  allowOutsideClick: () => true
                }}
                active={autoFocus && isFocus}
              >
                <AnimatedDrawer
                  style={styles}
                  $height={height}
                  $width={width}
                  $placement={placement}
                >
                  {showCloseBtn && (
                    <CloseBtn onClick={handleClose}>
                      <SvgIcon svg={<CloseSvg />} />
                    </CloseBtn>
                  )}
                  <Scrollable ref={scrollNode}>{children}</Scrollable>
                </AnimatedDrawer>
              </FocusTrap>
            )
        )}
      </Portal>
    )
  }
)

export default Drawer
