const converter = require('number-to-chinese-words')
const moment = require('moment-timezone')
const secOfDay = 86400000

module.exports = {
  toCurrency: value => `$${new Intl.NumberFormat().format(value)}`,
  toNumber: value => new Intl.NumberFormat().format(value),
  toChineseNumber: value => converter.toWords(value),
  dateDiff: (date1, date2) => {
    date2 = moment(date2) || moment()
    const diff = moment(date1).diff(moment())

    if (diff < secOfDay * 2) {
      return `${parseInt(diff / 3600000, 10)} 小時`
    }
    return moment.duration(diff).humanize()
  }
}
