import { all, fork } from 'redux-saga/effects'

export const combineSagas = sagas =>
  function* rootSaga(args) {
    try {
      yield all(sagas.map(saga => fork(saga, args)))
    } catch (err) {
      console.error(err)
    }
  }
