import { createActions } from 'redux-actions'

import { createSyncAsyncActions } from '../../utils/reduxUtils'

export const {
  // member
  createFundraisingInitSucceeded,
  createFundraisingSucceeded,
  patchFundraisingSucceeded,
  putFundraisingSucceeded,
  deleteFundraisingSucceeded,
  publishFundraisingSucceeded,
  getMemberFundraisingSucceeded,
  getMemberFundraisingsSucceeded,
  getFundraisingStatisticsSucceeded,
  // public
  getPublicFundraisingSucceeded,
  getWidgetFundraisingsSucceeded,
  getLatestDonorsSucceeded,
  getFundraisingCreatorStatisticsSucceeded,
  setFundraisingEventArchived
} = createActions({
  // member
  CREATE_FUNDRAISING_INIT_SUCCEEDED: normalized => normalized,
  CREATE_FUNDRAISING_SUCCEEDED: normalized => normalized,
  PATCH_FUNDRAISING_SUCCEEDED: normalized => normalized,
  PUT_FUNDRAISING_SUCCEEDED: normalized => normalized,
  DELETE_FUNDRAISING_SUCCEEDED: fundraisingEventNo => ({
    fundraisingEventNo
  }),
  PUBLISH_FUNDRAISING_SUCCEEDED: normalized => normalized,
  GET_MEMBER_FUNDRAISING_SUCCEEDED: normalized => normalized,
  GET_MEMBER_FUNDRAISINGS_SUCCEEDED: (page, limit, total, normalized) => ({
    page,
    limit,
    total,
    ...normalized
  }),
  GET_FUNDRAISING_STATISTICS_SUCCEEDED: normalized => normalized,
  // public
  GET_PUBLIC_FUNDRAISING_SUCCEEDED: normalized => normalized,
  GET_WIDGET_FUNDRAISINGS_SUCCEEDED: normalized => normalized,
  GET_LATEST_DONORS_SUCCEEDED: result => ({
    result
  }),
  GET_FUNDRAISING_CREATOR_STATISTICS_SUCCEEDED: result => ({
    result
  }),
  SET_FUNDRAISING_EVENT_ARCHIVED: isArchived => ({ isArchived })
})

// member
export const { createFundraisingRequestAsync } = createSyncAsyncActions(
  'CREATE_FUNDRAISING_REQUEST',
  createForm => ({
    createForm
  })
)

export const { createFundraisingInitRequestAsync } = createSyncAsyncActions(
  'CREATE_FUNDRAISING_INIT_REQUEST',
  createForm => ({
    createForm
  })
)

export const { patchFundraisingRequestAsync } = createSyncAsyncActions(
  'PATCH_FUNDRAISING_REQUEST',
  createForm => ({
    createForm
  })
)

export const { putFundraisingRequestAsync } = createSyncAsyncActions(
  'PUT_FUNDRAISING_REQUEST',
  createForm => ({
    createForm
  })
)

export const { deleteFundraisingRequestAsync } = createSyncAsyncActions(
  'DELETE_FUNDRAISING_REQUEST',
  fundraisingEventNo => ({
    fundraisingEventNo
  })
)

export const { publishFundraisingRequestAsync } = createSyncAsyncActions(
  'PUBLISH_FUNDRAISING_REQUEST',
  fundraisingEventNo => ({
    fundraisingEventNo
  })
)

export const { getMemberFundraisingsRequestAsync } = createSyncAsyncActions(
  'GET_MEMBER_FUNDRAISINGS_REQUEST',
  (page, limit) => ({
    page,
    limit,
    sortBy: 'createdTime'
  })
)

export const { getMemberFundraisingPageRequestAsync } = createSyncAsyncActions(
  'GET_MEMBER_FUNDRAISING_PAGE_REQUEST',
  fundraisingEventNo => ({
    fundraisingEventNo
  })
)

// public
export const { getWidgetFundraisingsRequestAsync } = createSyncAsyncActions(
  'GET_WIDGET_FUNDRAISINGS_REQUEST'
)

export const { getFundraisingCreatePageRequest } = createSyncAsyncActions(
  'GET_FUNDRAISING_CREATE_PAGE_REQUEST',
  projectNo => ({
    projectNo
  })
)

export const { getPublicFundraisingPageRequest } = createSyncAsyncActions(
  'GET_PUBLIC_FUNDRAISING_PAGE_REQUEST',
  fundraisingEventNo => ({
    fundraisingEventNo
  })
)

export const { getFundraisingStatisticsRequestAsync } = createSyncAsyncActions(
  'GET_FUNDRAISING_STATISTICS_REQUEST',
  fundraisingEventNos => ({
    fundraisingEventNos
  })
)
