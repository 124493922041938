const config = require('../config')

const URI = require('urijs')

const fetch = require('../utils/fetch')

const R = require('ramda')

const checkStatus = function (response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    throw new Error(response.status)
  }
}

const appendQuery = R.ifElse(R.isNil, R.always(''), R.concat('?'))

const apiUrl = config.API_URL

const hk01WebApiUrl = config.HK01_WEB_API_URL

module.exports = {
  createOrder: async ({
    token,
    projectNo,
    donationItemId,
    donationAmount,
    promoCode,
    fundraisingEventNo,
    donorMessage,
    hideIdentity
  }) => {
    const response = await fetch(`${apiUrl}/v3.0/member/orders`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(
        R.reject(R.either(R.isNil, R.isEmpty), {
          projectNo,
          donationItemId,
          donationAmount: donationAmount || undefined,
          promoCode,
          fundraisingEventNo,
          donorMessage,
          hideIdentity
        })
      )
    })

    checkStatus(response)

    if (!response.ok) {
      throw new Error(
        `enrollment error : ${response.status}/${response.statusText}`
      )
    }

    const { paymentUrl } = await response.json()

    return paymentUrl
  },
  getPromoItem: async () => {
    const response = await fetch(`${apiUrl}/v1.0/promoItems`, { mode: 'cors' })

    checkStatus(response)

    return response.json()
  },
  getPromoWidget: async () => {
    const response = await fetch(`${apiUrl}/v3.0/projects/promoWidget`)

    checkStatus(response)

    return response.json()
  },
  getFrontendSetting: async () => {
    const response = await fetch(`${apiUrl}/v2.0/frontendSetting`, {
      mode: 'cors'
    })

    checkStatus(response)

    return response.json()
  },
  getSearchCategories: async categoryNos => {
    const response = await fetch(
      `${apiUrl}/v2.0/categories?categoryNos=${R.join(',')(categoryNos)}`,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getHomeCategories: async categoryNos => {
    const response = await fetch(
      `${apiUrl}/v2.0/categories?categoryNos=${R.join(',')(categoryNos)}`,
      {
        mode: 'cors'
      }
    )
    checkStatus(response)
    return response.json()
  },
  getMemberProfile: async token => {
    const response = await fetch(`${apiUrl}/v1.0/member/donors`, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`
      }
    })

    checkStatus(response)

    return response.json()
  },
  updateMemberProfile: async ({
    agreeToAutoFill,
    receiptEmail,
    receiptName,
    token
  }) => {
    const response = await fetch(`${apiUrl}/v1.0/member/donors`, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(
        R.reject(v => v === null || v === undefined || v === '', {
          agreeToAutoFill,
          email: receiptEmail,
          receiptName
        })
      )
    })

    checkStatus(response)

    return response.json()
  },
  getOrganizationNoByOrganizationId: async organizationId => {
    const response = await fetch(
      `${apiUrl}/v1.0/organization/` + organizationId,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getOrganizationByNo: async organizationNo => {
    const response = await fetch(
      `${apiUrl}/v2.0/organizations/` + organizationNo,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getOrganizationByProjectNo: async projectNo => {
    const response = await fetch(
      `${apiUrl}/v2.0/organizations/project/` + projectNo,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getHighlightedProjects: async () => {
    const response = await fetch(`${apiUrl}/v1.1/project/featured`, {
      mode: 'cors'
    })

    checkStatus(response)

    return response.json()
  },
  getRandomProjects: async (limit, ignoreFeatured = false) => {
    let response = await fetch(
      `${apiUrl}/v1.1/project/sample?limit=${limit}${
        ignoreFeatured ? '&ignoreFeatured=Y' : ''
      }`,
      { mode: 'cors' }
    )

    checkStatus(response)

    if (!response.ok) {
      throw new Error('unknown /projects response ', response)
    }

    response = await response.json()

    return response.result
  },
  getSearchProjects: async (
    page,
    limit,
    sortBy,
    keyword,
    categories,
    organizationNo,
    ignoreFeatured = false,
    typeFilter
  ) => {
    const query = URI.buildQuery({
      page,
      limit,
      sortBy,
      q: keyword,
      categoryNos:
        R.is(Array, categories) && R.length(categories) > 0
          ? R.join(',', categories)
          : undefined,
      organizationNo,
      ...(ignoreFeatured && { ignoreFeatured: 'Y' }),
      typeFilter
    })

    const response = await fetch(
      `${apiUrl}/v1.1/project${appendQuery(query)}`,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    if (!response.ok) {
      throw new Error('unknown /projects response ', response)
    }

    const data = await response.json()

    return {
      page: data.page,
      limit: data.limit,
      total: data.total,
      result: data.result
    }
  },
  getOrderByNo: async (orderNo, accessToken) => {
    const response = await fetch(`${apiUrl}/v3.0/member/orders/${orderNo}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json'
      },
      mode: 'cors'
    })

    checkStatus(response)

    return response.json()
  },
  getProjectByNo: async (projectNo, previewToken) => {
    let response = await fetch(
      `${apiUrl}/v1.2/project/${projectNo}${
        previewToken ? '?previewToken=' + previewToken : ''
      }`,
      { mode: 'cors' }
    )

    checkStatus(response)

    response = await response.json()

    if (
      R.isNil(R.path(['project'], response)) ||
      R.isNil(R.path(['organization'], response))
    ) {
      return null
    }

    return response
  },
  getDonationItemDonorCountByNo: async projectNo => {
    const response = await fetch(
      `${apiUrl}/v2.0/donationItems/statistics/projectNo/${projectNo}`,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    const resp = await response.json()

    return resp
  },
  getDonationStats: async projectNos => {
    if (R.type(projectNos) === 'Array') {
      projectNos =
        R.length(projectNos) > 1 ? R.join(',', projectNos) : R.head(projectNos)
    }

    const response = await fetch(
      `${apiUrl}/v2.0/project/donationStatistics?projectNos=${projectNos}`,
      { mode: 'cors' }
    )

    checkStatus(response)

    return response.json()
  },
  getOrdersStats: async accessToken => {
    const response = await fetch(
      `${apiUrl}/v1.0/member/donors/donationStatistics`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          Accept: 'application/json'
        },
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getDonationHistory: async (page, limit, accessToken) => {
    const response = await fetch(
      `${apiUrl}/v3.0/member/orders/paid?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          Accept: 'application/json'
        },
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  sendReceipt: async (
    orderNo,
    receiptName,
    receiptEmail,
    promoCode,
    resendReceipt,
    forceResend,
    token
  ) => {
    const response = await fetch(
      `${apiUrl}/v3.0/member/orders/${orderNo}/receipt`,
      {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(
          R.reject(v => v === null || v === undefined || v === '', {
            receiptName,
            receiptEmail,
            promoCode,
            resendReceipt,
            forceResend
          })
        )
      }
    )

    checkStatus(response)

    if (!response.ok) {
      throw new Error(
        `sendReceipt error : ${response.status}/${response.statusText}`
      )
    }
  },
  getPartnerOrderHistory: async (page, limit, accessToken) => {
    const response = await fetch(
      `${apiUrl}/v3.0/member/orders/partner?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          Accept: 'application/json'
        },
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getPartnerOrderInfo: async (orderNo, token) => {
    const response = await fetch(
      `${apiUrl}/v3.0/member/orders/partner-initial/${orderNo}`,
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json'
        }
      }
    )

    checkStatus(response)

    return response.json()
  },
  patchPartnerOrder: async (orderNo, charityAmount, token) => {
    const response = await fetch(
      `${apiUrl}/v3.0/member/orders/partner-initial/${orderNo}`,
      {
        mode: 'cors',
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ charityAmount })
      }
    )

    checkStatus(response)

    return response.json()
  },
  getCorporateList: async (page, limit, sortBy, sortDirection) => {
    const query = URI.buildQuery({
      page,
      limit,
      sortBy,
      sortDirection
    })

    const response = await fetch(
      `${apiUrl}/v2.0/corporates${appendQuery(query)}`,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getCorporateDetail: async corporateNo => {
    const response = await fetch(`${apiUrl}/v2.0/corporates/${corporateNo}`, {
      mode: 'cors'
    })

    checkStatus(response)

    return response.json()
  },
  getCorporateCategoryList: async (
    page,
    limit,
    sortBy,
    sortDirection,
    corporateCategoryNos
  ) => {
    const query = URI.buildQuery({
      page,
      limit,
      sortBy,
      sortDirection,
      corporateCategoryNos
    })

    const response = await fetch(
      `${apiUrl}/v2.0/corporateCategories${appendQuery(query)}`,
      { mode: 'cors' }
    )

    checkStatus(response)

    return response.json()
  },
  getArticleListByTagId: async (tagId, offset, limit) => {
    const query = URI.buildQuery({
      offset,
      limit
    })

    const response = await fetch(
      `${hk01WebApiUrl}/v2/feed/tag/${tagId}${appendQuery(query)}`,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getArticleListByTagIds: async (tagIds, offset, limit) => {
    const query = URI.buildQuery({
      'tagIds[]': tagIds,
      offset,
      limit
    })
    const response = await fetch(
      `${hk01WebApiUrl}/v2/feed/tags${appendQuery(query)}`,
      {
        mode: 'cors'
      }
    )

    checkStatus(response)

    return response.json()
  },
  getArticleListById: async articleIds => {
    const query = URI.buildQuery({
      'articleIds[]': articleIds
    })
    const response = await fetch(
      `${hk01WebApiUrl}/v2/feed/articles${appendQuery(query)}`
    )

    checkStatus(response)

    return response.json()
  },
  sendContactForm: async contactForm => {
    const response = await fetch(`${apiUrl}/v1.0/corporates/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(contactForm)
    })

    checkStatus(response)

    return response.status === 204 ? null : response.json()
  },
  createFundraisingInit: async (createForm, token) => {
    const response = await fetch(
      `${apiUrl}/v1.2/member/fundraisingEvents/init`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(createForm)
      }
    )

    checkStatus(response)

    return response.json()
  },
  createFundraising: async (createForm, token) => {
    const response = await fetch(`${apiUrl}/v1.2/member/fundraisingEvents`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(createForm)
    })

    checkStatus(response)

    return response.json()
  },
  patchFundraising: async (createForm, token) => {
    const { fundraisingEventNo } = createForm
    const response = await fetch(
      `${apiUrl}/v1.2/member/fundraisingEvents/${fundraisingEventNo}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(createForm)
      }
    )

    checkStatus(response)

    return response.json()
  },
  putFundraising: async (createForm, token) => {
    const { fundraisingEventNo } = createForm
    const response = await fetch(
      `${apiUrl}/v1.2/member/fundraisingEvents/${fundraisingEventNo}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(createForm)
      }
    )

    checkStatus(response)

    return response.json()
  },
  deleteFundraising: async (fundraisingEventNo, token) => {
    const response = await fetch(
      `${apiUrl}/v1.0/member/fundraisingEvents/${fundraisingEventNo}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )

    checkStatus(response)

    return response.status
  },
  publishFundraising: async (fundraisingEventNo, token) => {
    const response = await fetch(
      `${apiUrl}/v1.0/member/fundraisingEvents/${fundraisingEventNo}/status/publish`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json'
        }
      }
    )
    checkStatus(response)
    return response.json()
  },
  getMemberFundraising: async (fundraisingEventNo, token) => {
    const response = await fetch(
      `${apiUrl}/v1.1/member/fundraisingEvents/${fundraisingEventNo}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json'
        }
      }
    )
    checkStatus(response)

    return response.json()
  },
  // public
  getPublicFundraising: async fundraisingEventNo => {
    const response = await fetch(
      `${apiUrl}/v1.1/fundraisingEvents/${fundraisingEventNo}`
    )

    checkStatus(response)

    return response.json()
  },
  getWidgetFundraisings: async () => {
    const response = await fetch(`${apiUrl}/v1.1/fundraisingEvents/widget`)

    checkStatus(response)

    return response.json()
  },
  getMemberFundraisingEvents: async (page, limit, sortBy, token) => {
    const query = URI.buildQuery({
      page,
      limit,
      sortBy
    })
    const response = await fetch(
      `${apiUrl}/v1.1/member/fundraisingEvents${appendQuery(query)}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json'
        }
      }
    )

    checkStatus(response)

    return response.json()
  },
  getFundraisingStatistics: async fundraisingEventNos => {
    fundraisingEventNos = R.when(R.is(Array), R.join(','))(fundraisingEventNos)
    const query = URI.buildQuery({
      fundraisingEventNos
    })

    const response = await fetch(
      `${apiUrl}/v1.0/fundraisingEvents/donationStatistics${appendQuery(query)}`
    )

    checkStatus(response)

    return response.json()
  },
  getFundraisingWidgetDonors: async (
    fundraisingEventNo,
    page,
    limit,
    sortBy,
    sortDirection
  ) => {
    const query = URI.buildQuery({
      page,
      limit,
      sortBy,
      sortDirection
    })

    const response = await fetch(
      `${apiUrl}/v1.0/orders/fundraisingEvent/${fundraisingEventNo}${appendQuery(
        query
      )}`
    )
    checkStatus(response)

    return response.json()
  },
  getProjectWidgetDonors: async (
    projectNo,
    page,
    limit,
    sortBy,
    sortDirection
  ) => {
    const query = URI.buildQuery({
      page,
      limit,
      sortBy,
      sortDirection
    })
    const response = await fetch(
      `${apiUrl}/v1.0/orders/project/${projectNo}${appendQuery(query)}`
    )
    checkStatus(response)

    return response.json()
  },
  getLatestDonors: async fundraisingEventNo => {
    const response = await fetch(
      `${apiUrl}/v1.0/fundraisingEvents/${fundraisingEventNo}/latestDonor`
    )
    checkStatus(response)

    return response.json()
  },
  getFundraisingCreatorStatistics: async fundraisingEventNo => {
    const response = await fetch(
      `${apiUrl}/v1.0/fundraisingEvents/${fundraisingEventNo}/creator/status/statistics`
    )

    checkStatus(response)

    return response.json()
  },
  getProjectFundraisingEventRanking: async (
    projectNo,
    type,
    sortBy,
    sortDirection
  ) => {
    const query = URI.buildQuery({
      sortBy,
      sortDirection
    })

    const response = await fetch(
      `${apiUrl}/v1.0/projects/${projectNo}/fundraisingEventRanking/${type}${appendQuery(
        query
      )}`
    )

    checkStatus(response)

    return response.json()
  },
  syncSso: async (displayName, avatarUrl, token) => {
    const response = await fetch(`${apiUrl}/v1.0/member/donors/syncSSO`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + token,
        Accept: 'application/json'
      },
      body: JSON.stringify({ displayName, avatarUrl })
    })

    checkStatus(response)

    return response.status
  }
}
