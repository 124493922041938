import { normalize } from 'normalizr'
import * as R from 'ramda'
import { call, put } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import { handleSyncAsync } from '../../../utils/reduxUtils'
import { memberAuthorizer } from '../../auth/saga/authSaga'
import {
  // member
  createFundraisingInitSucceeded,
  createFundraisingSucceeded,
  deleteFundraisingSucceeded,
  getFundraisingCreatorStatisticsSucceeded,
  getFundraisingStatisticsRequestAsync,
  getFundraisingStatisticsSucceeded,
  getLatestDonorsSucceeded,
  getMemberFundraisingsSucceeded,
  getMemberFundraisingSucceeded,
  // public
  getPublicFundraisingSucceeded,
  getWidgetFundraisingsSucceeded,
  patchFundraisingSucceeded,
  publishFundraisingSucceeded,
  putFundraisingSucceeded
} from '../actions'
import {
  fundraisingSchema,
  fundraisingsSchema,
  fundraisingStatsListSchema
} from '../schema'

const normalizeRes = data => normalize(data, fundraisingSchema)

// member
const createFundraisingInit = memberAuthorizer(function* (token, action) {
  const { createForm } = action.payload
  const response = yield call(
    charityApi.createFundraisingInit,
    createForm,
    token
  )
  yield put(createFundraisingInitSucceeded(normalizeRes(response)))
  return response
})

const createFundraising = memberAuthorizer(function* (token, action) {
  const { createForm } = action.payload
  const response = yield call(charityApi.createFundraising, createForm, token)
  yield put(createFundraisingSucceeded(normalizeRes(response)))
  return response
})

const patchFundraising = memberAuthorizer(function* (token, action) {
  const { createForm } = action.payload
  const response = yield call(charityApi.patchFundraising, createForm, token)
  yield put(patchFundraisingSucceeded(normalizeRes(response)))
  return response
})

const putFundraising = memberAuthorizer(function* (token, action) {
  const { createForm } = action.payload
  const response = yield call(charityApi.putFundraising, createForm, token)
  yield put(putFundraisingSucceeded(normalizeRes(response)))
  return response
})

const deleteFundraising = memberAuthorizer(function* (token, action) {
  const { fundraisingEventNo } = action.payload
  const response = yield call(
    charityApi.deleteFundraising,
    fundraisingEventNo,
    token
  )
  yield put(deleteFundraisingSucceeded(fundraisingEventNo))
  return response
})

const publishFundraising = memberAuthorizer(function* (token, action) {
  const { fundraisingEventNo } = action.payload
  const response = yield call(
    charityApi.publishFundraising,
    fundraisingEventNo,
    token
  )
  yield put(publishFundraisingSucceeded(normalizeRes(response)))
  return response
})

export const getMemberFundraisingWorker = function* (
  fundraisingEventNo,
  token
) {
  const response = yield call(
    charityApi.getMemberFundraising,
    fundraisingEventNo,
    token
  )
  yield put(getMemberFundraisingSucceeded(normalizeRes(response)))
  return response
}

const getMemberFundraisings = memberAuthorizer(function* (token, action) {
  const { page, limit, sortBy } = action.payload
  try {
    const response = yield call(
      charityApi.getMemberFundraisingEvents,
      page,
      limit,
      sortBy,
      token
    )
    const normalized = normalize(R.prop('result', response), fundraisingsSchema)
    const fundraisingEventNos = R.prop('result', normalized)
    yield put(
      getMemberFundraisingsSucceeded(
        page,
        limit,
        R.prop('total', response),
        normalized
      )
    )
    if (!R.isEmpty(fundraisingEventNos)) {
      yield call(requestFundraisingStatistics, fundraisingEventNos)
    }
    return response
  } catch (e) {
    console.log(e)
  }
})

export const createFundraisingInitWorker = handleSyncAsync(
  createFundraisingInit
)

export const createFundraisingWorker = handleSyncAsync(createFundraising)

export const patchFundraisingWorker = handleSyncAsync(patchFundraising)

export const putFundraisingWorker = handleSyncAsync(putFundraising)

export const deleteFundraisingWorker = handleSyncAsync(deleteFundraising)

export const publishFundraisingWorker = handleSyncAsync(publishFundraising)

export const getMemberFundraisingsWorker = handleSyncAsync(
  getMemberFundraisings
)

// public
export const getPublicFundraisingWorker = function* (fundraisingEventNo) {
  const response = yield call(
    charityApi.getPublicFundraising,
    fundraisingEventNo
  )
  yield put(getPublicFundraisingSucceeded(normalizeRes(response)))
  return response
}

const getWidgetFundraisings = function* () {
  const response = yield call(charityApi.getWidgetFundraisings)
  const normalized = normalize(response, fundraisingsSchema)
  yield put(getWidgetFundraisingsSucceeded(normalized))
  yield call(requestFundraisingStatistics, R.prop('result', normalized))
  return response
}

export function* requestFundraisingStatistics(fundraisingEventNos) {
  if (!R.isEmpty(fundraisingEventNos)) {
    yield put(getFundraisingStatisticsRequestAsync(fundraisingEventNos))
  }
}

export function* getFundraisingStatistics(action) {
  const { fundraisingEventNos } = action.payload
  try {
    const response = yield call(
      charityApi.getFundraisingStatistics,
      fundraisingEventNos
    )
    yield put(
      getFundraisingStatisticsSucceeded(
        normalize(response, fundraisingStatsListSchema)
      )
    )
  } catch (e) {
    console.log(e)
  }
}

export function* getLatestDonorsWorker(fundraisingEventNo) {
  try {
    const response = yield call(charityApi.getLatestDonors, fundraisingEventNo)
    yield put(getLatestDonorsSucceeded(response))
  } catch (e) {
    console.log(e)
  }
}

export function* getFundraisingCreatorStatisticsWorker(fundraisingEventNo) {
  try {
    const response = yield call(
      charityApi.getFundraisingCreatorStatistics,
      fundraisingEventNo
    )
    yield put(getFundraisingCreatorStatisticsSucceeded(response))
  } catch (e) {
    console.log(e)
  }
}

export const getWidgetFundraisingsWorker = handleSyncAsync(
  getWidgetFundraisings
)

export const getFundraisingStatisticsWorker = handleSyncAsync(
  getFundraisingStatistics
)
