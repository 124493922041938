import { createActions } from 'redux-actions'

export const {
  getPartnerOrderInfoRequest,
  getPartnerOrderInfoSucceeded,
  patchPartnerOrderRequest,
  patchPartnerOrderSucceeded
} = createActions({
  GET_PARTNER_ORDER_INFO_REQUEST: orderNo => ({
    orderNo
  }),
  GET_PARTNER_ORDER_INFO_SUCCEEDED: partnerOrderInfo => ({
    partnerOrderInfo
  }),
  PATCH_PARTNER_ORDER_REQUEST: (orderNo, charityAmount) => ({
    orderNo,
    charityAmount
  }),
  PATCH_PARTNER_ORDER_SUCCEEDED: paymentUrl => ({ paymentUrl })
})
