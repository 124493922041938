import * as R from 'ramda'
import { combineActions, handleActions } from 'redux-actions'

import {
  createFundraisingInitSucceeded,
  createFundraisingSucceeded,
  deleteFundraisingSucceeded,
  getFundraisingCreatorStatisticsSucceeded,
  getFundraisingStatisticsSucceeded,
  getLatestDonorsSucceeded,
  getMemberFundraisingsSucceeded,
  getMemberFundraisingSucceeded,
  getPublicFundraisingSucceeded,
  getWidgetFundraisingsSucceeded,
  patchFundraisingSucceeded,
  putFundraisingSucceeded,
  setFundraisingEventArchived
} from './actions'

export const fundraisingInitialState = {
  page: 1,
  limit: 10,
  total: null,
  result: {
    fundraising: null,
    fundraisings: null
  },
  entities: {
    fundraisings: {},
    fundraisingStats: {}
  },
  latestDonors: [],
  hasMoreDonors: true,
  creatorStatistics: {},
  isFundraisingEventArchived: null
}

const fundraisingReducer = handleActions(
  {
    [combineActions(
      createFundraisingInitSucceeded,
      createFundraisingSucceeded,
      patchFundraisingSucceeded,
      putFundraisingSucceeded,
      getMemberFundraisingSucceeded,
      getPublicFundraisingSucceeded
    )]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: R.mergeDeepRight(state.result, { fundraising: result }),
        entities: R.mergeDeepRight(state.entities, entities)
      }
    },
    [getMemberFundraisingsSucceeded]: (state, action) => {
      const { page, limit, total, result, entities } = action.payload

      return {
        ...state,
        page,
        limit,
        total,
        result: R.mergeDeepRight(state.result, { fundraisings: result }),
        entities: R.mergeDeepRight(state.entities, entities)
      }
    },
    [getFundraisingStatisticsSucceeded]: (state, action) => {
      const { entities } = action.payload

      return {
        ...state,
        entities: R.mergeDeepRight(state.entities, entities)
      }
    },
    [deleteFundraisingSucceeded]: (state, action) => {
      const { fundraisingEventNo } = action.payload

      return {
        ...state,
        result: {
          ...state.result,
          fundraising: null,
          fundraisings: R.without(
            [fundraisingEventNo],
            state.result.fundraisings
          )
        },
        entities: R.pipe(
          R.dissocPath(['fundraisings', fundraisingEventNo]),
          R.dissocPath(['fundraisingStats', fundraisingEventNo])
        )(state.entities)
      }
    },
    [getWidgetFundraisingsSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: R.mergeDeepRight(state.result, { fundraisings: result }),
        entities: R.mergeDeepRight(state.entities, entities)
      }
    },
    [getLatestDonorsSucceeded]: (state, action) => {
      const { result } = action.payload
      return {
        ...state,
        latestDonors: result
      }
    },
    [getFundraisingCreatorStatisticsSucceeded]: (state, action) => {
      const { result } = action.payload

      return {
        ...state,
        creatorStatistics: result
      }
    },
    [setFundraisingEventArchived]: (state, action) => {
      return {
        ...state,
        isFundraisingEventArchived: action.payload.isArchived
      }
    }
  },
  fundraisingInitialState
)

export default fundraisingReducer
