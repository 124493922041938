import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

import { getColor, getFontSize } from '../utils/cssUtils'
import SvgIcon from './SvgIcon'

const TabNavItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  ${({ active }) =>
    active &&
    css`
      color: ${getColor('theme')};
    `}
  transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:active {
    background-color: rgba(0, 0, 0, 0.03);
  }
`
const TabText = styled.span`
  user-select: none;
  margin-top: 4px;
  font-size: ${getFontSize('caption')};
  white-space: nowrap;
  color: inherit;
`
const TabNav = React.memo(({ icon, text, active: tabActive }) => {
  const [active, setActive] = useState(false)
  const setTabActive = useCallback(() => {
    setActive(true)
  }, [])
  const setTabInactive = useCallback(() => {
    setActive(false)
  }, [])
  return (
    <TabNavItem
      onTouchStart={setTabActive}
      onTouchCancel={setTabInactive}
      onTouchEnd={setTabInactive}
      onContextMenu={setTabInactive}
      active={active || tabActive}
    >
      <SvgIcon svg={icon} />
      <TabText>{text}</TabText>
    </TabNavItem>
  )
})

export default TabNav
