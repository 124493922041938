import { combineSagas } from '../utils/sagaUtils'
import { appWatcher } from './app/saga'
import { articleWatcher } from './article/saga'
import { authWatcher } from './auth/saga'
import { corporateWatcher } from './corporate/saga'
import { donorWatcher } from './donor/saga'
import { fileWatcher } from './file/saga'
import { fundraisingWatcher } from './fundraising/saga'
import { orderWatcher } from './order/saga'
import { paymentWatcher } from './payment/saga'
import { projectWatcher } from './project/saga'
import { trackingWatcher } from './tracking/saga'

export const rootSaga = combineSagas([
  appWatcher,
  authWatcher,
  projectWatcher,
  orderWatcher,
  paymentWatcher,
  corporateWatcher,
  trackingWatcher,
  articleWatcher,
  fileWatcher,
  fundraisingWatcher,
  donorWatcher
])
