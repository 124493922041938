import { useRouter } from 'next/router'
import * as R from 'ramda'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css, ThemeContext } from 'styled-components'

import SvgIcon from '../../components/SvgIcon'
import { useBodyScrollLock, useTracking } from '../../hooks'
import { useTranslation } from '../../i18n'
import FilterSvg from '../../public/static/images/icon-filter.svg'
import { setShowAppviewSearchOverlay } from '../../redux/app/actions'
import { getCategoryDisplayNames } from '../../redux/category/selectors'
import { getColor, getFontSize, safeArea } from '../../utils/cssUtils'
import {
  discoverClickAppviewShowResultBtn,
  discoverViewAppviewFilterPanel
} from '../../utils/dataTracker'
import Button from '../Button'
import { InstantSearchComponent, InstantSearchWrapper } from '../InstantSearch'

const AppviewInstantSearch = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: #fff;
`
const AppviewSearchHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 23px 0 #e4e6e9;
`
const AppviewTextSearch = styled(InstantSearchComponent)`
  flex-grow: 1;
  .inputwrapper {
    box-shadow: none;
    &__prefix {
      border: none;
    }
    &__input {
      height: 44px;
    }
    &:focus-within {
      .inputwrapper__prefix {
        border: none;
      }
    }
  }
`
const AppviewSelectSearch = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.layout.appviewFooterHeight});
  background-color: #fff;
  padding: 44px 0 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: ${({ show }) => (show ? 'block' : 'none')};
`
const Scrollable = styled.div`
  height: calc(100% + 104px);
  padding: 16px;
`
const ButtonRow = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  bottom: calc(${({ theme }) => theme.layout.appviewFooterHeight} + 24px);
  left: 0;
  right: 0;
  ${safeArea(
    inset => css`
      bottom: calc(
        ${({ theme }) => theme.layout.appviewFooterHeight} + ${inset} + 24px
      );
    `
  )}
`
const SorterWrapper = styled.div`
  margin: 0 16px;
`
const SelectSearchToggle = styled.button`
  appearance: none;
  flex-grow: 0;
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: ${getFontSize('body2')};
  white-space: nowrap;
  outline: none;
  &:focus,
  &:hover {
    color: ${R.path(['theme', 'color', 'pink'])};
  }
`
const StyledButton = styled(Button)`
  box-shadow: 0 2px 16px 0 ${getColor('black', 0.15)};
`

const InstantSearchOverlay = React.memo(() => {
  const dispatch = useDispatch()

  const { t } = useTranslation('common')

  const { color } = useContext(ThemeContext)

  const track = useTracking()

  const { query } = useRouter()

  let { q: keyword = '', categories, typeFilter, sort } = query

  categories = !R.isNil(categories) ? R.split(',', categories) : []

  const categoryDisplayNames = useSelector(
    state => getCategoryDisplayNames(state, categories),
    R.equals
  )

  const [showAppviewSearchOverlay, isFetching, total] = useSelector(
    R.paths([
      ['appState', 'showAppviewSearchOverlay'],
      ['projectState', 'fetching', 'instantSearch'],
      ['projectState', 'total']
    ]),
    R.equals
  )

  const setShowSearch = show => {
    dispatch(setShowAppviewSearchOverlay(show))
  }

  const scrollNode = useBodyScrollLock(showAppviewSearchOverlay)

  const toggleSearch = () => {
    setShowSearch(!showAppviewSearchOverlay)

    if (showAppviewSearchOverlay) {
      track(
        discoverClickAppviewShowResultBtn(
          total,
          keyword,
          categoryDisplayNames,
          categories,
          typeFilter,
          sort
        )
      )
    }
  }

  useEffect(() => {
    if (showAppviewSearchOverlay) {
      track(discoverViewAppviewFilterPanel())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAppviewSearchOverlay])

  return (
    <InstantSearchWrapper>
      <AppviewInstantSearch>
        <AppviewSearchHeader>
          <AppviewTextSearch textSearch />
          <SelectSearchToggle type='button' onClick={toggleSearch}>
            <SvgIcon svg={<FilterSvg />} />
            {t('listing.filter')}
          </SelectSearchToggle>
        </AppviewSearchHeader>
        <AppviewSelectSearch show={showAppviewSearchOverlay} ref={scrollNode}>
          <Scrollable>
            <InstantSearchComponent selectSearch />
            <InstantSearchComponent resetBtn />
          </Scrollable>
        </AppviewSelectSearch>
        <ButtonRow show={showAppviewSearchOverlay}>
          <StyledButton
            height={48}
            bgColor={color.lightYellow}
            textColor={color.theme}
            loading={isFetching}
            onClick={toggleSearch}
          >
            {t('search.displayingNumberOfResults', {
              count: total
            })}
          </StyledButton>
        </ButtonRow>
      </AppviewInstantSearch>
      <SorterWrapper>
        <InstantSearchComponent sorter />
      </SorterWrapper>
    </InstantSearchWrapper>
  )
})

export default InstantSearchOverlay
