import Router from 'next/router'
import { normalize } from 'normalizr'
import { all, call, put } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import { setShowErrorPage } from '../../app/actions'
import {
  getOrganizationSucceeded,
  getOrgPageSucceeded,
  getProjectsSucceeded
} from '../actions'
import { organizationSchema, projectListSchema } from '../schema'
import { requestDonationStats } from './projectSaga'

export function* getOrgPageWorker(action) {
  const { page, limit, sortBy, organizationNo, nextCtx } = action.payload

  const categories = null

  const keyword = null

  try {
    const organization = yield call(
      charityApi.getOrganizationByNo,
      organizationNo
    )

    const response = yield call(
      charityApi.getSearchProjects,
      page,
      limit,
      sortBy,
      keyword,
      categories,
      organizationNo
    )

    const normalized = yield call(normalize, response.result, projectListSchema)

    const normalizedOrganization = yield call(
      normalize,
      organization,
      organizationSchema
    )

    yield all([
      put(getOrgPageSucceeded()),
      put(
        getProjectsSucceeded(
          response.page,
          response.limit,
          response.total,
          normalized
        )
      ),
      put(getOrganizationSucceeded(normalizedOrganization)),
      call(requestDonationStats, normalized.result)
    ])
  } catch (err) {
    if (parseInt(err.message) === 403) {
      // archived organizations
      const as = `/`

      const href = '/'

      if (nextCtx.req) {
        nextCtx.res.redirect(as)
      } else {
        yield call(Router.push, href, as)
      }
    } else {
      nextCtx.res.status(404)
      yield put(setShowErrorPage(true, 404))
    }
  }
}
