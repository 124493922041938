import React from 'react'
import styled, { css } from 'styled-components'

import { fixIe, getColor } from '../../../utils/cssUtils'
import mq from '../../../utils/mediaQuery'
import { loadingAnimation } from '../BackgroundEffect'

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(181, 181, 181, 0.2);
  background-color: #fff;
  ${({ listView }) =>
    listView &&
    css`
      ${mq.desktopTablet} {
        flex-wrap: nowrap;
      }
    `}
`
const CardImgWrapper = styled.div`
  width: 100%;
  ${({ listView }) =>
    listView &&
    css`
      ${mq.desktopTablet} {
        flex-shrink: 0;
        width: 277px;
      }
    `}
`
const CardImg = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-color: ${getColor('skeleton')};
`
const CardContent = styled.div`
  width: 100%;
  padding: 24px;
  ${({ listView }) =>
    listView &&
    css`
      ${mq.desktopTablet} {
        padding: 16px 24px;
      }
    `}
`
const Title = styled.div`
  width: 100%;
  height: ${18 * 1.5 * 2}px;
  max-height: 50px;
  margin-bottom: 8px;
  ${({ listView }) =>
    listView &&
    css`
      ${mq.desktopTablet} {
        margin-bottom: 4px;
      }
    `}
  ${fixIe(
    css`
      display: flex;
    `
  )}
`
const Description = styled.div`
  height: ${16 * 1.5 * 3}px;
  max-height: 70px;
  margin-bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
`
const Bar = styled.div`
  width: ${({ long }) => (long ? '100%' : '70%')};
  height: 20px;
  ${({ long }) =>
    long &&
    css`
      margin-bottom: 8px;
    `}
  ${loadingAnimation}
`
const ArticleCard = React.memo(({ listView, className }) => (
  <Card className={className} listView={listView}>
    <CardImgWrapper listView={listView}>
      <CardImg />
    </CardImgWrapper>
    <CardContent listView={listView}>
      <Title listView={listView}>
        <Bar long />
        <Bar />
      </Title>
      <Description>
        <Bar long />
        <Bar />
      </Description>
    </CardContent>
  </Card>
))

export default ArticleCard
