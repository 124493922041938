import {
  createPromiseAction,
  implementPromiseAction
} from '@adobe/redux-saga-promise'
import camelCase from 'lodash/camelCase'
import * as R from 'ramda'
import { createAction } from 'redux-actions'
import { call } from 'redux-saga/effects'

export const createSyncAsyncActions = (action, payloadCreator) => {
  const syncAction = createAction(action + '_SYNC', payloadCreator)
  const asyncAction = createPromiseAction(action + '_ASYNC', payloadCreator)
  const obj = {
    [camelCase(`${action}_SYNC`)]: syncAction,
    [camelCase(`${action}_ASYNC`)]: asyncAction,
    [camelCase(action)]: payload => ({
      sync: syncAction(payload),
      async: asyncAction(payload)
    })
  }
  obj[camelCase(action)].getTypes = actionObj => {
    if (R.isNil(actionObj)) return [`${action}_SYNC`, `${action}_ASYNC.TRIGGER`]
    return (
      R.includes(action + '_SYNC', actionObj.type) ||
      R.includes(action + '_ASYNC.TRIGGER', actionObj.type)
    )
  }
  return obj
}

// dispatch promise on server and normal dispatch on client
export const nextDispatch = store => async syncAsyncActions => {
  const isServer = typeof window === 'undefined'
  if (
    R.type(syncAsyncActions) === 'Object' &&
    R.has('sync', syncAsyncActions) &&
    R.has('async', syncAsyncActions)
  ) {
    const { sync, async } = syncAsyncActions
    if (isServer) {
      // server side
      const response = await store.dispatch(async)
      return response
    } else {
      store.dispatch(sync)
    }
  } else {
    store.dispatch(syncAsyncActions)
  }
}

// can resolve / reject promise
export function handleSyncAsync(sagaFn) {
  return function* (action) {
    try {
      const { type } = action
      if (R.includes('ASYNC.TRIGGER', type)) {
        yield call(implementPromiseAction, action, function* () {
          return yield call(sagaFn, action)
        })
      } else {
        yield call(sagaFn, action)
      }
    } catch (e) {
      console.log(e)
    }
  }
}
