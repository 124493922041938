import { all, takeLatest } from 'redux-saga/effects'

import { getWidgetDonorsRequest } from '../actions'
import { getWidgetDonorsWorker } from './donorSaga'

export function* donorWatcher() {
  try {
    yield all([takeLatest(getWidgetDonorsRequest, getWidgetDonorsWorker)])
  } catch (e) {
    console.log(e)
  }
}
