import React from 'react'
import styled from 'styled-components'

import { getColor } from '../utils/cssUtils'

const FixedRatioContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${({ ratio }) => ratio && ratio}%;
  background-color: ${getColor('skeleton')};
`
const AspectBox = React.memo(({ className, ratio = 56.25, children }) => (
  <FixedRatioContainer ratio={ratio} className={className}>
    {children}
  </FixedRatioContainer>
))

export default AspectBox
