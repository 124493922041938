import * as R from 'ramda'
import Tabs, { TabPane } from 'rc-tabs'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import TabContent from 'rc-tabs/lib/TabContent'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { withTranslation } from '../../i18n'
import { getColor } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'
import { CtaBtn } from '../../containers/Button'
import { Col, Row } from '../Layout/FlexGrid'
import MaxWidth from '../Layout/MaxWidth'
import TabsStyle from '../Styled/Tabs'
import SectionTitle from '../Text/SectionTitle'
import { loadingAnimation } from './BackgroundEffect'
import FeaturedProject from './FeaturedProject'
import OrganizationInfo from './OrganizationInfo'
import ProjectCard from './ProjectCardSkeleton'

const DonationItem = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 32px 24px;
  border: solid 1px transparent;
  transition: border 0.5s linear;
  margin-top: 16px;
  box-shadow: 0 0 16px 0 rgba(196, 205, 213, 0.5);
`
const DonationItemControl = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
const DonationCount = styled.div`
  float: right;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const StyledWrapper = styled.div`
  width: 100%;
  background-color: #fff;
`
const Hero = styled.div`
  padding: 56px 0px 40px 0px;
  ${mq.mobile} {
    padding: 24px 0px 16px 0px;
  }
`
const ProjectContent = styled(Row)`
  align-items: flex-start;
  ${mq.tablet} {
    flex-direction: column-reverse;
  }
  ${mq.mobile} {
    flex-direction: column-reverse;
  }
`
const LineBar = styled.div`
  width: ${({ width }) => width || `100%`};
  height: 15px;
  margin-bottom: ${({ bottom }) => bottom || `15px`};
  ${loadingAnimation}
`
const ContentPic = styled.div`
  width: 100%;
  height: 192px;
  background-color: ${getColor('skeleton')};
  margin-bottom: 30px;
`
const StatsCount = styled.div`
  display: inline-block;
  width: 20px;
  height: 15px;
  background-color: #f3f3f3;
`
const DonationList = styled.div``
const StatsText = styled.div`
  display: inline-block;
`
const Divider = styled.div`
  margin: 40px 0;
  height: 2px;
  width: 100%;
  border: solid 1px ${getColor('border')};
`
const Highlights = styled.div`
  padding: 32px 0px 56px 0px;
  background: ${getColor('lightYellow')};
  box-shadow: inset 0 2px 23px 0 #e4e6e9;
`
const ProjectDetailSkeleton = withTranslation('common')(({ t }) => {
  const isAppview = useSelector(R.path(['appState', 'isAppview']))

  const renderContentItem = () => (
    <>
      {R.times(
        index => (
          <LineBar key={index} />
        ),
        7
      )}
      <LineBar width='50%' bottom='30px' />
    </>
  )

  const renderContent = () => (
    <div
      css={`
        padding: 32px 24px;
      `}
    >
      <LineBar width='80%' bottom='30px' />
      {renderContentItem()}
      <ContentPic />
      {renderContentItem()}
      {renderContentItem()}
    </div>
  )

  const renderDonationContent = () => (
    <>
      <LineBar width='70%' bottom='30px' />
      <LineBar />
      <LineBar />
      <LineBar />
      <LineBar width='50%' bottom='30px' />
    </>
  )

  const renderDonationItem = index => (
    <DonationItem key={`donationItem_${index}`}>
      <div>{renderDonationContent()}</div>
      <DonationItemControl>
        <CtaBtn>{t('donationItem.donate')}</CtaBtn>
        <DonationCount>
          <StatsCount />
          <StatsText>{t('donationItem.donationCount')}</StatsText>
        </DonationCount>
      </DonationItemControl>
    </DonationItem>
  )

  return (
    <StyledWrapper>
      <TabsStyle />
      <Hero>
        <MaxWidth>
          <FeaturedProject />
        </MaxWidth>
      </Hero>
      <MaxWidth>
        <ProjectContent gutter={{ xl: 24 }}>
          <Col
            xl={16}
            xs={24}
            css={`
              align-self: stretch;
            `}
          >
            <Tabs
              defaultActiveKey='1'
              renderTabBar={() => (
                <ScrollableInkTabBar
                  css={`
                    .rc-tabs-nav {
                      .rc-tabs-ink-bar {
                        height: 4px;
                        border-radius: 2px;
                      }
                    }
                  `}
                />
              )}
              renderTabContent={() => <TabContent />}
            >
              <TabPane tab={t('project.projectContent')} key='1'>
                {renderContent()}
              </TabPane>
              <TabPane tab={t('project.caution')} key='2'>
                {renderContent()}
              </TabPane>
            </Tabs>
          </Col>
          <Col xl={8} xs={24} className='donationItems'>
            {!isAppview && (
              <DonationList>
                {R.times(index => renderDonationItem(index), 3)}
              </DonationList>
            )}
          </Col>
        </ProjectContent>
      </MaxWidth>
      <MaxWidth>
        <Divider />
      </MaxWidth>
      <OrganizationInfo />
      <Highlights>
        <MaxWidth>
          <SectionTitle>{t('highlightedProject.title')}</SectionTitle>
          <Row gutter={[24, 24]}>
            {R.times(
              index => (
                <Col xs={24} md={12} xl={8} key={index}>
                  <ProjectCard />
                </Col>
              ),
              6
            )}
          </Row>
        </MaxWidth>
      </Highlights>
    </StyledWrapper>
  )
})

export default ProjectDetailSkeleton
