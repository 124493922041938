import Bugsnag from '@bugsnag/js'
import BugsnagReact from '@bugsnag/plugin-react'
import * as React from 'react'

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  appVersion: process.env.APP_VERSION,
  plugins: [new BugsnagReact()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.APP_ENV
})

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React
)
