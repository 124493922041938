import { schema } from 'normalizr'

const categorySchema = new schema.Entity(
  'categories',
  {},
  { idAttribute: 'categoryNo' }
)

const parentCategorySchema = new schema.Entity(
  'categories',
  { children: [categorySchema] },
  { idAttribute: 'categoryNo' }
)

export const categoriesSchema = [parentCategorySchema]
