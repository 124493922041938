import React from 'react'
import styled from 'styled-components'

import mq from '../../utils/mediaQuery'

const MaxWidth = styled.div`
  margin: 0 auto;
  ${mq.desktop} {
    width: 100%;
    max-width: 1200px;
  }
  ${mq.tablet} {
    margin: 0 64px;
  }
  ${mq.mobile} {
    margin: 0 16px;
  }
`

export default React.forwardRef((props, ref) => (
  <MaxWidth ref={ref} {...props} />
))
