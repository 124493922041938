import { normalize } from 'normalizr'
import { buffers, END, eventChannel } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'

import { uploadFailed, uploadProgress, uploadSucceeded } from '../actions'
import { fileSchema } from '../schema'

const normalizeRes = data => normalize(data, fileSchema)
// Upload the specified file
export function* uploadFileWorker(action) {
  const { file, key } = action.payload
  const channel = yield call(
    createUploadFileChannel,
    `${process.env.API_URL}/v1.0/files`,
    file
  )
  while (true) {
    const { progress = 0, err, success, successPayload } = yield take(channel)
    if (err) {
      yield put(uploadFailed(normalizeRes({ key, error: err })))
      return
    }
    if (success) {
      yield put(uploadSucceeded(normalizeRes({ key, ...successPayload })))
      return
    }
    yield put(uploadProgress(normalizeRes({ key, progress })))
  }
}

function createUploadFileChannel(endpoint, file) {
  return eventChannel(emitter => {
    const xhr = new window.XMLHttpRequest()
    const onProgress = e => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total
        emitter({ progress })
      }
    }
    const onFailure = e => {
      emitter({ err: new Error('Upload failed') })
      emitter(END)
    }
    xhr.upload.addEventListener('progress', onProgress)
    xhr.upload.addEventListener('error', onFailure)
    xhr.upload.addEventListener('abort', onFailure)
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr
      if (readyState === 4) {
        if (status >= 200 && status < 300) {
          emitter({ success: true, successPayload: JSON.parse(response) })
          emitter(END)
        } else {
          onFailure(null)
        }
      }
    }
    xhr.open('POST', endpoint, true)
    xhr.send(file)
    return () => {
      xhr.upload.removeEventListener('progress', onProgress)
      xhr.upload.removeEventListener('error', onFailure)
      xhr.upload.removeEventListener('abort', onFailure)
      xhr.onreadystatechange = null
      xhr.abort()
    }
  }, buffers.sliding(2))
}
