import React from 'react'
import { useFormContext } from 'react-hook-form/dist/index.ie11'
import styled from 'styled-components'

import { getColor, getFontSize } from '../../utils/cssUtils'

const HiddenInput = styled.input`
  appearance: none;
  opacity: 0;
  position: absolute;
`

const Label = styled.label`
  cursor: pointer;
  color: ${getColor('grey')};
  font-size: ${getFontSize('body2')};
  &:hover {
    color: ${getColor('pink')};
  }
  ${HiddenInput}:focus~&, ${HiddenInput}:focus:checked~& {
    color: ${getColor('pink')};
  }
  ${HiddenInput}:checked~& {
    color: ${getColor('theme')};
    &:hover {
      color: ${getColor('pink')};
    }
  }
`

const InstantSearchCheckbox = ({ name, label, value }) => {
  const { register } = useFormContext()

  return (
    <>
      <HiddenInput
        type='checkbox'
        id={value}
        name={name}
        value={value}
        ref={register}
      />
      <Label htmlFor={value}>{label}</Label>
    </>
  )
}

export default InstantSearchCheckbox
