import { normalize } from 'normalizr'
import * as R from 'ramda'
import { all, call, put, select } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import { isExist } from '../../../utils/ramdaUtils'
import { handleSyncAsync } from '../../../utils/reduxUtils'
import { getFrontendSettingSucceeded } from '../../app/actions'
import { getArticleListByIdSucceeded } from '../../article/actions'
import { articleListSchema } from '../../article/schema'
import {
  getCorporateCategoryListSucceeded,
  getCorporateDetailSucceeded,
  getCorporateListSucceeded,
  sendContactFormSucceeded
} from '../actions'
import {
  corporateCategoryListSchema,
  corporateListSchema,
  corporateSchema
} from '../schema'

export function* getCorporateListWorker(action) {
  try {
    const { page, limit, sortBy, sortDirection } = action.payload
    const corporateList = yield call(
      charityApi.getCorporateList,
      page,
      limit,
      sortBy,
      sortDirection
    )
    yield put(
      getCorporateListSucceeded(
        normalize(R.prop('result', corporateList), corporateListSchema)
      )
    )
  } catch (err) {
    console.log(err)
  }
}

export function* getCorporateDetailWorker(action) {
  try {
    const { corporateNo } = action.payload
    const corporateDetail = yield call(
      charityApi.getCorporateDetail,
      corporateNo
    )
    yield put(
      getCorporateDetailSucceeded(normalize(corporateDetail, corporateSchema))
    )
    const articleIds = R.path(['articleIds'], corporateDetail)
    if (!R.isEmpty(articleIds)) {
      const articleList = yield call(charityApi.getArticleListById, articleIds)
      yield put(
        getArticleListByIdSucceeded(
          normalize(R.prop('items', articleList), articleListSchema)
        )
      )
    }
  } catch (err) {
    console.log(err)
  }
}

export function* getCorporateCategoryListWorker(action) {
  try {
    const {
      page,
      limit,
      sortBy,
      sortDirection,
      corporateCategoryNos
    } = action.payload
    const corporateCategoryList = yield call(
      charityApi.getCorporateCategoryList,
      page,
      limit,
      sortBy,
      sortDirection,
      corporateCategoryNos
    )
    yield put(
      getCorporateCategoryListSucceeded(
        normalize(
          R.prop('result', corporateCategoryList),
          corporateCategoryListSchema
        )
      )
    )
  } catch (err) {
    console.log(err)
  }
}

export function* getCorporateCategoryListPageWorker(action) {
  try {
    const { isInitialRender } = action.payload
    let frontendSetting
    if (isInitialRender) {
      frontendSetting = yield call(charityApi.getFrontendSetting)
    } else {
      frontendSetting = yield select(state =>
        R.path(['appState', 'frontendSetting'], state)
      )
    }
    yield put(getFrontendSettingSucceeded(frontendSetting))
    const corporateCategoryNos = R.pluck(
      'corporateCategoryNo',
      R.prop('corporateCategoryList', frontendSetting)
    )
    const corporateCategoryList = yield call(
      charityApi.getCorporateCategoryList,
      1,
      undefined,
      undefined,
      undefined,
      corporateCategoryNos
    )
    yield put(
      getCorporateCategoryListSucceeded(
        normalize(
          R.prop('result', corporateCategoryList),
          corporateCategoryListSchema
        )
      )
    )
  } catch (e) {
    console.log(e)
  }
}

export function* getCorporateHomePageWorker(action) {
  try {
    const { isInitialRender } = action.payload
    let frontendSetting
    if (isInitialRender) {
      frontendSetting = yield call(charityApi.getFrontendSetting)
    } else {
      frontendSetting = yield select(R.path(['appState', 'frontendSetting']))
    }
    yield put(getFrontendSettingSucceeded(frontendSetting))
    const { articleIds, displayCorporateCategory } = R.prop(
      'corporateHome',
      frontendSetting
    )
    if (isExist(displayCorporateCategory)) {
      const corporateCategory = yield call(
        charityApi.getCorporateCategoryList,
        1,
        undefined,
        undefined,
        undefined,
        displayCorporateCategory
      )
      yield put(
        getCorporateCategoryListSucceeded(
          normalize(
            R.prop('result', corporateCategory),
            corporateCategoryListSchema
          )
        )
      )
    }
    const [articles, corporateList] = yield all([
      call(charityApi.getArticleListById, articleIds),
      call(charityApi.getCorporateList, 1, 16, 'random', undefined)
    ])
    yield all([
      put(
        getArticleListByIdSucceeded(
          normalize(R.prop('items', articles), articleListSchema)
        )
      ),
      put(
        getCorporateListSucceeded(
          normalize(R.prop('result', corporateList), corporateListSchema)
        )
      )
    ])
  } catch (e) {
    console.log(e)
  }
}

const sendContactForm = function* (action) {
  const { contactForm } = action.payload
  yield call(charityApi.sendContactForm, contactForm)
  yield put(sendContactFormSucceeded())
}

export const sendContactFormWorker = handleSyncAsync(sendContactForm)
