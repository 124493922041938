import React from 'react'
import { useFormContext } from 'react-hook-form/dist/index.ie11'
import styled from 'styled-components'

import { getColor, getFontSize } from '../../utils/cssUtils'

const HiddenInput = styled.input`
  appearance: none;
  opacity: 0;
  position: absolute;
`
const Label = styled.label`
  cursor: pointer;
  color: ${getColor('grey')};
  font-size: ${getFontSize('body2')};
  &:hover {
    color: ${getColor('pink')};
  }
  ${HiddenInput}:focus~&, ${HiddenInput}:focus:checked~& {
    color: ${getColor('pink')};
  }
  ${HiddenInput}:checked~& {
    color: ${getColor('theme')};
    &:hover {
      color: ${getColor('pink')};
    }
  }
`

const InstantSearchRadio = ({ name, options, renderOption }) => {
  const { register } = useFormContext()

  return options.map(({ id, label }, index) => {
    const radio = (
      <>
        <HiddenInput
          type='radio'
          id={id}
          name={name}
          value={id}
          ref={register}
        />
        <Label htmlFor={id}>{label}</Label>
      </>
    )
    return renderOption ? (
      renderOption(radio, index)
    ) : (
      <React.Fragment key={index}>{radio}</React.Fragment>
    )
  })
}

export default InstantSearchRadio
