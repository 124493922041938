import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form/dist/index.ie11'

const Hidden = ({ name, value, validationRules, ...props }) => {
  const { register, unregister, setValue } = useFormContext()

  useEffect(() => {
    register({ name }, validationRules)
    return () => unregister(name)
  }, [register])

  useEffect(() => {
    setValue(name, value)
  }, [value])
  return null
}

export default Hidden
