import Color from 'color'
import * as R from 'ramda'
import { css } from 'styled-components'

export const objectFit = (fit, position = null) =>
  position
    ? css`
        font-family: ${`"object-fit: ${fit}; object-position: ${position}"`};
      `
    : css`
        font-family: ${`"object-fit: ${fit};"`};
      `

export const safeArea = fn => {
  const saib = 'safe-area-inset-bottom'

  const env = envVar =>
    R.map(R.when(R.includes(saib), R.replace(saib, `${envVar}(${saib})`)))

  const cssText = env('env')(fn(saib))

  const cssTextFallback = env('constant')(fn(saib))

  const _cssText = css`
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      ${cssText}
    }
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
      ${cssTextFallback}
    }
  `
  return _cssText
}

export const getTheme = (...args) => R.path(R.concat(['theme'], args))

export const getColor = (name, alpha = 1) => props =>
  alpha === 1
    ? getTheme('color', name)(props)
    : Color(getTheme('color', name)(props)).alpha(alpha).string()

export const getFontSize = R.partial(getTheme, ['fontSize'])

export const calcLineHeightOffset = (fontSize, lineHeight) =>
  -(fontSize * lineHeight - fontSize) / 2

export const fixIe = cssText => css`
  @media all and (-ms-high-contrast: none) {
    & {
      ${cssText}
    }
  }
`
