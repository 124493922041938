import { memo } from 'react'
import { createGlobalStyle } from 'styled-components'

export default memo(createGlobalStyle`
* {
  font-family: system-ui, sans-serif;
}
body{
  overflow-x: hidden;
  overflow-y: auto;
}
p > a {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}
#__next {
  height: 100%;
}

`)
