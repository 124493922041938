import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import React from 'react'

const Seo = React.memo(defaultProps => {
  const { asPath } = useRouter()

  const props = {
    ...defaultProps,
    canonical: process.env.HEART_URL + asPath,
    openGraph: {
      ...defaultProps.openGraph,
      images: [
        {
          url:
            defaultProps?.openGraph?.images ?? defaultProps?.image
              ? R.when(
                  R.startsWith('static'),
                  R.concat(process.env.ASSET_PREFIX)
                )(defaultProps.image)
              : process.env.ASSET_PREFIX + process.env.OG_IMG_PATH ??
                process.env.ASSET_PREFIX + process.env.OG_IMG_PATH
        }
      ]
    }
  }

  return <NextSeo {...props} />
})

export default Seo
