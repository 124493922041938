import { take, all, call, put, select, spawn } from 'redux-saga/effects'

import { PAGE } from '../../../constants'
import {
  getCurrentOrgNo,
  getCurrentOrgName,
  getCurrentProjectName,
  getCurrentProjectNo
} from '../../project/selectors'
import {
  getCurrentCatNames,
  getCurrentCatNos,
  getCategoryDisplayNames
} from '../../category/selectors'
import {
  fire,
  discoverSearchCompleted,
  emptyResultView
} from '../../../utils/dataTracker'
import jssdk from '../../../utils/sdkUtils'
import { fireTracking, trackInstantSearch } from '../actions'
import {
  instantSearchSucceeded,
  instantSearchRequest
} from '../../project/actions'

export function* firePageViewWorker(action) {
  try {
    const tracker = yield call(jssdk.tracker)
    yield spawn(tracker.pageView, { GA: true, Piwik: true })
  } catch (err) {
    console.log(err)
  }
}

export function* fireTrackingWorker(action) {
  try {
    const { trackingParams, trackPageView = false } = action.payload
    const [category, trackingAction, label = {}] = trackingParams
    const tracker = yield call(jssdk.tracker)
    yield spawn(
      tracker.fire,
      { GA: true, Piwik: true },
      {
        category,
        action: trackingAction,
        label: JSON.stringify({ ...jssdk.getOpts(), ...label })
      }
    )
    if (trackPageView) {
      yield spawn(tracker.pageView, { GA: true, Piwik: true })
    }
  } catch (err) {
    console.log(err)
  }
}

export function* fireGtmEventWorker(action) {
  try {
    const isWebview = yield call(jssdk.isWebview)
    if (isWebview) {
      const { event, params } = action.payload
      yield call(jssdk.fireGtmEvent, event, params)
    }
  } catch (err) {
    console.log(err)
  }
}

export function* trackShareClickWorker(action) {
  try {
    switch (action.payload.currentPageName) {
      case PAGE.PROJECT:
        const [projectNo, projectName] = yield all([
          select(getCurrentProjectNo),
          select(getCurrentProjectName)
        ])
        yield call(fire, 'heart_detail', 'click_share', {
          item_id: projectNo,
          item_title: projectName,
          share_target: 'link'
        })
        break
      case PAGE.ORGANIZATION:
        const [orgNo, orgName] = yield all([
          select(getCurrentOrgNo),
          select(getCurrentOrgName)
        ])
        yield call(fire, 'heart_organization', 'click_share', {
          organization_id: orgNo,
          organization_name: orgName,
          share_target: 'link'
        })
        break
      case PAGE.HOME:
        yield call(fire, 'heart_home', 'click_share', {
          share_target: 'link'
        })
        break
      case PAGE.DISCOVER:
        const [catNos, catNames] = yield all([
          select(getCurrentCatNos),
          select(getCurrentCatNames)
        ])
        yield call(fire, 'heart_listing', 'click_share', {
          category_name: catNames,
          category_no: catNos,
          share_target: 'link'
        })
        break
    }
  } catch (err) {
    console.log(err)
  }
}

export function* trackInstantSearchWorker() {
  try {
    let lastActionType = null

    let cached = {
      total: 0,
      page: 1,
      keyword: '',
      categoryNos: [],
      categoryNames: []
    }

    while (true) {
      // keep track of all these actions and memo last action type
      const action = yield take([
        trackInstantSearch,
        instantSearchSucceeded,
        instantSearchRequest
      ])

      if (action.type === 'INSTANT_SEARCH_SUCCEEDED') {
        const { page, total } = action.payload

        cached['page'] = page

        cached['total'] = total
      }

      if (action.type === 'INSTANT_SEARCH_REQUEST') {
        cached['keyword'] = action.payload.keyword

        cached['categoryNos'] = action.payload.categories

        cached['sort'] = action.payload.sortBy

        cached['typeFilter'] = action.payload.typeFilter || 'all'
      }

      // if action type is TRACK_INSTANT_SEARCH, fire tracking
      if (action.type === 'TRACK_INSTANT_SEARCH') {
        if (lastActionType !== 'INSTANT_SEARCH_SUCCEEDED') {
          const {
            payload: { page, total }
          } = yield take(instantSearchSucceeded)

          cached['page'] = page

          cached['total'] = total
        }

        cached['categoryNames'] = yield select(state =>
          getCategoryDisplayNames(state, cached.categoryNos)
        )

        const {
          total,
          page,
          keyword,
          categoryNames,
          categoryNos,
          typeFilter,
          sort
        } = cached

        yield put(
          fireTracking(
            discoverSearchCompleted(
              total,
              page,
              keyword,
              categoryNames,
              categoryNos,
              typeFilter,
              sort
            )
          )
        )
        if (total === 0) {
          yield spawn(emptyResultView)
        }
      }

      // only memo when action is not TRACK_INSTANT_SEARCH
      if (action.type !== 'TRACK_INSTANT_SEARCH') {
        lastActionType = action.type
      }
    }
  } catch (e) {
    console.log(e)
  }
}
