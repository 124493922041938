import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const spinAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const Svg = styled.i`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({ iconColor }) => iconColor || 'inherit'};
  ${({ spin }) =>
    spin &&
    css`
      animation: ${spinAnimation} 1s infinite linear;
    `}
`

const SvgIcon = React.memo(({ svg, color: iconColor, spin, className }) => (
  <Svg iconColor={iconColor} spin={spin} className={className}>
    {svg}
  </Svg>
))

export default SvgIcon
