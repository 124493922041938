import { normalize } from 'normalizr'
import * as R from 'ramda'
import { all, call, cancel, fork, put, take } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import {
  instantSearchRequest,
  instantSearchSucceeded,
  setIsSearching
} from '../actions'
import { projectListSchema } from '../schema'
import { requestDonationStats } from './projectSaga'

export function* debounceSearchRequestWorker(action) {
  const {
    page,
    limit,
    sortBy,
    keyword,
    categories,
    organizationId,
    ignoreFeatured,
    typeFilter
  } = action.payload
  yield put(
    instantSearchRequest(
      page,
      limit,
      sortBy,
      keyword,
      categories,
      organizationId,
      ignoreFeatured,
      typeFilter
    )
  )
}

function* getSearchProjects(
  page,
  limit,
  sortBy,
  keyword,
  categories,
  organizationId,
  ignoreFeatured,
  typeFilter,
  payload
) {
  try {
    const response = yield call(
      charityApi.getSearchProjects,
      page,
      limit,
      sortBy,
      keyword,
      categories,
      organizationId,
      ignoreFeatured,
      typeFilter
    )

    const normalized = yield call(normalize, response.result, projectListSchema)

    const projectNos = normalized.result

    yield all([
      put(
        instantSearchSucceeded(
          response.page,
          response.limit,
          response.total,
          normalized,
          payload.categories
        )
      ),
      put(setIsSearching(false)),
      ...(R.isEmpty(projectNos) ? [] : [call(requestDonationStats, projectNos)])
    ])
  } catch (e) {
    yield put(setIsSearching(false))

    console.log(e)
  }
}

export function* instantSearchWorker() {
  let task

  try {
    while (true) {
      const { payload } = yield take(instantSearchRequest)

      const {
        page,
        limit,
        sortBy,
        keyword,
        categories,
        organizationId,
        ignoreFeatured,
        typeFilter
      } = payload

      if (task) {
        yield cancel(task)
      }

      yield put(setIsSearching(true))

      yield fork(
        getSearchProjects,
        page,
        limit,
        sortBy,
        keyword,
        categories,
        organizationId,
        ignoreFeatured,
        typeFilter,
        payload
      )
    }
  } catch (e) {
    console.log(e)
  }
}
