import * as R from 'ramda'
import { handleActions } from 'redux-actions'

import {
  getCorporateCategoryListPageRequest,
  getCorporateCategoryListSucceeded,
  getCorporateDetailSucceeded,
  getCorporateListSucceeded
} from './actions'

export const corporateInitialState = {
  isFetching: false,
  page: 1,
  limit: 6,
  total: 0,
  result: {
    corporate: null,
    corporateList: null,
    corporateCategoryList: null
  },
  entities: {}
}

const corporateReducer = handleActions(
  {
    [getCorporateListSucceeded]: (state, action) => {
      const { result, entities } = action.payload
      return {
        ...state,
        result: R.mergeDeepRight(state.result, { corporateList: result }),
        entities: R.mergeDeepRight(state.entities, entities)
      }
    },
    [getCorporateDetailSucceeded]: (state, action) => {
      const { result, entities } = action.payload
      return {
        ...state,
        result: R.mergeDeepRight(state.result, { corporate: result }),
        entities: R.mergeDeepRight(state.entities, entities)
      }
    },
    [getCorporateCategoryListPageRequest]: (state, action) => {
      return {
        ...state,
        isFetching: true
      }
    },
    [getCorporateCategoryListSucceeded]: (state, action) => {
      const { result, entities } = action.payload
      return {
        ...state,
        isFetching: false,
        result: R.mergeDeepRight(state.result, {
          corporateCategoryList: result
        }),
        entities: R.mergeDeepRight(state.entities, entities)
      }
    }
  },
  corporateInitialState
)

export default corporateReducer
