import { createActions } from 'redux-actions'

export const {
  uploadRequest,
  uploadRetry,
  uploadProgress,
  uploadSucceeded,
  uploadFailed
} = createActions({
  UPLOAD_REQUEST: (file, key) => ({ file, key }),
  UPLOAD_RETRY: (file, key) => ({ file, key }),
  UPLOAD_PROGRESS: normalized => normalized,
  UPLOAD_SUCCEEDED: normalized => normalized,
  UPLOAD_FAILED: normalized => normalized
})
