import { createActions } from 'redux-actions'

export const {
  getWidgetDonorsRequest,
  getWidgetDonorsSucceeded
} = createActions({
  GET_WIDGET_DONORS_REQUEST: (
    projectNo,
    page,
    limit,
    sortBy,
    sortDirection
  ) => ({ projectNo, page, limit, sortBy, sortDirection }),
  GET_WIDGET_DONORS_SUCCEEDED: (page, limit, total, result) => ({
    page,
    limit,
    total,
    result
  })
})
