import * as R from 'ramda'
import { handleActions } from 'redux-actions'

import {
  getDonationHistoryRequest,
  getDonationHistorySucceeded,
  getDonationOrdersSucceeded,
  getMyDonationPageRequest,
  getMyDonationPageSucceeded,
  getPartnerOrderHistoryRequest,
  getPartnerOrderHistorySucceeded,
  getPartnerOrdersSucceeded,
  getThankyouPageRequest,
  getThankyouPageSucceeded,
  saveReceiptSucceeded,
  sendOrSaveReceiptRequest,
  sendReceiptFailed,
  sendReceiptSucceeded
} from './actions'

export const paymentInitialState = {
  ordersStats: {
    totalDonationAmount: null,
    donationCount: null
  },
  page: 1,
  limit: 10,
  total: null,
  receiptSent: false,
  receiptSaved: false,
  sendWithError: false,
  result: {
    order: null,
    partnerOrders: null,
    donationOrders: null
  },
  entities: {
    orders: {}
  },
  fetching: {
    getMyDonationPage: false,
    getThankyouPage: false,
    getPartnerOrderPage: false
  }
}

const paymentReducer = handleActions(
  {
    [getThankyouPageRequest]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getThankyouPage: true
      }
    }),
    [getThankyouPageSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: {
          ...state.result,
          order: result
        },
        entities: {
          ...state.entities,
          orders: R.mergeDeepRight(state.entities.orders, entities.orders)
        },
        fetching: {
          ...state.fetching,
          getThankyouPage: false
        }
      }
    },
    [sendOrSaveReceiptRequest]: state => {
      return {
        ...state,
        receiptSent: false,
        receiptSaved: false,
        sendWithError: false
      }
    },
    [sendReceiptSucceeded]: state => {
      return {
        ...state,
        receiptSent: true
      }
    },
    [saveReceiptSucceeded]: state => {
      return {
        ...state,
        receiptSaved: true
      }
    },
    [sendReceiptFailed]: state => {
      return {
        ...state,
        sendWithError: true
      }
    },
    [getMyDonationPageRequest]: state => {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          getMyDonationPage: true
        }
      }
    },
    [getMyDonationPageSucceeded]: (state, action) => {
      const {
        payload: { ordersStats }
      } = action

      return {
        ...state,
        ordersStats: R.mergeDeepRight(state.ordersStats, ordersStats),
        fetching: {
          ...state.fetching,
          getMyDonationPage: false
        }
      }
    },
    [getDonationOrdersSucceeded]: (state, action) => {
      const { page, limit, total, result, entities } = action.payload

      return {
        ...state,
        page,
        limit,
        total,
        result: { ...state.result, donationOrders: result },
        entities: {
          orders: R.mergeDeepRight(
            state.entities.orders,
            entities?.orders ?? {}
          )
        }
      }
    },
    [getPartnerOrdersSucceeded]: (state, action) => {
      const { page, limit, total, result, entities } = action.payload

      return {
        ...state,
        page,
        limit,
        total,
        result: {
          ...state.result,
          partnerOrders: result
        },
        entities: {
          orders: R.mergeDeepRight(
            state.entities.orders,
            entities?.orders ?? {}
          )
        }
      }
    },
    [getDonationHistoryRequest]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getMyDonationPage: true
      }
    }),
    [getDonationHistorySucceeded]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getMyDonationPage: false
      }
    }),
    [getPartnerOrderHistoryRequest]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getPartnerOrderPage: true
      }
    }),
    [getPartnerOrderHistorySucceeded]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getPartnerOrderPage: false
      }
    })
  },
  paymentInitialState
)

export default paymentReducer
