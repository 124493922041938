import Link from 'next/link'
import * as R from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getCategory } from '../../redux/category/selectors'
import { resizeImg } from '../../utils/commonUtils'
import { getFontSize, objectFit } from '../../utils/cssUtils'
import { homeClickCategory } from '../../utils/dataTracker'
import mq from '../../utils/mediaQuery'

const CategoryItem = styled.li`
  text-align: center;
  display: flex;
  height: 104px;
  ${mq.tablet} {
    width: 25%;
  }
  ${mq.mobile} {
    margin-right: 20px;
    &:last-child {
      padding-right: 16px;
    }
  }
`
const CategoryLink = styled.a`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 auto;
`

const CategoryImgWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  ${mq.tabletMobile} {
    width: 56px;
    height: 56px;
  }
`

const CategoryImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${objectFit('contain')}
`

const CategoryName = styled.span`
  margin-top: 8px;
  white-space: nowrap;
  font-size: ${getFontSize('body2')};
  letter-spacing: 1px;
`

const CatIcon = ({ categoryNo }) => {
  const category = useSelector(
    state => getCategory(state, categoryNo),
    R.equals
  )

  if (R.isNil(category)) return null

  const { displayName, iconImageFile } = category

  return (
    <CategoryItem key={categoryNo}>
      <Link
        href={`/discover?categories=${categoryNo}`}
        as={`/discover?categories=${categoryNo}`}
        passHref
      >
        <CategoryLink
          onClick={() => {
            homeClickCategory(displayName, categoryNo)
          }}
        >
          <CategoryImgWrapper>
            {iconImageFile && (
              <CategoryImg src={resizeImg(R.prop('url', iconImageFile), 96)} />
            )}
          </CategoryImgWrapper>
          <CategoryName>{displayName}</CategoryName>
        </CategoryLink>
      </Link>
    </CategoryItem>
  )
}

export default React.memo(CatIcon)
