import Router from 'next/router'
import * as R from 'ramda'
import { all, call, delay, put, spawn, take } from 'redux-saga/effects'
import URI from 'urijs'

import { i18n } from '../../../i18n'
import * as charityApi from '../../../services'
import { getLoginOpts } from '../../../utils/commonUtils'
import { profileFailSave, profileSucceedSave } from '../../../utils/dataTracker'
import jssdk from '../../../utils/sdkUtils'
import {
  setFeedbackText,
  toggleBindPhoneScreen,
  updateBindPhoneCounter
} from '../../app/actions'
import {
  authorizeVisitRequest,
  bindPhoneFinished,
  checkBindPhoneSucceeded,
  checkLoginSucceeded,
  getHk01MemberProfileSucceeded,
  getMemberProfileDataSucceeded,
  loginSucceeded,
  memberVisitRequest,
  refreshLoginStatusSucceeded,
  updateMemberProfileDataSucceeded
} from '../actions'

export function memberAuthorizer(fn, requireBindPhone = false) {
  return function* (action) {
    const isMemberVisitRequest = action.type === memberVisitRequest().type
    const isAuthorizeVisitRequest = action.type === authorizeVisitRequest().type

    const redirectHref =
      isMemberVisitRequest || isAuthorizeVisitRequest
        ? action.payload.href
        : URI(Router.pathname).search(Router.query).toString()
    const redirectAs =
      isMemberVisitRequest || isAuthorizeVisitRequest
        ? action.payload.as
        : Router.asPath
    const redirectPath = URI('/authorize')
      .search({ redirectHref, redirectAs })
      .toString()
    yield call(authorizeLogin, redirectPath)
    if (requireBindPhone) {
      yield call(authorizeBindPhone, redirectPath)
    }
    const {
      charity: { token }
    } = yield call(jssdk.getTokens)
    return yield call(fn, token, action)
  }
}

function* authorizeLogin(redirectPath) {
  try {
    const [isWebview, isLoggedIn] = yield all([
      call(jssdk.isWebview),
      call(jssdk.isLoggedIn)
    ])
    if (!isLoggedIn) {
      yield delay(0)
      yield call(
        jssdk.login,
        window.location.origin + redirectPath,
        null,
        getLoginOpts(window.location.href)
      )
      if (isWebview) {
        yield call(checkLoginWorker)
      }
    }
  } catch (err) {
    console.log(err)
  }
}

function* authorizeBindPhone(redirectPath) {
  try {
    yield put(toggleBindPhoneScreen(false))
    let { isBoundPhone, isMergingAccount } = yield call(checkBindPhoneWorker)
    if (isMergingAccount) {
      // refresh jwt
      yield call(refreshLoginStatusWorker)
      isBoundPhone = yield call(jssdk.isBoundPhone)
    }
    if (!isBoundPhone) {
      // bindPhone
      // toggle bindPhone page
      // if action is not dispatch from authorize page
      if (redirectPath) {
        yield call(Router.push, redirectPath)
      }
      yield put(toggleBindPhoneScreen(true))
      yield take(bindPhoneFinished)
      yield call(authorizeBindPhone, redirectPath) // check bindphone again
    }
  } catch (err) {
    console.log(err)
  }
}

export const memberVisitWorker = memberAuthorizer(function* (token, action) {
  try {
    const { href, as } = action.payload
    const uri = new URI(href)
    if (uri.origin() && !R.equals(uri.origin(), process.env.HEART_URL)) {
      window.location.href = href
    } else {
      yield call(Router.push, href, as)
    }
  } catch (err) {
    console.log(err)
  }
})

export const authorizeVisitWorker = memberAuthorizer(function* (token, action) {
  try {
    const { href, as } = action.payload
    const uri = new URI(href)
    if (uri.origin() && !R.equals(uri.origin(), process.env.HEART_URL)) {
      window.location.href = href
    } else {
      yield call(Router.replace, href, as)
    }
  } catch (err) {
    console.log(err)
  }
})

export function* checkLoginWorker() {
  try {
    const {
      status,
      response: { expire }
    } = yield call(jssdk.getLoginStatus)
    const isLoggedIn = R.equals(status, jssdk.enum.STATUS.CONNECTED)

    if (R.not(R.isNil(expire))) {
      const currentTimestamp = parseInt(new Date().getTime() / 1000)
      if (isLoggedIn && R.gt(currentTimestamp, expire)) {
        // refresh access token if expired
        yield call(jssdk.refresh)
        yield call(checkLoginWorker)
      }
    }
    yield put(checkLoginSucceeded(isLoggedIn))
    if (isLoggedIn) {
      yield put(loginSucceeded(isLoggedIn))
      yield spawn(getHk01MemberProfileWorker)
    }
  } catch (err) {
    yield call(jssdk.logout)
    yield put(checkLoginSucceeded(false))
  }
}

export function* checkBindPhoneWorker(action) {
  try {
    const [isBoundPhone, isMergingAccount] = yield all([
      yield call(jssdk.isBoundPhone),
      yield call(jssdk.isMergingAccount)
    ])
    yield put(checkBindPhoneSucceeded(isBoundPhone, isMergingAccount))
    if (isBoundPhone && !isMergingAccount) {
      yield put(bindPhoneFinished())
    }
    return { isBoundPhone, isMergingAccount }
  } catch (err) {
    console.log(err)
  }
}

export function* loginWorker() {
  try {
    const [isWebview, isLoggedIn] = yield all([
      call(jssdk.isWebview),
      call(jssdk.isLoggedIn)
    ])
    if (!isLoggedIn) {
      yield delay(0)
      yield call(
        jssdk.login,
        window.location.href,
        null,
        getLoginOpts(window.location.href)
      )
      if (isWebview) {
        yield call(checkLoginWorker)
      }
    }
  } catch (err) {
    console.log(err)
  }
}

export function* logoutWorker() {
  try {
    const isLoggedIn = yield call(jssdk.isLoggedIn)
    if (isLoggedIn) {
      yield call(
        jssdk.logout,
        R.includes('/thankyou/', window.location.pathname)
          ? '/'
          : window.location.href
      )
    }
  } catch (err) {
    console.log(err)
  }
}

// member - hk01
export function* getHk01MemberProfileWorker() {
  try {
    const hk01MemberProfile = yield call(jssdk.getProfile)
    yield put(getHk01MemberProfileSucceeded(hk01MemberProfile))
    const { firstName, avatar } = hk01MemberProfile
    yield spawn(syncSsoWorker, firstName, avatar)
  } catch (err) {
    console.log(err)
  }
}

// donor - heart
export const getMemberProfileWorker = memberAuthorizer(function* (token) {
  try {
    const memberProfile = yield call(charityApi.getMemberProfile, token)
    yield put(getMemberProfileDataSucceeded(memberProfile))
  } catch (err) {
    console.log(err)
  }
})

export const updateMemberProfileWorker = memberAuthorizer(function* (
  token,
  action
) {
  try {
    const { agreeToAutoFill, receiptEmail, receiptName } = action.payload
    const memberProfile = yield call(charityApi.updateMemberProfile, {
      agreeToAutoFill,
      receiptEmail,
      receiptName,
      token
    })
    yield all([
      put(updateMemberProfileDataSucceeded(memberProfile)),
      put(setFeedbackText(i18n.t('account.info.saveSuccessfully'), 'success')),
      call(profileSucceedSave)
    ])
  } catch (err) {
    profileFailSave(err)
    console.log(err)
  }
})

export function* bindPhoneWorker(action) {
  try {
    yield call(jssdk.bindPhone, action.payload.redirectPath, null)
    yield all([
      put(updateBindPhoneCounter(process.env.BIND_PHONE_COUNTER)),
      put(bindPhoneFinished())
    ])
  } catch (err) {
    console.log(err)
  }
}

function* recursiveGetLoginStatusWorker() {
  let retryCount = 0
  function* getLoginStatus() {
    try {
      const isLoggedIn = yield call(jssdk.isLoggedIn)
      if (!isLoggedIn) {
        if (retryCount === 100) {
          // 20 seconds
          retryCount = 0
          throw new Error('fail to get merged account login status')
        }
        retryCount++
        yield delay(200)
        yield call(getLoginStatus)
      }
    } catch (err) {
      console.log(err)
    }
  }
  yield call(getLoginStatus)
}

export function* refreshLoginStatusWorker() {
  try {
    const { status } = yield call(jssdk.getSdk)

    if (status === jssdk.enum.STATUS.CONNECTED) {
      yield call(recursiveGetLoginStatusWorker)
      yield put(refreshLoginStatusSucceeded())
    } else {
      throw new Error('account not connected')
    }
  } catch (err) {
    console.log(err)
  }
}

export function* syncSsoWorker(firstName, avatarUrl) {
  const {
    charity: { token }
  } = yield call(jssdk.getTokens)
  yield call(charityApi.getMemberProfile, token) // create member profile if haven't already
  yield call(charityApi.syncSso, firstName, avatarUrl, token)
}
