import * as R from 'ramda'
import { animated } from 'react-spring'
import styled from 'styled-components'

import { getColor } from '../../../utils/cssUtils'

export const getUnit = R.pipe(
  R.toString,
  R.match(/(px|%|vh|vw)/g),
  R.head,
  R.when(R.isNil, R.always('px'))
)

export const Mask = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
`

export const CloseBtn = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 99999;
  outline: none;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${getColor('pink')};
  }
`
