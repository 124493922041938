import { all, takeLatest } from 'redux-saga/effects'

import {
  getDonationHistoryRequest,
  getMyDonationPageRequest,
  getPartnerOrderHistoryRequest,
  getThankyouPageRequest,
  initDonationRequest,
  proceedDonationRequest,
  sendOrSaveReceiptRequest
} from '../actions'
import {
  getDonationHistoryWorker,
  getMyDonationPageWorker,
  getPartnerOrderHistoryWorker,
  getThankyouPageWorker,
  initDonationWorker,
  proceedDonationWorker,
  sendOrSaveReceiptWorker
} from './paymentSaga'

export function* paymentWatcher() {
  try {
    yield all([
      takeLatest(initDonationRequest, initDonationWorker),
      takeLatest(proceedDonationRequest, proceedDonationWorker),
      takeLatest(getThankyouPageRequest, getThankyouPageWorker),
      takeLatest(sendOrSaveReceiptRequest, sendOrSaveReceiptWorker),
      takeLatest(getMyDonationPageRequest, getMyDonationPageWorker),
      takeLatest(getDonationHistoryRequest, getDonationHistoryWorker),
      takeLatest(getPartnerOrderHistoryRequest, getPartnerOrderHistoryWorker)
    ])
  } catch (err) {
    console.log(err)
  }
}
