import Link from 'next/link'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { AppviewNavContainer } from '../../components/Appview/shared'
import TabNav from '../../components/TabNav'
import { routesConfig } from '../../routes'
import { PAGE } from '../../constants'
import { setShowAppviewSearchOverlay } from '../../redux/app/actions'
import { getPageNameFromCurrentPath } from '../../utils/commonUtils'
import { menuClick } from '../../utils/dataTracker'

const menuItems = [
  PAGE.HOME,
  PAGE.DISCOVER,
  PAGE.CORPORATE,
  PAGE.ARTICLE,
  PAGE.MY_ACCOUNT
]

const BottomNav = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
`
const TabNavWrapper = styled.div`
  height: 100%;
  width: calc(100% / ${R.prop('tabCount')});
  display: flex;
  justify-content: center;
  align-items: center;
  > a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    text-decoration: none;
    &:hover {
      color: inherit;
    }
  }
`

const AppviewNavigation = () => {
  const dispatch = useDispatch()

  const router = useRouter()

  const currentPageName = getPageNameFromCurrentPath(router.pathname)

  const handleLinkClick = useCallback(
    e => {
      const href = e.currentTarget.getAttribute('href')

      if (
        href === R.path(['link', 'href'], routesConfig[PAGE.DISCOVER]) &&
        currentPageName !== PAGE.DISCOVER
      ) {
        dispatch(setShowAppviewSearchOverlay(true))
      }

      menuClick(href, 'bottom_menu')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPageName]
  )

  return (
    <AppviewNavContainer data-test-id='app-navigation-container'>
      <BottomNav>
        {menuItems.map((menuItem, index, array) => {
          const menuItemConfig = routesConfig[menuItem]

          const href = R.pathOr('', ['link', 'href'], menuItemConfig)

          const as = R.pathOr('', ['link', 'as'], menuItemConfig)

          const isActiveLink = R.equals(menuItem, currentPageName)
          return (
            <TabNavWrapper key={index} tabCount={array.length}>
              <Link href={href} as={as}>
                <a onClick={handleLinkClick}>
                  <TabNav
                    icon={menuItemConfig.menuIcon}
                    active={isActiveLink}
                    text={menuItemConfig.menuTitle}
                  />
                </a>
              </Link>
            </TabNavWrapper>
          )
        })}
      </BottomNav>
    </AppviewNavContainer>
  )
}

export default AppviewNavigation
