const isomorphicFetch = require('isomorphic-unfetch')
const fetch = require('fetch-retry')(isomorphicFetch)

module.exports = async (url, options) =>
  fetch(url, {
    ...(!process.browser
      ? {
          retryDelay: attempt => Math.pow(2, attempt) * 1000,
          retryOn: function (attempt, error, response) {
            // retry on network larger than 5xx status codes
            if (error !== null || response.status >= 500) {
              console.log(
                `fetch retrying ${url}, attempt number ${attempt + 1}`
              )
              return attempt < 2
            }
          }
        }
      : {}),
    ...options
  })
