import FocusTrap from 'focus-trap-react'
import * as R from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

import { useBodyScrollLock } from '../../hooks'
import CloseSvg from '../../public/static/images/icon-close.svg'
import { CloseBtn, getUnit, Mask } from '../PopUp/shared'
import { Portal } from '../Portal'
import SvgIcon from '../SvgIcon'

const ModalContainer = styled.div`
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0p;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: ${({ show }) => (show ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  padding: 16px 0;
`
const AnimatedModal = styled(animated.div)`
  background-color: #fff;
  z-index: 99999;
  min-width: 300px;
  min-height: 200px;
  max-width: 384px;
  width: ${({ $width }) => $width && parseFloat($width) + getUnit($width)};
  height: ${({ $height }) => $height && parseFloat($height) + getUnit($height)};
  margin: auto 16px;
  border-radius: 4px;
  pointer-events: auto;
`
const Scrollable = styled.div`
  width: 100%;
`
const Modal = React.memo(
  ({
    height,
    width,
    show,
    placement,
    onOpen,
    onClose,
    onClick,
    showCloseBtn,
    closeOnMaskClick,
    transitionEffect = 1,
    autoFocus = false,
    children,
    className,
    ...props
  }) => {
    const scrollNode = useBodyScrollLock(show)

    const [shouldRenderPortal, setShouldRenderPortal] = useState(false)

    const [isFocus, setIsFocus] = useState(false)

    const handleAfterOpen = useCallback(
      e => {
        if (show) {
          onOpen && onOpen(e)
          setIsFocus(true)
        }
      },
      [show, onOpen]
    )

    const handleClose = useCallback(e => {
      onClose && onClose(e)
    }, [])

    const handleAfterClose = useCallback(
      e => {
        if (!show) {
          setShouldRenderPortal(false)
          setIsFocus(false)
        }
      },
      [show]
    )

    const handleModalClick = useCallback(e => {
      e.stopPropagation()
      onClick && onClick(e)
    }, [])

    const maskTransition = useTransition(show, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      expires: 0
    })

    const slideIn = () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useTransition(show, {
        from: { opacity: 0, transform: 'translate3d(0,100px,0)' },
        enter: {
          opacity: 1,
          transform: 'translate3d(0,0px,0)',
          onRest: handleAfterOpen
        },
        leave: {
          opacity: 0,
          transform: 'translate3d(0,-100px,0)',
          onRest: handleAfterClose
        },
        expires: 0
      })

    const fadeIn = () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useTransition(show, {
        from: { opacity: 0, transform: 'scale(0.85)' },
        enter: { opacity: 1, transform: 'scale(1)', onRest: handleAfterOpen },
        leave: { opacity: 0, transform: 'scale(1.15)' },
        expires: 0
      })

    const transition = R.call(transitionEffect === 1 ? fadeIn : slideIn)

    useEffect(() => {
      if (show) {
        setShouldRenderPortal(true)
      }
    }, [show])

    return (
      <Portal shouldRender={shouldRenderPortal}>
        {maskTransition((styles, item) => item && <Mask style={styles} />)}
        {transition(
          (styles, item) =>
            item && (
              <ModalContainer
                show={show}
                {...(closeOnMaskClick ? { onClick: handleClose } : {})}
                ref={scrollNode}
              >
                <FocusTrap
                  focusTrapOptions={{
                    allowOutsideClick: () => true
                  }}
                  active={autoFocus && isFocus}
                >
                  <AnimatedModal
                    style={styles}
                    $height={height}
                    $width={width}
                    $placement={placement}
                    className={className}
                    onClick={handleModalClick}
                  >
                    {showCloseBtn && (
                      <CloseBtn onClick={handleClose}>
                        <SvgIcon svg={<CloseSvg />} />
                      </CloseBtn>
                    )}
                    <Scrollable>{children}</Scrollable>
                  </AnimatedModal>
                </FocusTrap>
              </ModalContainer>
            )
        )}
      </Portal>
    )
  }
)

export default Modal
