import moment from 'moment-timezone'
import * as R from 'ramda'
import { createSelector } from 'reselect'

import { DEFAULT_PLACEHOLDER, FUNDRAISING_STATUS_MAP } from '../../constants'
import { i18n } from '../../i18n'
import formatter from '../../utils/formatter'
import { newLineToArray } from '../../utils/patch'
import { getHk01MemberProfile } from '../auth/selectors'

const defaultProfileUrl = '/static/images/default-profile.svg'

export const getCreateFormProjectInfo = projectNo => state => {
  return R.paths(
    [
      ['projectState', 'entities', 'projects', projectNo, 'name'],
      ['projectState', 'entities', 'projects', projectNo, 'organizationName'],
      ['projectState', 'entities', 'projects', projectNo, 'endTime']
    ],
    state
  )
}

export const getOrganizationNo = projectNo =>
  R.path(['projectState', 'entities', 'projects', projectNo, 'organizationNo'])

const getOrganizationList = ({ projectState }) =>
  R.pathOr({}, ['entities', 'organizations'], projectState)

const getOrganizationEntity = organizationNo =>
  createSelector(getOrganizationList, organizationList => {
    return organizationList[organizationNo]
  })

export const getFundraisingOrgInfo = organizationNo =>
  createSelector(getOrganizationEntity(organizationNo), organizationEntity => {
    const get = R.pathOr(R.__, R.__, organizationEntity)
    const nameChi = get('', ['nameChi'])
    return {
      displayName: get(nameChi, ['displayName']),
      organizationNo: get('', ['organizationNo']),
      orgAvatar: get(DEFAULT_PLACEHOLDER, ['logoSquare', 'url'])
    }
  })

export const getDonationItemData = donationItemId =>
  R.pathOr({}, [
    'fundraisingState',
    'entities',
    'donationItems',
    donationItemId
  ])

export const getDonationItems = ({ fundraisingState }) => {
  const frEventNo = R.path(['result', 'fundraising'], fundraisingState)

  if (R.isNil(frEventNo)) return []

  const donationItemIds = R.path(
    ['entities', 'fundraisings', frEventNo, 'donationItems'],
    fundraisingState
  )

  return R.pipe(
    R.path(['entities', 'donationItems']),
    R.pick(donationItemIds),
    R.values
  )(fundraisingState)
}

export const getCustomDonationItem = createSelector(
  getDonationItems,
  donationItems => R.find(R.propEq('type', 'custom'))(donationItems)
)

export const getPredefinedDonationItemIds = createSelector(
  getDonationItems,
  donationItems => {
    return R.isEmpty(donationItems)
      ? []
      : R.pluck(
          'donationItemId',
          R.reject(R.propEq('type', 'custom'), donationItems)
        )
  }
)

const getFundraisingEntity = fundraisingEventNo =>
  R.path(['fundraisingState', 'entities', 'fundraisings', fundraisingEventNo])

export const getFundraisingData = fundraisingEventNo =>
  createSelector(
    getFundraisingEntity(fundraisingEventNo),
    fundraisingEntity => {
      if (R.isNil(fundraisingEntity)) return {}
      const { description } = fundraisingEntity
      return {
        ...fundraisingEntity,
        ...(R.isNil(description)
          ? {}
          : { description: newLineToArray(description) })
      }
    }
  )

export const getFundraisingStatData = fundraisingEventNo =>
  R.pathOr({}, [
    'fundraisingState',
    'entities',
    'fundraisingStats',
    fundraisingEventNo
  ])

export const getFundraisingStatus = fundraisingEventNo =>
  createSelector(getFundraisingData(fundraisingEventNo), fundraisingData => {
    const status = R.prop('status', fundraisingData)
    return R.isNil(status) ? null : FUNDRAISING_STATUS_MAP[status]
  })

export const getLatestDonors = R.pipe(
  R.path(['fundraisingState', 'latestDonors']),
  R.map(({ avatarUrl, donorId }) => ({
    avatar: R.when(
      R.either(R.isNil, R.isEmpty),
      R.always(defaultProfileUrl)
    )(avatarUrl),
    donorId
  }))
)

export const getIsPageCreator = fundraisingEventNo =>
  createSelector(
    getFundraisingEntity(fundraisingEventNo),
    getHk01MemberProfile,
    (fundraisingEntity, hk01MemberProfile) =>
      R.equals(
        Number(R.path(['creator', 'accountId'], fundraisingEntity)),
        R.path(['accountId'], hk01MemberProfile)
      )
  )

export const getCreatorStatistics = R.path([
  'fundraisingState',
  'creatorStatistics'
])

export const getCreatorProjectCount = createSelector(
  getCreatorStatistics,
  statistics =>
    R.converge(R.add, [R.propOr(0, 'approved'), R.propOr(0, 'published')])(
      statistics
    )
)

export const getFundraisingStatusText = fundraisingEventNo =>
  createSelector(
    getFundraisingData(fundraisingEventNo),
    getFundraisingStatData(fundraisingEventNo),
    (fundraisingData, fundraisingStatData) => {
      if (R.isEmpty(fundraisingData) || R.isEmpty(fundraisingStatData))
        return null
      const [endTime, donationTarget] = R.props(
        ['endTime', 'donationTarget'],
        fundraisingData
      )
      const totalDonationAmount = R.prop(
        'totalDonationAmount',
        fundraisingStatData
      )
      // 1. ended + meet target -> 超額完成
      // 2. ended + below or equal target -> 已完成
      // 3. in progress -> 倒數結束 {duration}
      const isEnded = moment().isAfter(endTime)
      const exceededTarget = totalDonationAmount > donationTarget
      return isEnded
        ? exceededTarget
          ? {
              value: i18n.t('common.endedAndMeetTarget')
            }
          : { value: i18n.t('common.ended') }
        : {
            label: i18n.t('common.endCountDown'),
            value: formatter.dateDiff(endTime)
          }
    }
  )
