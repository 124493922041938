import { forceCheck } from 'react-lazyload'
import { call, cancel, delay, fork, put, take } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import { isExist } from '../../../utils/ramdaUtils'
import jssdk from '../../../utils/sdkUtils'
import {
  getHomeCategoriesWorker,
  getSearchCategoriesWorker
} from '../../category/saga/categorySaga'
import {
  checkWebviewSucceeded,
  getFrontendSettingSucceeded,
  getPromoItemSucceeded,
  hideWebviewHeaderRequest,
  hideWebviewHeaderSucceeded,
  resetBindPhoneCounter,
  updateBindPhoneCounter
} from '../actions'

export function* checkWebviewWorker(action) {
  try {
    const isWebview = yield call(jssdk.isWebview)
    yield put(checkWebviewSucceeded(isWebview))
    if (isWebview) {
      yield put(hideWebviewHeaderRequest())
    }
  } catch (err) {
    console.log(err)
  }
}

export function* hideWebviewHeaderWorker(action) {
  try {
    yield call(jssdk.hideHeader)
    yield put(hideWebviewHeaderSucceeded())
    // react-lazyload forceCheck to ensure image in viewport is loaded properly
    yield call(forceCheck)
    yield delay(500)
    yield fork(jssdk.hideHeader)
    yield delay(2000)
    yield fork(jssdk.hideHeader)
  } catch (err) {
    console.log(err)
  }
}

export function* goTo01ArticleWorker(action) {
  try {
    yield call(jssdk.goTo01Article, action.payload.articleId)
  } catch (err) {
    console.log(err)
  }
}

export function* goTo01PageWorker(action) {
  try {
    yield call(jssdk.goTo01Page, action.payload.link)
  } catch (err) {
    console.log(err)
  }
}

export function* openNativeInAppBrowserWorker(action) {
  try {
    const { url, callbackFn, opts } = action.payload
    yield call(jssdk.openNativeInAppBrowser, url, callbackFn, opts)
  } catch (err) {
    console.log(err)
  }
}

export function* dismissWebviewWorker(action) {
  try {
    yield call(jssdk.dismissWebview)
  } catch (err) {
    console.log(err)
  }
}

export function* showShareDialogWorker(action) {
  const { message, url, title, callbackFn } = action.payload
  try {
    yield call(jssdk.showShareDialog, message, url, title)
    if (callbackFn) {
      yield call(callbackFn)
    }
  } catch (err) {
    console.log(err)
  }
}

function* runBindPhoneCounter() {
  try {
    for (let i = process.env.BIND_PHONE_COUNTER; i > 0; i--) {
      yield delay(1000)
      yield put(updateBindPhoneCounter(i - 1))
    }
  } catch (err) {
    console.log(err)
  }
}
export function* initBindPhoneCounterWorker() {
  const task = yield fork(runBindPhoneCounter)
  yield take(resetBindPhoneCounter)
  yield cancel(task)
}

export function* getFrontendSettingWorker(action) {
  try {
    const response = yield call(charityApi.getFrontendSetting)
    yield put(getFrontendSettingSucceeded(response))
    const { parentCategoryNos, homePageCategoryNos } = response
    if (isExist(parentCategoryNos)) {
      yield call(getSearchCategoriesWorker, parentCategoryNos)
    }
    if (isExist(homePageCategoryNos)) {
      yield call(getHomeCategoriesWorker, homePageCategoryNos)
    }
  } catch (err) {
    console.log(err)
  }
}

export function* getPromoItemWorker() {
  try {
    const response = yield call(charityApi.getPromoItem)
    yield put(getPromoItemSucceeded(response))
  } catch (err) {
    console.log(err)
  }
}
