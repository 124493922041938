import * as R from 'ramda'
import React from 'react'
import LazyLoad from 'react-lazyload'
import styled from 'styled-components'

import { objectFit } from '../utils/cssUtils'

const StyledAvatar = styled(
  React.forwardRef(({ size, ...props }, ref) => <div {...props} ref={ref} />)
)`
  flex-shrink: 0;
  ${({ size }) => size && `width: ${size}px; height: ${size}px`};
  position: relative;
`
const AvatarInner = styled.div`
  background-color: #fff;
  ${({ borderColor }) => borderColor && `border: 1px solid ${borderColor}`};
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  div {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    ${objectFit('contain')}
  }
  svg {
    width: 100%;
  }
`

const Avatar = React.memo(
  React.forwardRef(
    (
      { size, src, children, borderColor, onLoad, ring, style, className },
      ref
    ) => (
      <StyledAvatar size={size} className={className} style={style} ref={ref}>
        <AvatarInner borderColor={borderColor}>
          <LazyLoad>
            {R.and(!R.isNil(children), R.isNil(src)) ? (
              children
            ) : (
              <img onLoad={onLoad} src={src} />
            )}
          </LazyLoad>
        </AvatarInner>
        {ring}
      </StyledAvatar>
    )
  )
)

export default Avatar
