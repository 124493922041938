const cjk = require('cjk-regex')
const R = require('ramda')

const cjkLetters = cjk.letters().toRegExp()

const convertChar = R.unless(
  R.either(R.test(cjkLetters), R.test(/[a-zA-Z0-9]/)),
  R.always('-')
)

const cleanUpDuplicateDash = R.replace(/-+/g, '-')

const cleanUpLeadingTrailingDash = R.replace(/(^-|-$)/g, '')

const slugify = R.pipe(
  R.split(''),
  R.map(convertChar),
  R.join(''),
  R.toLower,
  cleanUpDuplicateDash,
  cleanUpLeadingTrailingDash
)

module.exports = {
  slugify
}
