import React from 'react'
import styled, { css } from 'styled-components'

import {
  calcLineHeightOffset,
  fixIe,
  getColor,
  getFontSize
} from '../../../utils/cssUtils'
import mq from '../../../utils/mediaQuery'
import { loadingAnimation } from '../BackgroundEffect'

const ArticleLink = styled.div`
  display: inline-flex;
  ${mq.mobile} {
    width: 100%;
  }
`
const Article = styled.div`
  position: relative;
  display: flex;
  width: 792px;
  height: 159px;
  margin: 0 auto 24px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px rgba(181, 181, 181, 0.4);
  font-size: ${getFontSize('body2')};
  color: ${getColor('grey')};
  ${mq.tablet} {
    width: 640px;
    height: 155px;
  }
  ${mq.mobile} {
    width: 100%;
    height: 96px;
    margin-bottom: 16px;
  }
`
const ArticleImgWrapper = styled.div`
  flex-shrink: 0;
  width: 283px;
  height: 100%;
  margin: 0;
  overflow: hidden;
  position: relative;
  ${mq.tablet} {
    width: 276px;
  }
  ${mq.mobile} {
    width: 160px;
  }
  background-color: ${getColor('skeleton')};
`
const ArticleInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px;
  ${mq.mobile} {
    padding: 12px;
  }
  // ie11 fix
  width: 100%;
`
const Title = styled.div`
  flex-shrink: 0;
  font-size: ${getFontSize('title')};
  color: ${getColor('black')};
  line-height: 1.5;
  margin-top: ${calcLineHeightOffset(18, 1.5)}px;
  margin-bottom: ${calcLineHeightOffset(18, 1.5) + 8}px;
  ${mq.tablet} {
    margin-bottom: ${calcLineHeightOffset(18, 1.5) + 6}px;
  }
  ${mq.mobile} {
    font-size: ${getFontSize('body2')};
    margin-bottom: ${calcLineHeightOffset(16, 1.5) + 4}px;
  }
`
const Description = styled.div`
  flex-shrink: 0;
  font-size: ${getFontSize('body2')};
  line-height: 1.5;
  margin-top: ${calcLineHeightOffset(16, 1.5)}px;
  margin-bottom: ${calcLineHeightOffset(16, 1.5) + 8}px;
  ${mq.tablet} {
    margin-bottom: ${calcLineHeightOffset(16, 1.5) + 6}px;
  }
  ${mq.mobile} {
    display: none !important;
  }
  ${fixIe(css`
    display: flex;
    overflow: hidden;
    max-height: ${16 * 1.5 * 2}px;
  `)}
`
const Bar = styled.div`
  width: ${({ long }) => (long ? '100%' : '70%')};
  height: 20px;
  ${({ long }) =>
    long &&
    css`
      margin-bottom: 8px;
    `}
  ${loadingAnimation}
`

const ArticleItem = React.memo(({ t, articleId, onClick, ...props }) => {
  return (
    <ArticleLink key={articleId}>
      <Article key={articleId}>
        <ArticleImgWrapper />
        <ArticleInfo>
          <Title>
            <Bar long />
            <Bar />
          </Title>
          <Description>
            <Bar long />
            <Bar />
          </Description>
        </ArticleInfo>
      </Article>
    </ArticleLink>
  )
})

export default ArticleItem
