import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { withTranslation } from '../../../i18n'
import { getColor, getFontSize } from '../../../utils/cssUtils'
import Flex from '../../Layout/Flex'
import { Col, Row } from '../../Layout/FlexGrid'
import { loadingAnimation } from '../BackgroundEffect'
import CorporateLogo from './CorpLogo'

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 48px;
`
const HeadlineWrapper = styled(Flex)`
  flex-wrap: wrap;
  margin-bottom: 16px;
`
const Headline = styled.h1`
  width: 100%;
  margin: 0 auto 4px auto;
  width: 180px;
  height: 24px;
  ${loadingAnimation}
`
const SubHeadline = styled.span`
  font-size: ${getFontSize('body1')};
  color: ${getColor('grey')};
  width: 100%;
  text-align: center;
`

const CorporateCategory = withTranslation('common')(({ t, isLarge }) => {
  return (
    <Wrapper>
      <HeadlineWrapper>
        <Headline />
        <SubHeadline>
          {t('corporate.corporatesSortByAlphabeticalOrder')}
        </SubHeadline>
      </HeadlineWrapper>
      <Row
        gutter={[
          { xl: 24, md: 24, xs: 8 },
          { xl: 24, md: 24, xs: 16 }
        ]}
      >
        {R.addIndex(R.map)(
          (corporateNo, i) => (
            <Col
              key={corporateNo || i}
              xl={isLarge ? 12 : 6}
              md={isLarge ? 24 : 12}
              xs={isLarge ? 24 : 12}
            >
              <CorporateLogo horizontal={isLarge} showDescription={isLarge} />
            </Col>
          ),
          Array(6).fill(null)
        )}
      </Row>
    </Wrapper>
  )
})

export default CorporateCategory
