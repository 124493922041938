import * as R from 'ramda'

import { PAGE } from '../constants'
import jssdk from './sdkUtils'

const tracker = jssdk.tracker()

const calRowNum = itemIdx => {
  if (window) {
    const w = window.outerWidth
    let noOfItemPerRow = 1
    if (w >= 1200) {
      noOfItemPerRow = 3
    } else if (w >= 768) {
      noOfItemPerRow = 2
    }

    return Math.floor(itemIdx / noOfItemPerRow) + 1
  }
  return 1
}

export const fire = (
  category,
  action,
  label = {},
  target = { GA: true, Piwik: true }
) => {
  tracker.then(trackerClient =>
    trackerClient.fire(target, {
      category,
      action,
      label: JSON.stringify({ ...jssdk.getOpts(), ...label })
    })
  )
}

const pageView = (target = { GA: true, Piwik: true }) => {
  tracker.then(trackerClient => trackerClient.pageView(target))
}

/********************************
ACCOUNT
********************************/
export const enterAccountPage = () => {
  pageView()
  fire('heart_account', 'view')
}

export const accountClickMenu = toTab => {
  fire('heart_account', 'click_menu', {
    to_tab: toTab
  })
}

export const enterLoginLoading = () => {
  pageView()
  fire('heart_account', 'view_login_loading')
}

export const loginLoadingClickBack = () => {
  fire('heart_account', 'back_login_loading')
}

export const profileEnter = () => {
  pageView()
  fire('heart_profile', 'view')
}

export const profileClickBack = () => {
  fire('heart_profile', 'back')
}

export const profileClickSave = () => {
  fire('heart_profile', 'save')
}

export const profileSucceedSave = () => {
  fire('heart_profile', 'profile_saved')
}

export const profileFailSave = errCode => {
  fire('heart_profile', 'profile_failed', {
    error_code: errCode
  })
}

export const menuClick = (toTab, menuType) => {
  fire('heart_menu', 'change_tab', {
    to_tab: toTab,
    menu_type: menuType
  })
}

/********************************
HOME
********************************/

export const homeClickCategory = (categoryName = null, categoryNo = null) => {
  fire('heart_home', 'click_category', {
    category_name: categoryName,
    category_no: categoryNo
  })
}

export const homeReachCategory = (displayNameList, categoryNos) => {
  fire('heart_home', 'reach_category', {
    category_name: displayNameList,
    category_no: categoryNos
  })
}

/********************************
PROJECT DETAIL
********************************/
export const detailClickBack = (projectNo, projectTitle) => {
  fire('heart_detail', 'click_back', {
    item_id: projectNo,
    item_title: projectTitle
  })
}

export const detailClickHome = (projectNo, projectTitle) => {
  fire('heart_detail', 'click_home', {
    item_id: projectNo,
    item_title: projectTitle
  })
}

export const detailClickDonatePopup = (projectNo, projectTitle) => {
  fire('heart_detail', 'click_donate_popup', {
    item_id: projectNo,
    item_title: projectTitle
  })
}

export const detailCloseDonatePopup = (projectNo, projectTitle) => {
  fire('heart_detail', 'close_donate_popup', {
    item_id: projectNo,
    item_title: projectTitle
  })
}

export const detailReachOrganization = (projectNo, projectTitle) =>
  fire('heart_detail', 'reach_ngo_logo', {
    item_id: projectNo,
    item_title: projectTitle
  })

export const detailClickOrganization = organizationNo =>
  fire('heart_detail', 'click_organization', {
    organization_id: organizationNo
  })

export const detailReachDescription = (projectNo, projectTitle) =>
  fire('heart_detail', 'reach_description', {
    item_id: projectNo,
    item_title: projectTitle
  })

export const detailReachTnc = (projectNo, projectTitle) =>
  fire('heart_detail', 'reach_tnc', {
    item_id: projectNo,
    item_title: projectTitle
  })

export const detailReachDonateBoxCustom = (
  projectNo,
  projectTitle,
  donationCount
) =>
  fire('heart_detail', 'reach_donate_box_custom', {
    item_id: projectNo,
    item_title: projectTitle,
    donation_count: donationCount
  })

export const detailReachDonateBox = (
  projectNo,
  projectTitle,
  donationCount,
  donateAmount
) =>
  fire('heart_detail', 'reach_donate_box', {
    item_id: projectNo,
    item_title: projectTitle,
    donate_amount: donateAmount,
    donation_count: donationCount
  })

export const detailClickVideo = (projectNo, projectTitle, videoId) =>
  fire('heart_detail', 'click_video', {
    item_id: projectNo,
    item_title: projectTitle,
    video_session_id: videoId
  })

export const detailClickDonateBoxCustom = (
  projectNo,
  projectTitle,
  donationCount,
  donationAmount
) =>
  fire('heart_detail', 'click_donate_box_custom', {
    item_id: projectNo,
    item_title: projectTitle,
    donate_amount: donationAmount,
    donation_count: donationCount
  })

/********************************
ENROLLMENT
********************************/
export const enrollmentSuccess = (
  projectNo,
  projectTitle,
  donateAmount,
  orderId,
  txnId
) =>
  fire('heart_enrollment', 'success', {
    item_id: projectNo,
    item_title: projectTitle,
    donate_amount: donateAmount,
    donation_id: orderId,
    transaction_id: txnId
  })

export const enrollmentSuccessDismiss = (
  projectNo,
  projectTitle,
  donateAmount,
  orderId,
  txnId
) =>
  fire('heart_enrollment', 'success_dismiss', {
    item_id: projectNo,
    item_title: projectTitle,
    donate_amount: donateAmount,
    donation_id: orderId,
    transaction_id: txnId
  })

export const enrollmentSuccessSendReceipt = (
  projectNo,
  projectTitle,
  donateAmount,
  orderId,
  txnId
) =>
  fire('heart_enrollment', 'success_send_receipt', {
    item_id: projectNo,
    item_title: projectTitle,
    donate_amount: donateAmount,
    donation_id: orderId,
    transaction_id: txnId
  })

export const enrollmentSuccessShare = (
  projectNo,
  projectTitle,
  donateAmount,
  orderId,
  txnId,
  shareTarget
) =>
  fire('heart_enrollment', 'success_share', {
    donation_id: orderId,
    transaction_id: txnId,
    item_id: projectNo,
    item_title: projectTitle,
    donate_amount: donateAmount,
    share_target: shareTarget
  })

export const enrollmentFailure = (
  projectNo,
  projectTitle,
  donateAmount,
  orderId,
  txnId = null
) =>
  fire('heart_enrollment', 'failure', {
    item_id: projectNo,
    item_title: projectTitle,
    donate_amount: donateAmount,
    donation_id: orderId,
    transaction_id: txnId
  })

export const enrollmentFailureClickBack = (
  projectNo,
  projectTitle,
  donateAmount,
  orderId,
  txnId = null
) =>
  fire('heart_enrollment', 'failure_click_back', {
    item_id: projectNo,
    item_title: projectTitle,
    donate_amount: donateAmount,
    donation_id: orderId,
    transaction_id: txnId
  })

export const enrollmentClickOrderListing = (
  donationId,
  transactionId,
  itemId,
  itemTitle
) => {
  fire('heart_enrollment', 'click_order_listing', {
    donation_id: donationId,
    transaction_id: transactionId,
    item_id: itemId,
    item_title: itemTitle
  })
}

/********************************
MY DONATION
********************************/
export const donationViewListing = () => {
  pageView()
  fire('heart_donation', 'view_listing')
}

export const donationViewEmpty = () => {
  fire('heart_donation', 'view_empty')
}

export const donationClickListing = () => {
  fire('heart_donation', 'click_heart_listing')
}

/********************************
MY TICKET
********************************/
export const enterTicketPage = () => {
  fire('heart_ticket', 'view')
}

export const ticketClickDetail = (ticketId, ticketTitle) => {
  fire('heart_ticket', 'click_ticket_detail', {
    ticket_id: ticketId,
    ticket_title: ticketTitle
  })
}

/********************************
ORDER
********************************/
export const orderView = (donationId, transactionId, itemId, itemTitle) => {
  pageView()
  fire('heart_order', 'view_order', {
    donation_id: donationId,
    transaction_id: transactionId,
    item_id: itemId,
    item_title: itemTitle
  })
}

export const orderShare = (itemId, itemTitle, shareTarget) => {
  fire('heart_order', 'order_share', {
    item_id: itemId,
    item_title: itemTitle,
    share_target: shareTarget
  })
}

export const orderUpdate = (donationId, transactionId, itemId, itemTitle) => {
  fire('heart_order', 'update_order', {
    donation_id: donationId,
    transaction_id: transactionId,
    item_id: itemId,
    item_title: itemTitle
  })
}

export const orderSendReceipt = (
  donationId,
  transactionId,
  itemId,
  itemTitle
) => {
  fire('heart_order', 'send_receipt', {
    donation_id: donationId,
    transaction_id: transactionId,
    item_id: itemId,
    item_title: itemTitle
  })
}

/********************************
EXTRA DONATION
********************************/
export const extraDonationView = () => {
  fire('heart_extra_donation', 'view')
}

export const extraDonationInputAmount = () => {
  fire('heart_extra_donation', 'input_amount')
}

export const extraDonationClickAmount = amount => {
  fire('heart_extra_donation', 'click_amount', { amount })
}

export const extraDonationClickNext = () => {
  fire('heart_extra_donation', 'click_next')
}

export const faqView = () => {
  pageView()
  fire('heart_misc', 'view_faq')
}

/********************************
ABOUT US
********************************/
export const aboutUsView = () => {
  pageView()
  fire('heart_misc', 'view_aboutus')
}

export const emptyResultView = () => fire('heart_misc', 'view_empty')

/********************************
404
********************************/
export const notFoundView = () => fire('heart_misc', 'view_404')

/********************************
500
********************************/
export const serverErrorView = () => fire('heart_misc', 'view_500')

/********************************
// BELOW ARE NEW
// IMPLEMENTATION USING
// REDUX SAGA
// FOR DATA TRACKING
********************************/

export const clickPromoBanner = promoBannerNo => [
  'heart_home',
  'click_promo_banner',
  { banner_id: promoBannerNo }
]

/********************************
HOME
********************************/
export const homeView = () => ['heart_home', 'view']

export const homeReachProjectCard = (
  projectNo,
  projectName,
  itemIndex,
  projectType
) => [
  'heart_home',
  'reach',
  {
    item_type: 'project',
    item_id: projectNo,
    item_title: projectName,
    row_num: calRowNum(itemIndex),
    project_type: projectType
  }
]

export const homeClickProjectCard = (
  button,
  buttonId,
  projectNo,
  projectName,
  itemIndex,
  projectType
) => [
  'heart_home',
  'click',
  {
    button,
    button_id: buttonId,
    item_type: 'project',
    item_id: projectNo,
    item_title: projectName,
    row_num: calRowNum(itemIndex),
    project_type: projectType
  }
]

export const homeReachFundraisingEventCard = (
  fundraisingEventNo,
  fundraisingEventTitle
) => [
  'heart_home',
  'reach',
  {
    item_type: 'fundraising_event',
    item_id: fundraisingEventNo,
    item_title: fundraisingEventTitle,
    row_num: 1
  }
]

export const homeClickFundraisingEventCard = (
  fundraisingEventNo,
  fundraisingEventTitle
) => [
  'heart_home',
  'click',
  {
    button: 'fundraising_event',
    button_id: fundraisingEventNo,
    item_type: 'fundraising_event',
    item_id: fundraisingEventNo,
    item_title: fundraisingEventTitle,
    row_num: 1
  }
]

export const homeClickFundraisingEventCta = () => [
  'heart_home',
  'click',
  {
    button: 'explore_project',
    button_id: 'fundraising_eligible'
  }
]

export const homeClickDiscoverBtn = () => [
  'heart_home',
  'click',
  {
    button: 'explore_project',
    button_id: 'all_project'
  }
]

export const homeReachPromotionListing = (orgNo, orgName, listOrder) => [
  'heart_home',
  'reach',
  {
    item_type: 'promotion_listing',
    item_id: orgNo,
    item_title: orgName,
    row_num: listOrder
  }
]

export const homeClickPromotionListingBanner = (bannerId, orgNo, orgName) => [
  'heart_home',
  'click',
  {
    button: 'banner',
    button_id: bannerId,
    item_type: 'organization',
    item_id: orgNo,
    item_title: orgName,
    row_num: 1,
    project_type: 'promotion_listing'
  }
]

/********************************
DISCOVER DETAIL
********************************/
export const discoverSearchCompleted = (
  returnCount,
  pageNum,
  searchText,
  filterCategoryName,
  filterCategoryNo,
  typeFilter,
  sort
) => [
  'heart_discover',
  'view',
  {
    return_count: returnCount,
    page_num: pageNum,
    search_text: searchText,
    filter_category_name: filterCategoryName,
    filter_category_no: filterCategoryNo,
    type_filter: typeFilter,
    sort
  }
]

export const discoverClickCard = (
  button,
  buttonId,
  projectNo,
  projectName,
  index,
  returnCount,
  pageNum,
  searchText,
  filterCategoryName,
  filterCategoryNo,
  typeFilter,
  sort
) => [
  'heart_discover',
  'click',
  {
    button: button,
    button_id: buttonId,
    item_id: projectNo,
    item_title: projectName,
    item_position: index + 1,
    return_count: returnCount,
    page_num: pageNum,
    search_text: searchText,
    filter_category_name: filterCategoryName,
    filter_category_no: filterCategoryNo,
    type_filter: typeFilter,
    sort
  }
]

export const discoverClickSearchFundraisingEventBtn = () => [
  'heart_discover',
  'click',
  {
    button: 'explore_project',
    button_id: 'fundraising_eligible'
  }
]

export const discoverClickFaq = () => [
  'heart_discover',
  'click',
  {
    button: 'faq'
  }
]

export const discoverClickContactUs = () => [
  'heart_discover',
  'click',
  {
    button: 'contact_us'
  }
]

export const discoverViewAppviewFilterPanel = () => [
  'heart_discover',
  'view',
  {
    screen: 'filter_panel'
  }
]

export const discoverClickAppviewShowResultBtn = (
  returnCount,
  searchText,
  filterCategoryName,
  filterCategoryNo,
  typeFilter,
  sort
) => [
  'heart_discover',
  'click',
  {
    screen: 'filter_panel',
    button: 'show_result',
    return_count: returnCount,
    search_text: searchText,
    filter_category_name: filterCategoryName,
    filter_category_no: filterCategoryNo,
    type_filter: typeFilter,
    sort
  }
]

/********************************
PROJECT DETAIL
********************************/
export const detailView = (itemId, itemTitle, isFundraisingEligible) => [
  'heart_detail',
  'view',
  {
    item_id: itemId,
    item_title: itemTitle,
    is_fundraising_eligible: isFundraisingEligible
  }
]

export const detailClickHero = (button, buttomId, itemId, itemTitle) => [
  'heart_detail',
  'click',
  {
    button,
    button_id: buttomId,
    item_id: itemId,
    item_title: itemTitle
  }
]

export const detailClickDonationItem = (
  itemId,
  itemTitle,
  donateAmount,
  donationCount
) => [
  'heart_detail',
  'click_donate_box',
  {
    item_id: itemId,
    item_title: itemTitle,
    donate_amount: donateAmount,
    donation_count: donationCount
  }
]

export const detailClickCreateFundraisingEventBtn = (itemId, itemTitle) => [
  'heart_detail',
  'click',
  {
    button: 'create_fundraising_event',
    item_id: itemId,
    item_title: itemTitle
  }
]

export const detailClickFaq = () => fire('heart_detail', 'click_faq')

export const detailReachProjectCard = (
  itemId,
  itemTitle,
  itemIndex,
  projectType
) => [
  'heart_detail',
  'reach',
  {
    item_type: 'project',
    item_id: itemId,
    item_title: itemTitle,
    row_num: calRowNum(itemIndex),
    project_type: projectType,
    page_num: 1
  }
]

export const detailClickProjectCard = (
  button,
  buttonId,
  itemId,
  itemTitle,
  itemIndex,
  projectType
) => [
  'heart_detail',
  'click',
  {
    button,
    button_id: buttonId,
    item_type: 'project',
    item_id: itemId,
    item_title: itemTitle,
    row_num: calRowNum(itemIndex),
    project_type: projectType,
    page_num: 1
  }
]

/********************************
ORGANIZATION DETAIL
********************************/
export const organizationView = (organizationNo, organizationName) => [
  'heart_organization',
  'view',
  {
    organization_id: organizationNo,
    organization_name: organizationName
  }
]

export const organizationLoadMore = (
  organizationNo,
  organizationName,
  pageNum
) => [
  'heart_organization',
  'load_more',
  {
    organization_id: organizationNo,
    organization_name: organizationName,
    page_num: pageNum
  }
]

export const organizationReachProjectCard = (
  projectNo,
  projectName,
  itemIndex,
  projectType,
  pageNum
) => [
  'heart_organization',
  'reach',
  {
    item_type: 'project',
    item_id: projectNo,
    item_title: projectName,
    row_num: calRowNum(itemIndex),
    project_type: projectType,
    page_num: pageNum
  }
]

export const organizationClickProjectCard = (
  button,
  buttonId,
  projectNo,
  projectName,
  itemIndex,
  projectType,
  pageNum
) => [
  'heart_organization',
  'click',
  {
    button,
    button_id: buttonId,
    item_type: 'project',
    item_id: projectNo,
    item_title: projectName,
    row_num: calRowNum(itemIndex),
    project_type: projectType,
    page_num: pageNum
  }
]

/********************************
FUNDRAISING DETAIL
********************************/
export const fundraisingDetailView = (itemId, itemTitle) => [
  'heart_fundraising',
  'view',
  {
    item_id: itemId,
    item_title: itemTitle
  }
]

export const fundraisingDetailClickDonateNowBtn = (
  itemId,
  itemTitle,
  donateAmount,
  donationCount
) => [
  'heart_fundraising',
  'click_donate_box',
  {
    item_id: itemId,
    item_title: itemTitle,
    donate_amount: donateAmount,
    donation_count: donationCount
  }
]

export const fundraisingDetailClickOrganization = (
  buttonId,
  itemId,
  itemTitle
) => [
  'heart_fundraising',
  'click',
  {
    button: 'organization',
    button_id: buttonId,
    item_id: itemId,
    item_title: itemTitle
  }
]

export const fundraisingDetailClickPopUpConfirmDonate = (
  itemId,
  itemTitle,
  donateAmount,
  donationCount
) => [
  'heart_fundraising',
  'click_confirm_donate',
  {
    item_id: itemId,
    item_title: itemTitle,
    donate_amount: donateAmount,
    donation_count: donationCount
  }
]

export const fundraisingDetailClickAppviewHomeBtn = (itemId, itemTitle) => [
  'heart_fundraising',
  'click_home',
  {
    item_id: itemId,
    item_title: itemTitle
  }
]

export const fundraisingDetailClickAppviewShowDrawerBtn = (
  itemId,
  itemTitle
) => [
  'heart_fundraising',
  'click_donate_popup',
  {
    item_id: itemId,
    item_title: itemTitle
  }
]

/********************************
FUNDRAISING CREATE FORM
********************************/
export const fundraisingCreateFormView = (
  itemId,
  itemTitle,
  fundraisingEventNo,
  status
) => [
  'heart_fundraising_form',
  'view',
  {
    item_id: itemId,
    item_title: itemTitle,
    fundraising_event_no: fundraisingEventNo,
    status: status
  }
]

export const fundraisingCreateFormClickCancelBtn = (
  itemId,
  itemTitle,
  fundraisingEventNo,
  status
) => [
  'heart_fundraising_form',
  'click',
  {
    button: 'exit',
    item_id: itemId,
    item_title: itemTitle,
    fundraising_event_no: fundraisingEventNo,
    status: status
  }
]

export const fundraisingCreateFormClickSubmitBtn = (
  button,
  itemId,
  itemTitle,
  fundraisingEventNo,
  status
) => [
  'heart_fundraising_form',
  'click',
  {
    button: button,
    item_id: itemId,
    item_title: itemTitle,
    fundraising_event_no: fundraisingEventNo,
    status: status
  }
]

/********************************
FUNDRAISING PREVIEW
********************************/
export const fundraisingPreviewView = (
  itemId,
  itemTitle,
  fundraisingEventNo,
  status
) => [
  'heart_fundraising_form',
  'view_preview',
  {
    item_id: itemId,
    item_title: itemTitle,
    fundraising_event_no: fundraisingEventNo,
    status: status
  }
]

export const fundraisingPreviewClickSubmitBtn = (
  button,
  itemId,
  itemTitle,
  fundraisingEventNo,
  status
) => [
  'heart_fundraising_form',
  'click',

  {
    button: button,
    item_id: itemId,
    item_title: itemTitle,
    fundraising_event_no: fundraisingEventNo,
    status: status
  }
]

/********************************
FUNDRAISING SUBMIT SUCCESS
********************************/
export const fundraisingSubmitSuccessView = (
  itemId,
  itemTitle,
  fundraisingEventNo
) => [
  'heart_fundraising_form',
  'submit_success',
  {
    item_id: itemId,
    item_title: itemTitle,
    fundraising_event_no: fundraisingEventNo
  }
]

/********************************
ARTICLE
********************************/
export const articleListingView = tab => [
  'heart_article_listing',
  'view',
  { tab }
]

export const articleListingClickArticle = (articleId, tab) => [
  'heart_article_listing',
  'click_article',
  { articleId, tab }
]

export const articleListingClickMoreArticle = tab => [
  'heart_article_listing',
  'click_more_article',
  { tab }
]

export const articleChangeTab = (toTab, fromTab) => [
  'heart_article_listing',
  'change_tab',
  {
    to_tab: toTab,
    from_tab: fromTab
  }
]

/********************************
CORPORATE
********************************/
export const corporateHomeView = () => ['heart_corporate_landing', 'view']

export const corporateHomeClickArticle = articleId => [
  'heart_corporate_landing',
  'click_article',
  { articleId }
]

export const corporateHomeClickMoreArticle = () => [
  'heart_corporate_landing',
  'click_more_article'
]

export const corporateHomeClickDetail = corporateId => [
  'heart_corporate_landing',
  'click_corporate_detail',
  { corporateId }
]

export const corporateHomeClickListing = () => [
  'heart_corporate_landing',
  'click_corporate_listing'
]

export const corporateContactView = () => ['heart_corporate_contact', 'view']

export const corporateContactSubmitForm = () => [
  'heart_corporate_contact',
  'submit_form'
]

export const corporateContactSubmitSuccess = () => [
  'heart_corporate_contact',
  'submit_success'
]

export const corporateContactSuccessClickBack = () => [
  'heart_corporate_contact',
  'success_click_back'
]

export const corporateListingView = () => ['heart_corporate_listing', 'view']

export const corporateListingClickDetail = corporateId => [
  'heart_corporate_listing',
  'click_corporate_detail',
  { corporateId }
]

export const corporateDetailView = corporateId => [
  'heart_corporate_detail',
  'view',
  { corporateId }
]

export const corporateDetailClickArticle = (corporateId, articleId) => [
  'heart_corporate_detail',
  'click_article',
  { corporateId, articleId }
]

export const corporateClickContact = (pageName, buttonPos) => {
  const category = R.cond([
    [R.equals(PAGE.CORPORATE), R.always('heart_corporate_landing')],
    [R.equals(PAGE.ARTICLE), R.always('heart_article_listing')],
    [R.equals(PAGE.CORPORATE_LISTING), R.always('heart_corporate_listing')]
  ])(pageName)
  return [
    category,
    'click_contact',
    ...(buttonPos ? [{ button_pos: buttonPos }] : [])
  ]
}
