import styled from 'styled-components'

import mq from '../../../utils/mediaQuery'

const PartnerCorpLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 282px;
  height: 172px;
  padding: 30px 0;
  ${mq.tablet} {
    width: 197px;
    height: 154px;
    padding: 24px 0;
  }
  ${mq.mobile} {
    width: 171px;
    height: 134px;
    padding: 16px 0;
  }
  background-color: rgba(181, 181, 181, 0.2);
`

export default PartnerCorpLink
