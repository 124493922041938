import { createActions } from 'redux-actions'

import { createSyncAsyncActions } from '../../utils/reduxUtils'

export const {
  getCorporateListRequest,
  getCorporateListSucceeded,
  getCorporateDetailRequest,
  getCorporateDetailSucceeded,
  getCorporateCategoryListRequest,
  getCorporateCategoryListSucceeded,
  getCorporateCategoryListPageRequest,
  getCorporateHomePageRequest,
  sendContactFormSucceeded
} = createActions({
  GET_CORPORATE_LIST_REQUEST: (page, limit, sortBy, sortDirection) => ({
    page,
    limit,
    sortBy,
    sortDirection
  }),
  GET_CORPORATE_LIST_SUCCEEDED: normalized => normalized,
  GET_CORPORATE_DETAIL_REQUEST: corporateNo => ({ corporateNo }),
  GET_CORPORATE_DETAIL_SUCCEEDED: normalized => normalized,
  GET_CORPORATE_CATEGORY_LIST_REQUEST: (
    page,
    limit,
    sortBy,
    sortDirection,
    corporateCategoryNos
  ) => ({ page, limit, sortBy, sortDirection, corporateCategoryNos }),
  GET_CORPORATE_CATEGORY_LIST_SUCCEEDED: normalized => normalized,
  GET_CORPORATE_CATEGORY_LIST_PAGE_REQUEST: isInitialRender => ({
    isInitialRender
  }),
  GET_CORPORATE_HOME_PAGE_REQUEST: isInitialRender => ({ isInitialRender }),
  SEND_CONTACT_FORM_SUCCEEDED: undefined
})

export const { sendContactFormRequestAsync } = createSyncAsyncActions(
  'SEND_CONTACT_FORM_REQUEST',
  contactForm => ({
    contactForm
  })
)
