import Link from 'next/link'
import * as R from 'ramda'
import React from 'react'
import { Waypoint } from 'react-waypoint'
import styled, { css } from 'styled-components'

import { MEDIA_CONDITIONS } from '../../constants'
import CardWrapper from '../../containers/CardWrapper'
import { getColor, getFontSize, objectFit } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'
import { isExist } from '../../utils/ramdaUtils'
import AspectBox from '../AspectBox'
import CardFlag from '../CardFlag'
import Picture from '../Picture'

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`
export const StyledWrapper = styled(CardWrapper)`
  width: 100%;
  ${({ inActive }) =>
    inActive &&
    css`
      background-color: ${getColor('lightGrey', 0.2)};
    `}
  ${({ listView }) =>
    listView &&
    css`
      height: 216px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      ${mq.mobile} {
        height: auto;
        display: block;
        justify-content: intial;
        align-items: intial;
      }
    `}
`
const MainImgWrapper = styled.div`
  margin: 0;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid ${getColor('border')};
  width: ${({ listView }) => (listView ? '384px' : 'auto')};
  ${mq.mobile} {
    margin: -1px -1px 0 -1px;
    width: auto;
  }
`
const ProjectContent = styled.div`
  width: 100%;
  height: 196px;
  padding: 16px 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  ${({ listView }) =>
    listView &&
    css`
      width: calc(100% - 384px);
      height: 100%;
      padding: 22px 24px;
      ${mq.tabletMobile} {
        padding: 12px 16px 16px;
      }
      ${mq.mobile} {
        width: 100%;
      }
    `}
`
const MainImg = styled(Picture)`
  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    object-position: 50% 50%;
    ${objectFit('cover', '50% 50%')}
  }
`
const StyledCardFlag = styled(CardFlag)`
  position: absolute;
  right: 0;
  bottom: 16px;
`
const ProjectName = styled.div`
  margin-bottom: 12px;
  height: 48px;
  flex-shrink: 0;
`
export const ProjectTitle = styled.h3`
  font-weight: 600;
  font-size: ${getFontSize('title')};
  line-height: 24px;
  color: ${getColor('grey')};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

const MainImage = ({ listView, mainImg, flagText }) => (
  <MainImgWrapper listView={listView}>
    <AspectBox>
      <MainImg
        image={mainImg}
        sizes={
          listView
            ? `${MEDIA_CONDITIONS.DESKTOP_TABLET} 384px, calc(100vw - 24px)`
            : `${MEDIA_CONDITIONS.DESKTOP} 384px, ${MEDIA_CONDITIONS.TABLET} calc(100vw - 128px - 12px), calc(100vw - 24px)`
        }
      />
      {isExist(flagText) && <StyledCardFlag>{flagText}</StyledCardFlag>}
    </AspectBox>
  </MainImgWrapper>
)

const LinkOverlay = ({ href }) =>
  R.isNil(href) ? null : (
    <Link href={href}>
      <a>
        <Overlay />
      </a>
    </Link>
  )

const ProjectCard = ({
  name,
  mainImg,
  flagText,
  listView,
  avatar,
  fundraisingProgress,
  href,
  className,
  clickTracking,
  reachTracking,
  showHoverEffect = true,
  inActive,
  isAppview
}) => {
  return (
    <StyledWrapper
      showHoverEffect={showHoverEffect}
      className={className}
      isAppview={isAppview}
      listView={listView}
      inActive={inActive}
      onClick={() => {
        clickTracking && clickTracking()
      }}
    >
      <MainImage listView={listView} mainImg={mainImg} flagText={flagText} />
      <ProjectContent listView={listView}>
        {avatar}
        <ProjectName>
          <ProjectTitle>{name}</ProjectTitle>
        </ProjectName>
        {fundraisingProgress}
      </ProjectContent>
      <LinkOverlay href={href} />
      <Waypoint
        onEnter={R.once(() => {
          reachTracking && reachTracking()
        })}
      />
    </StyledWrapper>
  )
}

export default ProjectCard
