import { createActions } from 'redux-actions'

import { createSyncAsyncActions } from '../../utils/reduxUtils'

export const {
  getPromoWidgetSucceeded,
  getProjectDetailSucceeded,
  getHighlightedProjectsSucceeded,
  getRandomProjectsSucceeded,
  getProjectsSucceeded,
  getHomePageRequest,
  getHomePageSucceeded,
  debounceInstantSearchRequest,
  instantSearchRequest,
  instantSearchSucceeded,
  getProjectDetailPageRequest,
  getProjectDetailPageSucceeded,
  getOrgPageRequest,
  getOrgPageSucceeded,
  getOrganizationSucceeded,
  getDonationStatsRequest,
  getDonationStatsSucceeded,
  getPaymentFailPageRequest,
  getProjectFundraisingEventRankingSucceeded,
  setIsSearching
} = createActions({
  GET_PROMO_WIDGET_SUCCEEDED: (key, totalDonation, normalized) => ({
    key,
    totalDonation,
    ...normalized
  }),
  GET_PROJECT_DETAIL_SUCCEEDED: normalized => normalized,
  GET_HIGHLIGHTED_PROJECTS_SUCCEEDED: normalized => normalized,
  GET_RANDOM_PROJECTS_SUCCEEDED: normalized => normalized,
  GET_PROJECTS_SUCCEEDED: (page, limit, total, normalized) => ({
    page,
    limit,
    total,
    ...normalized
  }),
  GET_HOME_PAGE_REQUEST: undefined,
  GET_HOME_PAGE_SUCCEEDED: undefined,
  DEBOUNCE_INSTANT_SEARCH_REQUEST: (
    page,
    limit,
    sortBy,
    keyword,
    categories,
    organizationId,
    ignoreFeatured = false,
    typeFilter
  ) => ({
    page,
    limit,
    sortBy,
    keyword,
    categories,
    organizationId,
    ignoreFeatured,
    typeFilter
  }),
  INSTANT_SEARCH_REQUEST: (
    page,
    limit,
    sortBy,
    keyword,
    categories,
    organizationId,
    ignoreFeatured = false,
    typeFilter
  ) => ({
    page,
    limit,
    sortBy,
    keyword,
    categories,
    organizationId,
    ignoreFeatured,
    typeFilter
  }),
  INSTANT_SEARCH_SUCCEEDED: (page, limit, total, normalized, categories) => ({
    page,
    limit,
    total,
    ...normalized,
    categories
  }),
  GET_PROJECT_DETAIL_PAGE_REQUEST: (projectNo, nextCtx, previewToken) => ({
    projectNo,
    nextCtx,
    previewToken
  }),
  GET_PROJECT_DETAIL_PAGE_SUCCEEDED: normalizedDonationItemsDonorCount => ({
    normalizedDonationItemsDonorCount
  }),
  GET_ORG_PAGE_REQUEST: (
    page,
    limit,
    sortBy,
    organizationNo,
    nextCtx,
    ignoreFeatured = false
  ) => ({
    page,
    limit,
    sortBy,
    organizationNo,
    nextCtx,
    ignoreFeatured
  }),
  GET_ORG_PAGE_SUCCEEDED: undefined,
  GET_ORGANIZATION_SUCCEEDED: normalized => normalized,
  GET_DONATION_STATS_REQUEST: projectNos => ({ projectNos }),
  GET_DONATION_STATS_SUCCEEDED: normalized => normalized,
  GET_PAYMENT_FAIL_PAGE_REQUEST: (projectNo, nextCtx) => ({
    projectNo,
    nextCtx
  }),
  GET_PROJECT_FUNDRAISING_EVENT_RANKING_SUCCEEDED: (projectNo, ranking) => ({
    projectNo,
    ranking
  }),
  SET_IS_SEARCHING: isSearching => ({ isSearching })
})

export const {
  getProjectFundraisingEventRankingRequestAsync
} = createSyncAsyncActions(
  'GET_PROJECT_FUNDRAISING_EVENT_RANKING_REQUEST',
  (projectNo, type, sortBy, sortDirection) => ({
    projectNo,
    type,
    sortBy,
    sortDirection
  })
)
