import 'react-dates/initialize'
import 'moment/locale/zh-hk'
import '../public/static/nprogress.css'

import Cookies from 'js-cookie'
import moment from 'moment-timezone'
import App from 'next/app'
import Head from 'next/head'
import Router, { withRouter } from 'next/router'
import withReduxSaga from 'next-redux-saga'
import withRedux from 'next-redux-wrapper'
import NProgress from 'nprogress'
import * as R from 'ramda'
import React from 'react'
import { connect, Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import RequestError from '../components/ErrorInfo/RequestError'
import GlobalStyle from '../components/Styled/Global'
import NormalizeStyle from '../components/Styled/Normalize'
import { PAGE } from '../constants'
import theme from '../constants/theme'
import MasterLayout from '../containers/MasterLayout'
import PageInitialize from '../containers/PageInitialize'
import { appWithTranslation } from '../i18n'
import { initializeStore } from '../redux/'
import {
  getFrontendSettingRequest,
  setIsAppview,
  setIsInitialRender,
  setShouldBackHome,
  setShowErrorPage
} from '../redux/app/actions'
import { getCurrentPageName, getFrontendSetting } from '../redux/app/selectors'
import { ErrorBoundary } from '../utils/bugsnagUtils'
import { detectMobile } from '../utils/commonUtils'

NProgress.configure({ showSpinner: false, easing: 'ease-in-out', speed: 500 })

moment.locale('zh-hk')

moment.tz.setDefault('Asia/Hong_Kong')

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let isMobile = null
    let isInitialRender = false
    let promoCode = null
    if (ctx.isServer) {
      ctx.store.dispatch(getFrontendSettingRequest())
      isMobile = ctx.req.isMobile
      promoCode = ctx.req.query.promoCode
      isInitialRender = true
    } else {
      ;({ isMobile } = detectMobile(navigator.userAgent))
    }
    ctx.store.dispatch(setShowErrorPage(false, null))
    ctx.store.dispatch(setIsAppview(isMobile))
    ctx.store.dispatch(setIsInitialRender(isInitialRender))
    let pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {}

    pageProps = { ...pageProps, isInitialRender }

    return {
      pageProps,
      promoCode,
      isAppview: isMobile
    }
  }

  state = {
    showNotification: false
  }

  shouldBackHome = () =>
    R.pathOr(false, ['history', 'state', 'options', 'shouldBackHome'], window)

  handleRouteChangeStart = () => {
    try {
      NProgress.start()
    } catch (e) {
      // do nothing
    }
  }

  handleRouteChangeComplete = () => {
    try {
      NProgress.done()

      this.props.setShouldBackHome(this.shouldBackHome())
    } catch (e) {
      // do nothing
    }
  }

  handleRouteChangeError = () => {
    try {
      NProgress.done()
    } catch (e) {
      // do nothing
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentPageName === PAGE.THANKYOU ||
      prevProps.currentPageName === PAGE.AUTHORIZE ||
      prevProps.currentPageName === PAGE.FUNDRAISING_PUBLISH_SUCCESS ||
      prevProps.currentPageName === PAGE.FUNDRAISING_PREVIEW
    ) {
      window.history.replaceState(
        Object.assign(window.history.state, {
          options: { shouldBackHome: true }
        })
      )
    }
  }

  componentDidMount() {
    const {
      frontendSettingData: { noticeMessage },
      promoCode
    } = this.props
    // no msg => hide
    // have msg + can get cookie => hide
    // have msg + can't get cookie => show
    const hasNotification = !R.isEmpty(noticeMessage)
    const isCookieSet = !R.isNil(Cookies.get(process.env.NOTIFICATION_COOKIE))

    if (R.and(hasNotification, !isCookieSet)) {
      this.setState({ showNotification: true })
    }
    if (!R.isNil(promoCode)) {
      window.sessionStorage.setItem('promoCode', promoCode)
    }
    Router.events.on('routeChangeStart', this.handleRouteChangeStart)
    Router.events.on('routeChangeComplete', this.handleRouteChangeComplete)
    Router.events.on('routeChangeError', this.handleRouteChangeError)
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.handleRouteChangeStart)
    Router.events.off('routeChangeComplete', this.handleRouteChangeComplete)
    Router.events.off('routeChangeError', this.handleRouteChangeError)
  }

  handleNotificationCloseClick = () => {
    // set cookie which expires in 2 hours (7200 seconds)
    Cookies.set(process.env.NOTIFICATION_COOKIE, '', {
      expires: process.env.COOKIE_MAX_AGE / 3600 / 24,
      path: '/'
    })
    this.setState({ showNotification: false })
  }

  appRender = () => {
    const {
      Component,
      store,
      frontendSettingData: { noticeMessage },
      currentPageName,
      router: { pathname },
      isAppview
    } = this.props

    let { showErrorPage, errorCode } = this.props

    if (R.includes(pathname, process.env.CLIENT_SIDE_BLACKLIST)) {
      showErrorPage = true
      errorCode = 400
    }

    const pageProps = { ...this.props.pageProps, currentPageName }

    return (
      <Provider store={store}>
        <>
          <PageInitialize />
          <Head>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
            />
            <title>01心意</title>
          </Head>
          <ThemeProvider theme={{ ...theme, isAppview }}>
            <>
              <NormalizeStyle />
              <GlobalStyle />
              <MasterLayout
                notification={{
                  show: this.state.showNotification,
                  msg: noticeMessage,
                  onClose: this.handleNotificationCloseClick
                }}
                renderPersistentLayout={Component.renderPersistentLayout}
              >
                {showErrorPage ? (
                  <RequestError errorCode={errorCode} {...pageProps} />
                ) : (
                  <Component {...pageProps} />
                )}
              </MasterLayout>
            </>
          </ThemeProvider>
        </>
      </Provider>
    )
  }

  render() {
    const AppBoundary = R.equals(process.env.APP_ENV, 'production')
      ? ErrorBoundary
      : React.Fragment
    return <AppBoundary>{this.appRender()}</AppBoundary>
  }
}

const mapStateToProps = ({ appState }, props) => {
  return {
    frontendSettingData: getFrontendSetting({ appState }, props),
    currentPageName: getCurrentPageName(props),
    showErrorPage: appState.showErrorPage,
    errorCode: appState.errorCode
  }
}

export default R.compose(
  withRedux(initializeStore),
  withReduxSaga,
  withRouter,
  appWithTranslation,
  connect(mapStateToProps, { setShouldBackHome, setShowErrorPage })
)(MyApp)
