import { all, takeEvery } from 'redux-saga/effects'

import { uploadRequest, uploadRetry } from '../actions'
import { uploadFileWorker } from './fileSaga'

export function* fileWatcher() {
  try {
    yield all([takeEvery([uploadRequest, uploadRetry], uploadFileWorker)])
  } catch (err) {
    console.log(err)
  }
}
