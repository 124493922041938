import { schema } from 'normalizr'
import * as R from 'ramda'

const articleSchema = new schema.Entity(
  'articles',
  {},
  { processStrategy: R.prop('data') }
)

export const articleListSchema = new schema.Array(articleSchema)
