import styled, { css } from 'styled-components'
import { safeArea } from '../../../utils/cssUtils'

const AppviewNavContainer = styled.div`
  box-shadow: 0 -2px 23px 0 #e4e6e9;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  ${safeArea(
    inset => css`
      height: calc(${inset}+ 56px);
      padding-bottom: ${inset};
    `
  )}
`

export { AppviewNavContainer }
