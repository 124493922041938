import * as R from 'ramda'
import React from 'react'

import FundraisingProgress from '../../../components/FundraisingProgress'
import FundraisingProgressSkeleton from '../../../components/Skeleton/FundraisingProgress'

const CardProgress = ({
  projectStatus,
  projectCardStatusDescription,
  projectType,
  startTime,
  endTime,
  donationTarget,
  projectNo,
  donationCount,
  totalDonatedAmount
}) => {
  return R.isNil(totalDonatedAmount) ? (
    <FundraisingProgressSkeleton />
  ) : (
    <FundraisingProgress
      data-test-id='fundraising-progress'
      projectNo={projectNo}
      projectType={projectType}
      projectStatus={projectStatus}
      projectCardStatusDescription={projectCardStatusDescription}
      startTime={startTime}
      endTime={endTime}
      donationTarget={donationTarget}
      donationCount={donationCount}
      totalDonatedAmount={totalDonatedAmount}
    />
  )
}

export default CardProgress
