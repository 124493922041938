import React from 'react'
import styled from 'styled-components'

import SvgIcon from '../../components/SvgIcon'
import SortSvg from '../../public/static/images/icon-sorting.svg'
import { getColor } from '../../utils/cssUtils'
import InstantSearchRadio from './InstantSearchRadio'

const Sorter = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
`
const SorterIcon = styled(SvgIcon)`
  margin-right: 8px;
`
const SortItem = styled.div`
  line-height: 1;
  padding: 0 8px;
  border-right: 1px solid ${getColor('black')};
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    border: none;
    padding-right: 0;
  }
`

const InstantSearchSorter = ({ options, name }) => {
  const renderOption = (radio, index) => (
    <SortItem key={index}>{radio}</SortItem>
  )

  return (
    <Sorter>
      <SorterIcon svg={<SortSvg />} />
      <InstantSearchRadio
        name={name}
        options={options}
        renderOption={renderOption}
      />
    </Sorter>
  )
}

export default InstantSearchSorter
