import { useRouter } from 'next/router'
import * as R from 'ramda'
import React, { useCallback } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import URI from 'urijs'

import AppviewHeader from '../../components/Appview/AppviewHeader'
import SvgIcon from '../../components/SvgIcon'
import { PAGE } from '../../constants'
import { withTranslation } from '../../i18n'
import Hk01CircleIcon from '../../public/static/images/hk01-logo-circle.svg'
import CopyLinkSvg from '../../public/static/images/icon-copylink.svg'
import BackBtnSvg from '../../public/static/images/icon-previous.svg'
import ShareSvg from '../../public/static/images/icon-share.svg'
import {
  dismissWebviewRequest,
  setFeedbackText,
  showShareDialog
} from '../../redux/app/actions'
import {
  getCurrentProjectName,
  getCurrentProjectNo
} from '../../redux/project/selectors'
import { trackShareClick } from '../../redux/tracking/actions'
import { routesConfig } from '../../routes'
import { getPageNameFromCurrentPath, isSsr } from '../../utils/commonUtils'
import { detailClickBack, profileClickBack } from '../../utils/dataTracker'

const BackBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    user-select: none;
    line-height: 1;
  }
`
const ShareBtn = styled.a`
  display: flex;
`

const AppHeader = withTranslation('common')(({ t }) => {
  const dispatch = useDispatch()

  const router = useRouter()

  const currentPageName = getPageNameFromCurrentPath(router.pathname)

  const isWebview = useSelector(R.path(['appState', 'isWebview']))

  const shouldBackHome = useSelector(R.path(['appState', 'shouldBackHome']))

  const isInitialRender = useSelector(R.path(['appState', 'isInitialRender']))

  const shareMsg = useSelector(R.path(['appState', 'shareMsg']))

  const currentProjectNo = useSelector(getCurrentProjectNo)

  const currentProjectName = useSelector(getCurrentProjectName)

  const isTabbedPage = useCallback(
    () =>
      R.pathOr(false, [currentPageName, 'appview', 'isTabbed'], routesConfig),
    [currentPageName]
  )

  const getUtm = () => R.pathOr({}, [currentPageName, 'utm'], routesConfig)

  const getShareLink = useCallback(() => {
    const uri = new URI(window.location.href)
    uri.addSearch(getUtm())
    return decodeURI(uri.toString())
  }, [])

  const handleBackBtnClick = useCallback(
    e => {
      e.preventDefault()

      if (currentPageName === PAGE.PROJECT) {
        detailClickBack(currentProjectNo, currentProjectName)
      } else if (currentPageName === PAGE.MY_INFO) {
        profileClickBack()
      }
      if (isInitialRender) {
        router.push('/')
      } else {
        if (shouldBackHome) {
          router.push('/')
        } else {
          router.back()
        }
      }
    },
    [
      currentProjectNo,
      currentProjectName,
      currentPageName,
      isInitialRender,
      shouldBackHome
    ]
  )

  const handleShare = useCallback(() => {
    if (isWebview) {
      dispatch(
        showShareDialog(shareMsg, encodeURI(getShareLink()), document.title)
      )
    }
  }, [isWebview, shareMsg, getShareLink])

  const handleHk01CircleIconClick = useCallback(
    e => {
      if (isWebview) {
        e.preventDefault()
        dispatch(dismissWebviewRequest())
      }
    },
    [isWebview]
  )

  const renderBackBtn = useCallback(() => {
    if (R.equals(currentPageName, PAGE.HOME)) {
      return (
        <a href={process.env.HK01_URL} onClick={handleHk01CircleIconClick}>
          <Hk01CircleIcon />
        </a>
      )
    } else if (
      !isTabbedPage() &&
      currentPageName !== PAGE.TIMEOUT &&
      currentPageName !== PAGE.FUNDRAISING_DRAFT_EDIT &&
      currentPageName !== PAGE.FUNDRAISING_CREATE
    ) {
      return (
        <BackBtn onClick={handleBackBtnClick}>
          <SvgIcon svg={<BackBtnSvg />} />
          {shouldBackHome && <span>{t('header.home')}</span>}
        </BackBtn>
      )
    } else {
      return null
    }
  }, [
    handleHk01CircleIconClick,
    isTabbedPage,
    currentPageName,
    handleBackBtnClick,
    shouldBackHome
  ])

  const handleOnCopy = useCallback(() => {
    dispatch(setFeedbackText(t('common.clipboardSuccess')))

    dispatch(trackShareClick(currentPageName))
  }, [currentPageName])

  const renderRightBtn = useCallback(() => {
    const showShare = R.pathOr(
      false,
      [currentPageName, 'appview', 'showShare'],
      routesConfig
    )

    if (showShare) {
      const Wrapper = isWebview
        ? React.Fragment
        : props => {
            return (
              <CopyToClipboard
                text={!isSsr && getShareLink()}
                onCopy={handleOnCopy}
              >
                {props.children}
              </CopyToClipboard>
            )
          }
      return (
        <Wrapper>
          <ShareBtn onClick={handleShare}>
            {isWebview ? (
              <SvgIcon svg={<ShareSvg />} />
            ) : (
              <SvgIcon svg={<CopyLinkSvg />} />
            )}
          </ShareBtn>
        </Wrapper>
      )
    }

    return null
  }, [isWebview, currentPageName, handleShare, handleOnCopy, getShareLink])

  const renderTitle = useCallback(() => {
    const lensPath = R.lensPath([currentPageName, 'title'])
    const headerTitle = R.view(lensPath, routesConfig)
    return headerTitle
  }, [currentPageName])

  return (
    <AppviewHeader
      renderHeaderLeft={renderBackBtn}
      renderHeaderCenter={renderTitle}
      renderHeaderRight={renderRightBtn}
    />
  )
})

export default AppHeader
