import * as R from 'ramda'
import React from 'react'
import { animated } from 'react-spring'
import styled from 'styled-components'

import SuccessSvg from '../../public/static/images/icon-check-circle.svg'
import WarningSvg from '../../public/static/images/icon-close-circle.svg'
import ErrorSvg from '../../public/static/images/icon-exclamation-circle.svg'
import { getColor, getFontSize } from '../../utils/cssUtils'
import SvgIcon from '../SvgIcon'

const Bar = styled(animated.div)`
  min-height: 46px;
  background-color: ${getColor('lightPink')};
  color: ${getColor('theme')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${getFontSize('body2')};
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  border-radius: 4px;
`
const Content = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
`
const Icon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`
const StyledSvgIcon = styled(SvgIcon)`
  display: flex;
  width: 1.2em;
  height: 1.2em;
`
const Text = styled.span``

const Feedback = React.memo(({ style, children, type, className, onClick }) => (
  <Bar
    className={className}
    style={style}
    onClick={onClick}
    data-test-id='feedback'
  >
    <Content>
      <Icon data-test-id='icon'>
        {R.cond([
          [
            R.equals('success'),
            R.always(
              <StyledSvgIcon svg={<SuccessSvg data-test-id='success-svg' />} />
            )
          ],
          [
            R.equals('warning'),
            R.always(
              <StyledSvgIcon svg={<WarningSvg data-test-id='warning-svg' />} />
            )
          ],
          [
            R.equals('error'),
            R.always(
              <StyledSvgIcon svg={<ErrorSvg data-test-id='error-svg' />} />
            )
          ],
          [(R.T, R.always(null))]
        ])(type)}
      </Icon>
      <Text>{children}</Text>
    </Content>
  </Bar>
))

export default Feedback
