import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock'
import { throttle } from 'lodash'
import { useRouter } from 'next/router'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { MEDIA_CONDITIONS } from '../constants'
import { fireTracking } from '../redux/tracking/actions'
import { getPageNameFromCurrentPath } from '../utils/commonUtils'

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

export const useDispatchPromise = () => {
  const dispatch = useDispatch()
  return action =>
    new Promise((resolve, reject) => {
      dispatch({ ...action, resolve, reject })
    })
}

export const usePrev = val => {
  const ref = useRef()
  useEffect(() => {
    ref.current = val
  })
  return ref.current
}

export const useMq = queryObj => {
  const [isClient, setIsClient] = useState(false)

  let isMatch = useMediaQuery(queryObj ?? { query: '(max-width: 0px)' })

  isMatch = queryObj ? isMatch : false

  const isMobile = useMediaQuery({ query: MEDIA_CONDITIONS.MOBILE })

  const isTablet = useMediaQuery({ query: MEDIA_CONDITIONS.TABLET })

  const isDesktop = useMediaQuery({ query: MEDIA_CONDITIONS.DESKTOP })

  const isTabletMobile = useMediaQuery({
    query: MEDIA_CONDITIONS.TABLET_MOBILE
  })

  const isDesktopTablet = useMediaQuery({
    query: MEDIA_CONDITIONS.DESKTOP_TABLET
  })

  useIsomorphicLayoutEffect(() => {
    setIsClient(typeof window !== 'undefined')
  }, [])

  return {
    isMobile: isClient ? isMobile : undefined,
    isTablet: isClient ? isTablet : undefined,
    isDesktop: isClient ? isDesktop : undefined,
    isTabletMobile: isClient ? isTabletMobile : undefined,
    isDesktopTablet: isClient ? isDesktopTablet : undefined,
    isMatch: isClient ? isMatch : undefined
  }
}

export const useTracking = () => {
  const dispatch = useDispatch()
  return (trackingParams, sendPageView = false) => {
    dispatch(fireTracking(trackingParams, sendPageView))
  }
}

export const useClientRect = () => {
  const [rect, setRect] = useState({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0
  })
  const node = useRef()
  const ref = useCallback(nodeRef => {
    if (nodeRef !== null) {
      node.current = nodeRef
    }
  }, [])

  const setClientRect = useCallback(node => {
    setRect(node.getBoundingClientRect())
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (node.current) {
      setClientRect(node.current)
    }
    const throttledSetRect = throttle(() => {
      setClientRect(node.current)
    }, 200)
    if (typeof ResizeObserver === 'function') {
      let resizeObserver = new window.ResizeObserver(throttledSetRect)
      resizeObserver.observe(node.current)

      return () => {
        if (!resizeObserver) {
          return
        }

        resizeObserver.disconnect()
        resizeObserver = null
      }
    } else {
      window.addEventListener('resize', throttledSetRect)

      return () => {
        window.removeEventListener('resize', throttledSetRect)
      }
    }
  }, [])

  return [rect, ref]
}

export const useBodyScrollLock = toggle => {
  const scrollNode = useRef()
  useEffect(() => {
    if (scrollNode.current) {
      if (toggle) {
        disableBodyScroll(scrollNode.current, {
          reserveScrollBarGap: true
        })
      } else {
        enableBodyScroll(scrollNode.current)
      }
      return () => {
        clearAllBodyScrollLocks()
      }
    }
  }, [toggle])
  return scrollNode
}

export const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useCallback(effect, deps)

  useEffect(() => {
    const timer = setTimeout(callback, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [callback, delay])
}

export const usePageName = () => {
  const { pathname } = useRouter()
  return getPageNameFromCurrentPath(pathname)
}
