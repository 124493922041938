import React from 'react'
import styled from 'styled-components'

import { getFontSize } from '../../../utils/cssUtils'

const StyledAppHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 23px 0 #e4e6e9;
`
const DefaultHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 12px;
`
const PageControlLeft = styled.div`
  flex-grow: 2;
  flex-basis: 0;
  display: flex;
  white-space: nowrap;
  > a {
    display: flex;
  }
`
const PageTitle = styled.div`
  display: flex;
  flex-grow: 4;
  align-items: center;
  white-space: nowrap;
  > svg {
    max-height: 32px;
  }
  justify-content: center;
  flex-basis: 0;
  font-size: ${getFontSize('title')};
  .ellipsisHeader {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 8em - 96px);
    text-align: right;
  }
  .headerResult {
    word-break: keep-all;
    flex-shrink: 0;
  }
`
const PageControlRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 2;
  flex-basis: 0;
  overflow: hidden;
  white-space: nowrap;
`

const AppviewHeader = React.memo(
  ({ renderHeaderLeft, renderHeaderCenter, renderHeaderRight }) => (
    <StyledAppHeader>
      <DefaultHeader>
        <PageControlLeft>
          {renderHeaderLeft && renderHeaderLeft()}
        </PageControlLeft>
        <PageTitle>{renderHeaderCenter && renderHeaderCenter()}</PageTitle>
        <PageControlRight>
          {renderHeaderRight && renderHeaderRight()}
        </PageControlRight>
      </DefaultHeader>
    </StyledAppHeader>
  )
)
export default AppviewHeader
