import Cookies from 'js-cookie'
import * as R from 'ramda'
import { v4 as uuidv4 } from 'uuid'

import { isSsr, sleep } from './commonUtils'

// ENUMS
// STATUS: CONNECTED, NOT_CONNECTED
// PLATFORM: WEBVIEW, WEB

class WebJsSdk {
  constructor() {
    const WebJsSdk =
      process.env.APP_ENV === 'test'
        ? require('nothing-mock').Nothing
        : require('@hk01-digital/web-js-sdk').default

    this.anonymousId = this.getAnonymousId()

    this.sessionId = this.getSessionId()

    const opts = {
      appId: process.env.SSO_APP_ID,
      appVersion: process.env.APP_VERSION,
      service: 'heart',
      anonymousId: this.anonymousId,
      sessionId: this.sessionId
    }

    const trackerOpts = {
      GA: {
        trackingId: process.env.SSO_GA
      },
      Piwik: {
        trackingUrl: process.env.SSO_PIWIK_ENDPOINT,
        siteId: process.env.SSO_PIWIK_ID,
        userId: this.anonymousId,
        isSPA: true
      },
      webviewBridgeEnabled: true
    }

    this.UnInitedSdk =
      typeof window === 'undefined' ? null : new WebJsSdk(opts, trackerOpts)

    this.Sdk = null

    this.accountId = undefined

    this.lastGetLoginStatusRequestTs = 0

    this.loginStatus = null
  }

  getAnonymousId = () => {
    let id = Cookies.get('hk01_annonymous_id')
    if (R.isNil(id)) {
      id = uuidv4()
      Cookies.set('hk01_annonymous_id', id, {
        path: '/',
        expires: 365,
        domain: process.env.COOKIES_DOMAIN
      })
    }
    return id
  }

  getOpts = () => {
    return {
      account_id: this.accountId,
      anonymous_id: this.anonymousId,
      session_id: this.sessionId,
      bucket_id: '0000'
    }
  }

  getSessionId = () => {
    let id = Cookies.get('hk01_session')
    if (!id) {
      id = uuidv4()
      Cookies.set('hk01_session', id, { path: '/', expires: 1800 / 360 / 24 })
    }
    return id
  }

  getSdk = async () => {
    if (R.isNil(this.Sdk)) {
      this.Sdk = this.UnInitedSdk

      await this.Sdk.init()

      return this.Sdk
    }
    return this.Sdk
  }

  get init() {
    return this.getSdk
  }

  get enum() {
    return {
      STATUS: this.UnInitedSdk.STATUS,
      PLATFORM: this.UnInitedSdk.PLATFORM
    }
  }

  setTrackerUserId = async userId => {
    const sdk = await this.getSdk()

    sdk.trackerClient.Piwik.setUserId(userId)
  }

  tracker = async () => {
    if (!isSsr) {
      const sdk = await this.getSdk()

      if (this.accountId === undefined) {
        const loginStatus = await this.getLoginStatus()

        this.accountId = R.pathOr(null, ['response', 'accountId'], loginStatus)
      }

      return sdk.trackerClient
    } else {
      return null
    }
  }

  getLoginStatus = async () => {
    const sdk = await this.getSdk()

    const msToHaveNewRequest = 5000

    const currentTs = new Date().getTime()

    // cache loginStatus for 5 seconds to reduce getLoginStatus call within short period of time
    if (currentTs - this.lastGetLoginStatusRequestTs > msToHaveNewRequest) {
      this.loginStatus = await sdk.auth.getLoginStatus(null)

      this.lastGetLoginStatusRequestTs = currentTs

      const {
        response: { accountId }
      } = this.loginStatus

      if (!R.isNil(accountId)) this.accountId = accountId

      return this.loginStatus
    } else {
      return this.loginStatus // return cached within 5 seconds
    }
  }

  getProfile = async () => {
    const sdk = await this.getSdk()

    const {
      response: { accessToken }
    } = await this.getLoginStatus()

    const profile = await sdk.auth.getProfile(accessToken)

    return profile
  }

  getTokens = async () => {
    const sdk = await this.getSdk()

    const {
      response: { accessToken }
    } = await this.getLoginStatus()

    return sdk.auth.getTokens(accessToken)
  }

  refresh = async () => {
    const sdk = await this.getSdk()

    const {
      response: { refreshToken }
    } = await this.getLoginStatus()

    return sdk.auth.refresh(refreshToken)
  }

  isLoggedIn = async () => {
    const sdk = await this.getSdk()

    const {
      status,
      response: { accountId }
    } = await this.getLoginStatus()

    const isLoggedIn = R.equals(status, sdk.STATUS.CONNECTED)

    if (isLoggedIn) await this.setTrackerUserId(accountId)

    return isLoggedIn
  }

  isBoundPhone = async () => {
    const boundPhoneConditions = R.whereEq({ method: 'phone', verified: true })

    const { logins } = await this.getProfile()

    const isBoundPhone = R.any(boundPhoneConditions)(logins)

    return isBoundPhone
  }

  isMergingAccount = async () => {
    const { logins } = await this.getProfile()

    return R.isEmpty(logins)
  }

  withTimeout = (duration, promise) => {
    const timeout = new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        reject(new Error('Timed out in ' + duration + 'ms.'))
      }, duration)
    })

    return Promise.race([promise(), timeout])
  }

  retry = (retryCount, delay, timeout, promise) => {
    if (R.equals(retryCount, 1)) {
      return R.isNil(timeout) ? this.withTimeout(timeout, promise) : promise()
    } else {
      const timedoutPromise = timeout
        ? this.withTimeout(timeout, promise)
        : promise()

      if (delay) {
        return timedoutPromise.catch(error => {
          console.log(error)
          return sleep(delay).then(() => {
            return this.retry(
              R.subtract(retryCount, 1),
              delay,
              timeout,
              promise
            )
          })
        })
      } else {
        return timedoutPromise.catch(error => {
          console.log(error)
          return this.retry(R.subtract(retryCount, 1), delay, timeout, promise)
        })
      }
    }
  }

  login = async (redirectPath, webviewCallback, opts) => {
    const sdk = await this.getSdk()

    return sdk.auth.login(redirectPath, webviewCallback, opts)
  }

  logout = async redirectPath => {
    const sdk = await this.getSdk()

    sdk.auth.logout(redirectPath)
  }

  bindPhone = async (redirectPath, webviewCallback, opts) => {
    const sdk = await this.getSdk()

    return sdk.auth.bindPhone(redirectPath, webviewCallback, opts)
  }

  showShareDialog = async (...args) => {
    const sdk = await this.getSdk()

    return sdk.app.showShareDialog(...args)
  }

  isWebview = () => {
    return R.equals(
      this.UnInitedSdk.getPlatform(),
      this.UnInitedSdk.PLATFORM.WEBVIEW
    )
  }

  dismissWebview = async () => {
    const sdk = await this.getSdk()

    return sdk.app.dismissWebview()
  }

  hideHeader = async () => {
    const sdk = await this.getSdk()

    return sdk.app.hideHeader()
  }

  goTo01Page = async link => {
    const sdk = await this.getSdk()

    return sdk.app.goTo(link)
  }

  goTo01Article = async articleId => {
    const sdk = await this.getSdk()

    return sdk.app.navigateToArticle(articleId)
  }

  openNativeInAppBrowser = async (url, callbackFn, opts) => {
    const sdk = await this.getSdk()

    return sdk.app.openNativeInAppBrowser(url, callbackFn, opts)
  }

  fireGtmEvent = async (event, params) => {
    const sdk = await this.getSdk()

    return sdk.app.fireGtmEvent(event, params)
  }
}

export default new WebJsSdk()
