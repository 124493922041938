import React from 'react'
import styled from 'styled-components'

const Flex = styled.div`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex-direction: ${({ col }) => (col ? 'column' : 'row')};
  flex-wrap: ${({ shouldWrap }) => (shouldWrap ? 'wrap' : 'nowrap')};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'normal'};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`

export default ({
  inline,
  col,
  wrap,
  justify,
  align,
  fullWidth,
  className,
  children
}) => (
  <Flex
    fullWidth={fullWidth}
    inline={inline}
    col={col}
    shouldWrap={wrap}
    justify={justify}
    align={align}
    className={className}
  >
    {children}
  </Flex>
)
