import styled from 'styled-components'

import { getColor } from '../../../utils/cssUtils'
import mq from '../../../utils/mediaQuery'

const SignedCorp = styled.div`
  width: 112px;
  height: 112px;
  ${mq.mobile} {
    width: 72px;
    height: 72px;
  }
  background-color: ${getColor('skeleton')};
`

export default SignedCorp
