import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import mq from '../../utils/mediaQuery'
import { loadingAnimation } from './BackgroundEffect'

const StyledAlert = styled.div`
  height: 42px;
  width: 100%;
  border-radius: 4px;
  ${mq.mobile} {
    height: 86px;
  }
  ${loadingAnimation}
`
const Content = styled.div`
  ${mq.desktop} {
    max-width: 588px;
    margin: 0 auto;
  }
`
const BlockInfo = styled.div`
  margin-bottom: 24px;
`
const BlockPicker = styled.div`
  display: flex;
  margin-bottom: 16px;
`
const BlockInput = styled.div`
  margin-bottom: 68px;
  ${mq.mobile} {
    margin-bottom: 72px;
  }
`
const BlockTable = styled.div`
  margin-bottom: 40px;
`
const Title = styled.div`
  height: 28px;
  width: 100px;
  border-radius: 4px;
  margin-bottom: 8px;
  ${loadingAnimation}
`
const Description = styled.div`
  height: 46px;
  width: 100%;
  border-radius: 4px;
  ${loadingAnimation}
`
const AmtPicker = styled.div`
  width: 120px;
  height: 52px;
  border-radius: 6px;
  margin-bottom: 8px;
  margin-right: 24px;
  ${mq.mobile} {
    width: 100%;
    max-width: 104px;
    margin-right: 16px;
    flex-glow: 1;
  }
  &:last-of-type {
    margin-right: 0;
  }
  ${loadingAnimation}
`
const Input = styled.div`
  height: 36px;
  width: 100%;
  border-radius: 4px;
  ${loadingAnimation}
`
const SummaryTable = styled.div`
  height: 189px;
  width: 100%;
  border-radius: 4px;
  ${loadingAnimation}
`
const ExtraDonation = () => {
  const renderAmtPicker = amt => <AmtPicker key={amt} />
  return (
    <Content>
      <BlockInfo>
        <StyledAlert />
      </BlockInfo>
      <BlockInfo>
        <Title />
        <Description />
      </BlockInfo>
      <div>
        <Title />
        <BlockPicker>{R.times(renderAmtPicker, 3)}</BlockPicker>
        <BlockInput>
          <Input />
        </BlockInput>
        <BlockTable>
          <SummaryTable />
        </BlockTable>
      </div>
    </Content>
  )
}

export default ExtraDonation
