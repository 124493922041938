import { all, takeLatest } from 'redux-saga/effects'

import {
  authorizeVisitRequest,
  bindPhoneRequest,
  checkBindPhoneRequest,
  checkLoginRequest,
  getMemberProfileDataRequest,
  loginRequest,
  logoutRequest,
  memberVisitRequest,
  refreshLoginStatusRequest,
  updateMemberProfileDataRequest
} from '../actions'
import {
  authorizeVisitWorker,
  bindPhoneWorker,
  checkBindPhoneWorker,
  checkLoginWorker,
  getMemberProfileWorker,
  loginWorker,
  logoutWorker,
  memberVisitWorker,
  refreshLoginStatusWorker,
  updateMemberProfileWorker
} from './authSaga'

export function* authWatcher() {
  try {
    yield all([
      takeLatest(loginRequest, loginWorker),
      takeLatest(logoutRequest, logoutWorker),
      takeLatest(checkLoginRequest, checkLoginWorker),
      takeLatest(checkBindPhoneRequest, checkBindPhoneWorker),
      takeLatest(bindPhoneRequest, bindPhoneWorker),
      takeLatest(refreshLoginStatusRequest, refreshLoginStatusWorker),
      takeLatest(getMemberProfileDataRequest, getMemberProfileWorker),
      takeLatest(updateMemberProfileDataRequest, updateMemberProfileWorker),
      takeLatest(memberVisitRequest, memberVisitWorker),
      takeLatest(authorizeVisitRequest, authorizeVisitWorker)
    ])
  } catch (err) {
    console.log(err)
  }
}
