import React from 'react'
import styled from 'styled-components'

import { getColor } from '../../utils/cssUtils'
import mediaQuery from '../../utils/mediaQuery'
import { Col, Row } from '../Layout/FlexGrid'
import { loadingAnimation } from './BackgroundEffect'

const FlexGridItem = styled.div`
  display: flex;
`
const ProjectMainImgContainer = styled(Col)`
  ${mediaQuery.tabletMobile} {
    margin-bottom: 16px;
  }
`
const ProjectMainImg = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-color: ${getColor('skeleton')};
  border-radius: 6px;
`
const ProjectInfo = styled(FlexGridItem)`
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
`
const Organization = styled.div`
  height: 80px;
  margin-bottom: 22px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  ${mediaQuery.tablet} {
    height: 56px;
  }
  ${mediaQuery.mobile} {
    height: 64px;
    margin-bottom: 7px;
  }
`
const OrgInfo = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  ${mediaQuery.mobile} {
    margin-left: 16px;
  }
`
const OrgName = styled.div`
  height: 1.44em;
  overflow: hidden;
  width: 100%;
`
const StyledText = styled.div`
  width: 90px;
  height: 16px;
  ${loadingAnimation}
`
const ProjectName = styled.div`
  width: 100%;
  height: 22px;
  ${loadingAnimation}
`
const ProjectCount = styled.div`
  margin-top: 4px;
  width: 60px;
  height: 16px;
  ${mediaQuery.mobile} {
    margin-top: 0px;
  }
  ${loadingAnimation}
`
const FundraisingProgress = styled.div`
  margin-bottom: 16px;
`
const ProgressTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 16px;
`
const ProgressText = styled.div`
  width: ${({ long }) => (long ? '256px' : '128px')};
  height: 22px;
  ${loadingAnimation}
`
const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  display: block;
  ${loadingAnimation}
`
const ProjectTitleWrapper = styled.div`
  margin-bottom: 16px;
`
const StyledAvatar = styled.div`
  width: 80px;
  height: 80px;
  background-color: ${getColor('skeleton')};
  border-radius: 100%;
  ${mediaQuery.tablet} {
    width: 56px;
    height: 56px;
  }
  ${mediaQuery.mobile} {
    width: 64px;
    height: 64px;
  }
`
const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const Status = styled.div`
  width: ${({ long }) => (long ? '144px' : '104px')};
  height: 12px;
  ${loadingAnimation};
`

const FeaturedProject = () => (
  <Row gutter={{ xl: 40, md: 32 }}>
    <ProjectMainImgContainer xl={12} md={24}>
      <ProjectMainImg />
    </ProjectMainImgContainer>
    <Col xl={12} md={24}>
      <ProjectInfo>
        <ProjectTitleWrapper>
          <ProjectName />
        </ProjectTitleWrapper>
        <Organization>
          <StyledAvatar />
          <OrgInfo>
            <OrgName>
              <StyledText />
            </OrgName>
            <ProjectCount />
          </OrgInfo>
        </Organization>
        <FundraisingProgress>
          <ProgressTextWrapper>
            <ProgressText long />
            <ProgressText />
          </ProgressTextWrapper>
          <ProgressBar />
        </FundraisingProgress>
        <StatusWrapper>
          <Status long />
          <Status />
        </StatusWrapper>
      </ProjectInfo>
    </Col>
  </Row>
)

export default FeaturedProject
