import * as R from 'ramda'

const patchParagraphTag = input => {
  const regex = /<p[^>]*>[\s|&nbsp;]*<\/p>/g
  const newvalue = '<p>&nbsp;</p>'
  const output = input.replace(regex, newvalue)
  return output
}

const patchParagraphBrTag = input => {
  const regex = /<p[^>]*>[\s|<br/>]*<\/p>/g
  const newvalue = '<p>&nbsp;</p><p>&nbsp;</p>'
  const output = input.replace(regex, newvalue)
  return output
}

export const patchHTML = input => {
  const output = !(R.isNil(input) || R.isEmpty(input))
    ? R.compose(patchParagraphBrTag, patchParagraphTag)(input)
    : ''
  return output
}

export const newLineToArray = input =>
  R.pipe(R.split('\n'), R.reject(R.isEmpty))(input)
