import * as R from 'ramda'
import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { useTranslation } from '../../../i18n'
import { getColor } from '../../../utils/cssUtils'
import mq from '../../../utils/mediaQuery'
import { Col, Row } from '../../Layout/FlexGrid'
import SectionTitle from '../../Text/SectionTitle'
import ArticleCard from './ArticleCard'

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0 16px 0;
  border-top: 1px solid ${getColor('border')};
`
const Column = styled(Col)`
  ${mq.tablet} {
    ${({ index }) =>
      index > 1 &&
      css`
        display: none;
      `}
  }
  ${mq.mobile} {
    ${({ index }) =>
      index > 0 &&
      css`
        display: none;
      `}
  }
`
const RelatedArticles = ({
  articleIds,
  current,
  pageSize,
  setCurrentPage,
  pagination
}) => {
  const { t } = useTranslation('common')

  const { color } = useContext(ThemeContext)

  return (
    <>
      <TitleWrapper>
        <SectionTitle
          color={color.black}
          css={`
            margin-bottom: 0;
          `}
        >
          {t('corporate.relatedArticles')}
        </SectionTitle>
      </TitleWrapper>
      <Row gutter={[24, 24]}>
        {R.times(
          i => (
            <Column xs={24} md={12} xl={8} key={i} index={i}>
              <ArticleCard />
            </Column>
          ),
          6
        )}
      </Row>
    </>
  )
}

export default RelatedArticles
