import { useRouter } from 'next/router'
import * as R from 'ramda'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Footer from '../../components/Footer'
import Notification from '../../components/Notification'
import { AppviewAlert } from '../../containers/Appview'
import Feedback from '../../containers/Feedback'
import { getPageNameFromCurrentPath } from '../../utils/commonUtils'
import { safeArea } from '../../utils/cssUtils'
import AppHeader from '../Appview/AppHeader'
import Header from '../Header'

const Main = styled.main`
  min-height: ${({ footerIsNull, isAppview, theme }) =>
    isAppview || footerIsNull
      ? '100%'
      : `calc(100% - ${R.path(['layout', 'footerHeight'], theme)})`};
  display: flex;
  flex: auto;
  flex-direction: column;
  background-color: white;
`
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
  min-height: 0;
  flex: auto;
  margin-top: ${R.path(['theme', 'layout', 'headerHeight'])};
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.99),
    hsla(0, 0%, 100%, 0)
  );
  ${({ isAppview, isAppviewNavigationShown }) =>
    isAppview &&
    css`
      padding-top: 44px;
      margin-top: 0;
      padding-bottom: ${isAppviewNavigationShown ? '56px' : '0px'};
      ${safeArea(
        inset => css`
          padding-bottom: ${isAppviewNavigationShown
            ? `calc(${inset} + 56px)`
            : '0px'};
        `
      )}
    `}
  && {
    ${({ isFullscreen }) =>
      isFullscreen &&
      css`
        margin-top: 0;
      `}
  }
`

const StyledAppviewAlert = styled(AppviewAlert)`
  position: fixed;
  bottom: ${({ hasBottomNav }) => (hasBottomNav ? 56 : 0)}px;
  z-index: 12;
  ${({ hasBottomNav }) => css`
    ${safeArea(
      inset => css`
        bottom: ${hasBottomNav ? `calc(${inset} + 56px)` : 0};
      `
    )}
  `}
`

const StyledNotification = styled(Notification)`
  ${({ isAppview, isAppviewNavigationShown }) =>
    isAppview
      ? css`
          bottom: ${isAppviewNavigationShown ? '56px' : '0px'};
          border-top: solid 1px rgba(151, 151, 151, 0.2);
          ${safeArea(
            inset => css`
              bottom: ${isAppviewNavigationShown
                ? `calc(${inset} + 56px)`
                : '0px'};
              padding-bottom: ${isAppviewNavigationShown ? '0px' : inset};
            `
          )}
        `
      : css`
          top: 64px;
        `}
`

const MasterLayout = ({ children, notification, renderPersistentLayout }) => {
  const router = useRouter()

  const isAppview = useSelector(R.path(['appState', 'isAppview']))

  const pageName = getPageNameFromCurrentPath(router.pathname)

  const {
    header,
    footer,
    appviewHeader,
    appviewNavigation
  } = renderPersistentLayout
    ? renderPersistentLayout()
    : {
        header: undefined,
        footer: undefined,
        appviewHeader: undefined,
        appviewNavigation: undefined
      }

  const isFullscreen = useMemo(
    () => header && footer && header() === null && footer() === null,
    [header, footer]
  )

  const footerIsNull = footer && footer() === null

  const isAppviewNavigationShown = !R.isNil(appviewNavigation)

  const { onClose: handleOnClose } = notification

  const renderNotification = useCallback(
    () => (
      <StyledNotification
        isAppview={isAppview}
        isAppviewNavigationShown={isAppviewNavigationShown}
        message={notification.msg}
        onClose={handleOnClose}
      />
    ),
    [isAppview, isAppviewNavigationShown, notification.msg, handleOnClose]
  )

  const renderAppview = () => (
    <>
      {R.isNil(appviewHeader) ? <AppHeader /> : appviewHeader()}
      <Main isAppview={isAppview}>
        <Content
          isFullscreen={isFullscreen}
          isAppviewNavigationShown={isAppviewNavigationShown}
          isAppview={isAppview}
          currentPageName={pageName}
        >
          {children}
        </Content>
      </Main>
      <StyledAppviewAlert hasBottomNav={isAppviewNavigationShown} />
      {R.isNil(appviewNavigation) ? null : appviewNavigation()}
      {R.and(notification.show, !isFullscreen) && renderNotification()}
    </>
  )

  const renderDesktopView = () => (
    <>
      <Feedback />
      {R.and(notification.show, !isFullscreen) && renderNotification()}
      {R.isNil(header) ? <Header /> : header()}
      <Main footerIsNull={footerIsNull}>
        <Content isFullscreen={isFullscreen}>{children}</Content>
      </Main>
      {R.isNil(footer) ? <Footer /> : footer()}
    </>
  )

  return isAppview ? renderAppview() : renderDesktopView()
}

export default MasterLayout
