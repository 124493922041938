import * as R from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'

import { OrgAvatarInfo } from '../../components/CardAvatar'
import ProjectCardComponent from '../../components/ProjectCard'
import { PAGE, PROJECT_STATUS } from '../../constants'
import { usePageName, useTracking } from '../../hooks'
import { withTranslation } from '../../i18n'
import { orgSelector, projectSelector } from '../../redux/project/selectors'
import {
  detailClickProjectCard,
  detailReachProjectCard,
  discoverClickCard,
  homeClickProjectCard,
  homeReachProjectCard,
  organizationClickProjectCard,
  organizationReachProjectCard
} from '../../utils/dataTracker'
import { slugify } from '../../utils/urlUtils'
import CardProgress from './CardProgress'

const ProjectCardContainer = ({
  t,
  projectNo,
  listView,
  index,
  projectType,
  reachTracking,
  total,
  page,
  searchText,
  categoryNames,
  categoryNos,
  typeFilter,
  sort,
  ...props
}) => {
  const track = useTracking()

  const pageName = usePageName()

  const isAppview = useSelector(R.path(['appState', 'isAppview']))

  const projectData = useSelector(
    state => projectSelector(state, projectNo),
    R.equals
  )

  const organizationData = useSelector(
    state => orgSelector(state, projectData.organizationNo),
    R.equals
  )

  const { name, mainImg, supportsFundraisingEvent, projectStatus } = projectData

  const { avatar, name: orgName, organizationNo } = organizationData

  const shouldDisplayFundraisingFlag =
    projectStatus === PROJECT_STATUS.IN_PROGRESS && supportsFundraisingEvent

  const handleOrgAvatarClick = () => {
    const common = ['organization', organizationNo, projectNo, name, index]
    if (pageName === PAGE.HOME) {
      track(homeClickProjectCard(...common, projectType))
    } else if (pageName === PAGE.PROJECT) {
      track(detailClickProjectCard(...common, projectType))
    } else if (pageName === PAGE.DISCOVER) {
      track(
        discoverClickCard(
          ...common,
          total,
          page,
          searchText,
          categoryNames,
          categoryNos,
          typeFilter,
          sort
        )
      )
    }
  }

  const handleCardClick = () => {
    const common = ['project', projectNo, projectNo, name, index]

    if (pageName === PAGE.HOME) {
      track(homeClickProjectCard(...common, projectType))
    } else if (pageName === PAGE.PROJECT) {
      track(detailClickProjectCard(...common, projectType))
    } else if (pageName === PAGE.DISCOVER) {
      track(
        discoverClickCard(
          ...common,
          total,
          page,
          searchText,
          categoryNames,
          categoryNos,
          typeFilter,
          sort
        )
      )
    } else if (pageName === PAGE.ORGANIZATION) {
      track(organizationClickProjectCard(...common, projectType, page))
    }
  }

  const handleCardReach = () => {
    const common = [projectNo, name, index, projectType]

    if (pageName === PAGE.HOME && projectType !== 'promotion_listing') {
      // reach tracking of project in promotion listing is handled in card slider
      track(homeReachProjectCard(...common))
    } else if (pageName === PAGE.PROJECT) {
      track(detailReachProjectCard(...common))
    } else if (pageName === PAGE.ORGANIZATION) {
      track(organizationReachProjectCard(...common, page))
    }
  }

  return (
    <ProjectCardComponent
      flagText={
        shouldDisplayFundraisingFlag
          ? t('project.supportsFundraisingEvent')
          : null
      }
      avatar={
        <OrgAvatarInfo
          listView={listView}
          avatar={avatar}
          orgName={orgName}
          orgNo={organizationNo}
          onLinkClick={handleOrgAvatarClick}
        />
      }
      fundraisingProgress={
        <CardProgress {...projectData} projectNo={projectNo} />
      }
      mainImg={mainImg}
      name={name}
      listView={listView}
      href={`/project/${projectNo}/${slugify(name)}`}
      clickTracking={handleCardClick}
      reachTracking={handleCardReach}
      isAppview={isAppview}
      {...props}
    />
  )
}

export default withTranslation('common')(ProjectCardContainer)
