import Router from 'next/router'
import * as R from 'ramda'
import { all, call, put } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import { slugify } from '../../../utils/urlUtils'
import { setShowErrorPage } from '../../app/actions'
import { getProjectDetailPageSucceeded } from '../actions'
import {
  getHighlightedProjectsWorker,
  getProjectDetailWorker,
  requestDonationStats
} from './projectSaga'

export function* getProjectDetailPageWorker(action) {
  const { projectNo, nextCtx, previewToken } = action.payload
  try {
    let [
      normalizedSelectedProject,
      normalizedHighlightedProjects,
      donationItemsDonorCount
    ] = yield all([
      call(getProjectDetailWorker, projectNo, previewToken, true),
      call(getHighlightedProjectsWorker),
      call(charityApi.getDonationItemDonorCountByNo, projectNo)
    ])

    const customDonationItemId = R.pipe(
      R.values,
      R.find(R.propEq('type', 'custom')),
      R.prop('donationItemId')
    )(normalizedSelectedProject.entities.donationItems)

    const customDonationItemIndex = R.findIndex(
      R.propEq('donationItemId', undefined)
    )(donationItemsDonorCount)

    if (customDonationItemIndex > -1) {
      donationItemsDonorCount = R.adjust(
        customDonationItemIndex,
        R.assoc('donationItemId', customDonationItemId),
        donationItemsDonorCount
      )
    }

    const normalizedDonationItemsDonorCount = R.indexBy(
      R.prop('donationItemId'),
      donationItemsDonorCount
    )

    yield all([
      put(getProjectDetailPageSucceeded(normalizedDonationItemsDonorCount)),
      call(requestDonationStats, [projectNo]),
      call(requestDonationStats, normalizedHighlightedProjects.result)
    ])
  } catch (err) {
    if (parseInt(err.message) === 404) {
      try {
        const { organizationNo } = yield call(
          charityApi.getOrganizationByProjectNo,
          projectNo
        )

        const { displayName, nameChi } = yield call(
          charityApi.getOrganizationByNo,
          organizationNo
        )

        const href = `/org/${organizationNo}/${slugify(displayName ?? nameChi)}`

        if (nextCtx.req) {
          nextCtx.res.redirect(href)
        } else {
          yield call(Router.push, href)

          return
        }
      } catch (err) {
        nextCtx.res.status(404)
        yield put(setShowErrorPage(true, 404))
      }
    } else {
      nextCtx.res.status(404)
      yield put(setShowErrorPage(true, 404))
    }
  }
}
