import * as R from 'ramda'
import React from 'react'

import { Col, Row } from '../Layout/FlexGrid'
import ProjectCard from './ProjectCardSkeleton'

const ProjectListing = ({ count }) => (
  <Row
    type='flex'
    justify='start'
    gutter={[24, 24]}
    css={`
      margin-bottom: 24px;
    `}
  >
    {R.times(
      index => (
        <Col xs={24} md={24} xl={24} key={index}>
          <ProjectCard listView />
        </Col>
      ),
      count
    )}
  </Row>
)

export default ProjectListing
