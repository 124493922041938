import * as R from 'ramda'
import { createSelector } from 'reselect'

import { getPageNameFromCurrentPath } from '../../utils/commonUtils'

export const getSettingEntity = ({ appState }) => appState.frontendSetting

export const getFrontendSetting = createSelector(
  getSettingEntity,
  settingEntity => {
    const get = R.pathOr(R.__, R.__, settingEntity)
    return {
      noticeMessage: get('', ['noticeMessage']),
      promoCodes: get([], ['promoCodes']),
      homePageCategoryNos: get([], ['homePageCategoryNos'])
    }
  }
)

export const getCurrentPageName = createSelector(
  ({ router }) => router.pathname,
  getPageNameFromCurrentPath
)

export const getFeedbackStatus = createSelector(
  ({ appState }) => appState,
  appState => !R.isEmpty(appState.feedback)
)
