import { createActions } from 'redux-actions'

export const {
  initDonationRequest,
  proceedDonationRequest,
  getThankyouPageRequest,
  getThankyouPageSucceeded,
  sendOrSaveReceiptRequest,
  sendReceiptSucceeded,
  saveReceiptSucceeded,
  sendReceiptFailed,
  getMyDonationPageRequest,
  getMyDonationPageSucceeded,
  getDonationHistoryRequest,
  getDonationHistorySucceeded,
  getPartnerOrderHistoryRequest,
  getPartnerOrderHistorySucceeded,
  getDonationOrdersSucceeded,
  getPartnerOrdersSucceeded
} = createActions({
  INIT_DONATION_REQUEST: redirectPath => ({ redirectPath }),
  PROCEED_DONATION_REQUEST: orderParams => ({
    orderParams
  }),
  GET_THANKYOU_PAGE_REQUEST: orderNo => ({
    orderNo
  }),
  GET_THANKYOU_PAGE_SUCCEEDED: normalized => normalized,
  SEND_OR_SAVE_RECEIPT_REQUEST: ({
    orderNo,
    receiptName,
    receiptEmail,
    promoCode,
    resendReceipt,
    forceResend
  }) => ({
    orderNo,
    receiptName,
    receiptEmail,
    promoCode,
    resendReceipt,
    forceResend
  }),
  SEND_RECEIPT_SUCCEEDED: undefined,
  SAVE_RECEIPT_SUCCEEDED: undefined,
  SEND_RECEIPT_FAILED: undefined,
  GET_MY_DONATION_PAGE_REQUEST: (page, limit) => ({ page, limit }),
  GET_MY_DONATION_PAGE_SUCCEEDED: ordersStats => ({
    ordersStats
  }),
  GET_DONATION_HISTORY_REQUEST: (page, limit) => ({ page, limit }),
  GET_DONATION_HISTORY_SUCCEEDED: undefined,
  GET_PARTNER_ORDER_HISTORY_REQUEST: (page, limit) => ({ page, limit }),
  GET_PARTNER_ORDER_HISTORY_SUCCEEDED: undefined,
  GET_DONATION_ORDERS_SUCCEEDED: (page, limit, total, normalized) => ({
    page,
    limit,
    total,
    ...normalized
  }),
  GET_PARTNER_ORDERS_SUCCEEDED: (page, limit, total, normalized) => ({
    page,
    limit,
    total,
    ...normalized
  })
})
