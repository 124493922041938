import { all, call, put } from 'redux-saga/effects'

import { getHomePageSucceeded } from '../actions'
import {
  getHighlightedProjectsWorker,
  getPromoWidgetWorker,
  getRandomProjectsWorker,
  requestDonationStats
} from './projectSaga'

export function* getHomePageWorker(action) {
  try {
    const [
      highlightedProjects,
      randomProjects,
      promoWidgetProjects
    ] = yield all([
      call(getHighlightedProjectsWorker),
      call(getRandomProjectsWorker),
      call(getPromoWidgetWorker)
    ])

    yield all([
      put(getHomePageSucceeded()),
      call(requestDonationStats, highlightedProjects.result),
      call(requestDonationStats, randomProjects.result),
      call(requestDonationStats, promoWidgetProjects?.[0]?.result ?? []),
      call(requestDonationStats, promoWidgetProjects?.[1]?.result ?? [])
    ])
  } catch (err) {
    console.log(err)
  }
}
