import { schema } from 'normalizr'
import { projectSchema } from '../project/schema'

export const orderSchema = new schema.Entity(
  'orders',
  { project: projectSchema },
  { idAttribute: 'orderNo' }
)

export const ordersSchema = [orderSchema]
