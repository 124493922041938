import * as R from 'ramda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import styled, { css } from 'styled-components'

import Carousel from '../../components/Carousel'
import SvgIcon from '../../components/SvgIcon'
import IconNextSvg from '../../public/static/images/icon-next.svg'
import IconPrevSvg from '../../public/static/images/icon-previous.svg'
import { getColor } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'

const StyledCarousel = styled(Carousel)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-bottom: 12px;
`
const NextPrevBtn = styled.button`
  appearance: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 32px;
  height: 32px;
  border: 1px solid ${getColor('grey')};
  line-height: 1;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s;
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          border: 1px solid ${getColor('lightGrey')};
          color: ${getColor('lightGrey')};
        `
      : css`
          &:hover {
            color: ${getColor('pink')};
            border-color: ${getColor('pink')};
          }
        `}
`
const PrevBtn = styled(NextPrevBtn)`
  right: 90px;
  ${mq.mobile} {
    right: 40px;
  }
`
const NextBtn = styled(NextPrevBtn)`
  right: 0;
`
const Pagination = styled.div`
  position: absolute;
  top: 4px;
  right: 50px;
  ${mq.mobile} {
    display: none;
  }
`
const CardWrapper = styled.div`
  width: 100%;
`

const CardSlider = React.memo(
  ({ slides: defaultSlides, reachTracking, ...props }) => {
    const slider = useRef(null)

    const [current, setCurrent] = useState(1)

    const [total, setTotal] = useState(1)

    const [pagination, setPagination] = useState(null)

    const [shouldFireTracker, setShouldFireTracker] = useState({})

    const isWebview = useSelector(R.path(['appState', 'isWebview']))

    const waypointOnEnter = useCallback(
      index => () => {
        setShouldFireTracker(R.assoc(index, false))
        R.call(reachTracking(index))
      },
      [reachTracking]
    )

    const slides = useMemo(
      () =>
        defaultSlides.map((slide, index) => (
          <CardWrapper key={index}>
            {slide}
            {shouldFireTracker[index] && (
              <Waypoint onEnter={waypointOnEnter(index)} />
            )}
          </CardWrapper>
        )),
      [JSON.stringify(shouldFireTracker), waypointOnEnter]
    )

    const handlePageChange = (current, total) => {
      setPagination(`${current}/${total}`)
      setTotal(total)
      setCurrent(current)
    }

    const slideNext = useCallback(() => {
      slider.current.handleNext()
    }, [slider])

    const slidePrev = useCallback(() => {
      slider.current.handlePrev()
    }, [slider])

    const handleSlideActive = useCallback(slideIndexes => {
      R.forEach(index => {
        setShouldFireTracker(prevTracker =>
          R.hasIn(index, prevTracker)
            ? prevTracker
            : R.assoc(index, true, prevTracker)
        )
      }, slideIndexes)
    }, [])

    return (
      <>
        <StyledCarousel
          ref={slider}
          slides={slides}
          spaceBetween={24}
          breakpoints={{
            768: { slidesPerView: 2, spaceBetween: 24, slidesPerGroup: 2 },
            1200: { slidesPerView: 3, spaceBetween: 24, slidesPerGroup: 3 }
          }}
          onPageChange={handlePageChange}
          onSlideActive={handleSlideActive}
        />
        <PrevBtn
          disabled={current === 1}
          isWebview={isWebview}
          onClick={slidePrev}
        >
          <SvgIcon
            svg={
              <IconPrevSvg
                css={`
                  height: 14px;
                `}
              />
            }
          />
        </PrevBtn>
        <Pagination isWebview={isWebview}>{pagination}</Pagination>
        <NextBtn disabled={current === total} onClick={slideNext}>
          <SvgIcon
            svg={
              <IconNextSvg
                css={`
                  height: 14px;
                `}
              />
            }
          />
        </NextBtn>
      </>
    )
  }
)

export default CardSlider
