import { createActions } from 'redux-actions'

export const {
  firePageView,
  fireTracking,
  fireGtmEvent,
  trackShareClick,
  trackInstantSearch
} = createActions({
  FIRE_PAGE_VIEW: undefined,
  FIRE_TRACKING: (trackingParams, trackPageView) => ({
    trackingParams,
    trackPageView
  }),
  FIRE_GTM_EVENT: (event, params) => ({ event, params }),
  TRACK_SHARE_CLICK: (currentPageName, trackingEventLabel = null) => ({
    currentPageName,
    e_n: trackingEventLabel
  }),
  TRACK_INSTANT_SEARCH: undefined
})
