import { all, takeLatest } from 'redux-saga/effects'

import {
  // member
  createFundraisingInitRequestAsync,
  createFundraisingRequestAsync,
  deleteFundraisingRequestAsync,
  // public
  getFundraisingCreatePageRequest,
  getFundraisingStatisticsRequestAsync,
  getMemberFundraisingPageRequestAsync,
  getMemberFundraisingsRequestAsync,
  getPublicFundraisingPageRequest,
  getWidgetFundraisingsRequestAsync,
  patchFundraisingRequestAsync,
  publishFundraisingRequestAsync,
  putFundraisingRequestAsync
} from '../actions'
import {
  // member
  createFundraisingInitWorker,
  createFundraisingWorker,
  deleteFundraisingWorker,
  getFundraisingStatisticsWorker,
  getMemberFundraisingsWorker,
  getWidgetFundraisingsWorker,
  patchFundraisingWorker,
  publishFundraisingWorker,
  putFundraisingWorker
} from './fundraisingSaga'
import { getFundraisingCreatePageWorker } from './getFundraisingCreatePageWorker'
import { getMemberFundraisingPageWorker } from './getMemberFundraisingPageWorker'
import { getPublicFundraisingPageWorker } from './getPublicFundraisingPageWorker'

export function* fundraisingWatcher() {
  try {
    yield all([
      // member
      takeLatest(
        createFundraisingInitRequestAsync,
        createFundraisingInitWorker
      ),
      takeLatest(createFundraisingRequestAsync, createFundraisingWorker),
      takeLatest(patchFundraisingRequestAsync, patchFundraisingWorker),
      takeLatest(putFundraisingRequestAsync, putFundraisingWorker),
      takeLatest(deleteFundraisingRequestAsync, deleteFundraisingWorker),
      takeLatest(publishFundraisingRequestAsync, publishFundraisingWorker),
      takeLatest(
        getMemberFundraisingPageRequestAsync,
        getMemberFundraisingPageWorker
      ),
      takeLatest(
        getMemberFundraisingsRequestAsync,
        getMemberFundraisingsWorker
      ),
      // public
      takeLatest(
        getFundraisingCreatePageRequest.getTypes,
        getFundraisingCreatePageWorker
      ),
      takeLatest(
        getPublicFundraisingPageRequest.getTypes,
        getPublicFundraisingPageWorker
      ),
      takeLatest(
        getWidgetFundraisingsRequestAsync,
        getWidgetFundraisingsWorker
      ),
      takeLatest(
        getFundraisingStatisticsRequestAsync,
        getFundraisingStatisticsWorker
      )
    ])
  } catch (e) {
    console.log(e)
  }
}
