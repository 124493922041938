import shuffle from 'lodash/shuffle'
import * as R from 'ramda'
import { combineActions, handleActions } from 'redux-actions'

import {
  getDonationOrdersSucceeded,
  getPartnerOrdersSucceeded,
  getThankyouPageSucceeded
} from '../payment/actions'
import {
  getDonationStatsSucceeded,
  getHighlightedProjectsSucceeded,
  getHomePageRequest,
  getHomePageSucceeded,
  getOrganizationSucceeded,
  getOrgPageRequest,
  getOrgPageSucceeded,
  getProjectDetailPageRequest,
  getProjectDetailPageSucceeded,
  getProjectDetailSucceeded,
  getProjectFundraisingEventRankingSucceeded,
  getProjectsSucceeded,
  getPromoWidgetSucceeded,
  getRandomProjectsSucceeded,
  instantSearchSucceeded,
  setIsSearching
} from './actions'

export const projectInitialState = {
  fetching: {
    getHomepageData: false,
    instantSearch: false,
    getProjectDetailPage: false
  },
  page: 1,
  limit: 10,
  total: null,
  result: {
    highlightedProjects: [],
    randomProjects: [],
    projects: [],
    project: null,
    organization: null,
    categories: [],
    promoWidget1: [],
    promoWidget2: []
  },
  entities: {
    projects: {},
    organizations: {},
    donationItems: {}
  }
}

const projectReducer = handleActions(
  {
    [getPromoWidgetSucceeded]: (state, action) => {
      const { key, result, entities } = action.payload

      return {
        ...state,
        result: {
          ...state.result,
          [`promoWidget${key}`]: result
        },
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.projects ?? {}
          ),
          organizations: R.mergeDeepRight(
            state.entities.organizations,
            entities?.organizations ?? {}
          )
        }
      }
    },
    [getProjectDetailSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      const organizationNo = entities?.projects?.[result]?.organizationNo

      return {
        ...state,
        result: {
          ...state.result,
          project: result,
          organization: organizationNo
        },
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.projects ?? {}
          ),
          organizations: R.mergeDeepRight(
            state.entities.organizations,
            entities?.organizations ?? {}
          ),
          donationItems: R.mergeDeepRight(
            state.entities.donationItems,
            entities?.donationItems ?? {}
          )
        }
      }
    },
    [getHighlightedProjectsSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: {
          ...state.result,
          highlightedProjects: result
        },
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.projects ?? {}
          ),
          organizations: R.mergeDeepRight(
            state.entities.organizations,
            entities?.organizations ?? {}
          )
        }
      }
    },
    [getRandomProjectsSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: {
          ...state.result,
          randomProjects: result
        },
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.projects ?? {}
          ),
          organizations: R.mergeDeepRight(
            state.entities.organizations,
            entities?.organizations ?? {}
          )
        }
      }
    },
    [getProjectsSucceeded]: (state, action) => {
      const { page, limit, total, result, entities } = action.payload

      return {
        ...state,
        page,
        limit,
        total,
        result: {
          ...state.result,
          projects: result
        },
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.projects ?? {}
          ),
          organizations: R.mergeDeepRight(
            state.entities.organizations,
            entities?.organizations ?? {}
          )
        }
      }
    },
    [getHomePageRequest]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getHomepageData: true
      }
    }),
    [getHomePageSucceeded]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getHomepageData: false
      }
    }),
    [instantSearchSucceeded]: (state, action) => {
      const {
        page,
        limit,
        total,
        categories,
        result,
        entities
      } = action.payload

      return {
        ...state,
        result: {
          ...state.result,
          projects: result,
          categories
        },
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.projects ?? {}
          ),
          organizations: R.mergeDeepRight(
            state.entities.organizations,
            entities?.organizations ?? {}
          )
        },
        page,
        limit,
        total
      }
    },
    [getProjectDetailPageRequest]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getProjectDetailPage: true
      }
    }),
    [getProjectDetailPageSucceeded]: (state, action) => {
      const { normalizedDonationItemsDonorCount } = action.payload

      return {
        ...state,
        fetching: {
          ...state.fetching,
          getProjectDetailPage: false
        },
        result: {
          ...state.result,
          highlightedProjects: shuffle(state.result.highlightedProjects)
        },
        entities: {
          ...state.entities,
          donationItems: R.mergeDeepRight(
            state.entities.donationItems,
            normalizedDonationItemsDonorCount
          )
        }
      }
    },
    [getOrgPageRequest]: state => {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          getOrgPage: true
        }
      }
    },
    [getOrgPageSucceeded]: state => ({
      ...state,
      fetching: {
        ...state.fetching,
        getOrgPage: false
      }
    }),
    [getProjectsSucceeded]: (state, action) => {
      const { page, limit, total, result, entities } = action.payload

      return {
        ...state,
        page,
        limit,
        total,
        result: {
          ...state.result,
          projects: result
        },
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.projects ?? {}
          ),
          organizations: R.mergeDeepRight(
            state.entities.organizations,
            entities?.organizations ?? {}
          )
        }
      }
    },
    [getOrganizationSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: {
          ...state.result,
          organization: result
        },
        entities: {
          ...state.entities,
          organizations: R.mergeDeepRight(
            state.entities.organizations,
            entities?.organizations ?? {}
          )
        }
      }
    },
    [getDonationStatsSucceeded]: (state, action) => {
      const { entities } = action.payload

      return {
        ...state,
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.donationStats ?? {}
          )
        }
      }
    },
    [setIsSearching]: (state, action) => {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          instantSearch: action.payload.isSearching
        }
      }
    },
    [getProjectFundraisingEventRankingSucceeded]: (state, action) => {
      const { projectNo, ranking } = action.payload

      return {
        ...state,
        entities: {
          ...state.entities,
          projects: {
            ...state.entities.projects,
            [projectNo]: {
              ...R.pathOr({}, ['entities', 'projects', projectNo], state),
              ranking
            }
          }
        }
      }
    },
    [combineActions(
      getDonationOrdersSucceeded,
      getPartnerOrdersSucceeded,
      getThankyouPageSucceeded
    )]: (state, action) => {
      const { entities } = action.payload

      return {
        ...state,
        entities: {
          ...state.entities,
          projects: R.mergeDeepRight(
            state.entities.projects,
            entities?.projects ?? {}
          )
        }
      }
    }
  },
  projectInitialState
)

export default projectReducer
