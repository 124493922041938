import * as R from 'ramda'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { useTracking } from '../../hooks'
import { getPicProps, hk01LinkHandler } from '../../utils/commonUtils'
import { objectFit } from '../../utils/cssUtils'
import { clickPromoBanner } from '../../utils/dataTracker'
import mq from '../../utils/mediaQuery'
import { isExist } from '../../utils/ramdaUtils'
import AspectBox from '../AspectBox'

const SlideItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  overflow: hidden;
  ${mq.mobile} {
    border-radius: 0;
  }
`
const SlidePic = styled.picture`
  width: 100%;
  height: 100%;
`
const SlideImg = styled.img`
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${objectFit('cover')}
`
const Link = ({ promoLink, promoBannerNo, children, className }) => {
  const track = useTracking()

  const handleClick = useCallback(
    e => {
      hk01LinkHandler(e)
      track(clickPromoBanner(promoBannerNo))
    },
    [promoBannerNo]
  )

  return isExist(promoLink) ? (
    <a
      draggable={false}
      href={promoLink}
      onClick={handleClick}
      className={className}
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Slide = React.memo(({ slide }) => {
  const image = R.pathOr('', ['imageFile', 'url'], slide)

  const imageMobile = R.pathOr(image, ['imageMobileFile', 'url'], slide)

  const promoBannerNo = R.prop('promoItemNo', slide)

  return (
    <StyledLink
      promoLink={R.prop('promoLink', slide)}
      promoBannerNo={promoBannerNo}
    >
      <AspectBox>
        <SlideItem>
          <SlidePic>
            <source {...getPicProps(imageMobile, '100vw', 768)} />
            <SlideImg
              src={imageMobile}
              {...getPicProps(imageMobile, '100vw', null, 640)}
            />
          </SlidePic>
        </SlideItem>
      </AspectBox>
    </StyledLink>
  )
})

export default Slide
