import { handleActions } from 'redux-actions'

import {
  checkLoginSucceeded,
  getMemberProfileDataSucceeded,
  getHk01MemberProfileSucceeded,
  loginSucceeded,
  updateMemberProfileDataSucceeded
} from './actions'

export const authInitialState = {
  isLoggedIn: null,
  hk01MemberProfile: {},
  memberProfile: {}
}

const authReducer = handleActions(
  {
    [loginSucceeded]: (state, action) => ({
      ...state,
      isLoggedIn: action.payload.isLoggedIn
    }),
    [checkLoginSucceeded]: (state, action) => ({
      ...state,
      isLoggedIn: action.payload.isLoggedIn
    }),
    [getHk01MemberProfileSucceeded]: (state, action) => ({
      ...state,
      hk01MemberProfile: action.payload.hk01MemberProfile
    }),
    [getMemberProfileDataSucceeded]: (state, action) => ({
      ...state,
      memberProfile: action.payload.memberProfile
    }),
    [updateMemberProfileDataSucceeded]: (state, action) => ({
      ...state,
      memberProfile: action.payload.memberProfile
    })
  },
  authInitialState
)

export default authReducer
