import * as R from 'ramda'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { getColor, getFontSize } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'
import { loadingAnimation } from './BackgroundEffect'

const DonationWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 16px;
`
const StyledWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`
const Link = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 16px;
  ${mq.mobile} {
    margin: 0 16px 16px 16px;
  }
`
const StyledDonationRecord = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 6px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  font-size: ${getFontSize('headline')};
  ${mq.mobile} {
    font-size: ${getFontSize('body1')};
  }
`
const DonationItemLeftInfo = styled.div`
  display: flex;
  padding: 16px;
  width: calc(100% - 162px);
  ${mq.mobile} {
    width: calc(100% - 90px);
  }
`
const ProjectImgContainer = styled.div`
  width: 28%;
  padding-bottom: ${0.28 * 56.25}%;
  ${mq.tabletMobile} {
    width: ${0.5 * 56.25}%;
  }
  background-color: ${getColor('skeleton')};
`
const DonationRecords = styled.div`
  width: 100%;
`
const ProjectInfo = styled.div`
  width: 50%;
  padding-left: 12px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  ${mq.mobile} {
    width: 70%;
  }
`
const ProjectInfoTop = styled.div``
const DonationDate = styled.div`
  ${loadingAnimation}
`
const InfoTitle = styled.span`
  width: 80px;
  height: 14px;
  display: flex;
  margin-bottom: 8px;
  ${mq.mobile} {
    width: 56px;
  }
  ${loadingAnimation}
`
const InfoValue = styled.div`
  width: 100px;
  height: 20px;
  display: flex;
  ${mq.mobile} {
    width: 56px;
  }
  ${loadingAnimation}
`
const ProjectName = styled.div`
  width: 100%;
  height: 18px;
  margin-bottom: 5px;
  &:last-child {
    width: 50%;
  }
  ${loadingAnimation}
`
const OrgInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 4px;
`
const OrgName = styled.div`
  flex: 1;
  height: 18px;
  margin-left: 8px;
  ${loadingAnimation}
`
const StyledAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${getColor('skeleton')};
`
const Separator = styled.span`
  display: inline-block;
  width: 1px;
  height: 100%;
  border: dashed 1px rgba(216, 216, 216, 0.6);
  transform: scaleX(0.5);
`
const DonationAmount = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 160px;
  ${mq.mobile} {
    width: 88px;
  }
`

const MyDonationSkeleton = ({ count }) => {
  const { color } = useContext(ThemeContext)

  return (
    <StyledWrapper>
      <DonationWrapper>
        <DonationRecords>
          {R.times(
            index => (
              <Link key={index}>
                <StyledDonationRecord>
                  <DonationItemLeftInfo>
                    <ProjectImgContainer />
                    <ProjectInfo>
                      <ProjectInfoTop>
                        <OrgInfo>
                          <StyledAvatar borderColor={color.border} />
                          <OrgName />
                        </OrgInfo>
                        <ProjectName />
                      </ProjectInfoTop>
                      <DonationDate />
                    </ProjectInfo>
                  </DonationItemLeftInfo>
                  <Separator />
                  <DonationAmount>
                    <InfoTitle />
                    <InfoValue />
                  </DonationAmount>
                </StyledDonationRecord>
              </Link>
            ),
            count
          )}
        </DonationRecords>
      </DonationWrapper>
    </StyledWrapper>
  )
}

export default MyDonationSkeleton
