import { schema } from 'normalizr'

const donationItemSchema = new schema.Entity(
  'donationItems',
  {},
  {
    idAttribute: 'donationItemId'
  }
)

export const fundraisingSchema = new schema.Entity(
  'fundraisings',
  {
    donationItems: [donationItemSchema]
  },
  { idAttribute: 'fundraisingEventNo' }
)

export const fundraisingsSchema = new schema.Array(fundraisingSchema)

export const fundraisingStatsSchema = new schema.Entity(
  'fundraisingStats',
  {},
  { idAttribute: 'fundraisingEventNo' }
)

export const fundraisingStatsListSchema = new schema.Array(
  fundraisingStatsSchema
)
