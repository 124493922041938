import Link from 'next/link'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { animated, Transition, useTransition } from 'react-spring'
import styled, { css } from 'styled-components'

import Avatar from '../../components/Avatar'
import SvgIcon from '../../components/SvgIcon'
import { PAGE } from '../../constants'
import { useTranslation } from '../../i18n'
import BtnMenuSvg from '../../public/static/images/btn-menu.svg'
import Logo from '../../public/static/images/hk01-heart-logo.svg'
import Hk01Logo from '../../public/static/images/hk01-logo.svg'
import AccountSvg from '../../public/static/images/icon-account-outline.svg'
import SearchSvg from '../../public/static/images/icon-search.svg'
import { loginRequest } from '../../redux/auth/actions'
import { routesConfig } from '../../routes'
import { getPageNameFromCurrentPath } from '../../utils/commonUtils'
import { getColor, getFontSize } from '../../utils/cssUtils'
import { menuClick } from '../../utils/dataTracker'
import mq from '../../utils/mediaQuery'

const defaultProfileUrl = '/static/images/default-profile.svg'

const logoHeight = '32px'

const getHeaderHeight = R.path(['theme', 'layout', 'headerHeight'])

const Wrapper = styled(animated.div)`
  z-index: 1;
  position: fixed;
  width: 100%;
  padding: 0;
  top: 0;
`
const StyledHeader = styled.header`
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.1);
`
const ContentWrapper = styled.div`
  height: ${getHeaderHeight};
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
`
const MobileMenuContainer = styled(animated.div)`
  position: fixed;
  top: ${getHeaderHeight};
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.95;
  backdrop-filter: blur(23.2px);
  background-color: #ffffff;
  z-index: 11;
`
const MobileMenuWrapper = styled.ul`
  padding: 0;
  margin: 0;
  border: none;
  margin-top: 20%;
  text-align: center;
`
const MobileMenuItem = styled.li`
  height: 76px;
  display: flex;
  justify-content: center;
  user-select: none;
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 0 16px;
    &:hover {
      color: ${getColor('pink')};
    }
  }
`
const LinkText = styled.span`
  padding-bottom: 12px;
  ${({ selected }) =>
    selected &&
    css`
      color: ${getColor('theme')};
      border-bottom: 4px solid ${getColor('theme')};
    `}
`
const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  height: 100%;
`
const BtnWrapper = styled.div`
  height: 100%;
  display: inline-flex;
  justify-content: center;
  margin-right: 16px;
  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: 480px) {
    margin-right: 8px;
  }
`
const SearchBtnLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`
const HeaderBtn = styled.button`
  appearance: none;
  background-color: transparent;
  outline: none;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  font-size: ${getFontSize('body2')};
  transition: all 0.4s;
  padding: 0;
  &:hover {
    color: ${getColor('pink')};
  }
  @media (max-width: 400px) {
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    > span {
      font-size: ${getFontSize('caption')};
    }
  }
`
const LoginBtn = styled(HeaderBtn)`
  > span {
    white-space: nowrap;
  }
`
const StyleAvatar = styled(Avatar)`
  margin-right: 3px;
`
const MobileMenuBtn = styled(HeaderBtn)`
  ${mq.desktop} {
    display: none;
  }
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${getHeaderHeight};
  float: left;
  svg {
    height: ${logoHeight};
  }
  ${mq.desktop} {
    width: 224px;
  }
`
const HeartLogoLink = styled.a`
  display: flex;
  align-items: center;
  height: ${getHeaderHeight};
  float: left;
  svg {
    height: ${logoHeight};
  }
  ${mq.desktop} {
    width: 78px;
  }
`
const Hk01LogoLink = styled.a`
  width: 54px;
  height: 32px;
  display: flex;
  margin-right: 20px;
  ${mq.mobile} {
    margin-right: 16px;
  }
`
const LogoDivider = styled.div`
  display: flex;
  height: 50%;
  margin-right: 12px;
  float: left;
  border: solid 0.5px rgba(0, 0, 0, 0.2);
`
const DesktopMenu = styled.ul`
  ${mq.desktop} {
    display: flex;
    height: 100%;
    font-size: ${getFontSize('body2')};
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  display: none;
`
const DesktopMenuItem = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  > a {
    display: flex;
    align-items: center;
    padding: 0 24px;
    &:hover {
      color: ${getColor('pink')};
    }
  }
  ${({ selected }) =>
    selected &&
    css`
      > a {
        font-weight: bold;
        color: ${getColor('theme')};
      }
      &::after {
        content: '';
        width: 50px;
        height: 4px;
        position: absolute;
        border-radius: 2px;
        background-color: ${getColor('theme')};
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    `}
`
const HeaderContent = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 1200px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  ${mq.tablet} {
    padding: 0 64px;
  }
  ${mq.mobile} {
    padding: 0 16px;
  }
`

const menuList = [PAGE.HOME, PAGE.CORPORATE, PAGE.ARTICLE, PAGE.ABOUT]

const getRouteConfig = R.pathOr(R.__, R.__, routesConfig)

const HeaderLoginBtn = React.memo(({ isLoggedIn, avatarUrl, onLoginClick }) => {
  const { t } = useTranslation('common')
  return (
    <>
      {isLoggedIn ? (
        <Link
          href={getRouteConfig('', [PAGE.MY_ACCOUNT, 'link', 'href'])}
          as={getRouteConfig('', [PAGE.MY_ACCOUNT, 'link', 'as'])}
        >
          <LoginBtn>
            <StyleAvatar
              size={24}
              borderColor='#fff'
              src={R.isEmpty(avatarUrl) ? defaultProfileUrl : avatarUrl}
            />
            <span>{t('header.myAccount')}</span>
          </LoginBtn>
        </Link>
      ) : (
        <LoginBtn onClick={onLoginClick}>
          <SvgIcon svg={<AccountSvg />} />
          <span>{t('header.login')}</span>
        </LoginBtn>
      )}
    </>
  )
})

const HeaderSearchBtn = React.memo(({ onSearchClick }) => {
  const { t } = useTranslation('common')
  return (
    <HeaderBtn onClick={onSearchClick}>
      <SvgIcon svg={<SearchSvg />} />
      <span>{t('header.discover')}</span>
    </HeaderBtn>
  )
})

const MobileMenu = React.memo(
  ({ show, currentPageName, onMobileMenuClick }) => (
    <Transition
      items={show}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      expires={0}
    >
      {(styles, showMenu) =>
        showMenu && (
          <MobileMenuContainer
            style={styles}
            data-test-id='mobile-navigation-container'
          >
            <MobileMenuWrapper>
              {R.map(
                menuItem => (
                  <MobileMenuItem key={menuItem}>
                    <Link
                      href={getRouteConfig('', [menuItem, 'link', 'href'])}
                      as={getRouteConfig('', [menuItem, 'link', 'as'])}
                    >
                      <a onClick={onMobileMenuClick}>
                        <LinkText selected={menuItem === currentPageName}>
                          {getRouteConfig('', [menuItem, 'menuTitle'])}
                        </LinkText>
                      </a>
                    </Link>
                  </MobileMenuItem>
                ),
                menuList
              )}
            </MobileMenuWrapper>
          </MobileMenuContainer>
        )
      }
    </Transition>
  )
)

const Header = React.memo(({ className }) => {
  const router = useRouter()

  const dispatch = useDispatch()

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const [isLoggedIn, isHeaderHidden] = useSelector(
    R.paths([
      ['authState', 'isLoggedIn'],
      ['appState', 'isHeaderHidden']
    ])
  )

  const avatarUrl = useSelector(
    R.pathOr('', ['authState', 'hk01MemberProfile', 'avatar'])
  )

  const currentPageName = getPageNameFromCurrentPath(router.pathname)

  const toggleMenu = useCallback(() => {
    setShowMobileMenu(!showMobileMenu)
  }, [showMobileMenu])

  const handleDesktopLinkClick = e => {
    menuClick(e.currentTarget.getAttribute('href'), 'header_menu')
  }

  const handleMobileLinkClick = useCallback(
    e => {
      toggleMenu()
      menuClick(e.currentTarget.getAttribute('href'), 'ant_menu')
    },
    [toggleMenu]
  )

  const handleLoginBtnClick = useCallback(
    e => {
      dispatch(loginRequest())
    },
    [dispatch]
  )

  const headerTransition = useTransition(!isHeaderHidden, {
    initial: { transform: 'translateY(0%)' },
    from: { transform: 'translateY(-100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(-100%)' }
  })

  return (
    <>
      {headerTransition(
        (styles, showHeader) =>
          showHeader && (
            <Wrapper style={styles} className={className}>
              <StyledHeader>
                <ContentWrapper>
                  <HeaderContent>
                    <LogoWrapper>
                      <Hk01LogoLink href={process.env.HK01_URL}>
                        <Hk01Logo />
                      </Hk01LogoLink>
                      <LogoDivider />
                      <HeartLogoLink href={process.env.HEART_URL}>
                        <Logo />
                      </HeartLogoLink>
                    </LogoWrapper>
                    <DesktopMenu data-test-id='desktop-navigation-container'>
                      {R.map(
                        menuItem => (
                          <DesktopMenuItem
                            key={menuItem}
                            selected={menuItem === currentPageName}
                          >
                            <Link
                              href={getRouteConfig('', [
                                menuItem,
                                'link',
                                'href'
                              ])}
                              as={getRouteConfig('', [menuItem, 'link', 'as'])}
                            >
                              <a onClick={handleDesktopLinkClick}>
                                {getRouteConfig('', [menuItem, 'menuTitle'])}
                              </a>
                            </Link>
                          </DesktopMenuItem>
                        ),
                        menuList
                      )}
                    </DesktopMenu>
                    <HeaderButtons>
                      <BtnWrapper>
                        <Link href='/discover' as='/discover' passHref>
                          <SearchBtnLink>
                            <HeaderSearchBtn />
                          </SearchBtnLink>
                        </Link>
                      </BtnWrapper>
                      <BtnWrapper>
                        <HeaderLoginBtn
                          isLoggedIn={isLoggedIn}
                          avatarUrl={avatarUrl}
                          onLoginClick={handleLoginBtnClick}
                        />
                      </BtnWrapper>
                      <BtnWrapper>
                        <MobileMenuBtn
                          onClick={toggleMenu}
                          data-test-id='mobile-menu-button'
                        >
                          <BtnMenuSvg />
                        </MobileMenuBtn>
                      </BtnWrapper>
                    </HeaderButtons>
                  </HeaderContent>
                </ContentWrapper>
              </StyledHeader>
            </Wrapper>
          )
      )}
      <MobileMenu
        show={showMobileMenu}
        currentPageName={currentPageName}
        onMobileMenuClick={handleMobileLinkClick}
      />
    </>
  )
})

export default Header
