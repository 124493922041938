import React from 'react'
import styled from 'styled-components'

import { loadingAnimation } from './BackgroundEffect'

const MajorInfo = styled.div`
  display: flex;
  height: 32px;
  width: 100%;
  max-width: 240px;
  margin-bottom: 16px;
  ${loadingAnimation}
`
const MinorInfo = styled.div`
  display: flex;
  height: 16px;
  width: 100%;
  max-width: 204px;
  ${loadingAnimation}
`

const FundraisingProgress = () => {
  return (
    <>
      <MajorInfo />
      <MinorInfo />
    </>
  )
}

export default React.memo(FundraisingProgress)
