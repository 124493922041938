import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { getColor, getFontSize } from '../../utils/cssUtils'
import mediaQuery from '../../utils/mediaQuery'
import MaxWidth from '../Layout/MaxWidth'
import { loadingAnimation } from './BackgroundEffect'

const CategoryWrapper = styled.div`
  font-size: ${getFontSize('body2')};
  font-weight: normal;
  letter-spacing: 1px;
  color: ${getColor('grey')};
`

const CategoryInnerWrapper = styled(MaxWidth)`
  ${mediaQuery.mobile} {
    overflow: hidden;
    margin: 0;
  }
`

const CategoryList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 32px 0;
  ${mediaQuery.tablet} {
    padding: 24px 0;
  }
  ${mediaQuery.mobile} {
    overflow-x: scroll;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 16px;
  }
`

const CategoryItem = styled.li`
  text-align: center;
  display: flex;
  height: 104px;
  ${mediaQuery.tablet} {
    width: 25%;
  }
  ${mediaQuery.mobile} {
    margin-right: 20px;
    &:last-child {
      padding-right: 16px;
    }
  }
`

const CategoryLink = styled.a`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 auto;
`

const CategoryImgWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: ${getColor('skeleton')};
  border-radius: 100%;
  ${mediaQuery.tabletMobile} {
    width: 56px;
    height: 56px;
  }
`
const CategoryImg = styled.div`
  width: 100%;
  height: 100%;
`

const CategoryName = styled.div`
  width: 80px;
  height: 1em;
  margin-top: 8px;
  display: inline-block;
  white-space: nowrap;
  ${mediaQuery.tabletMobile} {
    width: 56px;
  }
  ${loadingAnimation}
`

const HomeCatIcons = () => (
  <CategoryWrapper>
    <CategoryInnerWrapper>
      <CategoryList>
        {R.times(
          index => (
            <CategoryItem key={index}>
              <CategoryLink>
                <CategoryImgWrapper>
                  <CategoryImg />
                </CategoryImgWrapper>
                <CategoryName />
              </CategoryLink>
            </CategoryItem>
          ),
          8
        )}
      </CategoryList>
    </CategoryInnerWrapper>
  </CategoryWrapper>
)

export default HomeCatIcons
