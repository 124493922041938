import camelCase from 'lodash/camelCase'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import React, { useEffect, useMemo, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form/dist/index.ie11'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import URI from 'urijs'

import {
  Field,
  Form,
  InstantSearchCheckbox,
  InstantSearchRadio,
  InstantSearchSorter,
  TextInput
} from '../../components/Form'
import { PROJECT_FILTER } from '../../constants'
import { useDebouncedEffect, usePrev } from '../../hooks'
import { useTranslation } from '../../i18n'
import SearchSvg from '../../public/static/images/icon-search.svg'
import {
  getCategory,
  getSearchCategories
} from '../../redux/category/selectors'
import {
  debounceInstantSearchRequest,
  instantSearchRequest
} from '../../redux/project/actions'
import { trackInstantSearch } from '../../redux/tracking/actions'
import { getColor, getFontSize } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'

const Wrapper = styled.div`
  margin-bottom: ${({ isTextSearchOnly }) =>
    isTextSearchOnly ? '0px' : '16px'};
`
const Keyword = styled.div`
  margin-bottom: ${({ isTextSearchOnly }) =>
    isTextSearchOnly ? '0px' : '24px'};
`
const OptionsPanel = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 24px;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 0;
  ${mq.mobile} {
    flex-wrap: wrap;
    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }
`
const Title = styled.div`
  flex-shrink: 0;
  color: ${getColor('black')};
  font-size: ${getFontSize('body2')};
  font-weight: bold;
  border-right: 1px solid ${getColor('black', 0.2)};
  height: 100%;
  padding-right: 16px;
  width: calc(4em + 19px);
  ${mq.mobile} {
    height: auto;
    width: 100%;
    padding-right: 0;
    border: none;
  }
`
const FilterTitle = styled(Title)`
  border: none;
  padding: 12px 16px 12px 0;
`
const FilterOptionsWrapper = styled.div`
  padding: 12px 16px 12px 0;
  background-color: ${getColor('lightGrey', 0.2)};
  border-radius: 4px;
  width: 100%;
  ${mq.mobile} {
    padding-left: 16px;
  }
`
const SearchOptions = styled.div`
  ${Row}:last-of-type & {
    margin-bottom: -24px;
  }
  ${mq.mobile} {
    margin-top: 8px;
    ${Row}:last-of-type & {
      margin-bottom: -8px;
    }
    ${FilterOptionsWrapper} & {
      margin-top: 0px;
    }
  }
`
const Option = styled.div`
  display: inline-flex;
  padding: 0 0 24px 16px;
  ${mq.mobile} {
    padding: 0 16px 8px 0;
  }
`
const Control = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Reset = styled.a`
  text-decoration: underline;
  font-size: ${getFontSize('body2')};
`

const CategorySearchOption = ({ name, categoryNo }) => {
  const { displayName } = useSelector(
    state => getCategory(state, categoryNo),
    R.equals
  )
  return (
    <InstantSearchCheckbox name={name} label={displayName} value={categoryNo} />
  )
}

const projectFilters = R.map(camelCase, [
  PROJECT_FILTER.SHORT_TERM,
  PROJECT_FILTER.SUPPORTS_FUNDRAISING_EVENT,
  PROJECT_FILTER.REGULAR,
  PROJECT_FILTER.ENDED
])

const limit = 12

const Watcher = () => {
  const isKeywordChanged = useRef(false)

  const dispatch = useDispatch()

  const router = useRouter()

  const {
    asPath,
    query: { page = 1 }
  } = router

  let { keyword, sort, typeFilter, categories } = useWatch({}) // not passing default value since will affect reset api

  if (R.isEmpty(sort)) {
    sort = undefined
  }

  const {
    formState: { dirtyFields }
  } = useFormContext()

  if (dirtyFields['keyword']) {
    isKeywordChanged.current = true
  }

  const prevCategories = usePrev(categories)

  const prevKeyword = usePrev(keyword)

  const joinedCategories =
    R.is(Array, categories) && !R.isEmpty(categories)
      ? R.join(',', categories)
      : undefined

  const query = URI.buildQuery({
    sort,
    q: R.isEmpty(keyword) ? undefined : keyword,
    categories: joinedCategories,
    typeFilter
  })

  const prevQuery = usePrev(query)

  const ignoreFeatured = false

  const orgId = undefined

  useEffect(() => {
    if (isKeywordChanged.current) {
      // so won't dispatch on initial load but on user change only
      dispatch(
        debounceInstantSearchRequest(
          // TODO: reorder the params
          1, // page
          limit,
          sort,
          keyword,
          categories,
          orgId,
          ignoreFeatured,
          R.when(R.equals('all'), R.always(undefined))(typeFilter)
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword])

  useEffect(() => {
    if (prevCategories && prevKeyword === keyword) {
      // cannot use isFormDirty here, because will affect reset functionality
      dispatch(
        instantSearchRequest(
          // TODO: reorder the params
          1, // page
          limit,
          sort,
          keyword,
          categories,
          orgId,
          ignoreFeatured,
          R.when(R.equals('all'), R.always(undefined))(typeFilter)
        )
      )
      dispatch(trackInstantSearch())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedCategories, sort, typeFilter])

  useDebouncedEffect(
    () => {
      if (prevQuery) {
        // on search param change except page number
        const path = `/discover?page=1&${query}`
        router.push(path, path, { shallow: true })
      } else {
        // on initial render
        const path = `/discover?page=${page}&${query}`
        router.replace(path, path, { shallow: true })
      }
    },
    500,
    [query]
  )

  useEffect(() => {
    if (asPath === '/discover') {
      // if user visit /discover without params
      const path =
        '/discover?' +
        URI.buildQuery({
          page: 1,
          sort: 'newest',
          typeFilter: 'all'
        })
      router.replace(path, path, { shallow: true })
    }
  }, [asPath])

  return null
}

export const InstantSearchWrapper = ({ children }) => {
  const { query } = useRouter()

  const { sort, q: keyword, categories, typeFilter } = query

  let defaultValues = {
    sort: sort || 'newest',
    keyword: keyword || '',
    typeFilter: typeFilter || 'all',
    categories: null
  }

  if (!R.isNil(categories)) {
    const categoriesArr = R.split(',', categories)
    defaultValues.categories = categoriesArr
  }

  const handleSubmit = data => {
    // do nothing
  }

  // using router params to construct defaultValues so that the corresponding field are populated
  const formOptions = { defaultValues } // default is cached on initial render by the library

  return (
    <Form onSubmit={handleSubmit} options={formOptions}>
      <Watcher />
      {children}
    </Form>
  )
}

export const InstantSearchComponent = ({
  textSearch,
  selectSearch,
  sorter,
  resetBtn,
  className
}) => {
  const dispatch = useDispatch()

  const { t } = useTranslation('common')

  const { reset } = useFormContext()

  const searchCategories = useSelector(getSearchCategories, R.equals)

  const handleReset = e => {
    e.preventDefault()
    reset({
      sort: sorter ? 'newest' : undefined, // since user can pick which component to include in the instant search, reset should be dynamic, otherwise will cause unwanted api request
      keyword: '',
      typeFilter: selectSearch ? 'all' : undefined,
      categories: selectSearch ? [] : undefined
    })
  }

  const sorterOptions = useMemo(
    () => [
      { id: 'newest', label: t('listing.sorting.newest') },
      { id: 'hottest', label: t('listing.sorting.hottest') }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const filterOpts = useMemo(
    () =>
      R.pipe(
        R.map(filter => ({ id: filter, label: t(`search.${filter}`) })),
        R.prepend({ id: 'all', label: t('search.all') })
      )(projectFilters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const renderFilterOpts = (radio, index) => (
    <Option key={index}>{radio}</Option>
  )

  const isTextSearchOnly = !selectSearch && !sorter

  const handleInputBlur = () => {
    dispatch(trackInstantSearch())
  }

  return (
    <Wrapper isTextSearchOnly={isTextSearchOnly} className={className}>
      {textSearch && (
        <Keyword isTextSearchOnly={isTextSearchOnly}>
          <Field name='keyword'>
            <TextInput
              prefix={<SearchSvg />}
              placeholder={t('header.searchPlaceholder')}
              onBlur={handleInputBlur}
            />
          </Field>
        </Keyword>
      )}
      {selectSearch && (
        <>
          <OptionsPanel>
            {searchCategories.map(({ categoryNo, displayName, children }) => (
              <Row key={categoryNo}>
                <Title>{displayName}</Title>
                <SearchOptions>
                  {children.map(subCategoryNo => (
                    <Option key={subCategoryNo}>
                      <CategorySearchOption
                        name='categories'
                        categoryNo={subCategoryNo}
                      />
                    </Option>
                  ))}
                </SearchOptions>
              </Row>
            ))}
          </OptionsPanel>
          <OptionsPanel>
            <Row>
              <FilterTitle>{t('search.projectType')}</FilterTitle>
              <FilterOptionsWrapper>
                <SearchOptions>
                  <InstantSearchRadio
                    name='typeFilter'
                    options={filterOpts}
                    renderOption={renderFilterOpts}
                  />
                </SearchOptions>
              </FilterOptionsWrapper>
            </Row>
          </OptionsPanel>
        </>
      )}
      {(sorter || resetBtn) && (
        <Control>
          {resetBtn && (
            <Reset href='#' onClick={handleReset}>
              {t('search.resetSelected')}
            </Reset>
          )}
          {sorter && (
            <InstantSearchSorter name='sort' options={sorterOptions} />
          )}
        </Control>
      )}
    </Wrapper>
  )
}
