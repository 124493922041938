import { createActions } from 'redux-actions'

export const {
  getArticleListByIdRequest,
  getArticleListByIdSucceeded,
  getArticleListByTagIdRequest,
  getArticleListByTagIdSucceeded,
  getArticleListByTagIdsRequest,
  getArticleListByTagIdsSucceeded
} = createActions({
  GET_ARTICLE_LIST_BY_ID_REQUEST: articleIds => ({ articleIds }),
  GET_ARTICLE_LIST_BY_ID_SUCCEEDED: normalized => normalized,
  GET_ARTICLE_LIST_BY_TAG_ID_REQUEST: (tagId, offset, limit) => ({
    tagId,
    offset,
    limit
  }),
  GET_ARTICLE_LIST_BY_TAG_ID_SUCCEEDED: (normalized, nextOffset) => ({
    ...normalized,
    nextOffset
  }),
  GET_ARTICLE_LIST_BY_TAG_IDS_REQUEST: (tagIds, offset, limit) => ({
    tagIds,
    offset,
    limit
  }),
  GET_ARTICLE_LIST_BY_TAG_IDS_SUCCEEDED: (normalized, nextOffset) => ({
    ...normalized,
    nextOffset
  })
})
