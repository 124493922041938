import React from 'react'
import styled, { css } from 'styled-components'

import { getColor } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'
import { loadingAnimation } from './BackgroundEffect'

const StyledWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px transparent;
  position: relative;
  ${({ listView }) =>
    listView &&
    css`
      height: 216px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      ${mq.mobile} {
        height: auto;
        display: block;
        justify-content: intial;
        align-items: intial;
      }
    `}
`
const ProjectMainImgContainer = styled.div`
  border-radius: ${({ listView }) =>
    listView ? '4px 0 0 4px' : '4px 4px 0 0'};
  margin: -1px;
  overflow: hidden;
  position: relative;
  border: ${({ listView }) => (listView ? 'initial' : 'none')};
  border-bottom: 1px solid ${getColor('border')};
  width: ${({ listView }) => (listView ? '384px' : 'auto')};
  ${mq.mobile} {
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid ${getColor('border')};
    margin: -1px -1px 0px -1px;
    width: auto;
  }
`
const ProjectMainImg = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: ${getColor('skeleton')};
`
const ProjectContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px;
  height: 196px;
  ${({ listView }) =>
    listView &&
    css`
      width: calc(100% - 384px);
      height: 100%;
      padding: 16px 24px 20px 24px;
      ${mq.mobile} {
        width: 100%;
        padding: 16px 24px;
      }
    `}
`
const OrgInfo = styled.div`
  height: 64px;
  width: 100%;
  margin: -48px 0 12px 0;
  z-index: 9;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${mq.tablet} {
    margin-bottom: 8px;
  }
  ${mq.mobile} {
    margin-bottom: 16px;
  }
  ${({ listView }) =>
    listView &&
    css`
      height: auto;
      margin: 0px;
      width: 100%;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    `}
`
const StyledAvatar = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border: 1px solid ${getColor('border')};
  background-color: ${getColor('skeleton')};
  ${({ type }) =>
    type === 'listing' &&
    css`
      width: 32px;
      height: 32px;
      ${mq.mobile} {
        width: 64px;
        height: 64px;
      }
    `}
`
const OrgName = styled.div`
  position: relative;
  flex: 1;
  padding-left: 8px;
  max-height: 1.43em;
  overflow: hidden;
  height: 1em;
  margin-left: 5px;
  margin-bottom: ${({ listView }) => (listView ? '0px' : '4px')};
  ${mq.mobile} {
    align-self: flex-end;
    margin-bottom: 4px;
  }
  ${loadingAnimation}
`
const ProjectInfo = styled.div`
  margin-top: 8px;
  height: 48px;
  margin-bottom: 12px;
`
const ProjectTitle = styled.div`
  overflow: hidden;
  height: 1em;
  &:nth-child(2) {
    margin-top: 8px;
    width: 50%;
  }
  ${loadingAnimation}
`
const Progress = styled.div`
  height: 32px;
  margin-top: 0;
  margin-bottom: 12px;
`
const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  ${loadingAnimation}
`
const Amount = styled.div`
  height: 1em;
  width: 200px;
  margin-top: 8px;
  ${loadingAnimation};
`
const Status = styled.div`
  height: 1em;
  width: 144px;
  margin-top: 8px;
  ${loadingAnimation}
`

const ProjectCard = ({ listView }) => (
  <StyledWrapper listView={listView}>
    <ProjectMainImgContainer listView={listView}>
      <ProjectMainImg />
    </ProjectMainImgContainer>
    <ProjectContentWrapper listView={listView}>
      <OrgInfo listView={listView}>
        <StyledAvatar type={listView && 'listing'} />
        <OrgName />
      </OrgInfo>
      <ProjectInfo>
        <ProjectTitle />
      </ProjectInfo>
      <Progress>
        <ProgressBar />
        <Amount />
      </Progress>
      <Status />
    </ProjectContentWrapper>
  </StyledWrapper>
)

export default ProjectCard
