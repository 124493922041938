import { all, debounce, fork, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  debounceInstantSearchRequest,
  getDonationStatsRequest,
  getHomePageRequest,
  getOrgPageRequest,
  getPaymentFailPageRequest,
  getProjectDetailPageRequest,
  getProjectFundraisingEventRankingRequestAsync
} from '../actions'
import { getHomePageWorker } from './getHomePageWorker'
import { getOrgPageWorker } from './getOrganizationPageWorker'
import { getPaymentFailPageWorker } from './getPaymentFailPageWorker'
import { getProjectDetailPageWorker } from './getProjectPageWorker'
import {
  debounceSearchRequestWorker,
  instantSearchWorker
} from './instantSearchWorker'
import {
  getDonationStatsWorker,
  getProjectFundraisingEventRankingWorker
} from './projectSaga'

export function* projectWatcher() {
  try {
    yield all([
      takeLatest(getHomePageRequest, getHomePageWorker),
      debounce(500, debounceInstantSearchRequest, debounceSearchRequestWorker),
      takeLatest(getProjectDetailPageRequest, getProjectDetailPageWorker),
      takeLatest(getOrgPageRequest, getOrgPageWorker),
      takeEvery(getDonationStatsRequest, getDonationStatsWorker),
      takeLatest(getPaymentFailPageRequest, getPaymentFailPageWorker),
      takeLatest(
        getProjectFundraisingEventRankingRequestAsync,
        getProjectFundraisingEventRankingWorker
      ),
      fork(instantSearchWorker)
    ])
  } catch (err) {
    console.log(err)
  }
}
