import * as R from 'ramda'
import { call, put } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import { getWidgetDonorsSucceeded } from '../actions'

export const getWidgetDonorsWorker = function* (action) {
  const { page, limit, sortBy, sortDirection, projectNo } = action.payload

  try {
    const isFrEvent = R.test(/^FRE-/, projectNo)

    const api = isFrEvent
      ? charityApi.getFundraisingWidgetDonors
      : charityApi.getProjectWidgetDonors

    const response = yield call(
      api,
      projectNo,
      page,
      limit,
      sortBy,
      sortDirection
    )

    yield put(
      getWidgetDonorsSucceeded(
        page,
        limit,
        R.prop('total', response),
        R.prop('result', response)
      )
    )
  } catch (e) {
    console.log(e)
  }
}
