import styled from 'styled-components'

import { getColor } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'

const Container = styled.div`
  width: calc(100% + 20vw);
  height: calc(0.1875 * 120vw + 48px);
  position: relative;
  left: -10vw;
  background-color: ${getColor('skeleton')};
  ${mq.tablet} {
    width: calc(100% + 80vw);
    height: calc(0.1875 * 180vw + 48px);
    left: -40vw;
  }
  ${mq.mobile} {
    height: calc(0.5625 * 100vw);
  }
`

export default Container
