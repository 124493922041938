import { all, takeLatest } from 'redux-saga/effects'

import {
  getArticleListByIdRequest,
  getArticleListByTagIdRequest,
  getArticleListByTagIdsRequest
} from '../actions'
import {
  getArticleListByIdWorker,
  getArticleListByTagIdWorker,
  getArticleListByTagIdsWorker
} from './articleSaga'

export function* articleWatcher() {
  try {
    yield all([
      takeLatest(getArticleListByIdRequest, getArticleListByIdWorker),
      takeLatest(getArticleListByTagIdRequest, getArticleListByTagIdWorker),
      takeLatest(getArticleListByTagIdsRequest, getArticleListByTagIdsWorker)
    ])
  } catch (err) {
    console.log(err)
  }
}
