import * as R from 'ramda'
import React, { useState } from 'react'
import LazyLoad from 'react-lazyload'
import styled, { css } from 'styled-components'

import { getPicProps } from '../utils/commonUtils'
import { getColor } from '../utils/cssUtils'

const Wrapper = styled.div`
  ${({ showPlaceholder }) =>
    showPlaceholder &&
    css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    `}
  .lazyload-wrapper {
    width: 100%;
    height: 100%;
  }
`
const Placeholder = styled.div`
  width: ${({ placeholderDimension }) =>
    placeholderDimension ? `${placeholderDimension[0]}px` : `100%`};
  height: ${({ placeholderDimension }) =>
    placeholderDimension ? `${placeholderDimension[1]}px` : `100%`};
  background-color: ${getColor('skeleton')};
  position: absolute;
  opacity: ${({ isLoaded }) => (isLoaded ? 0 : 1)};
  transition: opacity 0.3s ease 0.1s;
`
const StyledImg = styled.img`
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  transition: opacity 0.3s ease 0.1s;
`

const Picture = ({
  image,
  className,
  sizes,
  showPlaceholder,
  placeholderDimension,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const setImgLoaded = e => {
    setIsLoaded(true)
  }
  return R.test(/^data:image/, image) ? (
    <img className={className} src={image} {...props} />
  ) : (
    <Wrapper showPlaceholder={showPlaceholder}>
      {showPlaceholder && (
        <Placeholder
          isLoaded={isLoaded}
          placeholderDimension={placeholderDimension}
        />
      )}
      <LazyLoad once>
        <picture className={className}>
          <StyledImg
            src={image}
            {...getPicProps(image, sizes)}
            {...props}
            isLoaded={isLoaded}
            onLoad={setImgLoaded}
          />
        </picture>
      </LazyLoad>
    </Wrapper>
  )
}

export default Picture
