import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { getColor } from '../../utils/cssUtils'
import mediaQuery from '../../utils/mediaQuery'
import MaxWidth from '../Layout/MaxWidth'
import { loadingAnimation } from './BackgroundEffect'

const OrganizationAvatar = styled.div`
  height: 80px;
  width: 165px;
  background-color: ${getColor('skeleton')};
`

const OrganizationInfoWrapper = styled(MaxWidth)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 23px;
  ${mediaQuery.mobile} {
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

const InfoWrapper = styled.div`
  margin-left: 24px;
  width: 100%;
  ${mediaQuery.mobile} {
    margin-left: 0;
  }
`

const OrganizationCategoryTags = styled.div`
  height: 16px;
  width: 50%;
  margin-top: 10px;
  ${loadingAnimation}
`

const IntroSectionTitle = styled.div`
  width: 100px;
  height: 16px;
  margin-top: 20px;
  ${loadingAnimation}
`

const IntroSectionContent = styled.div``
const LineBar = styled.div`
  width: 100%;
  height: 16px;
  margin-top: 10px;
  &:nth-child(1) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 80%;
  }
  ${loadingAnimation}
`

const OrganizationName = styled.h1`
  height: 24px;
  width: 30%;
  background-color: ${getColor('skeleton')};
  ${mediaQuery.mobile} {
    margin: 8px auto 0;
    width: 100%;
  }
  ${loadingAnimation}
`

const OrganizationInfo = () => (
  <OrganizationInfoWrapper>
    <OrganizationAvatar />
    <InfoWrapper>
      <OrganizationName />
      <IntroSectionTitle />
      <IntroSectionContent>
        {R.times(
          index => (
            <LineBar key={index} />
          ),
          3
        )}
      </IntroSectionContent>
      <IntroSectionTitle />
      <OrganizationCategoryTags />
    </InfoWrapper>
  </OrganizationInfoWrapper>
)

export default OrganizationInfo
