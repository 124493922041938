module.exports = {
  HEART_URL: 'https://heart.hk01.com',
  API_URL: 'https://heart-api.hk01.com',
  ASSET_PREFIX: 'https://heart.hk01.com',
  COOKIES_DOMAIN: '.hk01.com',
  ARTICLE_TAG_ID_KIND_FRIDAY: '25477',
  ARTICLE_TAG_ID_FEATURED_PROJECTS: '28777',
  ARTICLE_TAG_ID_CHARITY_EVENTS: '28776',
  ARTICLE_TAG_ID_URGENT_PROJECTS: '28775',
  ARTICLE_TAG_ID_CORPORATE: '28529',
  SSO_ENV: 'prod',
  SSO_APP_ID: 'd0bc91bd4490d6ebfe9c81dd8fe89506',
  SSO_URL: '@hk01-digital/web-js-sdk',
  SSO_GA: ['UA-70981149-1', 'G-JZXF87GF62', 'G-P13VP8RY2F'],
  SSO_PIWIK_ID: 6,
  SSO_PIWIK_ENDPOINT: 'https://track.hk01.com/v1web/piwik.php',
  GTM_ID: 'GTM-T4NVXQW',
  DFP_NETWORK_ID: '108346865',
  AD_UNIT_INDEX: 'HK01_Website_Charity',
  AD_UNIT_DETAIL: 'HK01_Website_Charity/Detail',
  BLACKLIST_PAGES: '/corporate,/fakeEventPartner',
  HK01_URL: 'https://www.hk01.com',
  DONATION_EVENT_URL: 'https://heart-event.hk01.com',
  HK01_WEB_API_URL: 'https://web-data.api.hk01.com',
  ARTICLE_CDN_URL: 'https://cdn.hk01.com/di/media/images',
  HEART_CDN_URL: 'https://heart-image-cdn.hk01.com',
  LETZGOAL_CDN_URL: 'https://01runtogether-image-cdn.hk01.com',
  ORDER_EDIT_TIMEOUT: 60 * 24 * 365, // minute
  PROMO_CODE_EDIT_TIMEOUT: 60 * 24 * 365, // minute
  SERVER_SIDE_BLACKLIST: [
    '/fakeEventPartner',
    '/timeout',
    '/fundraising/blocked',
    '/demo'
  ],
  CLIENT_SIDE_BLACKLIST: ['/fakeEventPartner']
}
