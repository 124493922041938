import * as R from 'ramda'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Carousel from '../../components/Carousel'
import Slide from '../../components/Slide'
import SvgIcon from '../../components/SvgIcon'
import NextSvg from '../../public/static/images/icon-next.svg'
import PrevSvg from '../../public/static/images/icon-previous.svg'
import { getColor } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'
import { isExist } from '../../utils/ramdaUtils'

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`
const CarouselWrapper = styled.div`
  width: calc(100% + 20vw);
  height: calc(0.1875 * 120vw + 48px);
  position: relative;
  left: -10vw;
  padding: 24px 0;
  ${mq.tablet} {
    width: calc(100% + 80vw);
    height: calc(0.1875 * 180vw + 48px);
    left: -40vw;
  }
  ${mq.mobile} {
    width: 100%;
    height: 0;
    padding: 0 0 56.25% 0;
    left: 0;
  }
`
const StyledCarousel = styled(Carousel)`
  ${mq.mobile} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
const NextPrevBtn = styled.button`
  appearance: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40px;
  height: 56px;
  border: none;
  position: absolute;
  top: 50%;
  margin-top: -28px;
  cursor: pointer;
  z-index: 10;
  color: ${getColor('black')};
  background-color: rgba(255, 255, 255, 0.4);
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  ${mq.mobile} {
    display: none;
  }
`
const PrevBtn = styled(NextPrevBtn)`
  left: 10vw;
  ${mq.tablet} {
    left: 40vw;
  }
`
const NextBtn = styled(NextPrevBtn)`
  right: 10vw;
  ${mq.tablet} {
    right: 40vw;
  }
`
const DotsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  ${mq.desktopTablet} {
    bottom: 0;
  }
`
const Dot = styled.div`
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: ${getColor('grey')};
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  &:hover {
    opacity: ${({ active }) => (active ? 1 : 0.5)};
  }
  ${mq.mobile} {
    width: 8px;
    height: 8px;
  }
`

const PromoBanner = React.memo(() => {
  const promoItems = useSelector(R.path(['appState', 'promoItems']), R.equals)

  const slides = useMemo(
    () =>
      R.map(
        slide => <Slide slide={slide} key={slide.promoItemId} />,
        promoItems
      ),
    [promoItems]
  )

  return isExist(slides) ? (
    <Wrapper>
      <CarouselWrapper>
        <StyledCarousel
          slides={slides}
          interval={5000}
          autoplay
          prevNextBtn
          dots
          loop
          breakpoints={{
            768: { slidesPerView: 3, spaceBetween: 24 }
          }}
          renderPrevBtn={() => (
            <PrevBtn>
              <SvgIcon svg={<PrevSvg />} />
            </PrevBtn>
          )}
          renderNextBtn={() => (
            <NextBtn>
              <SvgIcon svg={<NextSvg />} />
            </NextBtn>
          )}
          renderDotGroup={() => <DotsWrapper />}
          renderDot={activeIndex => <Dot active={activeIndex} />}
        />
      </CarouselWrapper>
    </Wrapper>
  ) : null
})

export default PromoBanner
