import Link from 'next/link'
import React from 'react'

import { CtaBtn } from '../../containers/Button'
import { withTranslation } from '../../i18n'
import ErrorInfo from '../ErrorInfo'

const RequestError = withTranslation('common')(({ t, errorCode, props }) => (
  <ErrorInfo type={errorCode} title={t(`errorMsg.${errorCode}`)} {...props}>
    <Link href='/'>
      <a>
        <CtaBtn height={48}>{t('common.backHome')}</CtaBtn>
      </a>
    </Link>
  </ErrorInfo>
))

export default RequestError
