import { normalize } from 'normalizr'
import { call, put } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import {
  getHomeCategoriesSucceeded,
  getSearchCategoriesSucceeded
} from '../actions'
import { categoriesSchema } from '../schema'

export function* getSearchCategoriesWorker(parentCategoryNos) {
  try {
    const response = yield call(
      charityApi.getSearchCategories,
      parentCategoryNos
    )
    yield put(
      getSearchCategoriesSucceeded(normalize(response.result, categoriesSchema))
    )
  } catch (err) {
    console.log(err)
  }
}

export function* getHomeCategoriesWorker(homePageCategoryNos) {
  try {
    const response = yield call(
      charityApi.getHomeCategories,
      homePageCategoryNos
    )
    yield put(
      getHomeCategoriesSucceeded(normalize(response.result, categoriesSchema))
    )
  } catch (err) {
    console.log(err)
  }
}
