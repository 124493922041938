import { schema } from 'normalizr'

export const organizationSchema = new schema.Entity(
  'organizations',
  {},
  { idAttribute: 'organizationNo' }
)

const donationItemSchema = new schema.Entity(
  'donationItems',
  {},
  { idAttribute: 'donationItemId' }
)

export const projectSchema = new schema.Entity(
  'projects',
  { donationItems: [donationItemSchema], organization: organizationSchema },
  { idAttribute: 'projectNo' }
)

const categorySchema = new schema.Entity(
  'categories',
  {},
  { idAttribute: 'categoryNo' }
)

export const projectDetailSchema = new schema.Entity(
  'projects',
  {
    project: projectSchema,
    organization: organizationSchema,
    categories: [categorySchema]
  },
  {
    idAttribute: ({ project }) => project.projectNo
  }
)

export const projectListSchema = [projectDetailSchema]

const donationStatSchema = new schema.Entity(
  'donationStats',
  {},
  { idAttribute: 'projectNo' }
)

export const donationStatsSchema = [donationStatSchema]

export const promoWidgetProjectsSchema = [projectSchema]
