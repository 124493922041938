import React, { useRef } from 'react'
import { Controller } from 'react-hook-form/dist/index.ie11'
import styled, { css, keyframes } from 'styled-components'

import { getColor, getFontSize } from '../../utils/cssUtils'

const checkboxAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
`
const HiddenInput = styled.input`
  display: none;
`
const Wrapper = styled.label`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
`
const CheckboxComponent = styled.span`
  transition: all 0.3s;
  flex-shrink: 0;
  display: flex;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid ${getColor('lightGrey')};
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${getColor('lightGrey')};
  }
  &:hover {
    outline: none;
    border-color: ${getColor('theme')};
  }
  ${HiddenInput}:checked + label & {
    background-color: ${getColor('theme')};
    border-color: ${getColor('theme')};
    &::after {
      content: '';
      width: 12px;
      height: 6px;
      border-width: 0 0 2px 2px;
      border-style: solid;
      border-color: #fff;
      background-color: transparent;
      transform: rotate(-45deg);
      transform-origin: center center;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: calc(-8px / 2);
      margin-left: calc(-12px / 2);
    }
    &::before {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 20px;
      height: 20px;
      border: 1px solid ${getColor('theme')};
      border-radius: 4px;
      animation: ${checkboxAnimation} 0.36s ease-in-out;
      animation-fill-mode: both;
      content: '';
    }
  }
`
const Field = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 8px 0;
`
const CheckboxText = styled.span`
  color: ${getColor('black')};
  font-size: ${getFontSize('body2')};
  margin-left: 8px;
  cursor: pointer;
  ${({ hideLabel }) =>
    hideLabel &&
    css`
      display: none;
    `}
`

const Checkbox = ({ id, label, hideLabel, checked, onChange, ...props }) => {
  const checkboxRef = useRef()
  const handleKeyUp = e => {
    const { keyCode } = e
    if (keyCode === 32) {
      onChange(!checked, e)
    }
  }
  const handleChange = e => {
    onChange(e.target.checked, e)
  }
  const handleLabelClick = e => {
    checkboxRef.current.focus()
  }
  return (
    <>
      <HiddenInput
        id={id}
        type='checkbox'
        checked={checked}
        {...props}
        onChange={handleChange}
      />
      <Wrapper htmlFor={id} onClick={handleLabelClick}>
        <CheckboxComponent
          ref={checkboxRef}
          tabIndex={0}
          onKeyUp={handleKeyUp}
        />
        <CheckboxText hideLabel={hideLabel}>{label}</CheckboxText>
      </Wrapper>
    </>
  )
}

const ControlledCheckbox = ({
  name,
  validationRules,
  label,
  hideLabel,
  className,
  defaultValue = false
}) => {
  return (
    <Field className={className}>
      <Controller
        name={name}
        render={({ onChange, value }) => (
          <Checkbox
            id={name}
            onChange={onChange}
            checked={value}
            label={label}
            hideLabel={hideLabel}
          />
        )}
        rules={validationRules}
        defaultValue={defaultValue}
      />
    </Field>
  )
}

export default ControlledCheckbox
