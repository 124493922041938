import * as R from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'

import { PROJECT_STATUS, PROJECT_TYPE } from '../../constants'
import Button from '../../containers/Button'
import { useTranslation } from '../../i18n'
import { getColor, getFontSize } from '../../utils/cssUtils'
import formatter from '../../utils/formatter'
import Flex from '../Layout/Flex'
import Progress from '../Progress'

export const ProgressWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: ${({ showProgressBar }) =>
    showProgressBar ? `space-between` : `center`};
  height: 32px;
  margin-bottom: 12px;
  flex-shrink: 0;
  position: relative;
`
const FundraisingStats = styled.div`
  width: 100%;
  align-self: flex-end;
  font-size: ${getFontSize('body2')};
  line-height: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${({ showProgressBar }) =>
    !showProgressBar &&
    css`
      height: 100%;
    `}
`
const StatLabel = styled.span`
  color: ${getColor('lightGrey')};
  white-space: nowrap;
  margin-right: 4px;
`
const StatValue = styled.span`
  color: #7b7b7b;
  white-space: nowrap;
  font-weight: bold;
  ${({ ended }) =>
    ended &&
    css`
      color: ${getColor('theme')};
    `}
`
const Slash = styled.span`
  &::before {
    content: '/';
    margin: 0 4px;
    display: inline-block;
  }
`
const StatValueAmt = styled(StatValue)`
  font-weight: 600;
`
const StatusLine = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 21px;
  width: 100%;
  font-size: ${getFontSize('body1')};
`
const DonationCount = styled.div`
  display: flex;
  align-items: center;
`
const Separator = styled.div`
  display: flex;
  align-items: center;
  &::after {
    content: '';
    display: block;
    width: 2px;
    height: 0.8em;
    margin: 0 12px;
    background-color: ${getColor('lightGrey')};
  }
`
const ProjectStatus = styled.div`
  display: flex;
  flex-flow: row nowrap;
`

const FundraisingProgress = ({
  projectStatus,
  projectCardStatusDescription,
  projectType,
  startTime,
  endTime,
  donationTarget,
  projectNo,
  donationCount,
  totalDonatedAmount,
  ...props
}) => {
  const { t } = useTranslation()

  const fundraisedAmt = parseInt(totalDonatedAmount / 100)

  const targetAmt = (donationTarget || Infinity) / 100

  const showProgressBar = R.where({
    projectType: R.equals(PROJECT_TYPE.WITH_TARGET),
    projectStatus: R.complement(R.equals(PROJECT_STATUS.COMING_SOON)),
    fundraisedAmt: R.complement(R.equals(0))
  })({ projectType, projectStatus, fundraisedAmt })

  const showFundraisedAmt = R.where({
    fundraisedAmt: R.gt(R.__, 0),
    projectStatus: R.complement(R.equals(PROJECT_STATUS.COMING_SOON))
  })({ fundraisedAmt, projectStatus })

  const showTargetAmt = R.equals(projectType, PROJECT_TYPE.WITH_TARGET)

  const showDonationCount = R.where({
    donationCount: R.gte(R.__, 5),
    projectStatus: R.complement(R.equals(PROJECT_STATUS.COMING_SOON))
  })({ donationCount, projectStatus })

  const fundraisedPercentage = parseInt((fundraisedAmt / targetAmt) * 100, 10)

  const {
    label: statusLabel,
    value: statusValue
  } = projectCardStatusDescription

  const renderStatus = () => (
    <>
      {showDonationCount && statusValue && <Separator />}
      {statusLabel && <StatLabel>{t(statusLabel)}</StatLabel>}
      {statusValue && (
        <>
          <StatValue
            ended={projectStatus === PROJECT_STATUS.ENDED}
            data-test-id='stat-status-value'
          >
            {t(statusValue)}
          </StatValue>
        </>
      )}
    </>
  )

  return (
    <>
      <ProgressWrapper showProgressBar={showProgressBar}>
        {showProgressBar && <Progress small percent={fundraisedPercentage} />}
        <FundraisingStats showProgressBar={showProgressBar}>
          <>
            {showFundraisedAmt && (
              <Flex>
                <StatLabel>{t('project.fundraised')}</StatLabel>
                <StatValueAmt
                  css={`
                    color: ${getColor('black')};
                  `}
                >
                  {formatter.toCurrency(fundraisedAmt)}
                </StatValueAmt>
              </Flex>
            )}
            {showTargetAmt && (
              <Flex
                nowrap
                css={`
                  flex-grow: 1;
                `}
              >
                {showFundraisedAmt ? (
                  <Slash />
                ) : (
                  <StatLabel>{t('project.target')}</StatLabel>
                )}
                <StatValueAmt
                  css={`
                    font-weight: normal;
                  `}
                >
                  {formatter.toCurrency(targetAmt)}
                </StatValueAmt>
              </Flex>
            )}
            {!showFundraisedAmt && (
              <Button data-test-id='project-status-button'>
                {projectStatus === PROJECT_STATUS.COMING_SOON ||
                projectStatus === PROJECT_STATUS.ENDED
                  ? t('project.learnMore')
                  : t('project.supportNow')}
              </Button>
            )}
          </>
        </FundraisingStats>
      </ProgressWrapper>
      <StatusLine>
        {showDonationCount && (
          <DonationCount>
            <StatLabel>{t('project.donationCount')}</StatLabel>
            <StatValue data-test-id='stat-donation-count'>
              {t('project.donations', { value: donationCount })}
            </StatValue>
          </DonationCount>
        )}
        <ProjectStatus>{renderStatus()}</ProjectStatus>
      </StatusLine>
    </>
  )
}

export default React.memo(FundraisingProgress)
