import { call } from 'redux-saga/effects'

import { PROJECT_STATUS } from '../../../constants'
import { computeProjectStatus } from '../../../utils/commonUtils'
import { getProjectDetailWorker } from '../saga/projectSaga'

export function* getPaymentFailPageWorker(action) {
  const { projectNo, nextCtx } = action.payload

  try {
    const normalized = yield call(getProjectDetailWorker, projectNo)

    const { entities, result } = normalized

    const project = entities.projects[result]

    const isInProgress =
      computeProjectStatus(project.startTime, project.endTime) ===
      PROJECT_STATUS.IN_PROGRESS

    if (!isInProgress) {
      nextCtx.res.statusCode = 500
      nextCtx.res.end('Project expired')
    }
  } catch (err) {
    console.log(err)
  }
}
