import * as R from 'ramda'

import { rootInitialState } from '../index'

function masterReducer(state, action) {
  switch (action.type) {
    case 'CLEAR_REDUX': {
      const {
        payload: { path }
      } = action
      return R.set(R.lensPath(path), R.path(path, rootInitialState), state)
    }
    default:
      return state
  }
}

export default masterReducer
