import Color from 'color'
import * as R from 'ramda'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css, keyframes, ThemeContext } from 'styled-components'

import LoadingIcon from '../../components/LoadingIcon'
import NextSvg from '../../public/static/images/icon-button-next.svg'
import PrevSvg from '../../public/static/images/icon-button-prev.svg'
import { getColor, getFontSize } from '../../utils/cssUtils'

const fadeEffect = keyframes`
  100% {
    opacity: 0;
  }
`
const waveEffect = ({ isTransparent, textHoverColor, bgColor }) => keyframes`
  100% {
    box-shadow: 0 0 0 6px ${isTransparent ? textHoverColor : bgColor};
  }
`
const StyledLoadingIcon = styled(LoadingIcon)`
  margin-right: 8px;
`
const StyledButton = styled.button`
  position: relative;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  outline: none;
  touch-action: manipulation;
  padding: 0 35px;
  font-size: ${getFontSize('body2')};
  height: ${({ btnHeight }) =>
    btnHeight
      ? R.when(
          R.is(Number),
          R.pipe(R.toString, R.concat(R.__, 'px'))
        )(btnHeight)
      : '38px'};
  line-height: 1;
  border-radius: ${({ btnHeight }) =>
    btnHeight ? `${R.divide(parseInt(btnHeight), 2)}px` : '19px'};
  white-space: nowrap;
  overflow: visible;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ disabled, visuallyDisabled, isLoading }) =>
    disabled || visuallyDisabled
      ? css`
          cursor: not-allowed;
          color: ${getColor('white')};
          border: 1px solid ${getColor('transparent')};
          background-color: ${getColor('lightGrey')};
          box-shadow: none;
          text-shadow: none;
        `
      : isLoading
      ? css`
          cursor: not-allowed;
          pointer-events: none;
          background-color: ${R.prop('bgHoverColor')};
          color: ${R.prop('textHoverColor')};
          border: 1px solid ${R.prop('borderHoverColor')};
        `
      : css`
          cursor: pointer;
          color: ${R.prop('textColor')};
          border: 1px solid ${R.prop('borderColor')};
          background-color: ${R.prop('bgColor')};
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
          &:hover,
          &:focus {
            background-color: ${R.prop('bgHoverColor')};
            color: ${R.prop('textHoverColor')};
            border: 1px solid ${R.prop('borderHoverColor')};
          }
        `}
  ${({ animate }) =>
    animate &&
    css`
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border-radius: inherit;
        box-shadow: 0 0 0 0 ${R.prop('bgColor')};
        opacity: 0.2;
        animation: ${fadeEffect} 2s cubic-bezier(0.08, 0.82, 0.17, 1),
          ${waveEffect} 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-fill-mode: forwards;
        content: '';
        pointer-events: none;
      }
    `}
`
const StyledPrevSvg = styled(PrevSvg)`
  height: 1em;
  width: 1em;
`
const BtnCtnPrev = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  right: 4px;
`
const StyledNextSvg = styled(NextSvg)`
  height: 1em;
  width: 1em;
`
const BtnCtnNext = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: 4px;
`

const Button = ({
  className,
  children,
  height,
  bgColor,
  textColor,
  bgHoverColor,
  textHoverColor,
  transparent: isTransparent = false,
  fullWidth,
  loading,
  disabled,
  visuallyDisabled,
  onClick,
  'data-sharer': dataSharer,
  'data-url': dataUrl,
  'data-title': dataTitle,
  'data-test-id': dataTestId,
  ...props
}) => {
  const theme = useContext(ThemeContext)
  const [animate, setAnimate] = useState(false)

  const white = R.path(['color', 'white'], theme)
  const primaryColor = R.or(bgColor, R.path(['color', 'theme'], theme))
  const primaryHoverColor =
    bgHoverColor || Color(primaryColor).darken(0.2).toString()
  const transparent = R.path(['color', 'transparent'], theme)
  const btnColor = {
    textColor: isTransparent ? primaryColor : R.or(textColor, white),
    textHoverColor: isTransparent
      ? primaryHoverColor
      : textHoverColor || R.or(textColor, white),
    bgColor: isTransparent ? transparent : primaryColor,
    bgHoverColor: isTransparent ? transparent : primaryHoverColor,
    borderColor: isTransparent ? primaryColor : transparent,
    borderHoverColor: isTransparent ? primaryHoverColor : transparent
  }
  const btnOnClick = e => {
    if (disabled || loading) return
    if (!visuallyDisabled) setAnimate(true)
    R.when(R.complement(R.isNil), R.applyTo(e))(onClick)
  }

  const resetAnimate = e => {
    if (e.animationName === fadeEffect.name) {
      setAnimate(false)
    }
  }

  return (
    <StyledButton
      className={className}
      {...btnColor}
      isTransparent={isTransparent}
      btnHeight={height}
      fullWidth={fullWidth}
      disabled={disabled}
      visuallyDisabled={visuallyDisabled}
      isLoading={loading}
      animate={animate}
      onClick={btnOnClick}
      onAnimationEnd={resetAnimate}
      data-test-id={dataTestId}
      data-sharer={dataSharer}
      data-title={dataTitle}
      data-url={dataUrl}
      {...props}
    >
      {loading && (
        <StyledLoadingIcon
          color={isTransparent ? btnColor.textHoverColor : btnColor.textColor}
          data-test-id='loading-spinner'
        />
      )}
      {children}
    </StyledButton>
  )
}

export default React.memo(Button)

export const CtaBtn = styled(Button)`
  min-width: 128px;
`

export const PrevBtn = ({ children, ...props }) => {
  const {
    color: { lightYellow, theme }
  } = useContext(ThemeContext)

  const isAppview = useSelector(R.path(['appState', 'isAppview']))

  return (
    <Button
      height={isAppview ? 38 : 48}
      bgColor={lightYellow}
      textColor={theme}
      {...props}
    >
      <BtnCtnPrev>
        <StyledPrevSvg />
        {children}
      </BtnCtnPrev>
    </Button>
  )
}

export const NextBtn = ({ children, ...props }) => {
  const isAppview = useSelector(R.path(['appState', 'isAppview']))

  return (
    <Button height={isAppview ? 38 : 48} {...props}>
      <BtnCtnNext>
        {children}
        <StyledNextSvg />
      </BtnCtnNext>
    </Button>
  )
}

export const SocialBtn = styled(({ ...props }) => {
  const {
    color: { lightYellow, theme, white }
  } = useContext(ThemeContext)
  return (
    <Button
      bgColor={lightYellow}
      textColor={theme}
      bgHoverColor={theme}
      textHoverColor={white}
      {...props}
    />
  )
})`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 0;
  margin: 0 8px;
`
