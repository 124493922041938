import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import mq from '../../utils/mediaQuery'
import { Col, Row } from '../Layout/FlexGrid'
import ProjectCardSkeleton from './ProjectCardSkeleton'

const FundraisingWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 16px;
  ${mq.mobile} {
    padding: 0 16px;
  }
`

const MyFundraisings = ({ count }) => (
  <FundraisingWrapper>
    <Row gutter={{ xl: 24, md: 16, xs: 16 }}>
      {R.times(
        i => (
          <Col xs={24} md={12} xl={8} key={i}>
            <ProjectCardSkeleton />
          </Col>
        ),
        count
      )}
    </Row>
  </FundraisingWrapper>
)

export default MyFundraisings
