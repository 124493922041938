export default {
  layout: {
    headerHeight: '64px',
    footerHeight: '64px',
    appviewFooterHeight: '56px'
  },
  color: {
    theme: '#e3273c',
    lightYellow: '#fcf3e5',
    pink: '#ff8e8e',
    grey: '#5d5d5d',
    lightGrey: '#b5b5b5',
    lightPink: '#fff3f3',
    error: '#fe1200',
    white: '#fff',
    black: '#1f1f1f',
    border: 'rgba(23,51,80,0.1)',
    transparent: 'rgba(0,0,0,0)',
    skeleton: '#f5f6fa',
    input: '#9b9b9b',
    orange: '#ff6b02'
  },
  fontSize: {
    display2: '48px',
    display1: '32px',
    headline: '24px',
    title: '18px',
    body2: '16px',
    body1: '14px',
    caption: '10px'
  }
}
