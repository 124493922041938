import * as R from 'ramda'
import { handleActions } from 'redux-actions'

import { FILE_UPLOAD_STATUS } from '../../constants'
import {
  uploadFailed,
  uploadProgress,
  uploadRequest,
  uploadRetry,
  uploadSucceeded
} from './actions'

export const fileInitialState = {
  result: null,
  entities: {}
}

const fileReducer = handleActions(
  {
    [uploadRequest]: (state, action) => {
      const { key } = action.payload

      return {
        ...state,
        result: R.uniq(R.append(key, state.result || [])),
        entities: R.mergeDeepRight(state.entities, {
          files: {
            [key]: {
              status: FILE_UPLOAD_STATUS.FILE_UPLOAD_START
            }
          }
        })
      }
    },
    [uploadProgress]: (state, action) => {
      const { result, entities } = action.payload
      return {
        ...state,
        result: R.uniq(R.append(result, state.result || [])),
        entities: R.mergeDeepRight(
          state.entities,
          R.assocPath(
            ['files', result, 'status'],
            FILE_UPLOAD_STATUS.FILE_UPLOAD_IN_PROGRESS,
            entities
          )
        )
      }
    },
    [uploadRetry]: (state, action) => {
      const { key } = action.payload

      return {
        ...state,
        entities: R.pipe(
          R.dissocPath(['files', key, 'error']),
          R.set(R.lensPath(['files', key, 'progress']), 0)
        )(state.entities)
      }
    },
    [uploadSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: R.uniq(R.append(result, state.result || [])),
        entities: R.mergeDeepRight(
          state.entities,
          R.assocPath(
            ['files', result, 'status'],
            FILE_UPLOAD_STATUS.FILE_UPLOAD_SUCCEEDED,
            entities
          )
        )
      }
    },
    [uploadFailed]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: R.uniq(R.append(result, state.result || [])),
        entities: R.mergeDeepRight(
          state.entities,
          R.assocPath(
            ['files', result, 'status'],
            FILE_UPLOAD_STATUS.FILE_UPLOAD_FAILED,
            entities
          )
        )
      }
    }
  },
  fileInitialState
)

export default fileReducer
