import { MEDIA_CONDITIONS } from '../constants'

const {
  DESKTOP,
  TABLET,
  MOBILE,
  TABLET_MOBILE,
  DESKTOP_TABLET
} = MEDIA_CONDITIONS

export default {
  desktop: `@media all and ${DESKTOP}`,
  tablet: `@media all and ${TABLET}`,
  mobile: `@media all and ${MOBILE}`,
  tabletMobile: `@media all and ${TABLET_MOBILE}`,
  desktopTablet: `@media all and ${DESKTOP_TABLET}`
}
