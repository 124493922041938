import React from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
`
const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
const Spin = styled.div`
  animation: ${spin} 1s infinite linear;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
`

const Ring = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  border: 2px solid ${({ color, theme }) => color || theme.color.theme};
  border-top: 2px solid transparent;
  background-color: transparent;
  transform: rotate(-45deg);
`

const LoadingIcon = ({ 'data-test-id': dataTestId, color, ...props }) => (
  <Wrapper data-test-id={dataTestId} {...props}>
    <Spin>
      <Ring color={color} />
    </Spin>
  </Wrapper>
)

export default LoadingIcon
