import { css, keyframes } from 'styled-components'

import { getColor } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'

const shimmer = keyframes`
  0% {
    transform: translate3d(-200px,0,0);
  }
  100% {
    transform: translate3d(1200px,0,0);
  }
`
const shimmerMobile = keyframes`
  0%{
    transform: translate3d(-200px,0,0);
  }
  100%{
    transform: translate3d(767px,0,0);
  }
`

const BackgroundGradient = css`
  &::after {
    content: '';
    display: flex;
    width: 200px;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      ${getColor('skeleton')} 0px,
      #eaeaea 100px,
      ${getColor('skeleton')} 200px,
      ${getColor('skeleton')} 100%
    );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    transform: translate3d(-200px, 0, 0);
    animation: ${shimmer} 2s infinite ease-out;
    ${mq.mobile} {
      animation: ${shimmerMobile} 2s infinite ease-out;
    }
  }
`

export const loadingAnimation = css`
  overflow: hidden;
  background-color: ${getColor('skeleton')};
  ${BackgroundGradient}
`
