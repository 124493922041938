import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { getColor } from '../../../utils/cssUtils'
import mq from '../../../utils/mediaQuery'
import { Col, Row } from '../../Layout/FlexGrid'
import { loadingAnimation } from '../BackgroundEffect'

const CorpAvatar = styled.div`
  width: 100%;
  max-width: 196px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    background-color: ${getColor('skeleton')};
  }
  ${mq.mobile} {
    margin: 0 auto;
  }
`
const Bar = styled.div`
  width: ${({ mid, short }) => (short ? '78px' : mid ? '384px' : '100%')};
  height: 20px;
  margin-bottom: 8px;
  ${loadingAnimation};
`
const MobileBar = styled(Bar)`
  ${mq.desktopTablet} {
    display: none;
  }
`
const CorpName = styled.div`
  height: 24px;
  width: 30%;
  max-width: 144px;
  margin-bottom: 20px;
  ${mq.mobile} {
    margin: 0 auto 20px auto;
    width: 100%;
  }
  ${loadingAnimation}
`
const SubWrapper = styled.div`
  margin-bottom: 24px;
`
const Box = styled.div`
  width: 100%;
  max-width: 588px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    background-color: ${getColor('skeleton')};
  }
`

const CorpDetail = () => (
  <Row gutter={[16, 16]}>
    <Col
      xs={24}
      md={4}
      css={`
        ${mq.mobile} {
          display: none;
        }
      `}
    />
    <Col xs={24} md={20}>
      <CorpName />
    </Col>
    <Col
      xs={24}
      md={4}
      css={`
        ${mq.mobile} {
          order: -1;
        }
      `}
    >
      <CorpAvatar />
    </Col>
    <Col xs={24} md={20}>
      {R.times(
        i => (
          <SubWrapper key={i}>
            <Bar short />
            <Bar />
            <MobileBar />
            <MobileBar />
            <Bar mid />
          </SubWrapper>
        ),
        2
      )}
      {R.times(
        i => (
          <React.Fragment key={i}>
            <SubWrapper>
              <Box />
            </SubWrapper>
            <SubWrapper>
              <Bar />
              <Bar />
              <MobileBar />
              <MobileBar />
              <Bar mid />
            </SubWrapper>
          </React.Fragment>
        ),
        2
      )}
    </Col>
  </Row>
)

export default CorpDetail
