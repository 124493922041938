import * as R from 'ramda'
import { handleActions } from 'redux-actions'

import {
  getArticleListByIdSucceeded,
  getArticleListByTagIdRequest,
  getArticleListByTagIdSucceeded,
  getArticleListByTagIdsRequest,
  getArticleListByTagIdsSucceeded
} from './actions'

export const articleInitialState = {
  nextOffset: 0,
  limit: 8,
  result: null,
  hasMoreArticle: false,
  entities: {},
  fetching: {
    getArticlePage: false
  }
}

const articleReducer = handleActions(
  {
    [getArticleListByTagIdRequest]: (state, action) => {
      const { offset } = action.payload
      return {
        ...state,
        ...(offset === 0 ? { result: null } : {}),
        fetching: {
          getArticlePage: true
        }
      }
    },
    [getArticleListByTagIdSucceeded]: (state, action) => {
      const { nextOffset, result, entities } = action.payload
      return {
        ...state,
        nextOffset: nextOffset,
        result: R.uniq(R.concat(state.result || [], result)),
        entities: R.mergeDeepRight(state.entities, entities),
        hasMoreArticle: R.equals(R.length(result), 8),
        fetching: {
          getArticlePage: false
        }
      }
    },
    [getArticleListByTagIdsRequest]: (state, action) => {
      const { offset } = action.payload
      return {
        ...state,
        ...(offset === 0 ? { result: null } : {}),
        fetching: {
          getArticlePage: true
        }
      }
    },
    [getArticleListByTagIdsSucceeded]: (state, action) => {
      const { nextOffset, result, entities } = action.payload
      return {
        ...state,
        nextOffset: nextOffset,
        result: R.uniq(R.concat(state.result || [], result)),
        entities: R.mergeDeepRight(state.entities, entities),
        hasMoreArticle: R.equals(R.length(result), 8),
        fetching: {
          getArticlePage: false
        }
      }
    },
    [getArticleListByIdSucceeded]: (state, action) => {
      const { result, entities } = action.payload
      return {
        ...state,
        result,
        entities: R.mergeDeepRight(state.entities, entities)
      }
    }
  },
  articleInitialState
)

export default articleReducer
