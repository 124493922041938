import * as R from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import styled from 'styled-components'

import MaxWidth from '../../components/Layout/MaxWidth'
import { getFrontendSetting } from '../../redux/app/selectors'
import { getHomeCategoryDisplayNames } from '../../redux/category/selectors'
import { homeReachCategory } from '../../utils/dataTracker'
import mq from '../../utils/mediaQuery'
import CatIcon from './CatIcon'

const CategoryWrapper = styled.div``

const CategoryInnerWrapper = styled(MaxWidth)`
  ${mq.mobile} {
    overflow: ${({ isAppview }) => (isAppview ? 'hidden' : 'visible')};
    margin: 0;
  }
`

const CategoryList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 32px 0;
  ${mq.tablet} {
    padding: 24px 0;
  }
  ${mq.mobile} {
    overflow-x: scroll;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 16px;
  }
`

const HomeCatIcons = () => {
  const isAppview = useSelector(R.path(['appState', 'isAppview']))

  const { homePageCategoryNos } = useSelector(getFrontendSetting)

  const categoryDisplayNames = useSelector(getHomeCategoryDisplayNames)

  const trackHomeReachCategory = () => {
    homeReachCategory(categoryDisplayNames, homePageCategoryNos)
  }

  return (
    <CategoryWrapper>
      <CategoryInnerWrapper isAppview={isAppview}>
        <CategoryList>
          {homePageCategoryNos.map(catNo => (
            <CatIcon categoryNo={catNo} key={catNo} />
          ))}
        </CategoryList>
      </CategoryInnerWrapper>
      <Waypoint onEnter={R.once(trackHomeReachCategory)} />
    </CategoryWrapper>
  )
}

export default React.memo(HomeCatIcons)
