import * as R from 'ramda'
import React, { useEffect } from 'react'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form/dist/index.ie11'
import styled, { css } from 'styled-components'

import { usePrev } from '../../hooks'
import { getColor, getFontSize } from '../../utils/cssUtils'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import Hidden from './Hidden'
import InstantSearchCheckbox from './InstantSearchCheckbox'
import InstantSearchRadio from './InstantSearchRadio'
import InstantSearchSorter from './InstantSearchSorter'
import {
  ErrorLabel,
  FieldComponent,
  HelpText,
  Input,
  InputWrapper,
  InsideLabel,
  OutsideLabel
} from './shared'

const InputField = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`
const Prefix = styled.div.attrs({ className: 'inputwrapper__prefix' })`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getColor('grey')};
  background-color: ${getColor('input', 0.04)};
  font-size: ${getFontSize('title')};
  padding: 0 12px;
  border-right: 1px solid ${getColor('lightGrey')};
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${getColor('lightGrey')};
      background-color: ${getColor('lightGrey', 0.2)};
    `}
  ${InputWrapper}:focus-within & {
    border-right: 1px solid ${getColor('grey')};
  }
`

const AnimatedLabel = ({ name, className, children }) => {
  const val = useWatch({ name, defaultValue: '' })

  const isEmpty = R.isEmpty(val)

  return (
    <InsideLabel className={className} isFieldEmpty={isEmpty}>
      {children}
    </InsideLabel>
  )
}

const isNumberKeyCode = keyCode => keyCode >= 48 && keyCode <= 57

const TextInput = React.memo(
  React.forwardRef(
    (
      {
        name,
        hasInsideLabel,
        type,
        prefix,
        onPressEnter,
        onKeyPress,
        className,
        ...props
      },
      ref
    ) => {
      const handleKeyPress = e => {
        const { charCode } = e
        const isNumKeyPressed = isNumberKeyCode(charCode)
        if (
          type === 'number' &&
          !isNumKeyPressed &&
          charCode !== 13 // should not prevent 13 (enter)
        )
          e.preventDefault()
        onKeyPress && onKeyPress(e)
        if (charCode === 13) {
          onPressEnter && onPressEnter()
        }
      }
      return (
        <Input
          name={name}
          ref={ref}
          type={type}
          className={`input${className}`}
          hasInsideLabel={hasInsideLabel}
          $prefix={prefix}
          {...(type === 'number' ||
          !R.isNil(onKeyPress) ||
          !R.isNil(onPressEnter)
            ? { onKeyPress: handleKeyPress }
            : {})}
          {...props}
        />
      )
    }
  )
)

const TextArea = React.forwardRef((props, ref) => (
  <TextInput as='textarea' ref={ref} {...props} />
))

const Field = React.memo(
  ({
    name,
    children,
    label,
    helpText,
    validationRules,
    labelOutside,
    className,
    index,
    showError = true
  }) => {
    const methods = useFormContext()

    const { register, errors } = methods || {}

    const isWrappedByForm = !R.isNil(methods) // import as a single input without wrapped by form

    const value = R.path(['props', 'value'], children)

    const displayError = R.pipe(
      R.prop(R.__, errors),
      R.unless(R.isNil, R.prop('message'))
    )

    const isDisabled = R.pathOr(false, ['props', 'disabled'], children)

    const checkHasError = () => {
      if (R.isNil(errors) || R.isEmpty(errors)) return false

      if (!R.isNil(index)) {
        const fieldName = R.replace(`[${index}]`, '', name)

        if (R.type(errors[fieldName]) === 'Array') {
          return !R.isNil(errors[fieldName][index])
        }
      } else {
        return !R.isNil(errors[name])
      }
    }

    const hasError = checkHasError()

    const input = React.cloneElement(children, {
      name,
      hasInsideLabel: !R.isNil(label) && !labelOutside,
      ...(isWrappedByForm
        ? {
            ref: element => {
              register(element, validationRules)
              if (children.ref) {
                children.ref.current = element
              }
            }
          }
        : { value })
    })

    return (
      <>
        {labelOutside && label && <OutsideLabel>{label}</OutsideLabel>}
        <FieldComponent className={className}>
          <InputWrapper hasError={hasError}>
            {children.props.prefix && (
              <Prefix disabled={isDisabled}>{children.props.prefix}</Prefix>
            )}
            <InputField>
              {!labelOutside && label && (
                <AnimatedLabel name={name}>{label}</AnimatedLabel>
              )}
              {input}
            </InputField>
          </InputWrapper>
          {helpText && <HelpText>{helpText}</HelpText>}
          {showError && <ErrorLabel>{displayError(name)}</ErrorLabel>}
        </FieldComponent>
      </>
    )
  }
)

const Form = React.memo(({ children, onSubmit, options = {}, ...props }) => {
  const methods = useForm(options)

  const { setValue, handleSubmit } = methods

  const { defaultValues } = options

  const prevDefaultValues = usePrev(defaultValues)

  useEffect(() => {
    if (!R.equals(defaultValues, prevDefaultValues)) {
      R.forEachObjIndexed((val, key) => {
        setValue(key, val)
      }, defaultValues)
    }
  }, [defaultValues, prevDefaultValues, setValue])

  return (
    <FormProvider {...methods}>
      <form {...props} onSubmit={handleSubmit(onSubmit)}>
        {R.type(children) === 'Function' ? children(methods) : children}
      </form>
    </FormProvider>
  )
})

export {
  Form,
  Field,
  TextInput,
  TextArea,
  Checkbox,
  DatePicker,
  Hidden,
  InstantSearchCheckbox,
  InstantSearchRadio,
  InstantSearchSorter
}
