import moment from 'moment'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useFormContext, useWatch } from 'react-hook-form/dist/index.ie11'
import styled, { css } from 'styled-components'

import { getColor, getFontSize } from '../../utils/cssUtils'
import { isExist } from '../../utils/ramdaUtils'
import { Col, Row } from '../Layout/FlexGrid'
import ReactDatesStyle from '../Styled/ReactDates'
import {
  ErrorLabel,
  FieldComponent,
  HelpText,
  OutsideLabel as Label
} from './shared'

const Field = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${getColor('input', 0.04)};
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 0 0 1px ${getColor('lightGrey')};
  &:focus-within {
    box-shadow: 0 0 0 1px ${getColor('grey')};
  }
  ${({ hasError }) =>
    hasError &&
    css`
      box-shadow: 0 0 0 1px ${getColor('error')};
    `}
  .SingleDatePicker {
    width: 100%;
  }
  .SingleDatePickerInput {
    background: transparent;
  }
  .DateInput {
    background: transparent;
  }
  .DateInput_input {
    font-family: system-ui, sans-serif;
    font-size: ${getFontSize('body2')};
    font-weight: normal;
    color: ${getColor('black')};
    height: 50px;
    border: none;
    background: transparent;
    &::placeholder {
      color: ${getColor('lightGrey')};
    }
  }
  .DateInput_input__focused {
    border: none;
  }
  .CalendarMonth_caption {
    padding-top: 18px;
  }
  .CalendarDay__selected {
    background-color: ${getColor('pink')};
    border-color: ${getColor('pink')};
    &:hover,
    &:active {
      background-color: ${getColor('pink')};
      border-color: ${getColor('pink')};
    }
  }
  .DateInput_fang {
    margin-top: 1px;
  }
`
const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Select = styled.select`
  min-width: 70px;
  display: inline-flex;
  align-items: center;
  padding: 4px;
  appearance: none;
  border-radius: 3px;
  text-align: center;
  text-align-last: center;
  font-size: ${getFontSize('body2')};
  padding-right: 16px;
  background-image: url('/static/images/icon-down-grey.svg');
  background-repeat: no-repeat;
  background-size: 10px auto;
  background-position: calc(100% - 4px);
`
const MonthElement = ({
  month: selectedMoment,
  onMonthSelect,
  onYearSelect
}) => {
  const selectedMonth = selectedMoment.month()
  const selectedYear = selectedMoment.year()
  const handleMonthChange = e => {
    onMonthSelect(selectedMoment, e.target.value)
  }
  const handleYearChange = e => {
    onYearSelect(selectedMoment, e.target.value)
  }
  return (
    <SelectWrapper>
      <Row gutter={[8, 0]}>
        <Col xs={12}>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            className='DayPickerNavigation_button__default'
          >
            {moment.months().map((label, value) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </Select>
        </Col>
        <Col xs={12}>
          <Select
            value={selectedYear}
            onChange={handleYearChange}
            className='DayPickerNavigation_button__default'
          >
            {R.range(-5, 6).map(i => {
              const year = selectedYear + i
              return (
                <option value={year} key={year}>
                  {year}
                </option>
              )
            })}
          </Select>
        </Col>
      </Row>
    </SelectWrapper>
  )
}

const DatePicker = ({
  name,
  label,
  placeholder,
  validationRules,
  isOutsideRange,
  helpText,
  showClearDate,
  showError = true
}) => {
  const { register, unregister, setValue, errors, trigger } = useFormContext()

  const date = useWatch({ name, defautlValue: undefined })

  const [focused, setFocused] = useState(false)

  const datePickerValue = isExist(date) ? moment(date, 'YYYY/MM/DD') : null

  const handleDateChange = date => {
    // if manually type a date and is out of range, `date` will be null
    setValue(name, date ? date.format('YYYY/MM/DD') : '', { shouldDirty: true })
    trigger(name) // doesn't need to await because don't need the result here
  }

  const handleFocusChange = ({ focused }) => {
    setFocused(focused)
  }

  const hasError = !R.isNil(errors[name])

  useEffect(() => {
    register({ name }, validationRules)
    return () => unregister(name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register])

  useEffect(() => {
    // react-hook-form cache the validationRules, therefore need to register again when the rules change
    register({ name }, validationRules)
    handleDateChange(datePickerValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationRules])

  return (
    <>
      {label && <Label>{label}</Label>}
      <FieldComponent>
        <Field hasError={hasError}>
          <ReactDatesStyle />
          <SingleDatePicker
            placeholder={placeholder}
            date={datePickerValue}
            onDateChange={handleDateChange}
            focused={focused}
            onFocusChange={handleFocusChange}
            id={name}
            numberOfMonths={1}
            noBorder
            hideKeyboardShortcutsPanel
            block
            renderMonthElement={MonthElement}
            isOutsideRange={isOutsideRange}
            showClearDate={showClearDate}
            readOnly
          />
        </Field>
        {helpText && <HelpText>{helpText}</HelpText>}
        {showError && hasError && (
          <ErrorLabel>{R.path([name, 'message'], errors)}</ErrorLabel>
        )}
      </FieldComponent>
    </>
  )
}

export default DatePicker
