import * as R from 'ramda'
import { handleActions } from 'redux-actions'

import { getProjectDetailSucceeded } from '../project/actions'
import {
  getHomeCategoriesSucceeded,
  getSearchCategoriesSucceeded
} from './actions'

export const categoryInitialState = {
  result: {
    home: [],
    search: []
  },
  entities: {
    categories: {}
  }
}

const categoryReducer = handleActions(
  {
    [getProjectDetailSucceeded]: (state, action) => {
      const { entities } = action.payload

      return {
        ...state,
        entities: {
          ...state.entities,
          categories: R.mergeDeepRight(
            state.entities.categories,
            entities?.categories ?? {}
          )
        }
      }
    },
    [getSearchCategoriesSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: R.mergeDeepRight(state.result, { search: result }),
        entities: R.mergeDeepRight(state.entities, entities)
      }
    },
    [getHomeCategoriesSucceeded]: (state, action) => {
      const { result, entities } = action.payload

      return {
        ...state,
        result: R.mergeDeepRight(state.result, { home: result }),
        entities: R.mergeDeepRight(state.entities, entities)
      }
    }
  },
  categoryInitialState
)

export default categoryReducer
