import { handleActions } from 'redux-actions'

import { getPartnerOrderInfoSucceeded } from './actions'

export const orderInitialState = {
  partnerOrderInfo: null
}

const orderReducer = handleActions(
  {
    [getPartnerOrderInfoSucceeded]: (state, action) => {
      return {
        ...state,
        partnerOrderInfo: action.payload.partnerOrderInfo
      }
    }
  },
  orderInitialState
)

export default orderReducer
