export const POST_DONATE_PHASE1 = `POST_DONATE_PHASE1` // < 24hrs
export const POST_DONATE_PHASE2 = `POST_DONATE_PHASE2` // > 24hrs && < 90days
export const POST_DONATE_PHASE3 = `POST_DONATE_PHASE3` // > 90days

export const PAGE = {
  HOME: 'HOME',
  DISCOVER: 'DISCOVER',
  PROJECT: 'PROJECT',
  FUNDRAISING_DETAIL: 'FUNDRAISING_DETAIL',
  FUNDRAISING_CREATE: 'FUNDRAISING_CREATE',
  FUNDRAISING_DRAFT_EDIT: 'FUNDRAISING_DRAFT_EDIT',
  FUNDRAISING_PREVIEW: 'FUNDRAISING_PREVIEW',
  FUNDRAISING_PUBLISH_SUCCESS: 'FUNDRAISING_PUBLISH_SUCCESS',
  ORGANIZATION: 'ORGANIZATION',
  ABOUT: 'ABOUT',
  CORPORATE: 'CORPORATE',
  CORPORATE_DETAIL: 'CORPORATE_DETAIL',
  CORPORATE_LISTING: 'CORPORATE_LISTING',
  CORPORATE_CONTACT: 'CORPORATE_CONTACT',
  ARTICLE: 'ARTICLE',
  FAQ: 'FAQ',
  TERMS: 'TERMS',
  MY_ACCOUNT: 'MY_ACCOUNT',
  MY_DONATION: 'MY_DONATION',
  MY_PERSONAL_FUNDRAISING: 'MY_PERSONAL_FUNDRAISING',
  MY_TICKET: 'MY_TICKET',
  MY_INFO: 'MY_INFO',
  HK01_MY_DONATION: 'HK01_MY_DONATION',
  DONATION_DETAIL: 'DONATION_DETAIL',
  REDIRECT: 'REDIRECT',
  AUTHORIZE: 'AUTHORIZE',
  THANKYOU: 'THANKYOU',
  EXTRA_DONATION: 'EXTRA_DONATION',
  TIMEOUT: 'TIMEOUT'
}

export const ERROR_ICON_TYPE = {
  BIND_PHONE: 'BIND_PHONE',
  TIMEOUT: 'TIMEOUT',
  NO_RESULT: 'NO_RESULT',
  NO_LOGIN: 'NO_LOGIN',
  PAYMENT_FAIL: 'PAYMENT_FAIL',
  PAGE_UPDATING: 'PAGE_UPDATING',
  DELETE_DRAFT: 'DELETE_DRAFT',
  NO_FUNDRAISING_EVENTS: 'NO_FUNDRAISING_EVENTS',
  NO_TICKETS: 'NO_TICKETS',
  NO_DONATIONS: 'NO_DONATIONS'
}

export const PROJECT_STATUS = {
  COMING_SOON: 'COMING_SOON',
  ENDED: 'ENDED',
  IN_PROGRESS: 'IN_PROGRESS',
  // fundraising
  PAUSE_PUBLISH: 'PAUSE_PUBLISH',
  DRAFT: 'DRAFT'
}

export const FUNDRAISING_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  APPROVED: 'APPROVED',
  ARCHIVED: 'ARCHIVED'
}

export const FUNDRAISING_STATUS_MAP = [
  FUNDRAISING_STATUS.DRAFT,
  FUNDRAISING_STATUS.PUBLISHED,
  FUNDRAISING_STATUS.APPROVED,
  FUNDRAISING_STATUS.ARCHIVED
]

export const PROJECT_TYPE = {
  WITH_TARGET: 'WITH_TARGET',
  WITHOUT_TARGET: 'WITHOUT_TARGET'
}

export const PROJECT_MODE = {
  EVER_LASTING: 'EVER_LASTING',
  TIME_LAPSE: 'TIME_LAPSE'
}

export const PROJECT_FILTER = {
  ENDED: 'ENDED',
  REGULAR: 'REGULAR',
  SHORT_TERM: 'SHORT_TERM',
  SUPPORTS_FUNDRAISING_EVENT: 'SUPPORTS_FUNDRAISING_EVENT'
}

export const DEFAULT_PLACEHOLDER =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8+u3XfwAJswPmgIRAlgAAAABJRU5ErkJggg=='
export const DEFAULT_PROFILE_URL = '/static/images/default-profile.svg'

export const MEDIA_CONDITIONS = {
  DESKTOP: '(min-width: 1200px)',
  TABLET: '(min-width: 768px) and (max-width: 1199px)',
  MOBILE: '(max-width: 767px)',
  DESKTOP_TABLET: '(min-width: 768px)',
  TABLET_MOBILE: '(max-width: 1199px)'
}

export const CORPORATE_CATEGORY_DISPLAY_TYPE = {
  LARGE: 1,
  SMALL: 0
}

export const FILE_UPLOAD_STATUS = {
  FILE_UPLOAD_START: 'FILE_UPLOAD_START',
  FILE_UPLOAD_IN_PROGRESS: 'FILE_UPLOAD_IN_PROGRESS',
  FILE_UPLOAD_SUCCEEDED: 'FILE_UPLOAD_SUCCEEDED',
  FILE_UPLOAD_FAILED: 'FILE_UPLOAD_FAILED'
}
