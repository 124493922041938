import * as R from 'ramda'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { animated, useSpring } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import styled from 'styled-components'

import { useClientRect, usePrev } from '../../hooks'
import NextSvg from '../../public/static/images/icon-next.svg'
import PrevSvg from '../../public/static/images/icon-previous.svg'
import { SafeSetInterval } from '../../utils/commonUtils'
import { getColor } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'
import { isExist } from '../../utils/ramdaUtils'
import SvgIcon from '../SvgIcon'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`
const Slides = styled(animated.div)`
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  align-items: center;
  will-change: transform;
  touch-action: pan-y;
`
const SlideContainer = styled.div`
  width: ${({ slidesPerView }) => slidesPerView && 100 / slidesPerView}%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  position: relative;
`
const NextPrevBtn = styled.button`
  appearance: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40px;
  height: 56px;
  border: none;
  position: absolute;
  top: 50%;
  margin-top: -28px;
  cursor: pointer;
  z-index: 10;
  color: ${getColor('black')};
  background-color: rgba(255, 255, 255, 0.4);
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  ${mq.mobile} {
    display: none;
  }
`
const PrevBtn = styled(NextPrevBtn)`
  left: 0;
`
const NextBtn = styled(NextPrevBtn)`
  right: 0;
`
const DotsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
`
const Dot = styled.div`
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: ${getColor('grey')};
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  &:hover {
    opacity: ${({ active }) => (active ? 1 : 0.5)};
  }
  ${mq.mobile} {
    width: 8px;
    height: 8px;
  }
`

const Carousel = React.memo(
  React.forwardRef(
    (
      {
        className,
        slides: defaultSlides,
        autoplay = false,
        interval = 5000,
        slidesPerView: defaultSlidesPerView = 1,
        slidesPerGroup: defaultSlidesPerGroup = 1,
        spaceBetween: defaultSpaceBetween = 0,
        swipeThreshold = 60,
        swipeVelocity = 0.8,
        loop = false,
        prevNextBtn = false,
        dots = false,
        renderNextBtn,
        renderPrevBtn,
        renderDot,
        renderDotGroup,
        breakpoints,
        onPageChange,
        onSlideActive
      },
      ref
    ) => {
      const isSsr = typeof window === 'undefined'

      const [isInited, setIsInited] = useState(false)

      const winWidth = isSsr ? 0 : window.innerWidth

      useEffect(() => {
        setIsInited(true)
      }, [])

      const [rect, rectRef] = useClientRect()

      const sliderWidth = useMemo(
        () => R.pathOr(0, ['width'], rect),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(rect)]
      )

      const { slidesPerView, spaceBetween, slidesPerGroup } = useMemo(() => {
        let slidesPerView = defaultSlidesPerView
        let spaceBetween = defaultSpaceBetween
        let slidesPerGroup = defaultSlidesPerGroup

        if (!isSsr && isInited) {
          if (breakpoints) {
            R.forEachObjIndexed((breakpointConfig, breakpoint) => {
              if (window.matchMedia(`(min-width: ${breakpoint}px)`).matches) {
                slidesPerView =
                  breakpointConfig.slidesPerView || defaultSlidesPerView
                spaceBetween =
                  breakpointConfig.spaceBetween || defaultSpaceBetween
                slidesPerGroup =
                  breakpointConfig.slidesPerGroup || defaultSlidesPerGroup
              }
            }, breakpoints)
          }
        }
        return { slidesPerView, spaceBetween, slidesPerGroup }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        isSsr,
        isInited,
        winWidth,
        defaultSlidesPerView,
        defaultSpaceBetween,
        defaultSlidesPerGroup,
        JSON.stringify(breakpoints)
      ])

      const isSlidesGrouped = slidesPerGroup > 1

      const getSlides = useCallback(() => {
        if (slidesPerGroup === 1) return defaultSlides

        const count = R.length(defaultSlides)

        const blankSlidesToAppend =
          count % slidesPerGroup === 0
            ? 0
            : slidesPerGroup - (count % slidesPerGroup)

        return R.concat(
          defaultSlides,
          R.times(
            i => <SlideContainer key={i} slidesPerView={slidesPerView} />,
            blankSlidesToAppend
          )
        )
      }, [slidesPerGroup, slidesPerView, defaultSlides])

      const slideCount = useMemo(() => R.length(getSlides()), [getSlides])

      const multiple = useMemo(
        () =>
          slideCount < slidesPerView
            ? Math.ceil(slidesPerView / slideCount)
            : 1,
        [slideCount, slidesPerView]
      )

      const slides = useMemo(() => {
        let slides = getSlides()
        if (!isSsr && isInited) {
          if (loop) {
            if (slideCount < slidesPerView) {
              slides = R.unnest(R.repeat(slides, multiple))
            }
            slides = [
              ...R.takeLast(
                isSlidesGrouped
                  ? slidesPerGroup * Math.ceil(slidesPerView / slidesPerGroup)
                  : slidesPerView,
                slides
              ),
              ...slides,
              ...R.take(
                isSlidesGrouped
                  ? slidesPerGroup * Math.ceil(slidesPerView / slidesPerGroup)
                  : slidesPerView,
                slides
              )
            ]
          } else {
            slides =
              slideCount < slidesPerView
                ? R.take(
                    slidesPerView,
                    R.unnest(
                      R.repeat(slides, Math.ceil(slidesPerView / slideCount))
                    )
                  )
                : slides
          }
        }
        return slides
      }, [
        isSsr,
        isInited,
        loop,
        getSlides,
        slideCount,
        slidesPerView,
        multiple,
        slidesPerGroup,
        isSlidesGrouped
      ])

      const all = useMemo(() => R.length(slides), [slides])

      const timer = useRef(false)

      const prevSliderWidth = usePrev(sliderWidth)

      const [currentIndex, setCurrentIndex] = useState(0)

      const slideIndex = useMemo(() => R.mathMod(currentIndex, slideCount), [
        currentIndex,
        slideCount
      ])

      const slideWidth = useMemo(
        () =>
          (sliderWidth - (slidesPerView - 1) * spaceBetween) / slidesPerView,
        [sliderWidth, slidesPerView, spaceBetween]
      )

      const defaultDeltaX = useMemo(
        () =>
          loop
            ? // calculate offset for duplicated slide on the left
              (isSlidesGrouped
                ? slidesPerGroup * Math.ceil(slidesPerView / slidesPerGroup)
                : slidesPerView) *
              (slideWidth + spaceBetween)
            : 0,
        [
          loop,
          slidesPerView,
          slidesPerGroup,
          slideWidth,
          spaceBetween,
          isSlidesGrouped
        ]
      )

      const deltaX = useCallback(
        index => defaultDeltaX + index * (slideWidth + spaceBetween),
        [slideWidth, defaultDeltaX, spaceBetween]
      )

      const [props, set] = useSpring(
        () => ({
          immediate: prevSliderWidth === 0 || prevSliderWidth !== sliderWidth,
          default: { immediate: false },
          to: {
            visibility: isInited ? 'visible' : 'hidden',
            transform: `translate3d(${0 - deltaX(currentIndex)}px,0,0)`
          },
          config: {
            tension: 200
          }
        }),
        [currentIndex, deltaX, isInited]
      )

      const resetSlideWithoutAnimation = useCallback(
        (resetToIndex, callback) => {
          set(() => ({
            to: async next => {
              await next({
                immediate: true,
                default: { immediate: false },
                transform: `translate3d(${0 - deltaX(resetToIndex)}px,0,0)`
              })
              setCurrentIndex(resetToIndex)
              callback && callback()
            }
          }))
        },
        [deltaX, setCurrentIndex]
      )

      const duplicatedSlidesCount = isSlidesGrouped
        ? slidesPerGroup * Math.ceil(slidesPerView / slidesPerGroup)
        : slidesPerView

      const moveSlide = useCallback(
        nextIndex => {
          if (isSlidesGrouped) {
            if (
              nextIndex ===
              all - duplicatedSlidesCount * 2 + slidesPerGroup
            ) {
              nextIndex = 0
              resetSlideWithoutAnimation(nextIndex, () => {
                setCurrentIndex(nextIndex + slidesPerGroup)
              })
            } else if (nextIndex === -slidesPerGroup) {
              // * 2 because currentIndex start from negative
              nextIndex = all - duplicatedSlidesCount * 2
              resetSlideWithoutAnimation(nextIndex, () => {
                setCurrentIndex(nextIndex - slidesPerGroup)
              })
            } else {
              setCurrentIndex(nextIndex)
            }
          } else {
            // slidesPerView * 2 === number of slides duplicated at both end
            if (
              nextIndex ===
              all - duplicatedSlidesCount * 2 + slidesPerGroup
            ) {
              nextIndex = 0
              // special case, single image loop
              if (currentIndex === nextIndex + 1) {
                nextIndex -= 1
              }
              resetSlideWithoutAnimation(nextIndex, () => {
                setCurrentIndex(nextIndex + slidesPerGroup)
              })
            } else if (nextIndex === -2) {
              nextIndex = all - duplicatedSlidesCount * 2 - 1
              // special case, single image loop
              if (currentIndex === nextIndex - 1) {
                nextIndex += 1
              }
              resetSlideWithoutAnimation(nextIndex, () => {
                setCurrentIndex(nextIndex - 1)
              })
            } else {
              setCurrentIndex(nextIndex)
            }
          }
        },
        [
          currentIndex,
          all,
          isSlidesGrouped,
          slidesPerGroup,
          resetSlideWithoutAnimation,
          setCurrentIndex,
          duplicatedSlidesCount
        ]
      )

      const handlePrev = useCallback(() => {
        if (loop) {
          const nextIndex = R.modulo(currentIndex - slidesPerGroup, all)
          moveSlide(nextIndex)
        } else {
          setCurrentIndex(
            R.clamp(0, all - slidesPerView, currentIndex - slidesPerGroup)
          )
        }
      }, [loop, currentIndex, all, moveSlide, slidesPerGroup, slidesPerView])

      const handleNext = useCallback(() => {
        if (loop) {
          const nextIndex = R.modulo(currentIndex, all) + slidesPerGroup
          moveSlide(nextIndex)
        } else {
          setCurrentIndex(
            R.clamp(0, all - slidesPerView, currentIndex + slidesPerGroup)
          )
        }
      }, [loop, currentIndex, all, moveSlide, slidesPerGroup, slidesPerView])

      const handleDotClick = useCallback(
        selectedIndex => {
          if (
            R.mathMod(currentIndex, slideCount) === 0 &&
            selectedIndex < currentIndex
          ) {
            resetSlideWithoutAnimation(0, () => {
              setCurrentIndex(selectedIndex)
            })
          } else if (currentIndex === -1 && selectedIndex > currentIndex) {
            resetSlideWithoutAnimation(slideCount - 1, () => {
              setCurrentIndex(selectedIndex)
            })
          } else {
            setCurrentIndex(selectedIndex)
          }
        },
        [currentIndex, slideCount, resetSlideWithoutAnimation]
      )

      const hasDragged = useRef(false)

      const bind = useGesture(
        {
          onDrag: ({
            event,
            down,
            velocity,
            direction: [xDir],
            distance,
            movement: [mx],
            cancel,
            tap,
            last,
            dragging,
            swipe: [swipeX],
            offset: [offsetX]
          }) => {
            hasDragged.current = !tap

            const transitSlide = factor => {
              if (cancel) {
                if (loop) {
                  cancel(
                    (factor < 0 && handleNext(), factor > 0 && handlePrev())
                  )
                } else {
                  cancel(
                    (factor < 0 &&
                      currentIndex < slideCount - slidesPerView &&
                      handleNext(),
                    factor > 0 && currentIndex > 0 && handlePrev())
                  )
                }
              }
            }

            if (autoplay && loop && timer.current) {
              down ? timer.current.stop() : timer.current.reset()
            }

            if (last) {
              if (swipeX !== 0) {
                transitSlide(xDir)
              } else if (
                distance >
                (isSlidesGrouped ? slidesPerGroup * slideWidth : slideWidth) / 2
              ) {
                set(() => ({
                  to: async next => {
                    await next({
                      immediate: true,
                      default: { immediate: false },
                      transform: `translate3d(${offsetX - defaultDeltaX}px,0,0)`
                    })
                    transitSlide(mx)
                  }
                }))
              }
            }

            const dragDeltaX = down ? mx : 0

            let nextPrevIndex = R.modulo(currentIndex - slidesPerGroup, all)

            let nextNextIndex = R.modulo(currentIndex, all) + slidesPerGroup

            const setSpring = index => {
              set(() => ({
                to: async next => {
                  await next({
                    immediate: true,
                    default: { immediate: false },
                    transform: `translate3d(${
                      dragDeltaX - deltaX(index)
                    }px,0,0)`
                  })
                  setCurrentIndex(index)
                }
              }))
            }
            if (isSlidesGrouped) {
              if (
                down &&
                loop &&
                xDir > 0 &&
                nextPrevIndex === -slidesPerGroup
              ) {
                // prev
                nextPrevIndex = all - duplicatedSlidesCount * 2
                setSpring(nextPrevIndex)
              } else if (
                down &&
                loop &&
                xDir < 0 &&
                nextNextIndex ===
                  all - duplicatedSlidesCount * 2 + slidesPerGroup // next
              ) {
                nextNextIndex = 0
                setSpring(nextNextIndex)
              } else {
                set(() => ({
                  to: {
                    transform: `translate3d(${
                      dragDeltaX - deltaX(currentIndex)
                    }px,0,0)`
                  },
                  config: {
                    tension: 200
                  }
                }))
              }
            } else {
              if (down && loop && xDir > 0 && nextPrevIndex === -2) {
                // prev
                nextPrevIndex = all - duplicatedSlidesCount * 2 - 1
                setSpring(nextPrevIndex)
              } else if (
                down &&
                loop &&
                xDir < 0 &&
                nextNextIndex === all - duplicatedSlidesCount * 2 + 1 // next
              ) {
                nextNextIndex = 0
                setSpring(nextNextIndex)
              } else {
                set(() => ({
                  to: {
                    transform: `translate3d(${
                      dragDeltaX - deltaX(currentIndex)
                    }px,0,0)`
                  },
                  config: {
                    tension: 200
                  }
                }))
              }
            }
          },
          onClickCapture: ({ event }) => {
            if (hasDragged.current) {
              event.preventDefault()
              event.stopPropagation()
            }
          },
          onMouseDown: ({ event }) => {
            event.preventDefault()
          }
        },
        {
          drag: {
            filterTaps: true,
            axis: 'x',
            swipeDistance: swipeThreshold,
            swipeVelocity,
            ...(!loop
              ? {
                  bounds:
                    currentIndex === 0
                      ? {
                          right: 0,
                          ...(currentIndex === slideCount - slidesPerView
                            ? { left: 0 }
                            : {})
                        }
                      : currentIndex === slideCount - slidesPerView
                      ? {
                          left: 0,
                          ...(currentIndex === 0 ? { right: 0 } : {})
                        }
                      : {},
                  rubberband: true
                }
              : {})
          }
        }
      )

      useEffect(() => {
        if (autoplay && loop) {
          timer.current = new SafeSetInterval(() => {
            handleNext()
          }, interval).start()
        }
        return () => {
          if (timer.current) {
            timer.current.stop()
          }
        }
      }, [loop, currentIndex, handleNext, autoplay, interval])

      const shouldRenderDot = useCallback(
        i => {
          if (loop) {
            if (R.modulo(i, slidesPerGroup) !== 0) return false
          } else {
            // display a single dot when slidesPerView >= slideCount
            if (slidesPerView >= slideCount && i > 0) return false
            if (slidesPerView < slideCount && i > all - slidesPerView) {
              return false
            } else {
              if (
                R.modulo(i, slidesPerGroup) !== 0 &&
                i !== all - slidesPerView
              )
                return false
            }
          }
          return true
        },
        [all, loop, slideCount, slidesPerGroup, slidesPerView]
      )

      const activeIndexCandidates = useMemo(
        () =>
          R.range(0, slideCount).filter((slideIndex, i) => {
            return shouldRenderDot(slideIndex)
          }),
        [slideCount, shouldRenderDot]
      )

      const currentPage = R.indexOf(slideIndex, activeIndexCandidates) + 1

      const totalPage = R.length(activeIndexCandidates)

      const isPrevDisabled = currentPage === 1

      const isNextDisabled = totalPage === currentPage

      const NextButton = useMemo(
        () =>
          renderNextBtn ? (
            React.cloneElement(renderNextBtn(isNextDisabled), {
              onClick: handleNext
            })
          ) : (
            <NextBtn onClick={handleNext}>
              <SvgIcon svg={<NextSvg />} />
            </NextBtn>
          ),
        [handleNext, isNextDisabled, renderNextBtn]
      )

      const PrevButton = useMemo(
        () =>
          renderPrevBtn ? (
            React.cloneElement(renderPrevBtn(isPrevDisabled), {
              onClick: handlePrev
            })
          ) : (
            <PrevBtn onClick={handlePrev}>
              <SvgIcon svg={<PrevSvg />} />
            </PrevBtn>
          ),
        [handlePrev, isPrevDisabled, renderPrevBtn]
      )

      const Dots = useMemo(() => {
        if (renderDot) {
          const DotGroup = renderDotGroup || (() => <div />)
          const DotGroupWithChildren = ({ children }) =>
            React.cloneElement(DotGroup(), { children })
          return (
            <DotGroupWithChildren>
              {R.times(i => {
                if (!R.includes(i, activeIndexCandidates)) return null
                const UserDot = renderDot(i === slideIndex, i)
                const Dot = React.cloneElement(UserDot, {
                  onClick: () => {
                    handleDotClick(i)
                  },
                  key: i
                })
                return Dot
              }, slideCount)}
            </DotGroupWithChildren>
          )
        } else {
          return (
            <DotsWrapper>
              {R.times(i => {
                if (!R.includes(i, activeIndexCandidates)) return null
                return (
                  <Dot
                    key={i}
                    active={i === slideIndex}
                    onClick={() => {
                      handleDotClick(i)
                    }}
                  />
                )
              }, slideCount)}
            </DotsWrapper>
          )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        loop,
        slideCount,
        slideIndex,
        JSON.stringify(activeIndexCandidates),
        renderDot,
        slidesPerView,
        slidesPerGroup,
        handleDotClick,
        renderDotGroup
      ])

      useEffect(() => {
        if (isInited) {
          onPageChange &&
            onPageChange(
              R.indexOf(slideIndex, activeIndexCandidates) + 1,
              R.length(activeIndexCandidates)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        slideIndex,
        JSON.stringify(activeIndexCandidates),
        onPageChange,
        isInited
      ])

      useImperativeHandle(ref, () => ({
        handleNext,
        handlePrev
      }))

      useEffect(() => {
        const remainder = R.modulo(currentIndex, slidesPerGroup)

        if (remainder !== 0) {
          setCurrentIndex(currentIndex - remainder)
        }
      }, [currentIndex, slidesPerGroup])

      useEffect(() => {
        if (isInited) {
          const activeSlideIndexes =
            slidesPerView > 1
              ? R.range(currentIndex, currentIndex + slidesPerView)
              : [currentIndex]

          onSlideActive && onSlideActive(activeSlideIndexes)
        }
      }, [currentIndex, slidesPerView, isInited, onSlideActive])

      return (
        <>
          <Container ref={rectRef} className={className}>
            <Slides {...bind()} style={props}>
              {R.addIndex(R.map)(
                (slide, i) => (
                  <SlideContainer
                    key={i}
                    slidesPerView={slidesPerView}
                    style={{
                      width: slideWidth,
                      marginRight: spaceBetween
                    }}
                  >
                    {slide}
                  </SlideContainer>
                ),
                slides
              )}
            </Slides>
          </Container>
          {prevNextBtn && (
            <>
              {PrevButton}
              {NextButton}
            </>
          )}
          {dots && Dots}
        </>
      )
    }
  )
)

const ConditionallyRenderedCarousel = React.forwardRef((props, ref) =>
  isExist(props.slides) ? <Carousel ref={ref} {...props} /> : null
)

export default ConditionallyRenderedCarousel
