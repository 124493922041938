import Router from 'next/router'
import { call, put } from 'redux-saga/effects'

import { i18nInstance as i18n } from '../../../i18n'
import * as charityApi from '../../../services'
import { slugify } from '../../../utils/urlUtils'
import { setFeedbackText } from '../../app/actions'
import { memberAuthorizer } from '../../auth/saga/authSaga'
import {
  getPartnerOrderInfoSucceeded,
  patchPartnerOrderSucceeded
} from '../actions'

const handleGetPartnerOrderInfoErrorWorker = memberAuthorizer(function* (
  token,
  orderNo
) {
  try {
    const {
      orders: {
        [orderNo]: {
          status,
          project: { projectNo, name }
        }
      }
    } = yield call(charityApi.getOrderByNo, orderNo, token)
    if (status === 1) {
      Router.push(
        `/account/donationDetail?orderNo=${orderNo}`,
        `/account/donation/${orderNo}`
      )
    } else {
      Router.push(`/project/${projectNo}/${slugify(name)}`)
    }
  } catch (err) {
    console.log(err)
    Router.push('/_error', '/404')
  }
})

export const getPartnerOrderInfoWorker = memberAuthorizer(function* (
  token,
  action
) {
  const { orderNo } = action.payload
  try {
    const response = yield call(charityApi.getPartnerOrderInfo, orderNo, token)
    yield put(getPartnerOrderInfoSucceeded(response))
  } catch (err) {
    console.log(err)
    if (err.message >= 400 && err.message < 500) {
      yield call(handleGetPartnerOrderInfoErrorWorker, orderNo)
    } else {
      Router.push('/_error', '/404')
    }
  }
})

export const patchPartnerOrderWorker = memberAuthorizer(function* (
  token,
  action
) {
  try {
    const { orderNo, charityAmount } = action.payload
    const { paymentUrl } = yield call(
      charityApi.patchPartnerOrder,
      orderNo,
      charityAmount,
      token
    )
    yield put(patchPartnerOrderSucceeded(paymentUrl))
  } catch (err) {
    if (err.message === '500') {
      yield put(setFeedbackText(i18n.t('errorMsg.500')))
    }
    console.log(err)
  }
})

// eslint-disable-next-line require-yield
export const redirectToWalletWorker = memberAuthorizer(function* (
  token,
  action
) {
  try {
    const { paymentUrl } = action.payload
    window.location.href = paymentUrl
  } catch (err) {
    console.log(err)
  }
},
true)
