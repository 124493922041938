import { createActions } from 'redux-actions'

export const {
  setIsAppview,
  setIsInitialRender,
  setShouldBackHome,
  checkWebviewRequest,
  checkWebviewSucceeded,
  hideWebviewHeaderRequest,
  hideWebviewHeaderSucceeded,
  toggleBindPhoneScreen,
  toggleLoadingBackBtn,
  initBindPhoneCounter,
  updateBindPhoneCounter,
  resetBindPhoneCounter,
  goTo01PageRequest,
  goTo01ArticleRequest,
  openNativeInAppBrowserRequest,
  dismissWebviewRequest,
  showShareDialog,
  getFrontendSettingRequest,
  getFrontendSettingSucceeded,
  getPromoItemRequest,
  getPromoItemSucceeded,
  hideHeader,
  setFeedbackText,
  dismissFeedback,
  toggleDonationItemDrawer,
  generateShareMsg,
  setShowAppviewSearchOverlay,
  setShowErrorPage
} = createActions({
  SET_IS_APPVIEW: isAppview => ({ isAppview }),
  SET_IS_INITIAL_RENDER: isInitialRender => ({ isInitialRender }),
  SET_SHOULD_BACK_HOME: shouldBackHome => ({ shouldBackHome }),
  CHECK_WEBVIEW_REQUEST: undefined,
  CHECK_WEBVIEW_SUCCEEDED: isWebview => ({ isWebview }),
  HIDE_WEBVIEW_HEADER_REQUEST: undefined,
  HIDE_WEBVIEW_HEADER_SUCCEEDED: undefined,
  TOGGLE_BIND_PHONE_SCREEN: isShown => ({ isShown }),
  TOGGLE_LOADING_BACK_BTN: isShown => ({ isShown }),
  INIT_BIND_PHONE_COUNTER: undefined,
  UPDATE_BIND_PHONE_COUNTER: count => ({ count }),
  RESET_BIND_PHONE_COUNTER: undefined,
  GO_TO_01_PAGE_REQUEST: link => ({ link }),
  GO_TO_01_ARTICLE_REQUEST: articleId => ({ articleId }),
  OPEN_NATIVE_IN_APP_BROWSER_REQUEST: (url, callbackFn, opts) => ({
    url,
    callbackFn,
    opts
  }),
  DISMISS_WEBVIEW_REQUEST: undefined,
  SHOW_SHARE_DIALOG: (message, url, title, callbackFn) => ({
    message,
    url,
    title,
    callbackFn
  }),
  GET_FRONTEND_SETTING_REQUEST: undefined,
  GET_FRONTEND_SETTING_SUCCEEDED: frontendSetting => ({ frontendSetting }),
  GET_PROMO_ITEM_REQUEST: undefined,
  GET_PROMO_ITEM_SUCCEEDED: promoItems => ({ promoItems }),
  // ui
  HIDE_HEADER: isHeaderHidden => ({ isHeaderHidden }),
  SET_FEEDBACK_TEXT: (text, type = null) => ({ text, type }),
  DISMISS_FEEDBACK: undefined,
  TOGGLE_DONATION_ITEM_DRAWER: undefined,
  GENERATE_SHARE_MSG: shareMsg => ({ shareMsg }),
  SET_SHOW_APPVIEW_SEARCH_OVERLAY: show => ({
    show
  }),
  SET_SHOW_ERROR_PAGE: (show, errorCode) => ({ show, errorCode })
})
