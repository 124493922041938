import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { resizeImg } from '../../utils/commonUtils'
import { getFontSize } from '../../utils/cssUtils'
import mq from '../../utils/mediaQuery'
import Avatar from '../Avatar'
import OrgLinkWrapper from '../OrgLinkWrapper'

const StyledAvatar = styled(Avatar)`
  width: 64px;
  height: 64px;
  ${({ type }) =>
    type === 'listing' &&
    css`
      width: 32px;
      height: 32px;
      ${mq.mobile} {
        width: 64px;
        height: 64px;
      }
    `}
`
const AvatarNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  ${mq.mobile} {
    align-self: flex-end;
  }
`
const AvatarName = styled.div`
  position: relative;
  width: 100%;
  padding-left: 8px;
  max-height: 1.43em;
  overflow: hidden;
  font-size: ${getFontSize('body1')};
  line-height: 1.43;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${({ listView }) => (listView ? '0' : '4px')};
  ${mq.mobile} {
    align-self: flex-end;
    margin-bottom: 4px;
  }
`
const Wrapper = styled.div`
  pointer-events: none;
  height: 64px;
  width: 100%;
  margin-top: -48px;
  margin-bottom: 12px;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${mq.tabletMobile} {
    margin-bottom: 8px;
  }
  ${({ listView }) =>
    listView &&
    css`
      height: auto;
      margin: 0;
      width: 100%;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      ${mq.mobile} {
        margin-top: -48px;
      }
    `}
`

export const MemberAvatarInfo = ({ listView, avatar, name }) => {
  const { color } = useContext(ThemeContext)
  return (
    <Wrapper listView={listView}>
      <StyledAvatar
        type={listView && 'listing'}
        src={resizeImg(avatar, 96)}
        borderColor={color.border}
      />
      <AvatarNameWrapper>
        <AvatarName>
          <span>{name}</span>
        </AvatarName>
      </AvatarNameWrapper>
    </Wrapper>
  )
}

export const OrgAvatarInfo = ({
  listView,
  avatar,
  orgNo,
  orgName,
  onLinkClick
}) => {
  const { color } = useContext(ThemeContext)

  return (
    <Wrapper listView={listView}>
      <OrgLinkWrapper
        organizationNo={orgNo}
        organizationName={orgName}
        onLinkClick={onLinkClick}
      >
        <StyledAvatar
          type={listView && 'listing'}
          src={resizeImg(avatar, 96)}
          borderColor={color.border}
        />
      </OrgLinkWrapper>
      <AvatarNameWrapper>
        <AvatarName>
          <OrgLinkWrapper
            organizationNo={orgNo}
            organizationName={orgName}
            onLinkClick={onLinkClick}
          >
            <span>{orgName}</span>
          </OrgLinkWrapper>
        </AvatarName>
      </AvatarNameWrapper>
    </Wrapper>
  )
}
