import Router from 'next/router'
import * as R from 'ramda'
import { call } from 'redux-saga/effects'

import { isSsr } from '../../../utils/commonUtils'
import { handleSyncAsync } from '../../../utils/reduxUtils'
import { getProjectDetailWorker } from '../../project/saga/projectSaga'

// public
const getFundraisingCreatePage = function* (action) {
  const { projectNo } = action.payload

  const response = yield call(getProjectDetailWorker, projectNo)

  const { startTime, endTime } = R.path(
    ['entities', 'projects', projectNo],
    response
  )

  if (
    startTime > new Date().getTime() ||
    (endTime && endTime < new Date().getTime())
  ) {
    if (isSsr) {
      const err = new Error()
      err.code = 'ENOENT'
      throw err
    } else {
      Router.push('/')
    }
  }
}

export const getFundraisingCreatePageWorker = handleSyncAsync(
  getFundraisingCreatePage
)
