import { normalize } from 'normalizr'
import * as R from 'ramda'
import { all, call, put, select } from 'redux-saga/effects'

import * as charityApi from '../../../services'
import { isSsr } from '../../../utils/commonUtils'
import { handleSyncAsync } from '../../../utils/reduxUtils'
import {
  getDonationStatsRequest,
  getDonationStatsSucceeded,
  getHighlightedProjectsSucceeded,
  getProjectDetailSucceeded,
  getProjectFundraisingEventRankingSucceeded,
  getPromoWidgetSucceeded,
  getRandomProjectsSucceeded
} from '../actions'
import {
  donationStatsSchema,
  projectDetailSchema,
  projectListSchema,
  promoWidgetProjectsSchema
} from '../schema'
import { getRandomProjectsNos } from '../selectors'

export const getProjectDetailWorker = function* (
  projectNo,
  previewToken,
  throwErr = false
) {
  try {
    const response = yield call(
      charityApi.getProjectByNo,
      projectNo,
      previewToken
    )

    const normalized = yield call(normalize, response, projectDetailSchema)

    yield put(getProjectDetailSucceeded(normalized))

    return normalized
  } catch (err) {
    console.log(err)
    if (throwErr) throw err
  }
}

export const getHighlightedProjectsWorker = function* () {
  try {
    const response = yield call(charityApi.getHighlightedProjects)

    const normalized = yield call(normalize, response, projectListSchema)

    yield put(getHighlightedProjectsSucceeded(normalized))

    return normalized
  } catch (err) {
    console.log(err)
  }
}

export const getRandomProjectsWorker = function* () {
  try {
    const randomProjectNos = yield select(getRandomProjectsNos)

    if (R.isEmpty(randomProjectNos)) {
      const response = yield call(charityApi.getRandomProjects, 18, true)
      const normalized = yield call(normalize, response, projectListSchema)
      yield put(getRandomProjectsSucceeded(normalized))
      return normalized
    } else {
      return {
        result: randomProjectNos
      }
    }
  } catch (err) {
    console.log(err)
  }
}

export function* requestDonationStats(projectNos) {
  projectNos = R.reject(R.isNil, projectNos)
  if (!isSsr && !R.isEmpty(projectNos)) {
    yield put(getDonationStatsRequest(projectNos))
  }
}

export function* getDonationStatsWorker(action) {
  try {
    const response = yield call(
      charityApi.getDonationStats,
      action.payload.projectNos
    )

    const normalized = yield call(normalize, response, donationStatsSchema)

    yield put(getDonationStatsSucceeded(normalized))
  } catch (err) {
    console.log(err)
  }
}

const getProjectFundraisingEventRanking = function* (action) {
  const { projectNo, type, sortBy, sortDirection } = action.payload
  const ranking = yield call(
    charityApi.getProjectFundraisingEventRanking,
    projectNo,
    type,
    sortBy,
    sortDirection
  )
  yield put(getProjectFundraisingEventRankingSucceeded(projectNo, ranking))
}

export const getProjectFundraisingEventRankingWorker = handleSyncAsync(
  getProjectFundraisingEventRanking
)

export const getPromoWidgetWorker = function* () {
  try {
    const response = yield call(charityApi.getPromoWidget)

    const {
      totalDonation: totalDonation1,
      result: result1 = []
    } = response?.[0]

    const {
      totalDonation: totalDonation2,
      result: result2 = []
    } = response?.[1]

    const normalized1 = yield call(
      normalize,
      result1,
      promoWidgetProjectsSchema
    )

    const normalized2 = yield call(
      normalize,
      result2,
      promoWidgetProjectsSchema
    )

    yield all([
      put(getPromoWidgetSucceeded(1, totalDonation1, normalized1)),
      put(getPromoWidgetSucceeded(2, totalDonation2, normalized2))
    ])

    return [normalized1, normalized2]
  } catch (err) {
    console.log(err)
  }
}
