import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { ERROR_ICON_TYPE } from '../../constants'
import NotFoundIcon from '../../public/static/images/icon-404-2.svg'
import PageUpdatingSvg from '../../public/static/images/icon-page-updating.svg'
import DeleteIcon from '../../public/static/images/icon-popup-delete-2.svg'
import ServerErrorIcon from '../../public/static/images/img-500.svg'
import BindPhoneIcon from '../../public/static/images/img-bindphone.svg'
import EmptyDonation from '../../public/static/images/img-donation-empty.svg'
import EmptyFundraising from '../../public/static/images/img-fundraising-empty.svg'
import NotLoginSvg from '../../public/static/images/img-not-login.svg'
import PaymentFailIcon from '../../public/static/images/img-payment-fail.svg'
import TimeoutIcon from '../../public/static/images/img-popup-timeout.svg'
import EmptyIcon from '../../public/static/images/img-search-empty.svg'
import EmptyTicket from '../../public/static/images/img-ticket-empty.svg'
import { getColor, getFontSize } from '../../utils/cssUtils'

const {
  BIND_PHONE,
  TIMEOUT,
  NO_RESULT,
  NO_LOGIN,
  PAYMENT_FAIL,
  PAGE_UPDATING,
  DELETE_DRAFT,
  NO_FUNDRAISING_EVENTS,
  NO_TICKETS,
  NO_DONATIONS
} = ERROR_ICON_TYPE

const Icon = styled.div`
  width: 100%;
  height: 100%;
`
const IconContainer = styled.div`
  width: ${({ large }) => (large ? '300px' : '250px')};
  margin-bottom: 24px;
`
const Wrapper = styled.div`
  max-width: 768px;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`
const Title = styled.h1`
  max-width: 320px;
  font-size: ${getFontSize('headline')};
  font-weight: 600;
  margin-bottom: ${({ hasDescOrCaption }) =>
    hasDescOrCaption ? '8px' : '24px'};
  color: ${getColor('black')};
  text-align: center;
`
const Description = styled.p`
  max-width: 320px;
  font-size: ${getFontSize('body2')};
  color: ${getColor('grey')};
  margin-bottom: ${({ hasCaption }) => (hasCaption ? '16px' : '24px')};
  text-align: center;
`
const Caption = styled.span`
  max-width: 320px;
  font-size: ${getFontSize('body1')};
  color: ${getColor('lightGrey')};
  margin-bottom: 24px;
  text-align: center;
`
const renderArrayOrStr = R.when(
  R.pipe(R.type, R.equals('Array')),
  R.addIndex(R.map)((val, i) => (
    <span
      css={`
        display: block;
      `}
      key={i}
    >
      {val}
    </span>
  ))
)

const ErrorInfo = ({
  type,
  title,
  description,
  caption,
  children,
  ...props
}) => (
  <Wrapper {...props}>
    <IconContainer large={R.either(R.equals(404), R.equals(500))(type)}>
      <Icon
        as={R.cond([
          [R.equals(BIND_PHONE), R.always(BindPhoneIcon)],
          [R.equals(TIMEOUT), R.always(TimeoutIcon)],
          [R.equals(NO_RESULT), R.always(EmptyIcon)],
          [R.equals(NO_LOGIN), R.always(NotLoginSvg)],
          [R.equals(PAYMENT_FAIL), R.always(PaymentFailIcon)],
          [R.equals(PAGE_UPDATING), R.always(PageUpdatingSvg)],
          [R.equals(DELETE_DRAFT), R.always(DeleteIcon)],
          [R.equals(NO_FUNDRAISING_EVENTS), R.always(EmptyFundraising)],
          [R.equals(NO_TICKETS), R.always(EmptyTicket)],
          [R.equals(NO_DONATIONS), R.always(EmptyDonation)],
          [R.equals(404), R.always(NotFoundIcon)],
          [R.equals(500), R.always(ServerErrorIcon)]
        ])(type)}
      />
    </IconContainer>
    <Title hasDescOrCaption={!R.isNil(description) || !R.isNil(caption)}>
      {renderArrayOrStr(title)}
    </Title>
    {description && (
      <Description hasCaption={!R.isNil(caption)}>
        {renderArrayOrStr(description)}
      </Description>
    )}
    {caption && <Caption>{renderArrayOrStr(caption)}</Caption>}
    {children}
  </Wrapper>
)

export default ErrorInfo
